import React, { createContext, useContext, useEffect, useState } from 'react';
import {
    onWebAppCreateSpace,
    onWebAppAssignSpace,
    onWebAppUnassignSpace,
    onWebAppDeleteSpace,
    onWebAppEditSpace,
    onWebAppDuplicateSpace,
} from '../../tracking/trackers';
import { useFolders } from '../../screens/ReceiptsHome/folderContext';
import FolderService from '../folders/service';
import { ISpaceItem, IUserTag } from '../receipts/types';
import { useSpacesWithItems } from '../../hooks/useSpacesWithItems';
import { useSpacesStore } from '../../providers/IndexedDBProvider';
import { CompoundCategoryAndCategoryGroupId, Space } from './types';
import SpaceService from './service';
import { getSpacesForDisplay } from './helper/getSpacesForDisplay';

export interface SpacesState {
    spaces: Space[];
    reorderSpaces: (ids: (string | number)[]) => void;
    isLoadingSpaces: boolean;
    editing: boolean;
    setEditing: React.Dispatch<React.SetStateAction<boolean>>;
    createSpace: (newSpace: {
        name: string;
        icon?: string;
        categoryIds?: string[];
    }) => Promise<Space | undefined>;
    assignCategoryToSpace: (
        itemId: CompoundCategoryAndCategoryGroupId,
        sourceSpace: Space,
        targetSpace: Space,
        itemIndexInTargetSpace: number
    ) => Promise<void>;
    unassignCategoryFromSpace: (
        itemId: CompoundCategoryAndCategoryGroupId,
        sourceSpace: Space,
        targetSpace: Space,
        itemIndexInTargetSpace: number
    ) => Promise<void>;
    editSpace: (spaceId: number | string, name: string) => Promise<void>;
    deleteSpace: (spaceId: number) => Promise<void>;
    copySpace: (space: Space) => Promise<number | undefined>;
    refreshSpaces: () => Promise<void>;
    reorderFolder: (
        itemId: CompoundCategoryAndCategoryGroupId,
        newIndex: number,
        targetSpace: Space,
        sourceSpace?: Space
    ) => Promise<void>;
    groupCategoriesBySpace: (categories: IUserTag[]) => {
        [spaceId: string]: IUserTag[];
    };
}

const SpacesContext = createContext<SpacesState>({
    spaces: [],
    reorderSpaces: () => {},
    isLoadingSpaces: false,
    editing: false,
    setEditing: () => {},
    createSpace: () => Promise.resolve(undefined),
    assignCategoryToSpace: () => Promise.resolve(),
    unassignCategoryFromSpace: () => Promise.resolve(),
    editSpace: () => Promise.resolve(),
    deleteSpace: () => Promise.resolve(),
    refreshSpaces: () => Promise.resolve(),
    copySpace: () => Promise.resolve(undefined),
    reorderFolder: () => Promise.resolve(),
    groupCategoriesBySpace: () => ({}),
});

export const SpacesProvider = ({ children }: { children: React.ReactNode }) => {
    const token = localStorage.getItem('token');
    const { spaces: spacesInStore } = useSpacesStore();

    const {
        folders,
        setFolders,
        categoryGroups,
        setCategoryGroups,
        refresh: refreshFolders,
    } = useFolders();

    const [spacesNotForDisplay, setSpaces] = useState<Space[]>([]);
    const spaces = getSpacesForDisplay(
        spacesNotForDisplay,
        categoryGroups,
        folders
    );

    const [isLoadingSpaces, setIsLoadingSpaces] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);

    const spacesWithItems = useSpacesWithItems({ spaces });

    const getSpaces = () => {
        setIsLoadingSpaces(true);
        return SpaceService.getSpaces()
            .then((response) => {
                if (response.data) {
                    setSpaces(response.data);
                } else {
                    console.error(
                        'Error in fetching spaces after response',
                        response
                    );
                }
            })
            .catch((e) => {
                console.error('Error in fetching spaces', e);
            })
            .finally(() => {
                setIsLoadingSpaces(false);
            });
    };

    useEffect(() => {
        if (!spacesInStore) return;
        if (spacesInStore.length > 0) {
            setSpaces(spacesInStore);
            setIsLoadingSpaces(false);
            return;
        }
        getSpaces();
    }, [token, spacesInStore]);

    const createSpace = async (
        newSpace: { name: string; icon?: string },
        skipStateUpdate?: boolean
    ) => {
        onWebAppCreateSpace({
            name: newSpace.name,
        });

        const response = await SpaceService.createSpace(
            newSpace.name,
            newSpace.icon
        );
        const spaceId = response?.data?.id;
        if (spaceId) {
            if (!skipStateUpdate) {
                setSpaces((_spaces) => [
                    ..._spaces,
                    {
                        ...newSpace,
                        id: spaceId,
                        categoryIds: [],
                    },
                ]);
            }
        } else if (response.error) {
            throw response.error;
        }
        return response.data;
    };

    const copySpace = async (space: Space) => {
        onWebAppDuplicateSpace({ name: space.name });

        const spaceId = space.id as number;

        try {
            const response = await SpaceService.copySpace(spaceId);
            if (response.error) {
                throw response.error;
            }
            const copiedSpace = response?.data;
            if (!copiedSpace) {
                return;
            }

            await refreshFolders();
            setSpaces((_userSpaces) => [..._userSpaces, copiedSpace]);

            const newSpaceId = response.data?.id;
            return typeof newSpaceId === 'number' ? newSpaceId : undefined;
        } catch (e) {
            console.error('Error in copying the space', e);
            throw e;
        }
    };

    const assignCategoryToSpace = async (
        itemId: CompoundCategoryAndCategoryGroupId,
        sourceSpace: Space,
        targetSpace: Space,
        itemIndexInTargetSpace: number
    ) => {
        onWebAppAssignSpace({ name: targetSpace.name });

        locallyReorderFolder(
            itemId,
            itemIndexInTargetSpace,
            targetSpace,
            sourceSpace
        );

        setSpaces((_spaces) => {
            const newSpaces = [..._spaces];

            const oldSpaceIndex = newSpaces.findIndex(
                (_space) => _space.id === sourceSpace.id
            );
            const newSpaceIndex = newSpaces.findIndex(
                (_space) => _space.id === targetSpace.id
            );

            if (oldSpaceIndex !== -1) {
                newSpaces[oldSpaceIndex] = {
                    ...newSpaces[oldSpaceIndex],
                    categoryIds: newSpaces[oldSpaceIndex].categoryIds?.filter(
                        (categoryId) => categoryId !== itemId
                    ),
                };
            }

            if (newSpaceIndex !== -1) {
                newSpaces[newSpaceIndex] = {
                    ...newSpaces[newSpaceIndex],
                    categoryIds: [
                        ...(newSpaces[newSpaceIndex]?.categoryIds || []),
                        itemId,
                    ],
                };
            }
            return newSpaces;
        });

        const isFolder = folders.some((f) => f.tag_id === itemId);
        SpaceService.assignCategoryToSpace(
            targetSpace.id as number,
            isFolder ? [itemId as string] : [],
            !isFolder ? [itemId.toString()] : []
        ).finally(() => {
            reorderFolderInDb(
                itemId,
                itemIndexInTargetSpace,
                targetSpace,
                sourceSpace
            );
        });
    };

    const unassignCategoryFromSpace = async (
        itemId: CompoundCategoryAndCategoryGroupId,
        sourceSpace: Space,
        targetSpace: Space,
        itemIndexInTargetSpace: number
    ) => {
        onWebAppUnassignSpace({ name: sourceSpace.name });
        locallyReorderFolder(
            itemId,
            itemIndexInTargetSpace,
            targetSpace,
            sourceSpace
        );

        setSpaces((_spaces) => {
            const newSpaces = [..._spaces];

            const oldSpaceIndex = newSpaces.findIndex((_space) =>
                _space.categoryIds?.includes(itemId)
            );

            if (oldSpaceIndex !== -1) {
                newSpaces[oldSpaceIndex] = {
                    ...newSpaces[oldSpaceIndex],
                    categoryIds: newSpaces[oldSpaceIndex].categoryIds?.filter(
                        (categoryId) => categoryId !== itemId
                    ),
                };
            }
            return [...newSpaces];
        });

        const isFolder = folders.some((f) => f.tag_id === itemId);
        SpaceService.unassignCategoryToSpace(
            sourceSpace.id as number,
            isFolder ? [itemId as string] : [],
            !isFolder ? [itemId.toString()] : []
        ).finally(() => {
            reorderFolderInDb(
                itemId,
                itemIndexInTargetSpace,
                targetSpace,
                sourceSpace
            );
        });
    };

    const editSpace = (spaceId: number | string, name: string) => {
        if (!spaceId || !name) return Promise.reject();

        onWebAppEditSpace({
            name,
        });

        return SpaceService.updateSpace(spaceId, name)
            .then((response) => {
                if (response.data)
                    setSpaces((_spaces) => {
                        const newSpaces = [..._spaces];
                        const spaceIndex = newSpaces.findIndex(
                            (_space) => _space.id === spaceId
                        );
                        if (spaceIndex === -1) return newSpaces;
                        newSpaces[spaceIndex] = {
                            ...newSpaces[spaceIndex],
                            name,
                        };
                        return [...newSpaces];
                    });
            })
            .catch((e) => {
                console.error('Error in editing the space', e);
            });
    };

    const deleteSpace = async (spaceId: number) => {
        onWebAppDeleteSpace({
            id: spaceId,
        });

        await SpaceService.deleteSpace(spaceId);
        setSpaces((_spaces) => {
            return _spaces.filter((space) => space.id !== spaceId);
        });
    };

    // used for assigning a folder to a new space
    const getFoldersInNewOrder = (
        itemId: CompoundCategoryAndCategoryGroupId,
        newIndex: number, // "newIndex" should not account for the All folder
        targetSpace: Space,
        sourceSpace?: Space
    ): ISpaceItem[] => {
        const targetSpaceItems =
            spacesWithItems.find((s) => s.id === targetSpace.id)?.items ?? [];
        const sourceSpaceItems = sourceSpace
            ? spacesWithItems.find((s) => s.id === sourceSpace.id)?.items ?? []
            : targetSpaceItems;
        const spaceItemsWithoutTargetFolder = targetSpaceItems.filter(
            (item) => {
                switch (item.type) {
                    case 'tag':
                        return item.tag_id !== itemId;
                    case 'group':
                        return item.id !== itemId;
                    default:
                        return false;
                }
            }
        );

        const targetSpaceItem = sourceSpaceItems.find((item) => {
            switch (item.type) {
                case 'tag':
                    return item.tag_id === itemId;
                case 'group':
                    return item.id === itemId;
                default:
                    return false;
            }
        });

        if (!targetSpaceItem) {
            console.error('Could not find target item in space');
            return sourceSpaceItems;
        }
        const reorderedFolders = [
            ...spaceItemsWithoutTargetFolder.slice(0, newIndex),
            targetSpaceItem,
            ...spaceItemsWithoutTargetFolder.slice(newIndex),
        ];
        const withNewSpaceAndOrder = reorderedFolders.map((f, i) => {
            return {
                ...f,
                order: i,
                space_id:
                    targetSpace.id && typeof targetSpace.id === 'number'
                        ? targetSpace.id
                        : undefined,
            };
        });
        return withNewSpaceAndOrder;
    };

    const reorderFolderInDb = async (
        itemId: CompoundCategoryAndCategoryGroupId,
        newIndex: number,
        targetSpace: Space,
        sourceSpace?: Space
    ) => {
        const foldersInNewOrder = getFoldersInNewOrder(
            itemId,
            newIndex,
            targetSpace,
            sourceSpace
        );
        await FolderService.reorderFolders(foldersInNewOrder);
    };

    // Handles reordering of folders within a space
    const locallyReorderFolder = (
        itemId: CompoundCategoryAndCategoryGroupId,
        newIndex: number,
        targetSpace: Space,
        sourceSpace?: Space
    ) => {
        const foldersInNewOrder = getFoldersInNewOrder(
            itemId,
            newIndex,
            targetSpace,
            sourceSpace
        );

        const newOrderedFolders = foldersInNewOrder.filter(
            (f) => f.type === 'tag'
        );
        const newOrderedCategoryGroups = foldersInNewOrder.filter(
            (f) => f.type === 'group'
        );

        const newItemIds = foldersInNewOrder
            .map((f) => {
                switch (f.type) {
                    case 'tag':
                        return f.tag_id;
                    case 'group':
                        return f.id;
                    default:
                        return '';
                }
            })
            .filter(Boolean) as (string | number)[];

        // @ts-ignore - since these are folders, tag_id is defined
        setFolders((allFolders) => {
            return [
                // important when moving between groups:
                ...allFolders.filter(
                    ({ tag_id }) => !newItemIds.includes(tag_id)
                ),
                ...newOrderedFolders,
            ];
        });
        // @ts-ignore - since these are groups, id is defined
        setCategoryGroups((allCgs) => {
            return [
                ...allCgs.filter(({ id }) => !newItemIds.includes(id)),
                ...newOrderedCategoryGroups,
            ];
        });
    };

    const reorderFolder = async (
        itemId: CompoundCategoryAndCategoryGroupId,
        newIndex: number, // "newIndex" should not account for the All folder
        targetSpace: Space,
        sourceSpace?: Space
    ) => {
        locallyReorderFolder(itemId, newIndex, targetSpace, sourceSpace);
        reorderFolderInDb(itemId, newIndex, targetSpace, sourceSpace);
    };

    const reorderSpaces = async (orderedSpaceIds: (string | number)[]) => {
        const stringSpaceIds = orderedSpaceIds.filter(
            (id) => typeof id === 'string'
        ) as number[];
        const stringSpaces = stringSpaceIds
            .map((id) => spaces.find((s) => s.id === id))
            .filter(Boolean) as Space[];
        const numberSpaceIds = orderedSpaceIds.filter(
            (id) => typeof id === 'number'
        ) as number[];
        setSpaces((prev) => {
            const reorderedSpaces = numberSpaceIds
                .map((spaceId, i) => {
                    const prevSpace = prev.find(
                        (space) => space.id === spaceId
                    );
                    return {
                        ...prevSpace,
                        order: i,
                    };
                })
                .filter(Boolean) as Space[];
            return stringSpaces.concat(reorderedSpaces);
        });
        await SpaceService.reorderSpaces(numberSpaceIds);
    };

    const groupCategoriesBySpace = (categories: IUserTag[]) => {
        return categories.reduce(
            (acc: { [spaceId: string]: IUserTag[] }, curr) => {
                const spaceId = curr?.space_id || 'Home';
                if (acc[spaceId]) {
                    acc[spaceId].push(curr);
                } else {
                    acc[spaceId] = [curr];
                }
                return acc;
            },
            {}
        );
    };

    return (
        <SpacesContext.Provider
            value={{
                spaces,
                reorderSpaces,
                editing,
                isLoadingSpaces,
                setEditing,
                createSpace,
                copySpace,
                assignCategoryToSpace,
                unassignCategoryFromSpace,
                editSpace,
                deleteSpace,
                refreshSpaces: getSpaces,
                reorderFolder,
                groupCategoriesBySpace,
            }}
        >
            {children}
        </SpacesContext.Provider>
    );
};

export const useSpaces = () => {
    return useContext(SpacesContext);
};
