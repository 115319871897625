import { Buffer } from 'buffer';
import { AxiosResponse } from 'axios';
import axios from '../../util/axios';
import {
    IFilterState,
    ILineItem,
    ILoadReceiptsPageResponse,
    IScheduledState,
} from '../receipts/types';
import { IExportColumn } from '../../components/Spreadsheet/constants';
import {
    ICreateReportResponse,
    IGuestToken,
    IReportDeleteResponse,
    IReportEditResponse,
    IReportExportSignature,
    IReportListResponse,
    IReportResponse,
} from './types';

const getGuestToken = () => {
    const guestToken = localStorage.getItem('guestToken');
    if (guestToken) {
        return guestToken;
    }
    return '';
};

export default class ReportService {
    public static loadReports(page?: number): Promise<IReportListResponse> {
        return axios
            .post(
                `/shared-reviews/list?page=${page ?? 1}`,
                {},
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            )
            .then((value: AxiosResponse<IReportListResponse>) => {
                return value.data;
            });
    }

    public static getImageData(
        sharedReviewId: string | undefined,
        receipt_id: string | undefined,
        image_id: string | undefined
    ): Promise<string> {
        return axios
            .post(
                `shared-reviews/${sharedReviewId}/receipt/${receipt_id}/image/get`,
                {
                    guestToken: getGuestToken(),
                    image_id: image_id,
                },
                {
                    responseType: 'arraybuffer',
                }
            )
            .then((value) => {
                return Buffer.from(value.data, 'binary').toString('base64');
            });
    }

    public static loadReceiptsPaginated(
        sharedReviewId: string | undefined,
        pageSize?: number,
        page?: number
    ): Promise<ILoadReceiptsPageResponse> {
        return axios
            .post(
                `shared-reviews/${sharedReviewId}/receipts/get`,
                {
                    page_size: pageSize,
                    page,
                    guestToken: getGuestToken(),
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((value) => value.data);
    }

    public static loadReport(
        sharedReviewId: string | undefined
    ): Promise<IReportResponse> {
        return axios
            .post(`shared-reviews/${sharedReviewId}/get`, {
                guestToken: getGuestToken(),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((value) => value.data);
    }

    public static getLineItems(
        sharedReviewId: string,
        receiptId: string
    ): Promise<ILineItem[]> {
        const url = `shared-reviews/${sharedReviewId}/receipt/${receiptId}/line-items/get`;
        return axios
            .post(url, {
                guestToken: getGuestToken(),
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((value) => value.data.data);
    }

    public static getToken(
        sharedReviewId: string | undefined,
        passcode: string
    ): Promise<IGuestToken> {
        return axios
            .post(
                `shared-reviews/${sharedReviewId}/token/get`,
                {
                    passcode: passcode || '',
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((value) => value.data);
    }

    public static getDownload(
        sharedReviewId: string | undefined,
        fileType: string | undefined,
        includeLineItems: boolean | undefined,
        tag_ids: string[] | undefined,
        columns: IExportColumn[]
    ): Promise<string> {
        return axios
            .post(
                `shared-reviews/${sharedReviewId}/download/get`,
                {
                    guestToken: getGuestToken(),
                    file_type: fileType,
                    remove_line_items: includeLineItems === false,
                    time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    tag_ids: tag_ids,
                    columns,
                },
                {}
            )
            .then((value) => {
                return value.data.data.file_url_id;
            });
    }

    public static validateGuestToken(
        sharedReviewId: string | undefined,
        guestToken: string | null
    ): Promise<boolean> {
        return axios
            .post(
                `shared-reviews/${sharedReviewId}/validate-token/get`,
                {
                    guestToken: guestToken || '',
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((value) => value.data.data.is_valid);
    }

    public static containsOnlyNumbers(passcode: string): boolean {
        return /^\d+$/.test(passcode);
    }

    public static isPinGood(passcode: string): boolean {
        return passcode.length === 6 && this.containsOnlyNumbers(passcode);
    }

    public static createReport(
        title: string,
        passcode?: string,
        categories?: string[],
        filterState?: IFilterState,
        schedule?: IScheduledState,
        reportType?: string
    ): Promise<ICreateReportResponse> {
        if (schedule && (!schedule?.frequency || !schedule?.notify_type)) {
            schedule.enabled = false;
        }
        console.log({ reportType });
        return axios
            .post(
                'shared-reviews/create',
                {
                    title,
                    passcode: passcode,
                    tag_ids: categories || [],
                    filters: filterState || {},
                    schedule: schedule || {},
                    report_type: reportType,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then((value: AxiosResponse<ICreateReportResponse>) => value.data);
    }

    public static deleteReport(
        sharedReviewId: string
    ): Promise<IReportDeleteResponse> {
        return axios
            .post(`/shared-reviews/${sharedReviewId}/delete`, {})
            .then((value: AxiosResponse<IReportDeleteResponse>) => {
                return value.data;
            });
    }

    public static editReport({
        sharedReviewId,
        title,
        categoryIds,
        filterState,
        passcode,
        schedule,
        hasPin,
    }: {
        sharedReviewId: string;
        title?: string;
        categoryIds?: string[];
        filterState?: IFilterState;
        passcode?: string;
        schedule?: IScheduledState;
        hasPin?: boolean;
    }): Promise<IReportEditResponse> {
        const data: { [key: string]: any } = {};
        if (passcode !== undefined)
            data.passcode = passcode.length > 0 ? passcode : null;
        if (title) data.title = title;
        if (categoryIds) data.category_filters = categoryIds;
        if (filterState) data.filters = filterState;
        if (schedule) data.schedule = schedule;
        if (hasPin !== undefined) data.has_pin = hasPin;
        return axios
            .post(`/shared-reviews/${sharedReviewId}/edit`, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((value: AxiosResponse<IReportEditResponse>) => {
                return value.data;
            });
    }

    public static exportSignature(
        identifier: string | undefined,
        data: string
    ): Promise<string> {
        return axios
            .post(`/shared-reviews/export-signature`, { data, identifier }, {})
            .then((value: AxiosResponse<IReportExportSignature>) => {
                return value.data.data;
            });
    }
}
