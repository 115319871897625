import React, { useContext, useEffect, useState } from 'react';
import { init, setUserId, add } from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { AMPLITUDE_API_KEY, ENV } from '../../configuration';

interface IAmplitudeProviderProps {
    children: React.ReactNode;
}

export interface IAmplitudeContextValue {
    isInitialized: boolean;
    identify: (userId: string) => void;
}

const AmplitudeContext = React.createContext<IAmplitudeContextValue>({
    isInitialized: false,
    identify: () => {},
});

const AmplitudeProvider = (props: IAmplitudeProviderProps) => {
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    useEffect(() => {
        initAmplitude();
    }, []);

    const initAmplitude = () => {
        if (AMPLITUDE_API_KEY) {
            return init(
                AMPLITUDE_API_KEY,
                undefined,
                ENV === 'production' ? {} : {}
            ).promise.then(() => {
                console.log('amplitude initialized');
                setIsInitialized(true);

                const sessionReplayTracking = sessionReplayPlugin({
                    sampleRate: 1,
                });
                add(sessionReplayTracking);
                console.log('amplitude session initialized');
            });
        }
    };

    const identify = (userId: string) => {
        console.debug('Logging internal identifier:', userId);
        try {
            setUserId(userId);
        } catch {
            // do nothing
        }
    };

    return (
        <AmplitudeContext.Provider value={{ isInitialized, identify }}>
            {props.children}
        </AmplitudeContext.Provider>
    );
};

export default AmplitudeProvider;

export const useAmplitude = () => useContext(AmplitudeContext);
