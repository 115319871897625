import { StoreKey } from '../../types/storeNames';
import axios from '../../util/axios';
import { dispatchAndUpdateStore } from '../../util/dispatchAndUpdateStore';
import { Space } from './types';

export interface ISpacesResponse {
    status: 'success' | 'error';
    error?: string;
    data?: Space[];
}

interface ICreateSpaceResponse {
    status: 'success' | 'error';
    error?: string;
    data?: Space;
}

const ORGANIZATION_STORES = [
    StoreKey.CATEGORY_GROUPS,
    StoreKey.TAGS,
    StoreKey.SPACES,
];
export default class SpaceService {
    public static getSpaces(): Promise<ISpacesResponse> {
        return axios.get('/receipts/spaces/list').then((value) => {
            return value.data;
        });
    }

    public static createSpace(
        name: string,
        icon?: string,
        order?: number
    ): Promise<ICreateSpaceResponse> {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/spaces/create', {
                    name,
                    icon,
                    order,
                })
                .then((value) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }

    public static assignCategoryToSpace(
        spaceId: number,
        categoryIds: string[],
        categoryGroupIds: string[]
    ) {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/spaces/assign', {
                    space_id: spaceId,
                    category_ids: categoryIds,
                    category_group_ids: categoryGroupIds,
                })
                .then((value) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }

    public static unassignCategoryToSpace(
        spaceId: number,
        categoryIds: string[],
        categoryGroupIds: string[]
    ) {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/spaces/unassign', {
                    space_id: spaceId,
                    category_ids: categoryIds,
                    category_group_ids: categoryGroupIds,
                })
                .then((value) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }

    public static updateSpace(
        spaceId: number | string,
        name: string,
        icon?: string
    ) {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/spaces/edit', {
                    space_id: spaceId,
                    name,
                    icon,
                })
                .then((value) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }

    public static reorderSpaces(spaceIds: number[]) {
        return axios
            .post('/receipts/spaces/reorder', {
                space_ids: spaceIds,
            })
            .then((value) => {
                return value.data;
            });
    }

    public static deleteSpace(spaceId: number) {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/spaces/delete', {
                    space_id: spaceId,
                })
                .then((value) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }

    public static copySpace(spaceId: number): Promise<ICreateSpaceResponse> {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/spaces/copy', {
                    space_id: spaceId,
                })
                .then((value) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }
}
