import Drug from '../assets/icons/details/drug.svg';
import Prescribe from '../assets/icons/details/prescribe.svg';
import Location from '../assets/icons/details/location.svg';
import Mileage from '../assets/icons/details/mileage.svg';
import Refill from '../assets/icons/details/refill.svg';
import Retail from '../assets/icons/details/retail.svg';
import Phone from '../assets/icons/folders/phone.svg';
import Email from '../assets/icons/emailBadge.svg';
import Income from '../assets/icons/folders/income.svg';
import Tip from '../assets/icons/details/tip.svg';
import Card from '../assets/icons/card.svg';
import GasTank from '../assets/icons/details/gasTank.svg';
import BusinessCard from '../assets/icons/folders/businessCard.svg';
import {
    IFieldsDocument,
    IFormattedField,
    IFormattedFieldType,
} from '../components/DocumentTypes';

export enum SubDocumentType {
    RECEIPT = 'Receipt',
    GAS = 'Gas',
    RESTAURANT = 'Restaurant',
    PRESCRIPTION = 'Prescription',
    INCOME = 'Income',
    CREDIT_CARD = 'Credit Card',
    CONTACT_CARD = 'Contact Card',
    MILEAGE = 'Mileage',
}

export enum SubDocumentFields {
    PRESCRIBER = 'prescriber', // PRESCRIPTION
    RX_TOTAL = 'rx_total',
    FSA_TOTAL = 'fsa_total',
    REFILL = 'refill',
    RETAIL_PRICE = 'retail_price',
    DRUG_NAME = 'drug_name', // PRESCRIPTION
    PRICE_PER_GALLON = 'price_per_gallon', // GAS
    GALLONS = 'gallons', // GAS
    TIP = 'tip', // RESTAURANT
    CONTACT_NAME = 'contact_name', // BUSINESSCARD
    CONTACT_EMAIL = 'contact_email', // BUSINESSCARD
    CONTACT_PHONE = 'contact_phone', // BUSINESSCARD
    FROM_LOCATION = 'from_location', // MILEAGE
    START_TIME = 'start_time', // MILEAGE
    END_TIME = 'end_time', // MILEAGE
    TO_LOCATION = 'to_location', // MILEAGE
    MILES = 'miles', // MILEAGE
    POTENTIAL_DEDUCTIONS = 'potential_deductions', // MILEAGE
    CARD_NUMBER = 'card_number', // CARD
    CARD_EXP = 'card_exp', // CARD
    SUBJECT = 'subject', // ERECEIPTS
    FROM = 'from', // ERECEIPTS
    USER_EMAIL = 'user_email', // ERECEIPTS
}

// mainly for type safety, use when we EXPECT a string to be a sub document field
export const stringToSubDocumentField = (
    type: string | undefined
): SubDocumentFields => {
    if (
        Object.values(SubDocumentFields)
            .map((v) => v.toString())
            .includes(type || '')
    )
        return type as SubDocumentFields;
    return SubDocumentFields.RETAIL_PRICE;
};

export type ISubDocumentFormat = 'string' | 'dollar' | 'decimal' | undefined;

export const SUB_DOCUMENT_FIELDS_MAP: {
    [key in SubDocumentFields]: {
        name: string;
        icon: string;
        format?: ISubDocumentFormat;
        displayWithAmounts?: boolean; // if true, will display with amounts like tax+total. otherwise will be its own row
    };
} = {
    [SubDocumentFields.RETAIL_PRICE]: {
        name: 'Retail Price',
        icon: Retail,
        format: 'dollar',
    },
    [SubDocumentFields.GALLONS]: {
        name: 'Gallons',
        icon: GasTank,
        format: 'decimal',
    },
    [SubDocumentFields.PRICE_PER_GALLON]: {
        name: 'Price Per Gallon',
        icon: GasTank,
        format: 'dollar',
    },
    [SubDocumentFields.REFILL]: {
        name: 'Refill',
        icon: Refill,
    },
    [SubDocumentFields.TIP]: {
        name: 'Tip',
        icon: Tip,
        format: 'dollar',
        displayWithAmounts: true,
    },
    [SubDocumentFields.PRESCRIBER]: {
        name: 'Prescriber',
        icon: Prescribe,
    },
    [SubDocumentFields.DRUG_NAME]: {
        name: 'Drug Name',
        icon: Drug,
    },
    [SubDocumentFields.RX_TOTAL]: {
        name: 'RX Total',
        icon: Retail,
        format: 'dollar',
    },
    [SubDocumentFields.FSA_TOTAL]: {
        name: 'FSA Total',
        icon: Retail,
        format: 'dollar',
    },
    [SubDocumentFields.CONTACT_NAME]: {
        name: 'Name',
        icon: Retail,
    },
    [SubDocumentFields.CONTACT_EMAIL]: {
        name: 'Email',
        icon: Email,
    },
    [SubDocumentFields.CONTACT_PHONE]: {
        name: 'Phone',
        icon: Phone,
    },
    [SubDocumentFields.CARD_NUMBER]: {
        name: 'Card Number',
        icon: Card,
    },
    [SubDocumentFields.CARD_EXP]: {
        name: 'Card EXP',
        icon: Card,
    },
    [SubDocumentFields.FROM_LOCATION]: {
        name: 'From',
        icon: Location,
        format: 'string',
    },
    [SubDocumentFields.TO_LOCATION]: {
        name: 'To',
        icon: Location,
        format: 'string',
    },
    [SubDocumentFields.START_TIME]: {
        name: 'Start Time',
        icon: Location,
        format: 'decimal',
    },
    [SubDocumentFields.END_TIME]: {
        name: 'End Time',
        icon: Location,
        format: 'decimal',
    },
    [SubDocumentFields.MILES]: {
        name: 'Miles',
        icon: Location,
        format: 'decimal',
    },
    [SubDocumentFields.POTENTIAL_DEDUCTIONS]: {
        name: 'Potential Deductions',
        icon: Location,
        format: 'dollar',
    },
    [SubDocumentFields.FROM]: {
        name: 'From',
        icon: Location,
        format: 'string',
    },
    [SubDocumentFields.SUBJECT]: {
        name: 'Subject',
        icon: Location,
        format: 'string',
    },
    [SubDocumentFields.USER_EMAIL]: {
        name: 'Inbox',
        icon: Location,
        format: 'string',
    },
};

export const isSubDocumentType = (fieldName: string) => {
    //@ts-ignore
    return Object.values(SubDocumentType).includes(fieldName);
};

export const isSubDocumentField = (fieldName: string) => {
    //@ts-ignore
    return Object.values(SubDocumentFields).includes(fieldName);
};

// mainly for type safety, use when we EXPECT a string to be a sub document field
export const stringToSubDocumentType = (
    type: string | undefined
): SubDocumentType => {
    switch (type) {
        case 'Restaurant':
            return SubDocumentType.RESTAURANT;
        case 'Gas':
            return SubDocumentType.GAS;
        case 'Prescription':
            return SubDocumentType.PRESCRIPTION;
        case 'Income':
            return SubDocumentType.INCOME;
        case 'Credit Card':
            return SubDocumentType.CREDIT_CARD;
        case 'Contact Card':
            return SubDocumentType.CONTACT_CARD;
        case 'Mileage':
            return SubDocumentType.MILEAGE;
        default:
            return SubDocumentType.RECEIPT;
    }
};

/**
 * display document type name (Receipt, Bill) instead of sub document type (Gas, Restaurant)
 * because we don't want the header to say "Receipt (General)"
 */
export const isGeneralType = (subDocType: SubDocumentType) =>
    [SubDocumentType.RECEIPT].includes(subDocType);

export const RECEIPT_SUB_DOCUMENT_MAP: {
    [key in SubDocumentType]: { name: string; icon: string };
} = {
    [SubDocumentType.RECEIPT]: {
        name: 'Receipt (General)',
        icon: Retail,
    },
    [SubDocumentType.GAS]: {
        name: 'Gas Receipt',
        icon: GasTank,
    },
    [SubDocumentType.RESTAURANT]: {
        name: 'Restaurant Receipt',
        icon: Tip,
    },
    [SubDocumentType.PRESCRIPTION]: {
        name: 'Prescription',
        icon: Prescribe,
    },
    [SubDocumentType.INCOME]: {
        name: 'Income',
        icon: Income,
    },
    [SubDocumentType.CONTACT_CARD]: {
        name: 'Contact Card',
        icon: BusinessCard,
    },
    [SubDocumentType.CREDIT_CARD]: {
        name: 'Credit Card',
        icon: Card,
    },
    [SubDocumentType.MILEAGE]: {
        name: 'Mileage',
        icon: Mileage,
    },
};

// For just getting key:value pairs for additional totals fields
export type ISubDocumentField = { name: string; value: number | undefined };
export type ISubDocumentFields = {
    [field: string]: ISubDocumentField;
};

const subDocumentFormatToFieldType: {
    [key: string]: IFormattedFieldType;
} = {
    dollar: 'dollar',
    decimal: 'dollar',
    string: 'string',
};

export type IFormattedSubDocumentField = IFormattedField & {
    additionalFieldKey: string;
    format: ISubDocumentFormat;
};

// returns two, arrays, one for the "additional fields" section and one for the "totals" section
export const getAdditionalFields = (fieldsDocument: IFieldsDocument) => {
    const iterableFields = Object.keys(fieldsDocument.additional_fields || {});

    const additionalFields: IFormattedSubDocumentField[] = []; // rows to be displayed with "additional fields"
    const additionalAmountFields: IFormattedSubDocumentField[] = [];

    // const additionalTotalsFields: IFormattedField[] = []; // rows to be displayed with totals
    iterableFields.forEach((key) => {
        if (isSubDocumentField(key)) {
            const fieldInfo =
                SUB_DOCUMENT_FIELDS_MAP[stringToSubDocumentField(key)];
            const formattedFieldType = fieldInfo.format
                ? subDocumentFormatToFieldType[fieldInfo.format]
                : 'string';
            const formattedField: IFormattedSubDocumentField = {
                name: fieldInfo.name,
                type: formattedFieldType,
                field: 'additional_fields',
                icon: fieldInfo.icon,
                additionalFieldKey: key,
                format: fieldInfo.format,
            };
            if (fieldInfo.displayWithAmounts) {
                additionalAmountFields.push(formattedField);
            } else {
                additionalFields.push(formattedField);
            }
        }
    });

    return { additionalFields, additionalAmountFields };
};

export const getAdditionalTotalFields = (
    fieldsDocument: IFieldsDocument
): ISubDocumentFields => {
    const additionalTotalsRows: ISubDocumentFields = {}; // rows to be displayed with totals

    const iterableFields = Object.keys(fieldsDocument.additional_fields || {});

    iterableFields.forEach((key) => {
        if (isSubDocumentField(key)) {
            const additionalField = fieldsDocument.additional_fields?.[key] as
                | ISubDocumentField
                | undefined;
            const fieldInfo =
                SUB_DOCUMENT_FIELDS_MAP[stringToSubDocumentField(key)];
            if (fieldInfo.displayWithAmounts && additionalField) {
                // we display these fields with the amounts section
                additionalTotalsRows[key] = additionalField;
            }
        }
    });

    return additionalTotalsRows;
};
