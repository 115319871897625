import { IChargeFinderMerchantData } from '../Merchant';
import { BodyContainer } from '../styles';
import BlogPostHeader from './BlogPostHeader';
import CTASection from './CTASection';

const MerchantScript = ({ data }: { data: IChargeFinderMerchantData }) => {
    const merchant = data.merchant?.merchant ?? '';
    const codes = data.codes ?? [];
    const merchantCommonCodes = codes;
    const merchantGptDescription = data.merchant?.metadata.description;

    return (
        <div>
            <BodyContainer>
                <div style={{ height: 30 }} />
                <BlogPostHeader
                    title={`Charges from ${merchant}`}
                    author={data?.merchant?.author}
                    date={data?.merchant?.updated_at}
                    isMerchant
                />
                <p>{merchantGptDescription ?? ''}</p>
                <h2>
                    Other ways this charge from <b>{merchant}</b> can appear
                </h2>
                <p>
                    {merchant} transactions on your credit card statement can
                    appear in various ways, depending on the specific
                    transaction and how it was processed. Here are some common
                    descriptors you might see for {merchant} charges:
                </p>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <ul>
                        {merchantCommonCodes.map((c, i) => (
                            <li
                                key={i.toString()}
                                style={{ paddingBottom: 10 }}
                            >
                                <a
                                    href={`/whats-this-charge/${data?.merchant?.slug}/${c.slug}`}
                                    style={{
                                        marginBottom: 10,
                                    }}
                                >
                                    {c.code}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
                <p>
                    It’s important to note that the exact descriptor on your
                    credit card statement may vary, and these are just some
                    common examples. If you ever have a question about a
                    specific charge, you can usually find more details in your{' '}
                    {merchant} account’s order history or contact {merchant}{' '}
                    customer support for assistance. Additionally, always keep
                    an eye on your credit card statements to ensure that all
                    charges are legitimate.
                </p>
                <CTASection />
            </BodyContainer>
        </div>
    );
};

export default MerchantScript;
