import { IOrganization } from '../auth/types';

export type CompoundCategoryAndCategoryGroupId = string | number;

export type Space = {
    id: number | string; // The auxillary spaces will have string ids
    name: string;
    icon?: string;
    order?: number;
    categoryIds?: CompoundCategoryAndCategoryGroupId[];
    organization?: IOrganization;
};

export const DEFAULT_SPACE_ID = 'DEFAULT_SPACE';
export const CREATE_SPACE_ID = 'CREATE_SPACE';
export const PLUGIN_SPACE_ID = 'PLUGIN_SPACE';
