import { Button } from '@mui/material';
import { onWebAppCostEstimationEnterPrompt } from '../../../tracking/trackers';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import ChatSVG from '../../../assets/icons/chat.svg';
import theme from '../../../theme';

const AddDescription = ({
    prompt,
    setPrompt,
    setStep,
}: {
    prompt: string;
    setPrompt: (prompt: string) => void;
    setStep: (step: number) => void;
}) => {
    const { width } = useWindowDimensions();
    return (
        <>
            <div
                style={{
                    width: Math.min(width * 0.5, 250),
                    height: Math.min(width * 0.5, 250),
                    backgroundColor: theme.colors.offWhite,
                    borderRadius: 1000,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    margin: 10,
                }}
            >
                <img src={ChatSVG} width={150} height={150} alt="chat" />
            </div>
            <div
                style={{
                    fontWeight: '700',
                    marginTop: 20,
                    marginBottom: 10,
                }}
            >
                Describe your project
            </div>
            <textarea
                placeholder="Include the type of work and any dimensions if you have them"
                rows={5}
                style={{
                    width: Math.min(width * 0.8, 400),
                    padding: 10,
                    borderRadius: 10,
                    borderColor: '#ccc',
                    borderWidth: 1,
                    fontSize: 16,
                    marginTop: 10,
                    maxWidth: 650,
                }}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
            />
            <Button
                variant="contained"
                color="secondary"
                style={{
                    color: 'white',
                    boxShadow: 'none',
                    borderRadius: 25,
                    height: 50,
                    width: Math.min(width * 0.8, 400),
                    fontWeight: '700',
                    fontSize: 20,
                    marginTop: 30,
                }}
                onClick={() => {
                    setStep(2);
                    onWebAppCostEstimationEnterPrompt({
                        prompt,
                    });
                }}
                disabled={!prompt}
            >
                Submit
            </Button>
        </>
    );
};

export default AddDescription;
