import _ from 'lodash';
import moment from 'moment';
import { Moment } from 'moment';
import { IReceipt, IUserTag } from '../../services/receipts/types';
import { getCategoryTax, getReceiptTotal } from '../../util/getReceiptTotal';
import { ALL_DOCUMENTS } from '../../util/constants';

const isReceiptInCategory = (receipt: IReceipt, cat: string) => {
    return (
        cat === 'All Documents' ||
        receipt.categories.map((cat) => cat.tag_id).includes(cat)
    );
};

const isReceiptInMonth = (receipt: IReceipt, month: Moment) => {
    return moment(receipt.date).isBetween(
        month,
        moment(month).endOf('month'),
        'days',
        '[]'
    );
};

export interface IMonthTotal {
    month: string;
    total: number;
    tax: number;
}

export type ISummaryCategory = {
    total: number;
    tax: number;
    months: IMonthTotal[];
};

export type ISummaryData = {
    [id: string]: ISummaryCategory;
};

const getMonths = (dates: Moment[]) => {
    let sortedDates = dates.sort((a, b) => a.valueOf() - b.valueOf());
    if (sortedDates.length === 0) return [];
    let firstDate = sortedDates[0].clone().startOf('month');
    let lastDate = sortedDates[sortedDates.length - 1]
        .clone()
        .startOf('month')
        .add(1, 'day');
    let months = [];
    let currDate = firstDate;
    while (currDate.isBefore(lastDate)) {
        months.push(currDate);
        currDate = moment(currDate).add(1, 'month').startOf('month');
    }
    return months.reverse();
};

export const useSummaryData = (
    receipts: IReceipt[],
    categories: IUserTag[],
    isSummaryTab = true
): ISummaryData => {
    if (!isSummaryTab) return {};

    const months = getMonths(receipts.map((receipt) => moment(receipt.date)));

    const categoryIds = categories.map((cat) => cat.tag_id);

    const categoryData: { [id: string]: ISummaryCategory } = {};

    const addToCategoryData = (
        receiptsInCategory: IReceipt[],
        category: IUserTag,
        month: moment.Moment,
        isUncategorized = false // uncategorized documents have no space, but can be included in the totals (just not SPACE totals)
    ) => {
        const isAllDocs = category.tag_id === ALL_DOCUMENTS;
        const noDuplicates = _.uniqBy(receiptsInCategory, (r) => r.receipt_id);
        const receiptsInMonthAndCategory = noDuplicates.filter((r) =>
            isReceiptInMonth(r, month)
        );
        // gets the total amount and total tax amount, taking into account
        // split receipts and exchange rates
        const { total, tax } = receiptsInMonthAndCategory.reduce(
            (acc, receipt) => {
                const exchangeRate = receipt.exchange_rate;
                const to = getReceiptTotal(
                    receipt.amount,
                    receipt.totals || {},
                    isAllDocs ? undefined : category.tag_id,
                    isAllDocs ? categoryIds : undefined,
                    exchangeRate
                );
                acc.total += to;
                const tx = getCategoryTax(
                    receipt.amount,
                    receipt.secondary_amount,
                    receipt.totals || {},
                    isAllDocs ? undefined : category.tag_id,
                    isAllDocs ? categoryIds : undefined,
                    exchangeRate
                );
                acc.tax += tx;
                return acc;
            },
            {
                total: 0,
                tax: 0,
            }
        );

        // add totals to category data
        if (!(category.tag_id in categoryData)) {
            categoryData[category.tag_id] = {
                total: 0,
                tax: 0,
                months: [],
            };
        }
        categoryData[category.tag_id].total += total;
        categoryData[category.tag_id].tax += tax;
        categoryData[category.tag_id].months.push({
            month: month.format('MMM YYYY'),
            total,
            tax,
        });
    };

    // calculate all categories, not including all-docs and uncategorized
    months.forEach((month) => {
        const row: any[] = [];
        row.push(month.format('MMM YYYY'));
        categories.forEach((cat) => {
            // skip for all documents and uncategorized - handle separately to avoid double-pushing double-categorized receipts
            if ([ALL_DOCUMENTS, 'uncategorized'].includes(cat.tag_id)) {
                return;
            }

            const receiptsInCategory = receipts.filter((receipt) =>
                isReceiptInCategory(receipt, cat.tag_id)
            );

            addToCategoryData(receiptsInCategory, cat, month);
        });
    });

    // handle uncategorized
    const uncategorizedReceipts = receipts.filter(
        (receipt) => receipt.categories.length === 0
    );
    const uncategorizedCat = categories.find(
        (cat) => cat.tag_id === 'uncategorized'
    );
    if (uncategorizedCat) {
        months.forEach((month) => {
            addToCategoryData(
                uncategorizedReceipts,
                uncategorizedCat,
                month,
                true
            );
        });
    }

    // handle all documents
    const allDocsCat = categories.find((cat) => cat.tag_id === ALL_DOCUMENTS);
    if (allDocsCat) {
        months.forEach((month) => {
            addToCategoryData(receipts, allDocsCat, month);
        });
    }

    return categoryData;
};
