import { createTheme } from '@mui/material/styles';
import theme from '.';

const materialTheme = createTheme({
    typography: {
        fontFamily: [
            'Inter',
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h3: {
            textAlign: 'center',
            [`@media (min-width: 768px)`]: {
                textAlign: 'left',
            },
        },
        h4: {
            textAlign: 'center',
            [`@media (min-width: 768px)`]: {
                textAlign: 'left',
            },
        },
        h5: {
            textAlign: 'center',
            [`@media (min-width: 768px)`]: {
                textAlign: 'left',
            },
        },
        h6: {
            textAlign: 'center',
            [`@media (min-width: 768px)`]: {
                textAlign: 'left',
            },
            fontWeight: '700',
        },
        body2: {
            textAlign: 'center',
            [`@media (min-width: 768px)`]: {
                textAlign: 'left',
            },
        },
    },
    palette: {
        primary: {
            main: theme.colors.primary,
        },
        secondary: {
            main: theme.colors.secondary,
        },
        error: {
            main: theme.colors.error,
        },
        info: {
            main: theme.colors.white,
        },
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    borderRadius: theme.roundness,
                },
            },
        },
    },
});

export default materialTheme;
