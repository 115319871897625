import { SectionWrapper } from '../../../screens/Landing/styles';
import { whiteToOffWhiteGradient } from '../../../util/styleConstants';
import { SectionHeader } from '../../Text';
import LandingSection from '../../../screens/Landing/LandingSection';
import HeaderSnap from '../../../assets/landing/header_snap.svg';
import { number } from '../../../util/devices';
import Email from '../../../assets/icons/email.svg';
import CategorizeSection from '../../../assets/landing/categorize_section.svg';
import TrackSection from '../../../assets/landing/track_section.svg';

export const SectionHowItWorks = () => {
    return (
        <SectionWrapper style={{ backgroundImage: whiteToOffWhiteGradient }}>
            <SectionHeader
                style={{
                    textAlign: 'center',
                    marginTop: 30,
                }}
            >
                How it Works
            </SectionHeader>
            <div style={{ maxWidth: 1000, width: '90%' }}>
                <LandingSection
                    mode={'left'}
                    header={'Scan Paper'}
                    section={
                        'Take photos of receipts & bills to digitize the key info.'
                    }
                    detailSection={
                        'SimplyWise digitizes your paperwork and extracts key information, including merchant, date, total + tax, and line items. We use this data to create easily exportable reports, which simplifies your tax filing process. The app provides unlimited cloud storage for all your documents.'
                    }
                    asset={
                        <img
                            src={HeaderSnap}
                            alt=""
                            width={number.MOBILE_SM}
                            height={300}
                        />
                    }
                />
                <LandingSection
                    mode={'right'}
                    header={'Save Email Receipts'}
                    section={'No more losing receipts in your Inbox.'}
                    detailSection={
                        'If you connect your email, SimplyWise automatically identifies email receipts and bills from your email account and extracts relevant data (merchant, date, total + tax and line items) from each receipt or bill. We use this data to create exportable tax deductions reports, which simplifies the gathering of data for tax filing purposes. SimplyWise is a read-only consumer of this email information, and will never share with third parties.'
                    }
                    asset={
                        <img
                            src={Email}
                            alt=""
                            width={number.MOBILE_SM}
                            height={number.MOBILE_SM}
                        />
                    }
                />
                <LandingSection
                    mode={'left'}
                    header={'Categorize Expenses'}
                    section={
                        'File expenses into categories that make sense for your finances.'
                    }
                    detailSection={
                        'Stay organized with personalized folders & subfolders for all of your paperwork and expenses. The app tracks monthly totals within each category, making it easy to stay on top of your budget.'
                    }
                    asset={
                        <img
                            src={CategorizeSection}
                            alt=""
                            width={number.MOBILE_SM}
                            height={number.MOBILE_SM}
                        />
                    }
                />
                <LandingSection
                    mode={'right'}
                    header={'Track Deductions'}
                    section={
                        'Export itemized deductions to an excel spreadsheet. Your CPA will love you for it.'
                    }
                    detailSection={
                        'Export receipts, bills and invoices to an itemized Excel Spreadsheet, organized by category. Tracking expenses for your tax filing just got a whole lot easier.'
                    }
                    asset={
                        <img
                            src={TrackSection}
                            alt=""
                            width={number.MOBILE_SM}
                            height={200}
                        />
                    }
                />
            </div>
        </SectionWrapper>
    );
};
