import React, { useContext, useState } from 'react';

const DownloadUrlContext = React.createContext<{
    url: string | undefined;
    setUrl: (url: string) => void;
}>({
    url: undefined,
    setUrl: () => {},
});

const DownloadUrlProvider = (props: { children: React.ReactNode }) => {
    const [url, setUrl] = useState<string | undefined>(undefined);

    return (
        <DownloadUrlContext.Provider value={{ url, setUrl }}>
            {props.children}
        </DownloadUrlContext.Provider>
    );
};

export default DownloadUrlProvider;

export const useDownloadUrl = () => useContext(DownloadUrlContext);
