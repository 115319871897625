import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BodyBold } from '../../../components/Text';
import theme from '../../../theme';

const RedirectToTeamsPayment = () => {
    const navigate = useNavigate();
    const redirect = () => {
        navigate('/teams/payment?source=mobile-drawer');
    };
    useEffect(() => {
        redirect();
    }, []);
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: 60,
                alignItems: 'center',
                justifyContent: 'center',
            }}
            onClick={redirect}
        >
            <BodyBold>If you are not redirected,</BodyBold>
            <BodyBold
                style={{
                    textDecoration: 'underline',
                    color: theme.colors.secondary,
                    marginLeft: 5,
                }}
            >
                click here
            </BodyBold>
        </div>
    );
};

export default RedirectToTeamsPayment;
