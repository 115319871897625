import { Button, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { emitter } from '@marvelapp/react-ab-test';
import { Body, BodyBold } from '../../../../components/Text';
import PhoneDanger from '../../../../assets/chargeFinder/phone-danger.svg';
import ConnectEmail from '../../../../assets/chargeFinder/connectEmail.svg';
import ConnectBank from '../../../../assets/chargeFinder/connectPlaid.svg';
import Reconcile from '../../../../assets/chargeFinder/reconcileToStatements.svg';
import theme from '../../../../theme';
import './index.css';
import { IChargeFinderCode } from '../../../../services/plaid/types';
import { onMobileChargeFinderClickCTA } from '../../../../tracking/trackers';
import { ButtonContainer } from '../../../../components/Buttons/BottomButton';
import navigationActions from '../../../../util/navigationActions';
import ChargeFinderVariants from './ChargeFinderVariants';

const CTASection = ({
    code,
    variant,
}: {
    code?: IChargeFinderCode;
    variant?: string;
}) => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 900px)');

    const expName = ChargeFinderVariants.name;

    return (
        <div
            style={{
                fontSize: '0.7em',
            }}
        >
            <div style={{ height: 10 }} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    width: '100%',
                    borderRadius: 15,
                    padding: 10,
                    margin: '20px 0',
                    // very light gray
                    backgroundColor: '#f5f5f5',
                }}
            >
                <img alt={''} src={PhoneDanger} width={30} />
                <div style={{ width: 20 }} />
                <BodyBold>Find & fight fraud from anywhere</BodyBold>
                <Button
                    onClick={() => {
                        emitter.emitWin(expName);
                        // NOTE: the correct
                        onMobileChargeFinderClickCTA({
                            cta: 'get-started',
                            code: code?.code,
                            variantName: variant,
                            experimentName: expName,
                        });
                        /*
                        OnWebAppChargeFinderCTAClick({
                            variantName: variant,
                            experimentName: expName,
                        });
                        */

                        navigate('/redirect-download', {
                            state: {
                                alternativeWebUrl: '/link/chargefinder',
                                customData: {
                                    cta: 'get-started',
                                    code: code?.code,
                                    source: 'code',
                                },
                            },
                        });
                    }}
                    variant="contained"
                    color="secondary"
                    style={{
                        borderRadius: 100,
                        boxShadow: 'none',
                    }}
                >
                    <Body
                        style={{
                            color: 'white',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            fontSize: 14,
                        }}
                    >
                        Get Started
                    </Body>
                </Button>
            </div>
            <div style={{ height: 5 }} />
            <div>
                <h2>Verify With Receipt</h2>
                <div style={{ height: 5 }} />
                <div>
                    The best way to tell if this charge is fraudulent is to find
                    the receipt. You may have an electronic copy of the receipt,
                    which you can finding by searching in your email accounts
                    for {code?.merchant?.merchant}.
                </div>
                <div style={{ height: 5 }} />
                <div>
                    Or, if you want to automatically find and reconcile receipts
                    so you never worry about fraud, you can use SimplyWise to
                    automatically match all bank and credit card transactions to
                    email and paper receipts. The app instantly reconciles your
                    expenses and flags anything that doesn’t match.
                </div>
            </div>
            <div style={{ height: 40 }} />
            <div className="section">
                <div>
                    <div
                        style={{
                            borderRadius: 25,
                            border: `3px solid ${theme.colors.secondary}`,
                            width: 80,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontWeight: 'bold',
                            fontSize: 14,
                        }}
                    >
                        Step 1
                    </div>
                    <h4
                        style={{
                            lineHeight: 0.5,
                        }}
                    >
                        Connect your email
                    </h4>
                    <div style={{ fontSize: 12 }}>
                        Download the SimplyWise app and connect your email
                        account. SimplyWise will search through your emails and
                        find all the receipts in your inbox. This allows you to
                        understand what exactly you are paying for when you see
                        a bank transaction on your statement.
                    </div>
                    <div style={{ height: 10 }} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        alt={''}
                        src={ConnectEmail}
                        width={isMobile ? 100 : 200}
                        style={{
                            borderRadius: 15,
                        }}
                    />
                </div>
            </div>
            <div style={{ height: 40 }} />
            <div className="section mobile-reverse">
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        alt={''}
                        src={ConnectBank}
                        width={isMobile ? 100 : 200}
                        style={{
                            borderRadius: 15,
                        }}
                    />
                </div>
                <div>
                    <div
                        style={{
                            borderRadius: 25,
                            border: `3px solid ${theme.colors.secondary}`,
                            width: 80,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontWeight: 'bold',
                            fontSize: 14,
                        }}
                    >
                        Step 2
                    </div>
                    <h4
                        style={{
                            lineHeight: 0.5,
                        }}
                    >
                        Connect Your Bank
                    </h4>
                    <div style={{ fontSize: 12 }}>
                        Connect to your bank account/credit card transactions
                        through the secure (256 bit encryption) Reconciliation
                        feature within the app.
                    </div>
                    <div style={{ height: 10 }} />
                </div>
            </div>
            <div style={{ height: 40 }} />
            <div className="section">
                <div>
                    <div
                        style={{
                            borderRadius: 25,
                            border: `3px solid ${theme.colors.secondary}`,
                            width: 80,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontWeight: 'bold',
                            fontSize: 14,
                        }}
                    >
                        Step 3
                    </div>
                    <h4
                        style={{
                            lineHeight: 0.5,
                        }}
                    >
                        Reconcile
                    </h4>
                    <div style={{ fontSize: 12 }}>
                        Reconcile the charges reported by your financial
                        institution against what you’ve recorded in your
                        SimplyWise account. Find fraud quickly! SimplyWise will
                        match your transactions to your bank/credit card
                        spending and check those items off.
                    </div>
                    <div style={{ height: 10 }} />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        alt={''}
                        src={Reconcile}
                        width={isMobile ? 100 : 200}
                        style={{
                            borderRadius: 15,
                        }}
                    />
                </div>
            </div>

            <ButtonContainer
                onClick={() => {
                    onMobileChargeFinderClickCTA({
                        cta: 'verify-now',
                        code: code?.code,
                    });
                    const { url, options } = navigationActions.chargeFinderCTA(
                        'verify-now',
                        code?.code
                    );
                    navigate(url, options);
                }}
                style={{
                    marginTop: 50,
                    width: '100%',
                }}
            >
                <Body
                    style={{
                        color: 'white',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        fontSize: 28,
                    }}
                >
                    Verify Now
                </Body>
            </ButtonContainer>
        </div>
    );
};

export default CTASection;
