import WriterAvatar from '../../../../assets/chargeFinder/writer.png';
import theme from '../../../../theme';
import { IChargeFinderAuthor } from '../../../../services/plaid/types';

const BlogPostHeader = ({
    title,
    author,
    authorObject,
    date,
    isLoading,
    isMerchant,
}: {
    title: string;
    author?: string;
    authorObject?: IChargeFinderAuthor;
    date?: string;
    isLoading?: boolean;
    isMerchant?: boolean;
}) => {
    const d = new Date();
    const lastMonth = d.getMonth() - 1;
    const lastMonthName = new Date(d.setMonth(lastMonth)).toLocaleString(
        'default',
        { month: 'long' }
    );
    const lastMonthYear = new Date(d.setMonth(lastMonth)).getFullYear();

    const gray = theme.colors.blue10;

    const authorName = authorObject?.name.toLowerCase().split(' ') || [];
    const authorAvatar =
        authorName.length > 0
            ? require(`../../../../assets/chargeFinder/authors/${authorName[0]}_${authorName[1]}.jpeg`)
            : null;

    return (
        <>
            {isLoading ? (
                <>
                    <div
                        style={{
                            marginTop: 40,
                            backgroundColor: gray,
                            borderRadius: 10,
                            height: 50,
                            width: '100%',
                        }}
                    />
                    <div
                        style={{
                            marginTop: 30,
                            marginBottom: 10,
                            backgroundColor: gray,
                            borderRadius: 10,
                            height: 30,
                            width: '30%',
                        }}
                    />
                    <div
                        style={{
                            backgroundColor: gray,
                            width: '100%',
                            height: 300,
                        }}
                    />
                </>
            ) : (
                <>
                    <h1
                        style={{
                            lineHeight: 1,
                        }}
                    >
                        {title}
                    </h1>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingBottom: 10,
                        }}
                    >
                        <img
                            src={authorAvatar || WriterAvatar}
                            alt=""
                            style={{
                                width: 35,
                                height: 35,
                                borderRadius: 50,
                                marginRight: 10,
                                border: `4px solid ${theme.colors.secondary}`,
                            }}
                        />
                        <h4
                            style={{
                                color: theme.colors.blue60,
                                fontSize: '0.5em',
                                fontWeight: 'normal',
                            }}
                        >
                            Written by{' '}
                            {authorObject?.name || 'Jemina Owen-Jones'}
                        </h4>
                        <div>
                            <div
                                style={{
                                    width: 5,
                                    height: 5,
                                    backgroundColor: theme.colors.blue20,
                                    margin: 5,
                                }}
                            />
                        </div>
                        <h4
                            style={{
                                color: theme.colors.blue60,
                                fontSize: '0.5em',
                                fontWeight: 'normal',
                            }}
                        >
                            {date || (
                                <>
                                    {lastMonthName} 18th, {lastMonthYear}
                                </>
                            )}
                        </h4>
                    </div>
                </>
            )}
        </>
    );
};

export default BlogPostHeader;
