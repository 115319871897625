import React, { useContext, useEffect, useState } from 'react';
import { throttle } from 'lodash';
import {
    identify as amplitudeIdentify,
    Identify,
    setUserId,
} from '@amplitude/analytics-browser';
import AuthService from '../../services/auth/service';
import { IUser } from '../../services/auth/types';
import { onWebAppUserTokenFound } from '../../tracking/trackers';

interface IAuthProviderProps {
    children: React.ReactNode;
}

export interface IAuthContextValue {
    isAuthenticated: boolean;
    isSubscribed: boolean;
    token: string | null;
    setToken: (token: string | null) => void;
    user: IUser | null;
    authError: string | null;
    authLoading: boolean;
    refreshUser: () => void;
    didSignUp: boolean;
    setDidSignUp: (didSignUp: boolean) => void;
}

const AuthContext = React.createContext<IAuthContextValue>({
    isAuthenticated: false,
    isSubscribed: false,
    token: null,
    setToken: () => {},
    user: null,
    authError: null,
    authLoading: true,
    refreshUser: () => {},
    didSignUp: false,
    setDidSignUp: () => {},
});

const AuthProvider = (props: IAuthProviderProps) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [token, updateToken] = useState<string | null>(
        localStorage.getItem('token')
    );
    const [user, setUser] = useState<IUser | null>(null);
    const [authError, setAuthError] = useState<string | null>(null);
    const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [didSignUp, setDidSignUp] = useState<boolean>(false);

    useEffect(() => {
        subscribeToken();
    }, []);

    useEffect(() => {
        if (token !== null) tryToken();
        else setUser(null);
    }, [token]);

    useEffect(() => {
        setIsAuthenticated(!!user);
        setIsSubscribed(
            !!user &&
                ((!!user.subscription_tier &&
                    !user.subscription_tier?.includes('freemium')) ||
                    !!user.grandfathered)
        );
    }, [user]);

    const tokenListener = (event: StorageEvent) => {
        if (event.type === 'token') {
            const token = event.newValue;
            if (token) {
                updateToken(token);
            } else {
                updateToken(null);
            }
        }
    };

    const setToken = (token: string | null) => {
        if (token) {
            localStorage.setItem('token', token);
            updateToken(token);
        } else {
            localStorage.removeItem('token');
            updateToken(null);
        }
    };

    const subscribeToken = () => {
        window.addEventListener('storage', tokenListener);
        updateToken(localStorage.getItem('token'));
    };

    const handleError = (e: unknown) => {
        setUser(null);
        setAuthError(e?.toString() ?? 'error on auth');
        console.error(e);
    };

    const tryToken = () => {
        try {
            setIsLoading(true);
            AuthService.userInfo()
                .then((data) => {
                    setUser(data);
                    setAuthError(null);
                    onWebAppUserTokenFound({ user_id: data.id });
                    if (data?.mobile) {
                        setUserId(data?.mobile);
                    }
                    if (data?.organization) {
                        amplitudeIdentify(
                            new Identify().set(
                                'organization',
                                data?.organization?.id
                            )
                        );
                    }

                    // Paywall non-subscribers
                    // if (!data?.subscription_data && !data?.grandfathered) {
                    //     navigate('/welcome/sign-up?payment=true');
                    // }
                })
                .catch((err) => {
                    handleError(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } catch (e) {
            handleError(e);
            setIsLoading(false);
        }
    };

    // useEffect(() => {
    //     tryToken();
    // }, [token]);

    const refreshUser = throttle(tryToken, 500);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                isSubscribed,
                refreshUser,
                token,
                setToken,
                user,
                authError,
                authLoading: isLoading,
                didSignUp,
                setDidSignUp,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
