import { useEffect, useState } from 'react';
import { useAuth } from '../providers/AuthProvider';
import { AppSubscriptionTier } from '../services/subscriptions/types';

export const useIsProUser = () => {
    const { user } = useAuth();
    const [isProUser, setIsProUser] = useState<boolean>(false);

    useEffect(() => {
        setIsProUser(
            (user?.subscription_data &&
                [AppSubscriptionTier.PRO, AppSubscriptionTier.PREMIUM].includes(
                    user?.subscription_data?.tier || ('' as AppSubscriptionTier)
                )) ||
                false
        );
    }, [user?.subscription_data?.tier]);

    return { isProUser };
};
