import React, { cloneElement, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { ReviewsList } from './ReviewsList';
import './style.css';

const breakPoints = {
    xsmall: {
        centerSlidePercentage: 100,
    },
    small: {
        centerSlidePercentage: 76,
    },
    medium: {
        centerSlidePercentage: 40,
    },
    large: {
        centerSlidePercentage: 30,
    },
};

export const SectionReviewsCarousel = ({
    padding = 16,
    style,
}: {
    padding: number;
    style?: React.CSSProperties;
}) => {
    const reviews = ReviewsList();

    const content = reviews.map((review, index) =>
        cloneElement(review, { key: index })
    );

    const windowWidth = useWindowWidth();

    const width = windowWidth - 2 * padding;

    const breakPoint =
        width < 400
            ? 'xsmall'
            : width < 700
            ? 'small'
            : width < 1240
            ? 'medium'
            : 'large';

    return (
        <div
            style={{
                padding: `0 ${padding}px`,
                ...style,
                backgroundColor: '#F6F3EF',
            }}
        >
            <Carousel
                centerMode
                centerSlidePercentage={
                    breakPoints[breakPoint].centerSlidePercentage
                }
                infiniteLoop={true}
                autoPlay={true}
                selectedItem={4}
                interval={4000}
                transitionTime={2200}
                showIndicators={false}
                stopOnHover={false}
                showThumbs={false}
                showStatus={false}
                showArrows={false}
            >
                {content}
            </Carousel>
        </div>
    );
};

export const useWindowWidth = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return windowSize.innerWidth;
};

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

export default SectionReviewsCarousel;
