import { IFieldsDocument } from '../../components/DocumentTypes';
import { IUser } from '../auth/types';
import { Space } from '../spaces/types';

export interface IReceiptPayment {
    type: string | null;
    card_number: string | null;
}

export interface IReceipt {
    receipt_id: string;
    merchant?: string;
    amount?: number;
    date?: string;
    secondary_date?: string;
    secondary_amount?: number;
    additional_fields?: { [key: string]: any };
    created_at: string;
    image_url: string;
    icon_url?: string;
    notes: string;
    tags: string[];
    categories: IUserTag[];
    document_type: LegacyDocumentType;
    user_id: number;
    totals?: { [category_id: string]: number };
    image_ids?: string[];
    platform?: ReceiptPlatform | undefined;
    payment?: IReceiptPayment;
    sub_document_type?: string;
    highlight?: string;
    currency?: string;
    exchange_rate?: number;
    date_warning?: boolean;
    user?: IUser;
}

export interface ILineItem {
    item_id: string;
    item: string;
    total: number;
    receipt_id: string;
    tags: string[];
    category_ids: string[];
}

export interface IPlaidItem {
    item_id: string;
    institution: string;
    institution_id: string;
    created_at: string;
    accounts?: IPlaidAccount[];
}

export interface IPlaidAccount {
    account_id: string;
    name: string;
    mask: string;
    type: string;
}

export interface IPlaidTransaction {
    transaction_id: string;
    merchant_name: string;
    original_merchant_name?: string;
    account_owner?: string;
    full_name: string;
    amount: number;
    date: string;
    receipt_id?: string;
    account_id: string;
    logo_url?: string;
    personal_finance_category_icon_url?: string;
}

export type IImageData = { key: string; img: string };

export interface IProcessingReceipt extends IReceipt {
    parsed?: boolean;
}

export type IEditReceiptData = Partial<IReceipt> & {
    tag_ids?: string[];
    line_items?: ILineItem[];
    categoryAmount?: number;
};

export interface IUserPhoneName {
    mobile: string;
    first_name: string;
}

export interface IUserTag {
    tag_id: string;
    tagIds?: string[] | undefined;
    name: string;
    user?: IUser;
    user_id: number;
    shared_users: IUserPhoneName[];
    icon_id: string | null;
    category_group_ids?: number[];
    space_id?: number;
    space?: Space;
    order?: number;
}

export interface IUserCategoryGroup {
    id: number;
    name: string;
    icon: string | null;
    space_id?: number;
    order?: number;
}

export interface IUserTagResponse {
    status: 'error' | 'success';
    data: IUserTag[];
    errors?: { error: string; phone: string }[];
}

export type IReceiptSubtotal = {
    key: string;
    total: number;
    group: IReceipt[];
};

export enum ReceiptDocumentType {
    RECEIPT = 'RECEIPT',
    BILL = 'BILL',
    CARD = 'CARD',
    BUSINESSCARD = 'BUSINESSCARD',
    DOCUMENT = 'DOCUMENT',
    MILEAGE = 'MILEAGE',
}

export enum LegacyDocumentType {
    RECEIPT = 'RECEIPT',
    BILL = 'BILL',
    COUPON = 'COUPON',
    ID = 'ID',
    BUSINESSCARD = 'BUSINESSCARD',
    LOYALTY_CARD = 'LOYALTY_CARD',
    GIFT_CARD = 'GIFT_CARD',
    OTHER = 'OTHER',
    MILEAGE = 'MILEAGE',
}

export enum Experience {
    RECONCILIATION = 'reconciliation',
    RECONCILIATION_WTC = 'reconciliation_wtc',
    COST_ESTIMATION = 'cost-estimation',
    TAX_SAVINGS = 'tax-savings',
}

export enum AllDocumentType {
    ALL = 'ALL',
}

export interface IFilterState {
    dollar_min?: number;
    dollar_max?: number;
    date_start?: Date;
    date_end?: Date;
    document_type?: ReceiptDocumentType | undefined;
    remove_line_items?: boolean;
    date_filter_name?: string;
}

export type IField =
    | keyof IFieldsDocument
    | 'categories' // editingField.value is type IUserTag[]
    | 'amounts' // editingField.value is type IReceiptAmounts
    | 'images'
    | 'document-type'; // editingField.value is type ReceiptDocumentType

export type IEditingField = {
    field: IField;
    value: any | undefined;
};

export type IFolderNavProps =
    | {
          categoryId: string;
          categoryName: string;
      }
    | undefined;

export interface IProcessingLineItem {
    item_id: string;
    item: string;
    total: number;
    category_ids: string[];
    tags?: string[];
}

export interface IGetMatchingReceiptsResponse {
    status: 'success' | 'error';
    data: string[];
    metadata: IReceipt[];
}

export interface IPlaidCreateLinkTokenResponse {
    status: 'success' | 'error';
    data: string;
}

export interface IPlaidWebhookResponse {
    status: 'success' | 'error';
    data: any;
}

export interface IPlaidTransactionsResponse {
    status: 'success' | 'error';
    data?: IPlaidTransaction[];
    metadata?: any;
}

export interface IPlaidTransactionsExportResponse {
    status: 'success' | 'error';
    data?: string;
}

export interface IPlaidItemsResponse {
    status: 'success' | 'error';
    data: IPlaidItem[];
}

export type IReceiptAmounts = {
    lineItems: IProcessingLineItem[];
    total: number;
    tax: number;
    additionalFields: { [key: string]: any };
};

export enum ReceiptPlatform {
    VERYFI = 'veryfi',
    TEXTRACT = 'textract',
    TEXTRACT_REALTIME = 'textract-realtime',
    GALLERY = 'gallery',
    VERYFI_EMAIL = 'veryfi-email',
    TEXTRACT_EMAIL = 'textract-email',
    BLINK = 'blink',
    MANUAL = 'manual',
    DESKTOP = 'desktop',
}

export enum InboxTab {
    ALL = 'ALL',
    EMAIL = 'EMAIL',
    DESKTOP = 'DESKTOP',
    GALLERY = 'GALLERY',
    AMAZON = 'AMAZON',
    PAYPAL = 'PAYPAL',
    MILEAGE_TRACKING = 'MILEAGE_TRACKING',
}

export interface IProcessingReceiptData {
    merchant?: string;
    date?: string;
    amount?: number;
    notes?: string;
    secondary_amount?: number;
    secondary_date?: string;
}

export interface IProcessingCommitData {
    merchant?: string;
    date?: string;
    amount?: number;
    notes?: string;
    secondary_amount?: number;
    secondary_date?: string;
    document_type?: LegacyDocumentType;
    icon_url?: string;
    payment?: IReceiptPayment;
    line_items?: IProcessingLineItem[];
    categories?: IUserTag[];
    platform?: ReceiptPlatform;
    additional_fields?: { [key: string]: any };
    raw_text?: string;
    ocr_blocks?: any;
    currency?: string;
}

export interface IGetImageDataResponse {
    status: 'success' | 'error';
    error?: string;
    data: {
        count: number;
        current_page: number;
        has_next_page: boolean;
        results: IReceipt[];
    };
}

export interface ILoadReceiptsPageResponse {
    status: 'success' | 'error';
    error?: string;
    data: {
        count: number;
        current_page: number;
        has_next_page: boolean;
        results: IReceipt[];
        spaces?: {
            [spaceId: number]: Space;
        };
    };
}

export interface ILoadUncategorizedResponse {
    status: 'success' | 'error';
    error?: string;
    data: IReceipt[];
}

export interface ILoadReceiptsResponse {
    status: 'success' | 'error';
    error?: string;
    data: IReceipt[];
}

export interface ILoadInvalidationUpdatesResponse {
    status: 'success' | 'error';
    data: {
        load_many_invalidated: boolean;
    };
}

export interface ILoadImageIds {
    status: 'success' | 'error';
    data: string[];
}

export interface ILoadReceiptResponse {
    status: 'success' | 'error';
    error?: string;
    data: IReceipt;
}

export interface IReceiptExportResponse {
    status: 'success' | 'error';
    document_count: number;
}

export interface IReceiptUploadResponse {
    status: 'success' | 'error';
    data: string;
    metadata?: { receipt: IReceipt };
}

export interface IUserTagResponse {
    status: 'error' | 'success';
    data: IUserTag[];
    errors?: { error: string; phone: string }[];
}

export type ReceiptSearchResult = Partial<IReceipt> & {
    receipt_id: string;
    highlights?: [
        {
            field: string;
            matched_tokens: string[];
            snippet: string[];
        }
    ];
    match_score?: number;
};

export interface ISearchResponse {
    status: 'success' | 'error';
    data: {
        count: number;
        current_page: number;
        has_next_page: boolean;
        results: {
            receipts: ReceiptSearchResult[];
        };
    };
    error?: string;
}

export interface IReceiptUpdateResponseV2 {
    status: 'success' | 'error';
    data: IReceipt;
}

export interface IDownloadImagesResponse {
    status: 'success' | 'error';
    data: {
        url: string;
        file_url_id: string;
    };
}

export enum ScheduleFrequency {
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
}

export interface IScheduledState {
    enabled?: boolean;
    frequency?: string;
    frequency_on?: string;
    notify_type?: string;
}

export interface ICreateLineItemResponse {
    status: 'success' | 'error';
    data: ILineItem;
}

export interface IDeleteReceiptResponse {
    status: 'success' | 'error';
}

export interface ILoadAllProcessingResponse {
    status: 'success' | 'error';
    data: IReceipt[];
}

export interface IShareInvitation {
    category?: IUserTag;
    space?: Space;
    group?: IUserCategoryGroup;
    invitation_id: string;
    source_user: IUser;
    target_user: IUser;
    status: string;
}

export interface IShareTokenResponse {
    status: 'success' | 'error';
    error?: string;
    data?: {
        invitations: IShareInvitation[];
    };
}

/**
 * See: Discriminating Union Types
 * https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#discriminating-unions
 * - can use switch/case statements to narrow union types without errors
 */
type IUserTagWithType = IUserTag & { type: 'tag'; icon: string | null };
type IUserCategoryGroupWithType = IUserCategoryGroup & {
    type: 'group';
    icon: string | null;
};
export type ICategoryGroupWithItems = IUserCategoryGroupWithType & {
    items: IUserTag[];
};
export type ISpaceItem = IUserTagWithType | ICategoryGroupWithItems;
export type ISpaceWithItems = Space & {
    items: ISpaceItem[];
};

export interface IReceiptBulkCommitResponse {
    status: 'success' | 'error';
    data: IReceipt[];
}

export interface IGetProcessingLineItemResponse {
    status: 'success' | 'error';
    data?: IProcessingLineItem[];
}

export interface ICategorySuggestionResponse {
    status: 'success' | 'error';
    data?: {
        category: IUserTag | null;
    };
}

export interface IFolderIconSuggestionResponse {
    status: 'success' | 'error';
    data?: string[];
}
