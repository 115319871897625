import React from 'react';
import { Button } from '@mui/material';
import BubbleFilled from '../../../assets/icons/bubbleFilled.svg';
import BubbleEmpty from '../../../assets/icons/bubbleEmpty.svg';
import theme from '../../../theme';
import { Body, OnboardingSubheader } from '../../../components/Text';

const CheckableFeature = (_props: {
    header: string;
    body: React.ReactNode;
    selected: boolean;
    price: string;
    onPress?: () => void;
    style?: any;
}) => {
    const { header, body, selected, price, style, onPress } = _props;

    const underlayColor = 'rgba(0,0,0,0.06)';

    return (
        <Button
            style={{
                display: 'flex',
                backgroundColor: selected ? underlayColor : undefined,
                width: '100%',
                borderRadius: 5,
                borderColor: theme.colors.border,
                flexDirection: 'row',
                padding: 15,
                textTransform: 'none',
                alignItems: 'flex-start',
                ...style,
            }}
            disabled={!onPress}
            onClick={() => {
                onPress && onPress();
            }}
        >
            <>
                <div style={{ marginTop: 2 }}>
                    {selected ? (
                        <img src={BubbleFilled} alt="" width={24} height={24} />
                    ) : (
                        <img src={BubbleEmpty} alt="" width={24} height={24} />
                    )}
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: 12,
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <OnboardingSubheader
                            style={{
                                color: theme.colors.blue100,
                                fontWeight: '700',
                                textAlign: 'left',
                                width: '55%',
                            }}
                        >
                            {header}
                        </OnboardingSubheader>
                        <OnboardingSubheader
                            style={{
                                color: theme.colors.secondary,
                                paddingRight: 10,
                                fontWeight: '700',
                                width: '45%',
                                textAlign: 'right',
                            }}
                        >
                            {price}
                        </OnboardingSubheader>
                    </div>
                    <Body
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: 10,
                            color: theme.colors.blue60,
                            textAlign: 'left',
                        }}
                    >
                        {body}
                    </Body>
                </div>
            </>
        </Button>
    );
};

export default CheckableFeature;
