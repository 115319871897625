import { cloneElement } from 'react';
import { VariationImageElement } from '../configuration/types';

export const VariationSecondaryVisual = (props: VariationImageElement) => {
    if (props.type === 'ImageElementUrl') {
        return (
            <img
                src={props.url}
                alt={props.alt}
                width={250}
                style={{ alignSelf: 'center' }}
            />
        );
    }

    if (props.type === 'ImageElementComponent') {
        return cloneElement(props.component, { height: 65, width: 300 });
    }

    return null;
};
