import React, { lazy, useMemo, useRef, useState } from 'react';
import { CircularProgress, Container } from '@mui/material';
import Resizer from 'react-image-file-resizer';
import EXIF from 'exifr';
import { jsonrepair } from 'jsonrepair';
import { isMobile } from 'react-device-detect';
import { AddCircle } from '@mui/icons-material';
import theme from '../../theme';
import SectionStickyHeaderLogo from '../../components/LandingPageVariations/elements/SectionStickyHeaderLogo';
import PublicService from '../../services/public/service';
import {
    onWebAppPhotoAIClickUploadAnotherPhoto,
    onWebAppPhotoAIGotViewpoint,
    onWebAppPhotoAITakePicture,
    onWebAppPhotoAITagClick,
} from '../../tracking/trackers';
import getAddressFromCoordinates from '../../util/getAddressFromCoordinates';
import './index.css';
import { ButtonContainer } from '../../components/Buttons/BottomButton';
import { BodyBold } from '../../components/Text';

const DownloadBadges = lazy(
    () => import('../../screens/Payment/DownloadBadges')
);

type ProcessingImage = {
    id: string;
    image: string;
    latLng?: { lat: Number; lng: Number };
    geoLocation?: string;
    image_id?: string;
    viewpoint?: string;
    tags?: string[];
    viewpoints?: Record<string, ProcessingImage[]>;
};

const PhotoAIMulti = () => {
    const [search, setSearch] = useState<string>('');
    const [organizeBy, setOrganizeBy] = useState<'location'>('location');
    const [images, setImages] = useState<ProcessingImage[]>([]);

    const imageInputRef = useRef<HTMLInputElement>(null);

    const resizeFile = (file: File): Promise<Blob> => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1024,
                1024,
                'JPEG',
                85,
                0,
                //@ts-ignore
                (b: Blob) => {
                    resolve(b);
                },
                'blob'
            );
        });
    };

    const updateImage = (
        image_id: string,
        updateObject: Partial<ProcessingImage>
    ) => {
        setImages((v) => {
            const imageIndex = v.findIndex((i) => i.id === image_id);
            if (imageIndex === -1) return v;
            const newImages = [...v];
            newImages[imageIndex] = {
                ...newImages[imageIndex],
                ...updateObject,
            };
            return newImages;
        });
    };

    const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _files = event?.target?.files || [];
        for (let _file of _files) {
            if (!_file) continue;
            if (_file.type !== 'image/jpeg' && _file.type !== 'image/webp')
                continue;
            const id = Math.random().toString(36).substring(7);
            const image: ProcessingImage = {
                id,
                image: URL.createObjectURL(_file),
            };
            setImages((v) => [...v, image]);
            // @ts-ignore
            EXIF.gps(_file).then((gps) => {
                if (!gps) {
                    navigator?.geolocation?.getCurrentPosition((position) => {
                        updateImage(image?.id, {
                            latLng: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            },
                        });
                        getAddressFromCoordinates({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        })
                            .then((res) => {
                                updateImage(image?.id, {
                                    geoLocation: res,
                                });
                            })
                            .catch((e) => {
                                console.error('error', e);
                            });
                    });
                } else {
                    image.latLng = { lat: gps?.latitude, lng: gps?.longitude };
                    updateImage(image?.id, {
                        latLng: {
                            lat: gps?.latitude,
                            lng: gps?.longitude,
                        },
                    });
                    getAddressFromCoordinates({
                        latitude: gps?.latitude,
                        longitude: gps?.longitude,
                    })
                        .then((res) => {
                            updateImage(image?.id, {
                                geoLocation: res,
                            });
                        })
                        .catch((e) => {
                            console.error('error', e);
                        });
                }
            });
            resizeFile(_file).then((resizedFile) => {
                PublicService.uploadImage(resizedFile).then((res) => {
                    let isCamera = false;
                    EXIF.parse(_file)
                        .then((data) => {
                            if (!data?.ExifVersion) {
                                isCamera = true;
                            }
                        })
                        .finally(() => {
                            onWebAppPhotoAITakePicture({
                                image_id: res.data.image_ids[0],
                                exp_name: 'oct-3-photo-ai-location',
                                is_camera: isCamera,
                                number_of_images: images.length,
                            });
                        });

                    const imageId = res.data.image_ids[0];
                    updateImage(image?.id, {
                        image_id: imageId,
                    });
                    PublicService.getPhotoInformation(imageId).then((res) => {
                        let data = res.data?.location
                            ?.replace(/```/g, '')
                            ?.replace('json', '');
                        let json: any = {};
                        try {
                            json = JSON.parse(data);
                        } catch (e) {
                            console.error('error', e, data);
                            data = jsonrepair(data);
                            try {
                                json = JSON.parse(data);
                            } catch (e) {
                                console.error('error', e, data);
                            }
                        }
                        onWebAppPhotoAIGotViewpoint({
                            image_id: imageId,
                            viewpoint: json?.Location,
                            tags: json?.Tags,
                        });
                        updateImage(image?.id, {
                            viewpoint: json?.Location,
                            tags: json?.Tags,
                        });
                    });
                });
            });
        }
    };

    const downloadBadges = useMemo(() => {
        return !process.isServer ? (
            <DownloadBadges
                alternativeWebUrl="/"
                customData={{
                    source: 'photo-ai',
                    experience: 'photo-ai',
                }}
                branchAppStoreUrl="https://apps.apple.com/app/simplywise-receipt-scanner/id1538521095?ppid=e78e02eb-b58c-48b8-a142-c7b5f2e8e641"
                branchPlayStoreUrl="https://play.google.com/store/apps/details?id=com.simplywise.mobile&listing=estimator"
                black
            />
        ) : null;
    }, []);

    const downloadApp = () => {
        onWebAppPhotoAIClickUploadAnotherPhoto();
        setTimeout(() => {
            (
                document.getElementById('app-store-badge') ||
                document.getElementById('play-store-badge')
            )?.click();
        }, 1000);
    };

    const imagesArrayWithPlaceholders = [
        ...images,
        ...new Array(images?.length > 4 ? 0 : 4 - images?.length).fill({}),
    ];
    const imagesWithoutTags = images?.filter((image) => !image?.tags);
    const imagesWithGeolocation = images.filter((image) => image?.geoLocation);
    const imagesGroupedByLocation = imagesWithGeolocation?.reduce(
        (acc, image) => {
            if (!image?.geoLocation) return acc;
            if (!acc[image?.geoLocation]) {
                acc[image?.geoLocation] = [];
            }
            acc[image?.geoLocation].push(image);
            return acc;
        },
        {} as Record<string, ProcessingImage[]>
    );
    // For each item in grouped by geolocation add a new propery as a grouped by viewpoint
    Object.keys(imagesGroupedByLocation)?.forEach((location) => {
        const images = imagesGroupedByLocation[location];
        const imagesGroupedByViewpoint = images?.reduce((acc, image) => {
            if (!image?.viewpoint) return acc;
            if (!acc[image?.viewpoint]) {
                acc[image?.viewpoint] = [];
            }
            acc[image?.viewpoint].push(image);
            return acc;
        }, {} as Record<string, ProcessingImage[]>);
        // @ts-ignore
        images.viewpoints = imagesGroupedByViewpoint;
    });

    // const isLoading = images?.some((v) => !v?.tags);

    return (
        <Container
            maxWidth={false}
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: theme.colors.white,
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <div style={{ display: 'none' }}>{downloadBadges}</div>
            <SectionStickyHeaderLogo
                expName={'photo-ai-page'}
                pageId={'photo-ai-page'}
                headerMode={undefined}
                onClick={() => {}}
            >
                <div></div>
            </SectionStickyHeaderLogo>
            <input
                type="file"
                ref={imageInputRef}
                accept="image/*;capture=filesystem"
                multiple
                onChange={onUploadImage}
                style={{ display: 'none' }}
            />
            <div
                style={{
                    display: 'flex',
                    justifyContent: images?.length ? 'flex-start' : 'center',
                    alignItems: 'center',
                    flex: 1,
                    width: '100%',
                    flexDirection: 'column',
                    padding: '0 20px',
                }}
            >
                <div style={{ marginTop: 20 }} />
                {!images?.length ? (
                    <ButtonContainer
                        style={{
                            maxWidth: 300,
                        }}
                        onClick={() => {
                            imageInputRef.current?.click();
                        }}
                    >
                        <BodyBold>Open Gallery</BodyBold>
                    </ButtonContainer>
                ) : null}

                {images?.length ? null : (
                    <div
                        style={{
                            fontWeight: '700',
                            marginTop: 10,
                            color: 'gray',
                            fontSize: 14,
                        }}
                    >
                        Upload Photos to Get Started
                    </div>
                )}
                <div style={{ height: 50 }}></div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        padding: '20px 0px',
                        maxWidth: isMobile ? '100%' : 800,
                    }}
                >
                    {(images?.length
                        ? imagesWithoutTags
                        : imagesArrayWithPlaceholders
                    )?.map((image) => (
                        <div
                            key={image.id}
                            style={{
                                margin: 10,
                            }}
                        >
                            {image.image ? (
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <img
                                        src={image.image}
                                        style={{
                                            width: 150,
                                            height: 'auto',
                                            borderRadius: 10,
                                        }}
                                    />
                                    {!image?.tags ? (
                                        <CircularProgress
                                            size={25}
                                            color="secondary"
                                            thickness={10}
                                            style={{
                                                position: 'absolute',
                                                top: -15,
                                                right: -15,
                                            }}
                                        />
                                    ) : null}
                                </div>
                            ) : (
                                <div
                                    style={{
                                        width: 150,
                                        height: 150,
                                        backgroundColor: 'rgba(0,0,0,0.1)',
                                        borderRadius: 10,
                                    }}
                                />
                            )}
                        </div>
                    ))}

                    <div>
                        {Object.keys(imagesGroupedByLocation)?.map(
                            (location, key) => {
                                const _lok =
                                    imagesGroupedByLocation?.[location];
                                return (
                                    <div
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            margin: 10,
                                            padding: 10,
                                            borderRadius: 10,
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: 14,
                                                fontWeight: '700',
                                                color: 'gray',
                                            }}
                                        >
                                            Project
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 26,
                                                fontWeight: '700',
                                                color: 'black',
                                            }}
                                        >
                                            {location || 'Project A'}
                                        </div>
                                        <div style={{ height: 10 }} />
                                        <div>
                                            {/* @ts-ignore */}
                                            {Object.keys(_lok?.viewpoints)?.map(
                                                (view, key) => {
                                                    const _loc =
                                                        imagesGroupedByLocation?.[
                                                            location
                                                        ];
                                                    const _view =
                                                        // @ts-ignore
                                                        _loc?.viewpoints?.[
                                                            view
                                                        ];
                                                    return (
                                                        <>
                                                            <div
                                                                style={{
                                                                    fontSize: 18,
                                                                    fontWeight:
                                                                        '400',
                                                                    color: 'white',
                                                                    backgroundColor:
                                                                        '#1c1c1c',
                                                                    width: 'fit-content',
                                                                    padding:
                                                                        '5px 20px',
                                                                    borderRadius: 5,
                                                                }}
                                                            >
                                                                {view}
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    flexWrap:
                                                                        'wrap',
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                            >
                                                                {_view?.map(
                                                                    (
                                                                        image: ProcessingImage
                                                                    ) => (
                                                                        <div
                                                                            key={
                                                                                image.id
                                                                            }
                                                                            style={{
                                                                                margin: 10,
                                                                                width: 250,
                                                                            }}
                                                                        >
                                                                            {image.image ? (
                                                                                <div
                                                                                    style={{
                                                                                        position:
                                                                                            'relative',
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src={
                                                                                            image.image
                                                                                        }
                                                                                        style={{
                                                                                            width: '100%',
                                                                                            height: 'auto',
                                                                                            borderRadius: 10,
                                                                                        }}
                                                                                    />
                                                                                    {!image?.tags ? (
                                                                                        <CircularProgress
                                                                                            size={
                                                                                                25
                                                                                            }
                                                                                            color="secondary"
                                                                                            thickness={
                                                                                                10
                                                                                            }
                                                                                            style={{
                                                                                                position:
                                                                                                    'absolute',
                                                                                                top: -15,
                                                                                                right: -15,
                                                                                            }}
                                                                                        />
                                                                                    ) : (
                                                                                        <div
                                                                                            style={{
                                                                                                display:
                                                                                                    'flex',
                                                                                                flexDirection:
                                                                                                    'row',
                                                                                                alignItems:
                                                                                                    'center',
                                                                                                flexWrap:
                                                                                                    'wrap',
                                                                                            }}
                                                                                        >
                                                                                            {image?.tags?.map(
                                                                                                (
                                                                                                    tag,
                                                                                                    key
                                                                                                ) => (
                                                                                                    <div
                                                                                                        key={
                                                                                                            key
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            onWebAppPhotoAITagClick(
                                                                                                                {
                                                                                                                    image_id:
                                                                                                                        image?.image_id!,
                                                                                                                    tag,
                                                                                                                }
                                                                                                            );
                                                                                                        }}
                                                                                                        style={{
                                                                                                            borderRadius: 10,
                                                                                                            // unique color for each tag with transparency
                                                                                                            backgroundColor: `rgba(${Math.floor(
                                                                                                                Math.random() *
                                                                                                                    255
                                                                                                            )},${Math.floor(
                                                                                                                Math.random() *
                                                                                                                    255
                                                                                                            )},${Math.floor(
                                                                                                                Math.random() *
                                                                                                                    255
                                                                                                            )},0.4)`,
                                                                                                            padding: 10,
                                                                                                            fontSize: 12,
                                                                                                            lineHeight: 0.5,
                                                                                                            margin: 2,
                                                                                                            maxWidth: 150,
                                                                                                            cursor: 'pointer',
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            tag
                                                                                                        }
                                                                                                    </div>
                                                                                                )
                                                                                            )}
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <div
                                                                                    style={{
                                                                                        width: 150,
                                                                                        height: 150,
                                                                                        backgroundColor:
                                                                                            'rgba(0,0,0,0.1)',
                                                                                        borderRadius: 10,
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        </>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                        )}
                    </div>

                    {/* {isLoading ? (
                    <>
                        <div
                            style={{
                                gap: 10,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flex: 1,
                                width: '100%',
                                flexDirection: 'column',
                                padding: '0 20px',
                            }}
                        >
                            <CircularProgress
                                size={40}
                                style={{ color: theme.colors.secondary }}
                            />
                            <div style={{ fontSize: 15 }}>
                                Performing magic...
                            </div>
                        </div>
                    </>
                ) : null} */}

                    {images?.length ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 30,
                            }}
                        >
                            <AddCircle
                                style={{
                                    color: theme.colors.secondary,
                                    fontSize: 50,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    downloadApp();
                                }}
                            />
                            <div
                                style={{
                                    fontSize: 12,
                                    color: theme.colors.primary,
                                    marginTop: 5,
                                }}
                            >
                                Add More
                            </div>
                        </div>
                    ) : null}
                </div>
                <div style={{ marginTop: 10 }} />
            </div>
        </Container>
    );
};

export default PhotoAIMulti;
