import { VariationTextElement } from '../configuration/types';

export const VariationHeader = ({
    props,
    isMobile,
}: {
    props: VariationTextElement;
    isMobile?: boolean;
}) => {
    if (props.type !== 'TextElement' || !props.render) {
        return null;
    }

    const lines = props.text.split('\n').map((line, index) => {
        return (
            <p
                style={{
                    margin: 0,
                    ...props.style,
                    fontSize: isMobile ? 35 : props.style?.fontSize,
                }}
                key={index}
            >
                {line}
            </p>
        );
    });

    return <>{lines}</>;
};
