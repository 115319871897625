// import { TextFieldProps } from '@mui/material';
import { useEffect } from 'react';

const useKeydownListener = (callback: (keyCode: number) => void) => {
    const onKeydown = (e: KeyboardEvent) => {
        callback(e.keyCode);
    };

    useEffect(() => {
        document.addEventListener('keydown', onKeydown);
        return () => {
            document.removeEventListener('keydown', onKeydown);
        };
    }, []);
};

export default useKeydownListener;
