import { useEffect } from 'react';
import { Identify, identify } from '@amplitude/analytics-browser';
import { useLocation } from 'react-router-dom';
import { onWebAppOnboardingOriginWebUrlFound } from '../../tracking/trackers';
import { useQuery } from '../../util/hooks';
import { useAmplitude } from '../../providers/AmplitudeProvider';
import { landingPageVariationsConfig } from '../../landingPageVariationsConfig';

// sets the originWebUrl in localStorage
// by default sets it to the current pathname
// but can also be set from params so we can track landing page attribution (users might start in a FB browser, and end up in Safari, for ex)
const useOriginWebUrl = () => {
    const { isInitialized } = useAmplitude();
    const location = useLocation();

    const query = useQuery();
    const originWebUrl = query.get('origin_web_url');

    const setOriginWebUrl = (url: string) => {
        // create storage event
        const event = new Identify();
        event.set('origin_web_url', url);
        window.dispatchEvent(
            new StorageEvent('storage', {
                key: 'originWebUrl',
                newValue: url,
            })
        );
        identify(event);
        // set in local storage
        localStorage.setItem('originWebUrl', url);
        const variantConfig = landingPageVariationsConfig.find(
            (variation) => variation.path === url.split('lp-')[1]
        );
        if (variantConfig) {
            localStorage.setItem(
                'landingPageName',
                variantConfig?.landingPageName
            );
        }
    };

    // capture it from query params, if exists
    useEffect(() => {
        if (originWebUrl) {
            if (isInitialized) {
                onWebAppOnboardingOriginWebUrlFound();
            }
            setOriginWebUrl(originWebUrl);
        } else {
            setOriginWebUrl(location.pathname);
        }
    }, [isInitialized, originWebUrl, location.pathname]);
};

export default useOriginWebUrl;
