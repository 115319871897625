import { SectionWrapper } from '../../../screens/Landing/styles';
import theme from '../../../theme';
import OrangeStars from '../../../assets/landing/orange_stars.svg';

export const ReviewsList = () =>
    REVIEWS_DATA.map((review) => (
        <SectionWrapper
            style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 10,
                padding: '16px 0',
                height: '100%',
                backgroundColor: 'transparent',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    maxWidth: 450,
                    width: '90%',
                    height: '100%',
                    backgroundColor: theme.colors.white,
                    padding: '16px 16px 16px 24px',
                    borderRadius: 4,
                    boxShadow: theme.reviewsShadow,
                    gap: 16,
                }}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: 4,
                        }}
                    >
                        <span
                            style={{
                                fontSize: '1.2rem',
                                fontWeight: 'bold',
                                display: 'block',
                            }}
                        >
                            {review.title}
                        </span>
                        <img
                            src={OrangeStars}
                            alt=""
                            style={{
                                width: 100,
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: review.title ? 'column' : 'row',
                            alignItems: 'flex-end',
                            gap: review.title ? 4 : 8,
                        }}
                    >
                        <span
                            style={{
                                fontSize: '1rem',
                                color: theme.colors.disabled,
                            }}
                        >
                            {review.date}
                        </span>
                        <span
                            style={{
                                fontSize: '1rem',
                                color: theme.colors.disabled,
                            }}
                        >
                            {review.user}
                        </span>
                    </div>
                </div>
                <span style={{ textAlign: 'left', fontSize: '1rem' }}>
                    {review.body}
                </span>
            </div>
        </SectionWrapper>
    ));

export const B = ({ children }: { children: string }) => (
    <span style={{ fontWeight: '700' }}>{children}</span>
);

const REVIEWS_DATA = [
    // {
    //     user: 'Zupplemental',
    //     date: 'Feb 20',
    //     title: 'Love this App!!',
    //     body: (
    //         <span>
    //             “I can't believe how well the scanning feature works. Every
    //             single receipt I've scanned has imported the merchant and date
    //             correctly”
    //         </span>
    //     ),
    // },
    {
        user: 'bobbyburnsjr',
        date: 'Nov 03',
        title: '',
        body: (
            <span>
                “I’ve tried many receipt apps, <B>none come close</B> to
                SimplyWise. It’s <B>extremely accurate</B>, capturing dates,
                dollar amounts, vendors and much more.”
            </span>
        ),
    },
    {
        user: 'rayosmith58',
        date: 'Dic 20',
        title: '',
        body: (
            <span>
                “Very good app. <B>Easy</B> to add and print{' '}
                <B>detailed spreadsheets</B>.”
            </span>
        ),
    },
    {
        user: 'sgamemno',
        date: 'Nov 18',
        title: '',
        body: (
            <span>
                “This app is <B>great</B>. I use it for receipts, documents,
                etc... best of all <B>outstanding</B> customer service.”
            </span>
        ),
    },
    {
        user: 'Breamreaper',
        date: 'Oct 13',
        title: '',
        body: (
            <span>
                “<B>Worth every penny!</B> I’m very critical on apps as they all
                seem to have shortcomings. I honestly cannot find any reason to
                dislike this app!”
            </span>
        ),
    },
    {
        user: '#soonRetired',
        date: 'Sep 21',
        title: '',
        body: (
            <span>
                “<B>Love the App!</B> I can’t believe I just found this app!
                It’s amazing and will make my life much easier.”
            </span>
        ),
    },
    {
        user: 'apeamh75',
        date: 'Sep 17',
        title: '',
        body: (
            <span>
                “This is my <B>favorite</B> app ever!! <B>Amazing</B> - it makes
                my life <B>so easy and organized!</B> No more pieces of paper
                all over the place, receipts scattered, sorting through every
                bill.
            </span>
        ),
    },
    {
        user: 'quincyboyselvin',
        date: 'Sep 15',
        title: '',
        body: (
            <span>
                “Having the ability to declutter paper yet have important
                information at my fingertips helps me feel <B>in control</B> and{' '}
                <B>less stressed</B>.”
            </span>
        ),
    },
    {
        user: 'chemistrygirl2007',
        date: 'Sep 15',
        title: '',
        body: (
            <span>
                “This is the <B>easiest</B> app I’ve found to help organize all
                the paper documents, receipts for the farm.{' '}
                <B>Definitely worth the $$</B>.”
            </span>
        ),
    },
    {
        user: 'eby58',
        date: 'Aug 08',
        title: '',
        body: (
            <span>
                “I was very reluctant to believe it could do all it claims, but
                was very surprised how <B>precise</B> and <B>easy</B> it is.”
            </span>
        ),
    },
    {
        user: 'stylishrealtor',
        date: 'Nov 07',
        title: '',
        body: (
            <span>
                “This app is so <B>awesome</B> and now my CPA loves me because
                no more receipts. <B>Highly recommend</B> it especially for us
                real estate agents.”
            </span>
        ),
    },
];
