import { CircularProgress, styled } from '@mui/material';
import Button from '@mui/material/Button';
import theme from '../../theme';
import { OnboardingSubheader } from '../Text';
import { hexToRGB } from '../../util/hexToRGB';

export type IBottomButtonProps = {
    text: string;
    mode?: 'text' | 'contained' | 'outlined' | undefined;
    disabled?: boolean;
    isLoading?: boolean;
    onClick: () => void;
    style?: any;
    textStyle?: any;
    icon?: any | null;
    noshadow?: boolean;
};

export const BOTTOM_BUTTON_HEIGHT = 55;

export const ButtonContainer = styled(Button)<{
    disabled?: boolean;
    mode?: 'text' | 'contained' | 'outlined' | undefined;
    noshadow?: boolean;
}>`
    width: 80%;
    background-color: ${(props) =>
        props.disabled
            ? theme.colors.blue20
            : props.mode === 'outlined'
            ? theme.colors.white
            : props.mode === 'text'
            ? 'transparent'
            : theme.colors.secondary};
    color: ${(props) =>
        props.mode === 'outlined' || props.mode === 'text'
            ? theme.colors.secondary
            : theme.colors.white};
    border: ${(props) =>
        props.mode === 'outlined'
            ? `1px solid ${theme.colors.secondary}`
            : undefined};
    height: ${BOTTOM_BUTTON_HEIGHT}px;
    border-radius: 100px;
    text-transform: none;
    ${(props) => props.noshadow && 'box-shadow: none'};

    &:hover {
        background-color: ${(props) =>
            hexToRGB(
                props.disabled
                    ? theme.colors.blue20
                    : props.mode === 'outlined'
                    ? theme.colors.white
                    : props.mode === 'text'
                    ? 'transparent'
                    : theme.colors.secondary,
                0.8
            )};
    }
`;

const BottomButton = (button: IBottomButtonProps) => {
    const Icon = button.icon || null;
    return (
        <ButtonContainer
            key={button.text}
            disabled={button.disabled}
            onClick={button.onClick}
            variant={button.mode ?? 'contained'}
            mode={button.mode}
            noshadow={button.noshadow}
            style={{
                ...button.style,
            }}
        >
            {Icon}
            <OnboardingSubheader
                style={{
                    color:
                        button.mode === 'outlined' || button.mode === 'text'
                            ? theme.colors.secondary
                            : theme.colors.white,
                    ...button.textStyle,
                }}
            >
                {button.isLoading ? (
                    <CircularProgress
                        style={{
                            color: theme.colors.white,
                            marginTop: 5,
                        }}
                        size={20}
                    />
                ) : (
                    button.text
                )}
            </OnboardingSubheader>
        </ButtonContainer>
    );
};

export default BottomButton;
