import { Button } from '@mui/material';
import styled from 'styled-components';
import theme from '../../theme';
import { APP_FOOTER_HEIGHT } from '../../components/Drawer';

export const FolderListContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    justify-content: center;
    padding-bottom: ${APP_FOOTER_HEIGHT}px;
`;

export const ToBeSavedContainer = styled(Button)`
    text-decoration: none;
    text-transform: none;
    box-shadow: ${theme.defaultShadow};
    line-height: 1.2px;
    background-color: ${theme.colors.white};
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    margin-top: 5px;
    border-radius: 10px;

    &:hover {
        background-color: ${theme.colors.folderHover};
        border-width: 0px;
        box-shadow: ${theme.defaultShadowDark};
    }
`;

export const RedNumberCircle = styled.div({
    borderRadius: 25,
    width: 25,
    height: 25,
    backgroundColor: theme.colors.error,
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const ChevronRightContainer = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
});
