import { AxiosResponse } from 'axios';
import axios from '../../util/axios';
import { IStandardResponse } from '../../types/API';
import { dispatchAndUpdateStore } from '../../util/dispatchAndUpdateStore';
import { StoreKey } from '../../types/storeNames';
import {
    IReceiptCategoryGroupResponse,
    IReceiptCategoryGroupsResponse,
} from './types';

const ORGANIZATION_STORES = [
    StoreKey.CATEGORY_GROUPS,
    StoreKey.TAGS,
    StoreKey.SPACES,
];

export default class CategoryGroupService {
    public static getCategoryGroups(): Promise<IReceiptCategoryGroupsResponse> {
        return axios
            .post(
                '/receipts/tags/groups/list',
                {},
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            .then(
                (value: AxiosResponse<IReceiptCategoryGroupsResponse>) =>
                    value.data
            );
    }

    public static createCategoryGroup(
        name: string,
        categoryIds: string[],
        icon?: string | null,
        spaceId?: number
    ): Promise<IReceiptCategoryGroupResponse> {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/tags/groups/create', {
                    name,
                    icon,
                    category_ids: categoryIds,
                    space_id: spaceId,
                })
                .then((value: AxiosResponse<IReceiptCategoryGroupResponse>) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }

    public static assignCategoryGroup(
        categoryId: string,
        categoryGroupId: number
    ): Promise<IStandardResponse> {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/tags/groups/assign', {
                    category_id: categoryId,
                    category_group_id: categoryGroupId,
                })
                .then((value: AxiosResponse<IStandardResponse>) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }

    public static unassignCategoryGroup(
        folderId: string,
        categoryGroupId: number
    ): Promise<IStandardResponse> {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/tags/groups/unassign', {
                    category_id: folderId,
                    category_group_id: categoryGroupId,
                })
                .then((value: AxiosResponse<IStandardResponse>) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }

    public static editCategoryGroup(
        categoryGroupId: number,
        name: string,
        icon?: string | null
    ): Promise<IStandardResponse> {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/tags/groups/edit', {
                    name,
                    icon,
                    category_group_id: categoryGroupId,
                })
                .then((value: AxiosResponse<IStandardResponse>) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }

    public static deleteCategoryGroup(
        categoryGroupId: number
    ): Promise<IStandardResponse> {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/tags/groups/delete', {
                    category_group_id: categoryGroupId,
                })
                .then((value: AxiosResponse<IStandardResponse>) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }
}
