import React, { useContext, useEffect, useState } from 'react';
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import LogRocket from 'logrocket';
import HotTable from '@handsontable/react';
import {
    ILineItem,
    IProcessingLineItem,
    IReceipt,
    IUserTag,
} from '../../services/receipts/types';
import { IReport } from '../../services/sharedreviews/types';
import ReportService from '../../services/sharedreviews/service';
import { iNavigationStateOnboardingReport } from '../Onboarding/ReportFlow/LoadReport';
import {
    onWebAppDownloadReport,
    onWebAppDownloadReportCompleted,
    onWebAppViewReport,
} from '../../tracking/trackers';
import { DATE_ANDROID_RESTART_PAYMENT_DATE } from '../../util/constants';
import { Space } from '../../services/spaces/types';
import identify from '../../tracking/identify';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { number } from '../../util/devices';
import { useAsyncEffect } from '../../util/hooks';
import { ISummaryData, useSummaryData } from './summaryHelper';
import { useHandlePrint } from './useHandlePrint';
import { useReportReceipts } from './useReportReceipts';
import { getReportCategories } from './getSharedReviewCategories';

/**
 * Big picture plan here: we want SOME of this context to apply to all spreadsheets, folder and shared review
 * But we want other parts like permissions and shared review info to be specific to shared review
 *
 * So I think we should copy this context over into another file, and make that apply to everything.
 *
 * Separate things:
 * - how we fetch receipts
 * - fetching the actual shared review
 */
export interface IReportContext {
    sharedReviewId: string | undefined;
    sharedReview: IReport | undefined;
    receipts: IReceipt[];
    setReceipts: React.Dispatch<React.SetStateAction<IReceipt[]>>;
    isLoadingReceipts: boolean;
    isLoadingFirstReceipt: boolean;
    isOnboarding: boolean;
    isWebOnboarding: boolean;
    isMobileOnboarding: boolean;
    sourceIsMobileApp: boolean;
    isDemoReport: boolean;
    isIntroductionDemoReport: boolean;
    manualCodeEntry: boolean;
    tab: 'summary' | 'list';
    setTab: (tab: 'summary' | 'list') => void;
    summaryData: ISummaryData;
    categories: IUserTag[];
    spaces: Space[];
    showMargins: boolean;
    hasNoReportsAccess: boolean;
    showUpgradeMembershipPrompt: boolean;
    setShowUpgardeMembershipPrompt: (show: boolean) => void;
    printableSummaryComponentRef: React.MutableRefObject<
        null | HTMLDivElement | HotTable
    >;
    renderSummaryForPrint: boolean;
    setRenderSummaryForPrint: (render: boolean) => void;
    printableSpreadsheetComponentRef: React.MutableRefObject<
        null | HTMLDivElement | HotTable
    >;
    renderSpreadsheetForPrint: boolean;
    setRenderSpreadsheetForPrint: (render: boolean) => void;
    summaryWidth: number;
    companyName?: string | null;
    companyLogoUrl?: string | null;
    getLineItems: (
        receiptId: string
    ) => Promise<(ILineItem | IProcessingLineItem)[]>;
}

const ReportContext = React.createContext<IReportContext>({
    sharedReviewId: undefined,
    sharedReview: undefined,
    receipts: [],
    setReceipts: () => {},
    isLoadingReceipts: true,
    isLoadingFirstReceipt: true,
    isOnboarding: false,
    isWebOnboarding: false,
    isMobileOnboarding: false,
    sourceIsMobileApp: false,
    isDemoReport: false,
    isIntroductionDemoReport: false,
    manualCodeEntry: false,
    summaryData: {},
    categories: [],
    spaces: [],
    tab: 'summary',
    setTab: () => {},
    showMargins: false,
    hasNoReportsAccess: false,
    showUpgradeMembershipPrompt: false,
    setShowUpgardeMembershipPrompt: () => {},
    printableSpreadsheetComponentRef: { current: null },
    renderSpreadsheetForPrint: false,
    setRenderSpreadsheetForPrint: () => {},
    printableSummaryComponentRef: { current: null },
    renderSummaryForPrint: false,
    setRenderSummaryForPrint: () => {},
    summaryWidth: 0,
    companyName: null,
    companyLogoUrl: null,
    getLineItems: async () => [],
});

const ReportProvider = ({ children }: { children: React.ReactNode }) => {
    const guestToken = localStorage.getItem('guestToken');

    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const state = location.state as iNavigationStateOnboardingReport | null;

    const { width } = useWindowDimensions();
    const [companyName, setCompanyName] = useState<string | null>(null);
    const [companyLogoUrl, setCompanyLogoUrl] = useState<string | null>(null);

    const isWebOnboarding = state?.sourceIsOnboarding === true;
    const isDemoReport = state?.isDemoReport === true;
    const isIntroductionDemoReport = state?.isIntroductionDemoReport === true;
    const manualCodeEntry = state?.manualCodeEntry === true;
    const sourceIsMobileAppParam = searchParams.get('sourceIsMobileApp');
    const sourceIsMobileApp = sourceIsMobileAppParam === 'true';
    const isMobileOnboardingParam = searchParams.get('isMobileOnboarding');
    const isMobileOnboarding = isMobileOnboardingParam === 'true';
    const isOnboarding = isMobileOnboarding || isWebOnboarding;
    const source = sourceIsMobileApp ? 'mobile' : 'desktop';

    const [sharedReview, setReport] = useState<IReport>();
    const [tab, setTab] = useState<'summary' | 'list'>('list');
    const [showUpgradeMembershipPrompt, setShowUpgardeMembershipPrompt] =
        useState<boolean>(false);

    // Purpose of these hooks is solely to refactor the code out of this file. Don't invoke these from anywhere else to avoid circular dependencies
    const {
        printableComponentRef: printableSummaryComponentRef,
        renderForPrint: renderSummaryForPrint,
        setRenderForPrint: setRenderSummaryForPrint,
    } = useHandlePrint(() => {
        onWebAppDownloadReport({
            file_type: 'print',
            shared_review_id: id ?? '',
            source,
            tab: 'summary',
        });
        onWebAppDownloadReportCompleted({
            file_type: 'print',
            shared_review_id: id ?? '',
            source,
            tab: 'summary',
        });
    });
    const {
        renderForPrint: renderSpreadsheetForPrint,
        setRenderForPrint: setRenderSpreadsheetForPrint,
        printableComponentRef: printableSpreadsheetComponentRef,
    } = useHandlePrint(() => {
        onWebAppDownloadReport({
            file_type: 'print',
            shared_review_id: id ?? '',
            source,
            tab: 'spreadsheet',
        });
        onWebAppDownloadReportCompleted({
            file_type: 'print',
            shared_review_id: id ?? '',
            source,
            tab: 'spreadsheet',
        });
    });
    const {
        receipts,
        setReceipts,
        spaces,
        isLoadingReceipts,
        isLoadingFirstReceipt,
    } = useReportReceipts();
    const categories = getReportCategories(sharedReview, receipts);
    const summaryData = useSummaryData(receipts, categories, tab === 'summary');
    // gives spreadsheet a margin if exceeds tablet_sm width
    const showMargins = width > number.TABLET_SM;
    const searchParamsObj: { [key: string]: string | null } = {};
    searchParams.forEach((value, key) => {
        searchParamsObj[key] = value;
    });

    const hasNoReportsAccess =
        !!sharedReview &&
        !(
            ['premium', 'pro'].includes(
                sharedReview?.user?.subscription_data?.tier || ''
            ) ||
            new Date(sharedReview?.user?.created_at || '1970') <
                DATE_ANDROID_RESTART_PAYMENT_DATE
        );

    useEffect(() => {
        let nameParam = searchParams.get('companyName');
        if (nameParam && width < number.MOBILE_LG) {
            if (nameParam.length > 25) {
                setCompanyName(nameParam.substring(0, 22) + '...');
            } else {
                setCompanyName(nameParam);
            }
        } else {
            setCompanyName(nameParam);
        }
        const logoUrlParam = searchParams.get('companyLogoUrl');

        if (logoUrlParam !== null) {
            const decodedLogoUrl = decodeURIComponent(logoUrlParam);
            setCompanyLogoUrl(decodedLogoUrl);
        } else {
            setCompanyLogoUrl(null);
        }
    }, [companyName, companyLogoUrl]);

    useEffect(() => {
        if (hasNoReportsAccess) {
            identify(sharedReview?.user?.mobile);
            onWebAppViewReport({
                shared_review_id: id || '',
                source,
                hasNoReportsAccess: true,
                isV2: true,
                extra_params: searchParamsObj,
            });
        }
    }, [hasNoReportsAccess]);

    useAsyncEffect(async () => {
        const res = await ReportService.loadReport(id).catch((error) => {
            if (error.response?.status === 404)
                navigate(`/shared-review/${id}/error`);
            else navigate(`/shared-review/${id}/pin`);
        });
        if (res?.data) {
            setReport(res.data);
        }
    }, [guestToken]);

    useEffect(() => {
        onWebAppViewReport({
            shared_review_id: id || '',
            source,
            isV2: true,
            extra_params: searchParamsObj,
        });
        if (isWebOnboarding) {
            LogRocket.track('ReportOnboarding');
        } else if (isMobileOnboarding) {
            LogRocket.track('ReportOnboardingMobile');
        } else if (sourceIsMobileApp) {
            LogRocket.track('ReportMobile');
        } else {
            LogRocket.track('Report');
        }
    }, []);

    const summaryWidth = Math.min(800, width * (showMargins ? 1 : 0.9));

    const getLineItems = async (receiptId: string) => {
        const receipt = receipts.find((r) => r.receipt_id === receiptId);
        if (!receipt || !id) return [];
        return (await ReportService.getLineItems(id, receiptId)) ?? [];
    };

    return (
        <ReportContext.Provider
            value={{
                sharedReviewId: id,
                sharedReview,
                receipts,
                setReceipts,
                isLoadingReceipts,
                isLoadingFirstReceipt,
                isOnboarding,
                isWebOnboarding,
                isMobileOnboarding,
                sourceIsMobileApp,
                isDemoReport,
                isIntroductionDemoReport,
                manualCodeEntry,
                tab,
                setTab,
                summaryData,
                categories,
                spaces,
                showMargins,
                hasNoReportsAccess,
                showUpgradeMembershipPrompt,
                setShowUpgardeMembershipPrompt,
                printableSummaryComponentRef,
                renderSummaryForPrint,
                setRenderSummaryForPrint,
                renderSpreadsheetForPrint,
                setRenderSpreadsheetForPrint,
                printableSpreadsheetComponentRef,
                summaryWidth,
                companyName,
                companyLogoUrl,
                getLineItems,
            }}
        >
            {children}
        </ReportContext.Provider>
    );
};

export const useReport = () => useContext(ReportContext);

export default ReportProvider;
