import styled from 'styled-components';
import theme from '../../theme';

export const SurveyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
    padding: 5px;
    border: 1px solid ${theme.colors.divider};
`;
