import Header from '../../components/Header';
import { useAuth } from '../../providers/AuthProvider';
import theme from '../../theme';

const SectionHeader = ({ text }: { text: string }) => {
    return <div style={{ fontWeight: '700', fontSize: 24 }}>{text}</div>;
};

const Integrations = () => {
    const { user } = useAuth();
    const simplywiseEmail = user?.simplywise_email_id
        ? user?.simplywise_email_id + '@docs.simplywise.com'
        : '';

    return (
        <>
            <Header showDrawer title="Integrations" />
            <div style={{ padding: 40 }}>
                <SectionHeader text="Auto-forwarding" />
                <div style={{ fontSize: 14, marginTop: 20 }}>
                    {
                        'Forward e-receipts to this email to add them to SimplyWise:'
                    }
                </div>
                <div
                    style={{
                        fontSize: 14,
                        fontWeight: '700',
                        color: theme.colors.secondary,
                    }}
                >
                    {simplywiseEmail}
                </div>
            </div>
        </>
    );
};

export default Integrations;
