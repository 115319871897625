import { useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LeafForHeader from '../../assets/leafForHeader.svg';
import Logo from '../../assets/logoWhite.svg';
import BottomButton, {
    BOTTOM_BUTTON_HEIGHT,
} from '../../components/Buttons/BottomButton';
import ClickableText from '../../components/Buttons/ClickableText';
import DesktopModal from '../../components/DesktopModal';
import Header from '../../components/Header';
import {
    onWebAppLoginFailure,
    onWebAppLogin,
    onWebAppPasswordResetFailure,
    onWebAppVerifyCodeFailure,
    onWebAppSubmitNewPasswordFailure,
    onWebAppLoginClickSignup,
    onWebAppClickForgotPassword,
} from '../../tracking/trackers';
import {
    InputCustom,
    PasswordInput,
    PasswordInputCustom,
    PhoneNumberInputCustom,
} from '../../components/Inputs';
import { SignUpGoogleButton } from '../../components/Onboarding/SignUpGoogleButton';
import identify from '../../tracking/identify';
import { useAuth } from '../../providers/AuthProvider';
import useConnectEmail from '../Onboarding/useConnectEmail';
import Signup from '../Onboarding/Signup';
import OnboardingBackground from '../../components/OnboardingBackground';
import { Body, BodyBold, FormHeader } from '../../components/Text';
import useKeydownListener from '../../hooks/useKeydownListener';
import useStateRef from '../../hooks/useStateRef';
import AuthService from '../../services/auth/service';
import theme from '../../theme';

import { number as deviceWidths } from '../../util/devices';
import { useQuery } from '../../util/hooks';
import unformatPhoneInput from '../../util/unformatMobile';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { LoginContainer, LoginScreenContainer } from './styles';

export const MIN_MOBILE_LENGTH = 10;
export const MIN_PASSWORD_LENGTH = 6;
export const CODE_LENGTH = 6;

declare global {
    interface Window {
        myEvent: any;
    }
}

const Login = ({
    screen: initialScreen,
}: {
    screen:
        | 'login'
        | 'forgot-password'
        | 'verify-code'
        | 'new-password'
        | 'signup';
}) => {
    const { setToken } = useAuth();
    const navigate = useNavigate();
    const location = useLocation() as any;
    const { token } = useAuth();
    const isMobile = useMediaQuery(`(max-width:${deviceWidths.TABLET}px)`);
    const { setSnackbar } = useSnackbar();

    const query = useQuery();
    const queryMobile = query.get('mobile');
    const variant = query.get('variant');
    const oauthParams = query.get('$deeplink_path');
    const data = JSON.parse(oauthParams?.split('data=')[1] || '{}');
    const oauthToken = data?.token;
    const oauthMobile = data?.mobile;

    const shareToken = query.get('share_token') || undefined;
    const organizationToken = query.get('organization_token') || undefined;

    const { startGoogleOauth } = useConnectEmail(
        () => {},
        'api/v2/auth/oauth/google/login',
        {
            acquired_from: 'web',
            only_if_existent: true,
        },
        [
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/userinfo.email',
        ]
    );

    // Note: we use useStateRef because it prevents the useKeydownListener callback from using stale values
    const [screen, setScreen, screenRef] = useStateRef<
        'login' | 'forgot-password' | 'verify-code' | 'new-password' | 'signup'
    >(initialScreen);
    const [mobile, setMobile, mobileRef] = useStateRef<string>(
        queryMobile || ''
    );
    const [password, setPassword, passwordRef] = useStateRef<string>('');
    const [code, setCode, codeRef] = useStateRef<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
        undefined
    );
    const [showForgotPasswordModal, setShowForgotPasswordModal] =
        useState<boolean>(false);
    const redirectTo = useRef(location?.state?.redirectTo).current;
    const formerPath = useRef(location?.state?.from?.pathname).current;
    const formerState = useRef(location?.state?.from?.state).current;
    const experience = useRef(location?.state?.experience).current;
    const stateOptions = useRef(location?.state?.options ?? {}).current;

    const navigateToFormerPath = () => {
        const options: any = stateOptions ?? {};
        if (!redirectTo && formerPath) {
            options.state = formerState;
        }
        navigate(
            organizationToken
                ? `/organization/accept/${organizationToken}`
                : shareToken
                ? `/share/accept/${shareToken}`
                : redirectTo ?? formerPath ?? '/app',
            options
        );
    };

    useEffect(() => {
        if (errorMessage) {
            setSnackbar({
                status: 'error',
                message: errorMessage,
            });
        }
    }, [errorMessage]);

    // If the user is already logged in, redirect them to the app
    useEffect(() => {
        if (token) {
            navigateToFormerPath();
        }
    }, []);

    useEffect(() => {
        if (oauthToken) {
            if (oauthMobile) identify(oauthMobile);
            onWebAppLogin({
                mobile: '1' + oauthMobile,
                provider: 'google',
                oauth: true,
            });
            setToken(oauthToken);
            navigateToFormerPath();
        }
    }, [oauthToken]);

    useKeydownListener((keyCode) => {
        // if keyCode is for enter, submit
        if (keyCode === 13) {
            handleSubmit();
        }
    });

    const loginSubmit = () => {
        const formattedMobile = unformatPhoneInput(mobileRef.current);
        setIsLoading(true);
        setErrorMessage(undefined);
        AuthService.login(formattedMobile, passwordRef.current)
            .then((res) => {
                const token = res.token;
                identify(formattedMobile);
                onWebAppLogin({ mobile: '1' + mobileRef.current });
                setToken(token);
                navigateToFormerPath();
            })
            .catch((error) => {
                console.error('There was an error!', error);
                setErrorMessage(
                    `Wrong credentials. Please verify mobile number or password and try again.`
                );
                const { message } = error;
                onWebAppLoginFailure({
                    mobile: '1' + mobileRef.current,
                    message,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const appleLoginSubmit = (appleEvent: any) => {
        setErrorMessage(undefined);

        if (
            appleEvent?.error ||
            !appleEvent?.authorization?.id_token ||
            !appleEvent?.user?.email
        ) {
            setErrorMessage('There was an error with Apple login');
            onWebAppLoginFailure({
                mobile: '',
                message: appleEvent?.error,
            });
            return;
        }

        AuthService.validateAppleAuth(
            appleEvent?.authorization?.id_token,
            {
                firstName: appleEvent?.user?.fullName?.givenName || '',
                lastName: appleEvent?.user?.fullName?.familyName || '',
            },
            false,
            true
        )
            .then((response) => {
                if (!response.data) {
                    throw new Error(
                        'Error in conneting to your account. Please try again.'
                    );
                }
                const data = response.data;

                setToken(response.data?.token);
                navigateToFormerPath();
                onWebAppLogin({
                    mobile: data?.mobile,
                    oauth: true,
                    provider: 'apple',
                });
            })
            .catch((e) => {
                setErrorMessage('There was an error with Apple login');
                onWebAppLoginFailure({
                    mobile: '',
                    message: e?.message,
                });
            });
    };

    const forgotPasswordSubmit = () => {
        const formattedMobile = unformatPhoneInput(mobileRef.current);
        setIsLoading(true);
        setErrorMessage(undefined);
        AuthService.requestPasswordReset(formattedMobile)
            .then(() => {
                setScreen('verify-code');
            })
            .catch((error) => {
                console.error('There was an error!', error);
                setErrorMessage(`Sorry, there was an error on our end.`);
                onWebAppPasswordResetFailure();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const verifyCodeSubmit = () => {
        const formattedMobile = unformatPhoneInput(mobileRef.current);
        setIsLoading(true);
        setErrorMessage(undefined);
        AuthService.verifyAccount(formattedMobile, codeRef.current)
            .then(() => {
                setScreen('new-password');
            })
            .catch((error) => {
                console.error('There was an error!', error);
                setErrorMessage(
                    `Code not recognized. Please verify code and try again.`
                );
                onWebAppVerifyCodeFailure();
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const newPasswordSubmit = () => {
        const formattedMobile = unformatPhoneInput(mobileRef.current);
        setIsLoading(true);
        setErrorMessage(undefined);
        AuthService.setPassword(
            formattedMobile,
            codeRef.current,
            passwordRef.current
        )
            .then(() => {
                loginSubmit();
            })
            .catch((error) => {
                console.error('There was an error!', error);
                setErrorMessage(`Sorry, there was an error on our part`);
                onWebAppSubmitNewPasswordFailure();
            });
    };

    const bottomTextStyle: React.CSSProperties = {
        color: theme.colors.blue60,
        fontSize: 14,
        fontWeight: '500',
    };

    const handleSubmit = () => {
        switch (screenRef.current) {
            case 'login':
                loginSubmit();
                break;
            case 'forgot-password':
                forgotPasswordSubmit();
                break;
            case 'verify-code':
                verifyCodeSubmit();
                break;
            case 'new-password':
                newPasswordSubmit();
                break;
            default:
                break;
        }
    };

    const submitIsDisabled = (() => {
        if (isLoading) return true;
        switch (screenRef.current) {
            case 'login':
                return (
                    !mobile ||
                    mobile.length < MIN_MOBILE_LENGTH ||
                    password.length < MIN_PASSWORD_LENGTH
                );
            case 'forgot-password':
                return !mobile || mobile.length < MIN_MOBILE_LENGTH;
            case 'verify-code':
                return !code || code.length < CODE_LENGTH;
            case 'new-password':
                return !password || password.length < MIN_PASSWORD_LENGTH;
            default:
                return true;
        }
    })();

    const SubmitButton = ({ style }: { style?: React.CSSProperties }) => (
        <BottomButton
            text={'Submit'}
            onClick={handleSubmit}
            isLoading={isLoading}
            disabled={submitIsDisabled}
            style={{ width: '100%', ...style }}
        />
    );

    const loginRendered = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
                paddingTop: 20,
                maxWidth: 500,
            }}
        >
            {
                {
                    'login': (
                        <LoginScreenContainer>
                            <FormHeader>Log In</FormHeader>
                            <div style={{ padding: 10 }} />
                            {location?.state?.message ? (
                                <div
                                    style={{
                                        backgroundColor: theme.colors.blue10,
                                        padding: 10,
                                        borderRadius: 5,
                                        marginBottom: 20,
                                        fontSize: 16,
                                        textAlign: 'center',
                                        color: theme.colors.blue60,
                                    }}
                                >
                                    {location?.state?.message}
                                </div>
                            ) : null}
                            <PhoneNumberInputCustom
                                value={mobile}
                                setValue={(s: string) => setMobile(s)}
                            />
                            <div style={{ margin: 10 }} />
                            <PasswordInputCustom
                                value={password}
                                setValue={setPassword}
                            />
                            <ClickableText
                                text={'Forgot my password'}
                                onClick={() => {
                                    onWebAppClickForgotPassword();
                                    setPassword('');
                                    setScreen('forgot-password');
                                }}
                                style={{
                                    alignSelf: 'flex-start',
                                    color: theme.colors.secondary,
                                    textDecoration: 'underline',
                                    fontSize: 14,
                                    marginTop: 5,
                                    marginBottom: 10,
                                    fontWeight: '500',
                                }}
                            />
                            <SubmitButton />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    alignSelf: 'center',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        height: 1,
                                        backgroundColor: theme.colors.blue20,
                                        width: '40%',
                                    }}
                                />
                                <div
                                    style={{
                                        width: '20%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Body
                                        style={{
                                            color: theme.colors.blue60,
                                            marginTop: 30,
                                            marginBottom: 30,
                                            fontWeight: '600',
                                        }}
                                    >
                                        or
                                    </Body>
                                </div>
                                <div
                                    style={{
                                        height: 1,
                                        backgroundColor: theme.colors.blue20,
                                        width: '40%',
                                    }}
                                />
                            </div>
                            <SignUpGoogleButton
                                onClick={startGoogleOauth}
                                text={'Continue with Google'}
                                style={{
                                    height: BOTTOM_BUTTON_HEIGHT,
                                    width: '100%',
                                }}
                            />
                            <div style={{ margin: 10 }} />
                            {/* <AppleLogin
                                    clientId="com.simplywise.mobile"
                                    redirectURI="https://simplywise.com/login"
                                    usePopup
                                    callback={appleLoginSubmit}
                                    scope="email name"
                                    responseMode="query"
                                    responseType="id_token"
                                    render={(renderProps) => (
                                        <SignUpAppleButton
                                            onClick={renderProps.onClick}
                                            text={'Login with Apple'}
                                        />
                                    )}
                                /> */}
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'baseline',
                                    marginTop: 20,
                                    marginBottom: 20,
                                }}
                            >
                                <Body style={bottomTextStyle}>
                                    Don't have an account?
                                </Body>
                                <ClickableText
                                    text={'Sign up'}
                                    onClick={() => {
                                        onWebAppLoginClickSignup({
                                            formerPath: formerPath || '/',
                                        });
                                        navigate(
                                            '/signup?manualCodeEntry=true',
                                            {
                                                state: {
                                                    ...location.state,
                                                },
                                            }
                                        );
                                    }}
                                    style={{
                                        ...bottomTextStyle,
                                        color: theme.colors.secondary,
                                        textDecoration: 'underline',
                                        marginLeft: -5,
                                    }}
                                />
                            </div>
                        </LoginScreenContainer>
                    ),
                    'forgot-password': (
                        <LoginScreenContainer>
                            <FormHeader>Reset Password</FormHeader>
                            <div style={{ padding: 10 }} />
                            <PhoneNumberInputCustom
                                value={mobile}
                                setValue={setMobile}
                            />
                            <ClickableText
                                text={'Back to Login'}
                                onClick={() => {
                                    setScreen('login');
                                }}
                                style={{
                                    ...bottomTextStyle,
                                    marginTop: 20,
                                }}
                            />
                            <SubmitButton style={{ marginTop: 20 }} />
                        </LoginScreenContainer>
                    ),
                    'verify-code': (
                        <LoginScreenContainer>
                            <FormHeader>We texted you a code</FormHeader>
                            <div style={{ padding: 10 }} />
                            <InputCustom
                                inputProps={{
                                    value: code,
                                    onChange: (e) => setCode(e.target.value),
                                    placeholder: 'Code',
                                    type: 'number',
                                    maxLength: 6,
                                }}
                            />
                            <ClickableText
                                text={'Send again'}
                                onClick={() => {
                                    setScreen('forgot-password');
                                }}
                                style={{
                                    marginTop: 5,
                                    color: theme.colors.blue60,
                                }}
                            />
                            <SubmitButton style={{ marginTop: 20 }} />
                        </LoginScreenContainer>
                    ),
                    'new-password': (
                        <LoginScreenContainer>
                            <FormHeader>Enter New Password</FormHeader>
                            <div style={{ padding: 10 }} />
                            <PasswordInput
                                value={password}
                                setValue={setPassword}
                                toggleVisibility
                            />
                            <SubmitButton style={{ marginTop: 20 }} />
                        </LoginScreenContainer>
                    ),
                    'signup': (
                        <div style={{ width: '100%' }}>
                            <Signup
                                shareToken={shareToken}
                                organizationToken={organizationToken}
                                goNext={() => {
                                    if (variant === 'organization') {
                                        navigate('/teams/payment');
                                    } else {
                                        navigateToFormerPath();
                                    }
                                }}
                                experience={experience}
                            />
                        </div>
                    ),
                }[screen]
            }
        </div>
    );

    return (
        <>
            {isMobile ? (
                <OnboardingBackground>
                    <>{loginRendered}</>
                </OnboardingBackground>
            ) : variant === 'organization' ? (
                <>
                    <div>
                        <div
                            style={{
                                width: '100%',
                            }}
                        >
                            <img
                                alt=""
                                src={LeafForHeader}
                                width={500}
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                }}
                            />
                            <div style={{ margin: 'auto' }}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100vh',
                                        display: 'flex',
                                        flexDirection: isMobile
                                            ? 'column'
                                            : 'row',
                                        alignItems: 'center',
                                        backgroundColor: '#181C3F',
                                        padding: '5%',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: isMobile ? '100%' : '50%',
                                        }}
                                    >
                                        <img
                                            src={Logo}
                                            alt="logo"
                                            width={200}
                                        />
                                        <div style={{ marginTop: 25 }} />
                                        <div
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: '3.5rem',
                                                color: 'white',
                                            }}
                                        >
                                            For Teams
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: isMobile ? '100%' : '50%',
                                            flex: 1,
                                            paddingBottom: 40,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginTop: 40,
                                        }}
                                    >
                                        {loginRendered}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <LoginContainer>
                    <Header />
                    <LoginScreenContainer style={{ maxWidth: 600 }}>
                        {loginRendered}
                    </LoginScreenContainer>
                </LoginContainer>
            )}
            <DesktopModal
                visible={showForgotPasswordModal}
                setVisible={setShowForgotPasswordModal}
            >
                <>
                    <BodyBold style={{ marginBottom: 20 }}>
                        To reset your password, open the SimplyWise app on your
                        phone, and follow these instructions:
                    </BodyBold>
                    <Body style={{ marginTop: 10 }}>1. Log out</Body>
                    <Body style={{ marginTop: 10 }}>2. Tap "Get Started"</Body>
                    <Body style={{ marginTop: 10 }}>
                        3. Enter your mobile number and then tap "Continue"
                    </Body>
                    <Body style={{ marginTop: 10 }}>
                        4. On the "Log In" screen, tap on "Forgot password?
                        Reset here"
                    </Body>
                    <Body style={{ marginTop: 10 }}>
                        5. Enter the code that is texted to you
                    </Body>
                    <Body style={{ marginTop: 10 }}>
                        6. Create your new password
                    </Body>
                </>
            </DesktopModal>
        </>
    );
};

export default Login;
