import styled from 'styled-components';
import { Button } from '@mui/material';
import theme from '../../../theme';
import { BORDER_RADIUS, POST_WIDTH } from './constants';

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.screen};
    align-items: center;
    width: 100%;
`;

export const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: ${POST_WIDTH}px;
    padding: 0px 20px;
`;

export const ChargeFinderBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const ChargeFinderSectionContainer = styled.div<{
    windowWidth?: number;
}>`
    display: flex;
    flex-direction: column;
    box-shadow: ${theme.sectionShadow};
    background-color: ${theme.colors.white};
    padding: 24px;
    margin-top: 20px;
    border-radius: ${BORDER_RADIUS}px;
    width: 100%;
    max-width: 1200px;
`;

export const BodyListItem = styled(Button)<{ variant: 'text' }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;
    margin: 10px;
    align-items: center;
    text-transform: none;
`;
