import mileageIcon from '../../assets/icons/details/mileage.svg';
import storeIcon from '../../assets/icons/store.svg';
import { FieldLabel } from '../../components/Text';
import { IReceipt, LegacyDocumentType } from '../../services/receipts/types';
import theme from '../../theme';
import { formatAmount } from '../../util/formatAmount';
import { getReceiptTotal } from '../../util/getReceiptTotal';
import { truncateText } from '../../util/truncateText';
import { ReceiptListItemContainer, ReceiptListItemName } from './styles';

export const LIST_ITEM_HEIGHT = 70;

const listItemStyles: React.CSSProperties = {
    padding: 15,
    height: LIST_ITEM_HEIGHT,
};

type IReceiptListItemProps = {
    receipt: IReceipt;
    selectedReceiptId: string | undefined;
    onSetReceiptState: (r: IReceipt) => void;
    folderId?: string;
};

const ReceiptListItem = (props: IReceiptListItemProps) => {
    const { receipt, selectedReceiptId, onSetReceiptState, folderId } = props;

    const selected = receipt.receipt_id === selectedReceiptId;

    const _onClick = () => {
        onSetReceiptState(receipt);
    };

    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    } as const;
    let date: Date | undefined = receipt.date
        ? new Date(receipt.date)
        : undefined;
    let dateFormat = date?.toLocaleDateString('en-US', options) ?? '';

    const amount = getReceiptTotal(
        receipt.amount,
        receipt.totals || {},
        folderId,
        undefined,
        receipt.exchange_rate
    );

    return (
        <div style={{ backgroundColor: theme.colors.white, width: '100%' }}>
            <ReceiptListItemContainer
                onClick={_onClick}
                selected={selected}
                style={{
                    ...listItemStyles,
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <img
                        src={
                            receipt.icon_url
                                ? receipt.icon_url
                                : receipt.document_type ===
                                  LegacyDocumentType.MILEAGE
                                ? mileageIcon
                                : storeIcon
                        }
                        alt="Icon"
                        style={{
                            height: 30,
                            width: 30,
                            paddingRight: 10,
                            objectFit: 'contain',
                        }}
                    />
                    <div
                        className="ms-2 me-auto"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            alignSelf: 'flex-end',
                        }}
                    >
                        <FieldLabel>{dateFormat}</FieldLabel>
                        <ReceiptListItemName>
                            {receipt.document_type ===
                            LegacyDocumentType.MILEAGE
                                ? `Mileage Expense (${
                                      receipt.additional_fields?.miles?.value ||
                                      0
                                  } miles)`
                                : truncateText(
                                      receipt.merchant
                                          ? receipt.merchant
                                          : 'Unknown Merchant',
                                      40
                                  )}
                        </ReceiptListItemName>
                    </div>
                </div>
                <ReceiptListItemName>
                    {formatAmount(amount)}
                </ReceiptListItemName>
            </ReceiptListItemContainer>
        </div>
    );
};

export default ReceiptListItem;
