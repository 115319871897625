import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * Forces scroll to top on every react router redirect
 */
export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        scrollToTop();
    }, [pathname]);

    return null;
}

export const scrollToTop = () => window.scrollTo(0, 0);
