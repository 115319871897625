import { Container } from '@mui/material';
import { useAnimate } from 'framer-motion';
import React, { lazy, useEffect, useMemo, useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import SectionStickyHeaderLogo from '../../components/LandingPageVariations/elements/SectionStickyHeaderLogo';
import PublicService from '../../services/public/service';
import theme from '../../theme';
import {
    onWebAppCostEstimationClickDownload,
    onWebAppCostEstimationComplete,
    onWebAppCostEstimationFurtherQuestionsShown,
    onWebAppCostEstimationLoadVariant,
    onWebAppCostEstimationTakePicture,
    onWebAppCostEstimationViewPaywall,
} from '../../tracking/trackers';
import { useQuery } from '../../util/hooks';
import { useAuth } from '../../providers/AuthProvider';
import { getOS } from '../../util/isIOS';
import AddDescription from './components/AddDescription';
import FinalStep from './components/FinalStep';
import SurveyStep from './components/SurveyStep';
import TakePhoto from './components/TakePhoto';

const DownloadBadges = lazy(
    () => import('../../screens/Payment/DownloadBadges')
);

const CostEstimatorAppStoreUrl =
    'https://apps.apple.com/us/app/simplywise-cost-estimator/id6670619664';

const CostEstimator = () => {
    const query = useQuery();

    const imageInputRef = useRef<HTMLInputElement>(null);

    const [imageId, setImageId] = useState<string | undefined>(undefined);
    const [image, setImage] = useState<string | undefined>(undefined);
    const [step, setStep] = useState<number>(0);
    const [prompt, setPrompt] = useState<string>('');
    const [newInstruction, setNewInstruction] = useState<string>('');
    const [costEstimation, setCostEstimation] = useState<string | undefined>(
        undefined
    );
    const [userType, setUserType] = useState<string | undefined>(undefined);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
    const [showPaywallModal, setShowPaywallModal] = useState<boolean>(false);
    const [, setMessageHistory] = useState<string[]>([]);
    const [questionLoading, setQuestionLoading] = useState<boolean>(false);
    const [questionsToAnswer, setQuestionsToAnswer] = useState<
        { question: string; choices: string[] }[]
    >([]);
    const [initialQuestionCount, setInitialQuestionCount] = useState<number>(0);
    const [isAddMarkup, setIsAddMarkup] = useState<boolean>(true);

    const costEstimationLines = costEstimation
        ?.replaceAll('Total,,,,', 'Total,,,')
        .replaceAll('total,,,,', 'total,,,')
        ?.split('\n');
    const tableLineStart = costEstimationLines?.findIndex((l) =>
        l.toLowerCase().replaceAll(' ', '').includes('item,quantity')
    );
    let tableLineEnd = -1;
    if (tableLineStart && costEstimationLines)
        for (let i = tableLineStart + 1; i < costEstimationLines?.length; i++) {
            if (costEstimationLines[i].split(',').length > 3) tableLineEnd = i;
            else {
                break;
            }
        }
    const costEstimationLineItems =
        (tableLineStart && tableLineEnd
            ? costEstimationLines
                  ?.slice(tableLineStart + 1, tableLineEnd + 1)
                  .map((l) =>
                      l
                          .split(',')
                          .map((s, i) =>
                              i >= 1 ? s.replace(/[^\d.,]/g, '') : s
                          )
                  )
                  .filter((l) => !l[0].toLowerCase().includes('total'))
            : undefined) || [];

    const costEstimationLineItemsOriginal =
        (tableLineStart && tableLineEnd
            ? costEstimationLines
                  ?.slice(tableLineStart + 1, tableLineEnd + 1)
                  .map((l) => l.split(','))
                  .filter((l) => !l[0].toLowerCase().includes('total'))
            : undefined) || [];

    const addDollarSigns = (line: string[]) => {
        return line.map((s, i) =>
            i < 2 || isNaN(parseFloat(s.replace('$', ''))) ? s : `$${s}`
        );
    };

    const estimateTotalBeforeMarkup = costEstimationLineItems
        ?.filter((line: string[]) => line[0] !== 'Markup')
        .reduce((acc: string, line: string[]) => {
            const [, , , t] = line;
            return (parseFloat(acc) + parseFloat(t.replace('$', ''))).toFixed(
                2
            );
        }, '0');
    const estimateTotal = costEstimationLineItems?.reduce(
        (acc: string, line: string[]) => {
            const [, , , t] = line;
            return (parseFloat(acc) + parseFloat(t.replace('$', ''))).toFixed(
                2
            );
        },
        '0'
    );

    const downloadText = useMemo(() => {
        if (!userType) return ['', ''];
        if (
            userType === 'landlord' ||
            userType === 'homeowner' ||
            userType === 'other'
        ) {
            const rand = Math.floor(Math.random() * 2);
            if (rand === 0)
                return [
                    'Stay on budget with AI',
                    'Track every project expense with the #1 construction receipt tracker',
                ];
            return [
                'Keep your project budget on track',
                'Track every project expense with the #1 construction receipt tracker',
            ];
        }
        const rand = Math.floor(Math.random() * 4);
        if (rand === 0)
            return [
                'Make more money on each job',
                'Track expenses by job with the #1 construction receipt tracker',
            ];

        if (rand === 1)
            return [
                'Turn estimates into earnings',
                'Track expenses by job with the #1 construction receipt tracker',
            ];
        if (rand === 2)
            return [
                'Turn estimates into profits',
                'Track expenses by job with the #1 construction receipt tracker',
            ];
        return [
            'Use AI to automate more admin work',
            'Track every project expense with the #1 construction receipt tracker',
        ];
    }, [userType]);

    useEffect(() => {
        if (!downloadText[0].length) return;
        onWebAppCostEstimationLoadVariant({
            variant: downloadText[0],
        });
    }, [downloadText]);

    const resizeFile = (file: File): Promise<Blob> => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1024,
                1024,
                'JPEG',
                85,
                0,
                //@ts-ignore
                (b: Blob) => {
                    resolve(b);
                },
                'blob'
            );
        });
    };

    const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _file = event?.target?.files?.[0];
        if (!_file) return;
        if (_file.type !== 'image/jpeg') return;
        resizeFile(_file)
            .then((resizedFile) => {
                PublicService.uploadImage(resizedFile)
                    .then((res) => {
                        onWebAppCostEstimationTakePicture({
                            image_id: res.data.image_ids[0],
                            exp_name: 'aug-25-remove-paywall',
                        });
                        setImageId(res.data.image_ids[0]);
                        setImage(URL.createObjectURL(resizedFile));
                        // PublicService.getImageAnalyze(
                        //     res.data.image_ids[0]
                        // ).then((res) => {
                        //     setImageAnalyze(res.data.cost_estimation);
                        // });
                    })
                    .catch((err) => {});
            })
            .catch(() => {});
        setStep(1);
    };

    const { token } = useAuth();

    const onEstimateComplete = (isRefine: boolean = false) => {
        setIsCompleted(true);
        onWebAppCostEstimationComplete({
            image_id: imageId || '',
            prompt,
        });
        if (!isRefine) {
            PublicService.logCostEstimationResult(
                token!,
                imageId || '',
                prompt,
                JSON.stringify(costEstimationLineItems),
                undefined,
                false
            ).then(() => {
                // fetchQuota();
            });
        }
    };

    const animateText = async (
        fullText: string,
        markAsCompleted: boolean = true,
        isRefine: boolean = false
    ) => {
        let text = '';
        setIsCompleted(false);
        const stepSize = markAsCompleted ? 100 : 1000;
        for (let i = 0; i < fullText.length; i += stepSize) {
            text += fullText.substring(i, i + stepSize);
            setCostEstimation(text);
            await new Promise((resolve) => setTimeout(resolve, 150));
        }
        if (markAsCompleted) {
            onEstimateComplete(isRefine);
        }
    };

    const downloadApp = () => {
        onWebAppCostEstimationClickDownload();
        const os = getOS();
        setTimeout(() => {
            if (os === 'macos' || os === 'ios') {
                window.location.href = CostEstimatorAppStoreUrl;
                return;
            }
            (
                document.getElementById('app-store-badge') ||
                document.getElementById('play-store-badge')
            )?.click();
        }, 1000);
    };

    const downloadBadges = useMemo(() => {
        return !process.isServer ? (
            <DownloadBadges
                alternativeWebUrl="/"
                customData={{
                    source: 'cost-estimation',
                    initial_landing: query.get('initial-landing') || '',
                    experience: 'cost-estimation',
                }}
                branchAppStoreUrl={CostEstimatorAppStoreUrl}
                branchPlayStoreUrl="https://play.google.com/store/apps/details?id=com.simplywise.mobile&listing=estimator"
                black
            />
        ) : null;
    }, []);

    const userTypeList = useMemo(
        () =>
            [
                {
                    text: 'Contractor',
                    value: 'contractor',
                },
                {
                    text: 'Landlord',
                    value: 'landlord',
                },
                {
                    text: 'Homeowner',
                    value: 'homeowner',
                },
            ]
                .map((value) => ({
                    value,
                    sort: Math.random(),
                }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value),
        []
    );

    const ctaType = useMemo(() => {
        return 'appstore';
    }, []);

    useEffect(() => {
        if (step === 2 && imageId && prompt) {
            setTimeout(() => {
                PublicService.getCostEstimation(imageId, prompt).then((res) => {
                    const fullText = res.data.cost_estimation;
                    // animateText(fullText);
                    setMessageHistory([]);

                    const questions = res.data.questions
                        ? [
                              ...res.data.questions,
                              {
                                  question:
                                      'What is the average labor cost per hour ?',
                                  choices: [
                                      '$25',
                                      '$50',
                                      '$100',
                                      '$150',
                                      '$200',
                                      'Unsure',
                                  ],
                              },
                          ]
                        : [];
                    setQuestionsToAnswer(questions);
                    animateText(fullText, questions.length === 0);
                    setInitialQuestionCount(questions.length);
                    onWebAppCostEstimationFurtherQuestionsShown({
                        image_id: imageId || '',
                        prompt,
                        question_count: res.data.questions.length,
                    });
                });
            }, (Math.random() * 1 + 0.4) * 1000);
        }
    }, [step, imageId, prompt]);

    useEffect(() => {
        if (isCompleted && userType) {
            // setTimeout(() => setShowFeedbackModal(true), 12000);
        }
    }, [isCompleted, userType]);

    useEffect(() => {
        if (showPaywallModal) {
            onWebAppCostEstimationViewPaywall();
        }
    }, [showPaywallModal]);

    const refineEstimate = (instruction: string, isRefine: boolean = true) => {
        PublicService.getCostEstimation(
            imageId!,
            // messageHistory.join('\n') +
            //     '\n' +
            instruction,
            "Here's your estimate:\nItem,Quantity,Unit Price,Total\n" +
                costEstimationLineItems
                    .map((l) => addDollarSigns(l).join(','))
                    .join('\n'),
            prompt
        ).then((res) => {
            setMessageHistory((prev) => [...prev, instruction]);
            animateText(res.data.cost_estimation, true, isRefine);
        });
        setCostEstimation(undefined);
    };

    const isLineItemMeasurable = (index: number | undefined) => {
        if (index === undefined) return false;
        return (
            costEstimationLineItemsOriginal?.[index]?.[4]
                ?.toLowerCase()
                ?.includes('feet') ||
            costEstimationLineItemsOriginal?.[index]?.[4]
                ?.toLowerCase()
                ?.includes('ft')
        );
    };

    const updateLineItem = (
        index: number | undefined,
        field: number,
        value: string
    ) => {
        setCostEstimation((prev) => {
            if (!tableLineStart || index === undefined || !prev) return prev;
            const newLines = prev.split('\n');
            const columns = newLines[tableLineStart + 1 + index].split(',');
            columns[field] = value;
            if (columns[0] !== 'Markup')
                columns[3] = (
                    parseFloat(columns[1]?.replace('$', '') || '0') *
                    parseFloat(columns[2]?.replace('$', '') || '0')
                ).toFixed(2);
            newLines[tableLineStart + 1 + index] = columns.join(',');
            return newLines.join('\n');
        });
    };

    const removeLineItem = (index: number | undefined) => {
        setCostEstimation((prev) => {
            if (!tableLineStart || index === undefined || !prev) return prev;
            const newLines = prev.split('\n');
            newLines.splice(tableLineStart + 1 + index, 1);
            return newLines.join('\n');
        });
    };

    const addLineItem = (item: string) => {
        setCostEstimation((prev) => {
            if (!tableLineStart || !prev) return prev;
            const newLines = prev.split('\n');
            newLines.splice(
                tableLineStart + 1 + costEstimationLineItems.length,
                0,
                `${item}`
            );
            return newLines.join('\n');
        });
    };

    const getMarkupPercent = () => {
        for (let i = 0; i < costEstimationLineItems.length; i++) {
            const line = costEstimationLineItems[i];
            if (line[0] === 'Markup') {
                return parseFloat(line[2]);
            }
        }
        return 0;
    };

    const updateMarkupValue = (value: string) => {
        for (let i = 0; i < costEstimationLineItems.length; i++) {
            const line = costEstimationLineItems[i];
            if (line[0] === 'Markup') {
                updateLineItem(i, 3, value);
                break;
            }
        }
    };

    useEffect(() => {
        if (!isCompleted) return;
        if (costEstimationLineItems && isAddMarkup) {
            if (getMarkupPercent() === 0) {
                addLineItem('Markup,0,10,0');
            } else {
                updateMarkupValue(
                    (
                        (parseFloat(estimateTotalBeforeMarkup) *
                            getMarkupPercent()) /
                        100
                    ).toFixed(2)
                );
            }
        }
    }, [costEstimationLineItems, isCompleted]);

    return (
        <Container
            maxWidth={false}
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: theme.colors.white,
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            {/* rendering it here to avoid lazy loading down the road */}
            <div style={{ display: 'none' }}>{downloadBadges}</div>
            {/* Header Section */}
            <SectionStickyHeaderLogo
                expName={'cost-estimator-page'}
                pageId={'cost-estimator-page'}
                headerMode={undefined}
                onClick={() => {
                    downloadApp();
                }}
            >
                <div></div>
            </SectionStickyHeaderLogo>
            <input
                placeholder="Upload Image"
                type="file"
                accept="image/*"
                // capture="environment"
                ref={imageInputRef}
                onChange={onUploadImage}
                style={{ display: 'none' }}
            />

            <div
                style={{
                    display: 'flex',
                    // justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    width: '100%',
                    flexDirection: 'column',
                    padding: '40px',
                }}
            >
                {step === 0 ? (
                    <TakePhoto imageInputRef={imageInputRef} />
                ) : step === 1 ? (
                    <AddDescription
                        prompt={prompt}
                        setPrompt={setPrompt}
                        setStep={setStep}
                    />
                ) : (
                    <>
                        {userType && costEstimationLines ? (
                            <FinalStep
                                costEstimationLines={costEstimationLines}
                                tableLineStart={tableLineStart}
                                tableLineEnd={tableLineEnd}
                                downloadText={downloadText}
                                downloadApp={downloadApp}
                                ctaType={ctaType}
                                setShowPaywallModal={setShowPaywallModal}
                                showPaywallModal={showPaywallModal}
                                setShowFeedbackModal={setShowFeedbackModal}
                                showFeedbackModal={showFeedbackModal}
                                questionLoading={questionLoading}
                                questionsToAnswer={questionsToAnswer}
                                initialQuestionCount={initialQuestionCount}
                                setQuestionLoading={setQuestionLoading}
                                setNewInstruction={setNewInstruction}
                                refineEstimate={refineEstimate}
                                imageId={imageId}
                                setQuestionsToAnswer={setQuestionsToAnswer}
                                prompt={prompt}
                                costEstimationLineItems={
                                    costEstimationLineItems
                                }
                                isLineItemMeasurable={isLineItemMeasurable}
                                estimateTotal={estimateTotal}
                                removeLineItem={removeLineItem}
                                updateLineItem={updateLineItem}
                                setIsAddMarkup={setIsAddMarkup}
                            />
                        ) : (
                            <SurveyStep
                                costEstimationLines={costEstimationLines}
                                userType={userType}
                                userTypeList={userTypeList}
                                setUserType={setUserType}
                            />
                        )}
                    </>
                )}
            </div>
        </Container>
    );
};

export default CostEstimator;
