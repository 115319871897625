import { SignupScreen, useSignup } from '../context';
import { BasicInput } from '../../../../components/Inputs';
import OnboardingCardTemplate from '../../../../components/OnboardingCardTemplate';
import CircleWrapper from '../../../../assets/onboarding/circle-wrapper.svg';
import Phone from '../../../../assets/onboarding/phone.svg';
import { useQuery } from '../../../../util/hooks';
import { onWebAppNameSetSuccess } from '../../../../tracking/trackers';

const Name = () => {
    const { isLoading, sendCodeToMobile, mobile, name, setName, setScreen } =
        useSignup();
    const query = useQuery();
    const variant = query.get('variant');

    return (
        <OnboardingCardTemplate
            title={'Enter your name'}
            topComponent={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            minHeight: '250px',
                            minWidth: '250px',
                            background: `url(${CircleWrapper}) no-repeat center center / contain`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img src={Phone} alt={'phone'} style={{ width: 100 }} />
                    </div>
                </div>
            }
            content={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <div style={{ margin: 10 }} />
                    <BasicInput
                        style={{ width: '100%', marginBottom: 20 }}
                        value={name}
                        setValue={setName}
                        label={'First name'}
                        placeholder={'e.g. John'}
                        autoFocus
                    />
                </div>
            }
            buttons={[
                {
                    text: 'Continue',
                    onClick: () => {
                        if (variant === 'texting') {
                            setScreen(SignupScreen.PhoneNumber);
                        } else {
                            sendCodeToMobile(mobile);
                        }
                        onWebAppNameSetSuccess({
                            name,
                        });
                    },
                    isLoading,
                    disabled: isLoading || !name,
                },
            ]}
        />
    );
};

export default Name;
