import React from 'react';

type IVideoProps = {
    src: string;
    width: number;
    height: number;
    extension?: string; // "mp4" by default
    style?: React.CSSProperties;
};

const Video = (props: IVideoProps) => {
    const { src, width, height, extension } = props;
    const type = 'video/' + (extension || 'mp4');

    return (
        <video
            width={width}
            height={height}
            autoPlay
            muted
            loop
            playsInline
            style={props.style}
        >
            <source src={src} type={type} />
        </video>
    );
};

export default Video;
