import { Button } from '@mui/material';
import BubbleFilled from '../../assets/icons/bubbleFilled.svg';
import BubbleEmpty from '../../assets/icons/bubbleEmpty.svg';
import CheckFilled from '../../assets/icons/checkLightBlue.svg';
import CheckEmpty from '../../assets/icons/checkEmpty.svg';
import theme from '../../theme';
import { Body } from '../Text';
import { ISurveyChoice } from '.';

type ISurveyButtonProps = {
    choice: ISurveyChoice;
    isSelected: boolean;
    onSelect: (choice: ISurveyChoice) => void;
    indicatorType?: 'check' | 'bubble';
    textStyle?: React.CSSProperties;
};

const SurveyButton = (props: ISurveyButtonProps) => {
    const { choice, isSelected, onSelect, indicatorType, textStyle } = props;
    const isCheckmark = indicatorType === 'check';
    const bubble = isSelected ? (
        <img
            src={isCheckmark ? CheckFilled : BubbleFilled}
            alt=""
            width={24}
            height={24}
        />
    ) : (
        <img
            src={isCheckmark ? CheckEmpty : BubbleEmpty}
            alt=""
            width={24}
            height={24}
        />
    );
    return (
        <Button
            style={{
                display: 'flex',
                width: '100%',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                padding: 15,
                borderRadius: 5,
                flexDirection: 'row',
                backgroundColor: isSelected
                    ? theme.colors.divider
                    : theme.colors.white,
                textTransform: 'none',
            }}
            onClick={() => onSelect(choice)}
        >
            <>
                {bubble}
                <Body
                    style={{ marginLeft: 10, fontWeight: '500', ...textStyle }}
                >
                    {choice.text}
                </Body>
            </>
        </Button>
    );
};

export default SurveyButton;
