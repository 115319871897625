export const parseCodeDescription = (description: string): JSX.Element[] => {
    // every time we encounter ' - ', we split the string into an array

    const splitDescription = description.split('- ');
    return splitDescription.map((item: string, index: number) => {
        // every time we encounter ' : ', we split the string into an array
        const splitItem = item.split(': ');
        return splitItem.length < 2 ? (
            <div key={index.toString()} />
        ) : (
            <li key={index.toString()} style={{ paddingBottom: 10 }}>
                <b>{splitItem[0]}:</b> {splitItem[1]}
            </li>
        );
    });
};

export const parseStoryDescription = (
    description: string,
    isMobile: boolean,
    replaceWith: string,
    image?: string
): (JSX.Element | JSX.Element[])[] => {
    description = description.replaceAll(
        '[transaction_code]',
        `["${replaceWith}"]`
    );
    description = description.replaceAll(
        "['transaction_code']",
        `["${replaceWith}"]`
    );
    description = description.replaceAll(
        "'transaction_code'",
        `["${replaceWith}"]`
    );
    description = description.replaceAll(
        'transaction_code',
        `["${replaceWith}"]`
    );
    const splitDescription = description.split('-----');
    return splitDescription.map((item: string, index: number) => {
        // search for '*word*' to make the word bold
        const regExp = /\[(.*?)\]/g;
        const textArray = item.split(RegExp(regExp));
        const boldWord = item.match(RegExp(regExp));
        boldWord?.forEach((word) => {
            textArray.splice(
                textArray.indexOf(word.replaceAll('[', '').replaceAll(']', '')),
                1
            );
        });

        if (textArray.length > 1) {
            return (
                <div key={index.toString()}>
                    <p
                        style={{
                            fontSize: isMobile ? 16 : 18,
                        }}
                    >
                        {textArray.map((text: string, i: number) => {
                            return (
                                <div key={`t-${i.toString()}`}>
                                    {text}
                                    {i !== textArray.length - 1 && boldWord ? (
                                        <b>
                                            {boldWord[i]
                                                .replaceAll('[', '')
                                                .replaceAll(']', '')}
                                        </b>
                                    ) : null}
                                </div>
                            );
                        })}
                    </p>
                    {index === 0 && image && (
                        <img
                            src={image}
                            alt=""
                            style={{
                                width: '100%',
                                borderRadius: 10,
                            }}
                        />
                    )}
                </div>
            );
        }

        return (
            <div key={index.toString()}>
                <p
                    style={{
                        fontSize: isMobile ? 16 : 18,
                    }}
                >
                    {item}
                </p>
                {index === 0 && image && (
                    <img
                        src={image}
                        alt=""
                        style={{
                            width: '100%',
                            borderRadius: 10,
                        }}
                    />
                )}
            </div>
        );
    });
};

export const scamStorySecondSection = `
Scams can occur to anyone and they have become increasingly sophisticated. Therefore, it is crucial to stay on guard. Here are some steps to keep in mind:
-----
[1. Verify Emails:] Always check the sender's email address carefully. A tiny typo or an odd-looking address can often expose a scam.
-----
[2. Avoid Clicking Links:] Instead of clicking links in emails, access the official website by typing the URL into your browser. 
-----
[3. Enable Two-Factor Authentication:] This is an extra layer of protection for your accounts that goes beyond the simple username and password security.
-----
[4. Monitor Your Accounts:] Check your bank and credit card statements regularly to catch any unauthorized or suspicious transactions swiftly.
-----
[5. Report Suspicious Activity:] Upon receiving a questionable email or identifying unauthorized transactions, report it to the respective company and your bank immediately. 
-----
[6. Use SimplyWise:] The [SimplyWise] app assists you in managing your receipts and detecting unusual activities. Here’s how it works:
`;
