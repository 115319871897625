import React from 'react';
import GoogleIcon from '../../assets/icons/google.svg';
import { OnboardingSubheader } from '../Text';
import theme from '../../theme';
import { ButtonContainer } from '../Buttons/BottomButton';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export const SignUpGoogleButton = (props: {
    onClick: () => void;
    text?: string;
    style?: React.CSSProperties;
    disableLogo?: boolean;
}) => {
    const { width } = useWindowDimensions();
    return (
        <ButtonContainer
            onClick={props.onClick}
            style={{
                border: `1px solid ${theme.colors.blue20}`,
                backgroundColor: theme.colors.white,
                ...props.style,
            }}
        >
            {!props.disableLogo && width >= 600 ? (
                <div style={{ position: 'absolute', left: 20, top: 14 }}>
                    <img src={GoogleIcon} alt={'google logo'} />
                </div>
            ) : null}
            <OnboardingSubheader style={{ color: theme.colors.blue60 }}>
                {props.text || 'Sign up with Gmail'}
            </OnboardingSubheader>
        </ButtonContainer>
    );
};
