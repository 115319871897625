import { emitter, experimentDebugger } from '@marvelapp/react-ab-test';
import { LandingPageConfig } from '../configuration/types';

experimentDebugger.enable();

const defineABVariations = (landingPageVariation: LandingPageConfig) => {
    const variantNames = Object.keys(landingPageVariation.ABVariations ?? {});
    const experimentName = landingPageVariation.landingPageName;

    if (variantNames.length > 0) {
        emitter.defineVariants(experimentName, variantNames);
    } else {
        emitter.defineVariants(experimentName, ['baseVariation']);
    }
};

export const defineAllVariations = (config: LandingPageConfig[]) => {
    config.forEach(defineABVariations);
};
