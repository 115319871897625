import { useState } from 'react';
import { useDeepCompareEffect } from '../../util/hooks';
import ReceiptService from '../../services/receipts/service';
import {
    useProcessingInboxReceipts,
    useUncategorizedInboxReceipts,
} from './selectors';
import { IInboxReceipt } from './context';

export const useInboxReceipts = () => {
    const {
        receipts: inboxProcessingReceipts,
        isLoading: isLoadingProcessing,
    } = useProcessingInboxReceipts() || [];
    const {
        receipts: inboxStoredReceipts,
        isLoading: isLoadingUncategorized,
        refetch,
    } = useUncategorizedInboxReceipts();

    const [inboxReceipts, setInboxReceipts] = useState<IInboxReceipt[]>([]);

    const assignSuggestedFoldersToReceipts = async (
        receipts: IInboxReceipt[]
    ) => {
        await Promise.all(
            receipts.map(async (receipt) => {
                const { data } = await ReceiptService.getCategorySuggestion(
                    receipt.merchant || '',
                    receipt.receipt_id
                );
                if (data?.category) receipt.categories = [data?.category];
            })
        );
        setInboxReceipts((prev) =>
            prev.map((receipt) => {
                const r =
                    receipts.find((r) => r.receipt_id === receipt.receipt_id) ??
                    receipt;
                return {
                    ...r,
                    animate: r.categories && r.categories.length > 0,
                };
            }, [])
        );
    };

    useDeepCompareEffect(() => {
        const typedProcessing = [
            ...inboxProcessingReceipts?.map((r) => {
                return { ...r, type: 'processing', documentKey: r.receipt_id };
            }),
        ] as IInboxReceipt[];
        const typedStored = [
            ...inboxStoredReceipts?.map((r) => {
                return { ...r, type: 'stored', documentKey: r.receipt_id };
            }),
        ] as IInboxReceipt[];

        const _inboxReceipts = typedProcessing
            .concat(typedStored)
            .sort(
                (a, b) =>
                    new Date(b.date || b.created_at || '').getTime() -
                    new Date(a.date || a.created_at || '').getTime()
            );
        setInboxReceipts(_inboxReceipts);
        const iterableInboxReceipts = Array.from(_inboxReceipts);
        const recentReceipts = iterableInboxReceipts
            .filter(
                (r) =>
                    new Date(r.created_at || '').getTime() >=
                    new Date().getTime() - 1000 * 60 * 10
            )
            .slice(0, 5);
        if (recentReceipts.length > 0) {
            assignSuggestedFoldersToReceipts(recentReceipts);
        }
    }, [inboxProcessingReceipts, inboxStoredReceipts]);

    const isLoadingInboxReceipts =
        isLoadingProcessing || isLoadingUncategorized;

    return { inboxReceipts, isLoadingInboxReceipts, refetch };
};

export default useInboxReceipts;
