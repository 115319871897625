import React, { useEffect, useMemo, useState } from 'react';
import {
    Container,
    AppBar,
    Toolbar,
    Typography,
    Box,
    Grid,
    Paper,
    Button,
    CssBaseline,
    Link,
    Grow,
    CircularProgress,
    Autocomplete,
    TextField,
} from '@mui/material';
import ReactPixel from 'react-facebook-pixel';
import { useNavigate } from 'react-router-dom';
import { usePlaidLink } from 'react-plaid-link';
import { ExpandMore } from '@mui/icons-material';
import theme from '../../theme';
import './style.css';
import ReceiptService from '../../services/receipts/service';
import { useAuth } from '../../providers/AuthProvider';
import CardsImage from '../../assets/reconciliation_flow/cards.svg';
import EmailImage from '../../assets/reconciliation_flow/email.svg';
import AmazonImage from '../../assets/reconciliation_flow/amazon.svg';
import AppleImage from '../../assets/reconciliation_flow/apple.svg';
import GoogleImage from '../../assets/reconciliation_flow/google.svg';
import HomeDepotImage from '../../assets/reconciliation_flow/homedepot.svg';
import NetflixImage from '../../assets/reconciliation_flow/netflix.svg';
import TargetImage from '../../assets/reconciliation_flow/target.svg';
import TraderJoesImage from '../../assets/reconciliation_flow/traderjoes.svg';
import WalmartImage from '../../assets/reconciliation_flow/walmart.svg';
import WholeFoodsImage from '../../assets/reconciliation_flow/wholefoods.svg';
import ReconcileAccountsImage from '../../assets/onboarding/reconcile.svg';
import WriteOffsImage from '../../assets/reconciliation_flow/writeoffs.svg';
import SubscriptionsImage from '../../assets/reconciliation_flow/subscriptions.svg';
import RecordsImage from '../../assets/reconciliation_flow/records.svg';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import useConnectEmail from '../Onboarding/useConnectEmail';
import { useCreateEmailReceipts } from '../Onboarding/GoogleConnect/useCreateEmailReceipts';
import { IPlaidTransaction } from '../../services/receipts/types';
import {
    onWebAppReconciliationFlowConnectEmail,
    onWebAppReconciliationFlowConnectPlaid,
    onWebAppReconciliationFlowRedirected,
    onWebAppReconciliationFlowSelectGoals,
    onWebAppReconciliationFlowSelectIndustry,
    onWebAppReconciliationFlowStart,
} from '../../tracking/trackers';
import INDUSTRY_CATEGORIES from './industries.json';

const goals = [
    {
        text: 'Reconcile Accounts',
        imgSrc: ReconcileAccountsImage,
        id: 'reconcile-accounts',
    },
    {
        text: 'Keep Records for Tax Writeoffs',
        imgSrc: WriteOffsImage,
        id: 'writeoffs',
    },
    {
        text: 'Keep Records in case of Audit',
        imgSrc: RecordsImage,
        id: 'records-for-audit',
    },
    { text: 'Detect Fraud', imgSrc: CardsImage, id: 'detect-fraud' },
    {
        text: 'Manage Subscriptions',
        imgSrc: SubscriptionsImage,
        id: 'manage-subscriptions',
    },
];

const companies = [
    AmazonImage,
    AppleImage,
    GoogleImage,
    HomeDepotImage,
    NetflixImage,
    TraderJoesImage,
    WalmartImage,
    WholeFoodsImage,
    TargetImage,
    AppleImage,
    WholeFoodsImage,
    WalmartImage,
];

const ReconciliationFlow = () => {
    const navigate = useNavigate();
    const { token } = useAuth();
    const { width, height } = useWindowDimensions();

    const STEPS = [
        'goals',
        'industry',
        'bank',
        'email',
        'completed',
        'waiting_for_process',
    ];
    const [selectedGoals, setSelectedGoals] = useState<string[]>([]);
    const [selectedIndustry, setSelectedIndustry] = useState<string>('');
    const [currentStep, setCurrentStep] = useState<number>(0);
    const handleGoalClick = (id: string) => {
        setSelectedGoals((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((goalId) => goalId !== id)
                : [...prevSelected, id]
        );
    };
    const stepName = STEPS[currentStep];
    const { importState: emailImportState } = useCreateEmailReceipts(
        stepName === 'waiting_for_process'
    );

    const navigateToStep = (step: string) => {
        setCurrentStep(STEPS.findIndex((s) => s === step));
    };

    const [, setWaitTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [transactions, setTransactions] = useState<IPlaidTransaction[]>([]);
    const [linkToken, setLinkToken] = useState<string | null>(null);
    const { open } = usePlaidLink({
        token: linkToken,
        onExit: () => {
            setLinkToken(null);
        },
        onSuccess: (public_token, metadata) => {
            ReceiptService.createPlaidItem(token!, public_token).then(() => {
                ReactPixel.track('InitiateCheckout');
                onWebAppReconciliationFlowConnectPlaid();
                setLinkToken(null);
                navigateToStep('email');
            });
        },
    });

    const loadBankTransactions = async () => {
        let trs;

        for (let i = 0; i < 5; i++) {
            trs = await ReceiptService.getPlaidTransactions(token!);
            if ((trs.data?.length || 0) > 0) {
                setTransactions(trs.data || []);
                break;
            }
            await new Promise((resolve) => setTimeout(resolve, 1500));
        }
    };

    const topMerchants = useMemo(() => {
        const merchantCounts = transactions.reduce((acc, curr) => {
            if (!curr.original_merchant_name) return acc;
            acc[curr.original_merchant_name] =
                (acc[curr.original_merchant_name] || 0) + 1;
            return acc;
        }, {} as Record<string, number>);
        return Object.entries(merchantCounts)
            .sort((a, b) => b[1] - a[1])
            .slice(0, 10)
            .map((a) => a[0]);
    }, [transactions]);
    const [currentMerchant, setCurrentMerchant] = useState<number>(0);

    useEffect(() => {
        if (!['bank', 'email'].includes(stepName)) return;
        if (!linkToken) {
            ReceiptService.plaidCreateLinkToken(token!)
                .then((res) => {
                    setLinkToken(res.data);
                })
                .catch(() => {});
        }
    }, [linkToken, stepName]);

    useEffect(() => {
        if (stepName === 'bank') {
            if (transactions.length > 0) navigateToStep('email');
        }
        if (stepName === 'completed') {
            loadBankTransactions();
            setTimeout(() => {
                navigateToStep('waiting_for_process');
            }, 1500);
        }
    }, [stepName]);

    useEffect(() => {
        if (stepName === 'waiting_for_process' && topMerchants.length > 0) {
            for (let i = 1; i < topMerchants.length; i++) {
                setTimeout(() => {
                    setCurrentMerchant(i);
                }, 3000 * i);
            }
        }
    }, [topMerchants, stepName]);

    useEffect(() => {
        if (stepName === 'waiting_for_process') {
            if (['NOT_STARTED', 'IMPORTING'].includes(emailImportState)) {
                setWaitTimeoutId((prev) => {
                    if (prev) clearTimeout(prev);
                    return setTimeout(() => {
                        onWebAppReconciliationFlowRedirected();
                        navigate('/plaid');
                    }, 30000);
                });
            } else if (emailImportState === 'IMPORTED') {
                setWaitTimeoutId((prev) => {
                    if (prev) clearTimeout(prev);
                    onWebAppReconciliationFlowRedirected();
                    navigate('/plaid');
                    return null;
                });
            }
        }
    }, [emailImportState, stepName]);

    useEffect(() => {
        onWebAppReconciliationFlowStart();
        loadBankTransactions();
    }, []);

    const { startGoogleOauth } = useConnectEmail(
        () => {
            onWebAppReconciliationFlowConnectEmail();
            navigateToStep('completed');
        },
        undefined,
        {
            max_receipts: 100,
            after: '01/01/2024',
            source: 'reconciliation-flow',
        }
    );

    const companiesBasedOnScreenSize = [
        ...companies,
        ...companies,
        ...companies,
    ].slice(0, Math.floor(width / 120));
    let LeftSide: React.ReactNode = null;
    LeftSide = (
        <Box
            sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: { xs: '50px', md: '200px' },
                paddingBottom: 5,
            }}
        >
            <Box
                className="fading-box"
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    width: { xs: '100%', md: '100%' },
                }}
            >
                {companiesBasedOnScreenSize.map((src, index) => (
                    <Box
                        sx={{
                            width: 80,
                            height: 80,
                            borderRadius: 5,
                            border: `1px solid #f1f1f1`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: 1,
                        }}
                    >
                        <img src={src} alt={`company-${index}`} />
                    </Box>
                ))}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    mt: { xs: 2, md: 10 },
                    width: { xs: '100%', md: '90%' },
                }}
            >
                <Box sx={{ width: '30%' }} />
                <Box sx={{ flexDirection: 'column' }}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: '700',
                            textAlign: 'left',
                            fontSize: 32,
                        }}
                    >
                        {stepName === 'goals'
                            ? 'Choose Your Top Goals'
                            : stepName === 'industry'
                            ? 'Select Your Industry'
                            : 'Let’s reconcile your accounts now'}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ mt: 1, color: '#6c757d', textAlign: 'left' }}
                    >
                        {stepName === 'goals'
                            ? 'SimplyWise makes bookkeeping easier. Select your top goals, then hit Continue.'
                            : stepName === 'industry'
                            ? 'Please choose the industry that best describes your business.'
                            : 'Link accounts so we can detect your subscriptions and help you save money.'}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );

    let RightSide: React.ReactNode = null;
    if (stepName === 'goals')
        RightSide = (
            <Box
                sx={{
                    flex: 1,
                    textAlign: 'center',
                    p: 3,
                    bgcolor: '#e9ecef',
                }}
            >
                <Grid
                    container
                    spacing={2}
                    sx={{
                        // alignItems: 'center',
                        // justifyContent: 'center',
                        maxWidth: { xs: '100%', md: '600px' },
                        marginX: 'auto',
                    }}
                >
                    {goals.map((goal, i) => {
                        const selected = selectedGoals.includes(goal.id);
                        return (
                            <Grid item xs={12} md={6} key={goal.id}>
                                <Grow in={true} timeout={500 + i * 300}>
                                    <Paper
                                        variant="outlined"
                                        onClick={() => handleGoalClick(goal.id)}
                                        sx={{
                                            padding: 2,
                                            display: 'flex',
                                            flexDirection: {
                                                xs: 'row',
                                                md: 'column',
                                            },
                                            alignItems: 'center',
                                            cursor: 'pointer',
                                            backgroundColor: theme.colors.white,
                                            border: selected
                                                ? `2px solid ${theme.colors.secondary}`
                                                : `2px solid ${theme.colors.white}`,
                                            borderRadius: 3,
                                            height: {
                                                xs: undefined,
                                                md: 165,
                                            },
                                            maxWidth: {
                                                xs: undefined,
                                                md: 250,
                                            },
                                        }}
                                    >
                                        <img
                                            src={goal.imgSrc}
                                            alt={goal.text}
                                            style={{
                                                borderRadius: '8px',
                                                width: 80,
                                                height: 80,
                                            }}
                                        />
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                mt: { xs: 0, md: 1 },
                                                ml: { xs: 2, md: 0 },
                                            }}
                                        >
                                            {goal.text}
                                        </Typography>
                                    </Paper>
                                </Grow>
                            </Grid>
                        );
                    })}
                </Grid>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        mt: 3,
                        color: 'white',
                        borderRadius: 10,
                        paddingX: 12,
                        fontSize: 16,
                        height: 50,
                    }}
                    onClick={() => {
                        onWebAppReconciliationFlowSelectGoals({
                            goals: selectedGoals,
                        });
                        setCurrentStep((prev) => prev + 1);
                    }}
                    disabled={selectedGoals.length === 0}
                >
                    Continue
                </Button>
            </Box>
        );
    else if (stepName === 'industry')
        RightSide = (
            <Box
                sx={{
                    flex: 1,
                    textAlign: 'center',
                    p: 3,
                    bgcolor: '#e9ecef',
                }}
            >
                <Grid
                    container
                    spacing={2}
                    sx={{
                        // alignItems: 'center',
                        // justifyContent: 'center',
                        maxWidth: { xs: '100%', md: '600px' },
                        marginX: 'auto',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Autocomplete
                            options={Object.entries(
                                INDUSTRY_CATEGORIES.Industries
                            ).flatMap(([industry, details]) =>
                                details.Subcategories.map((subcategory) => ({
                                    label: `${subcategory}`,
                                    id: `${subcategory}`,
                                }))
                            )}
                            getOptionLabel={(option) => option.label}
                            sx={{
                                width: {
                                    xs: '100%',
                                    md: 400,
                                    backgroundColor: theme.colors.white,
                                },
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select Industry"
                                    variant="outlined"
                                />
                            )}
                            onChange={(event, value) =>
                                setSelectedIndustry(value?.label || '')
                            }
                            value={
                                selectedIndustry
                                    ? {
                                          label: `${selectedIndustry}`,
                                          id: selectedIndustry,
                                      }
                                    : null
                            }
                            isOptionEqualToValue={(option, value) =>
                                option.label === value.label
                            }
                        />
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        mt: 3,
                        color: 'white',
                        borderRadius: 10,
                        paddingX: 12,
                        fontSize: 16,
                        height: 50,
                    }}
                    onClick={() => {
                        onWebAppReconciliationFlowSelectIndustry({
                            industry: selectedIndustry,
                        });
                        setCurrentStep((prev) => prev + 1);
                    }}
                    disabled={selectedIndustry === ''}
                >
                    Continue
                </Button>
            </Box>
        );
    else
        RightSide = (
            <Box
                sx={{
                    flex: 1,
                    textAlign: 'center',
                    p: 3,
                    bgcolor: '#e9ecef',
                }}
            >
                {/* bank */}
                <Grow in={true} timeout={500}>
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: theme.colors.white,
                                padding: 3,
                                borderRadius: 3,
                                maxWidth: 500,
                                margin: {
                                    xs: '0 auto',
                                    md: '20px auto 0px auto',
                                },
                            }}
                        >
                            <Typography variant="h5">
                                Ready to Auto-Reconcile ?
                            </Typography>
                            <Box sx={{ my: 2 }}>
                                <img src={CardsImage} alt="cards" />
                            </Box>
                            <Typography variant="body1">
                                Connect a Credit Card or Checking Account to
                                reconcile those reciepts
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            sx={{
                                color: 'white',
                                borderRadius: 15,
                                paddingX: 8,
                                fontSize: 16,
                                mt: 2,
                                pointerEvents:
                                    stepName !== 'bank' ? 'none' : 'auto',
                                backgroundColor:
                                    stepName === 'bank'
                                        ? theme.colors.secondary
                                        : theme.colors.success,
                            }}
                            onClick={() => {
                                open();
                            }}
                        >
                            {stepName === 'bank'
                                ? 'Connect Account'
                                : 'Connected!'}
                        </Button>
                    </Box>
                </Grow>

                {/* email */}
                <Grow in={stepName !== 'bank'} timeout={500}>
                    <Box
                        sx={
                            stepName === 'bank'
                                ? { opacity: 0.4, pointerEvents: 'none' }
                                : {}
                        }
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: theme.colors.white,
                                padding: 3,
                                borderRadius: 3,
                                maxWidth: 500,
                                margin: {
                                    xs: '60px auto 0px auto',
                                    md: '60px auto 0px auto',
                                },
                            }}
                        >
                            <Typography variant="h5">
                                Have receipts in your email ?
                            </Typography>
                            <Box sx={{ my: 2 }}>
                                <img src={EmailImage} alt="email" />
                            </Box>
                            <Typography variant="body1">
                                Connect email to reconcile those receipts
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{
                                color: 'white',
                                borderRadius: 15,
                                paddingX: 8,
                                fontSize: 16,
                                mt: 2,
                                pointerEvents:
                                    stepName !== 'email' ? 'none' : 'auto',
                                backgroundColor:
                                    stepName === 'completed'
                                        ? theme.colors.success
                                        : theme.colors.secondary,
                            }}
                            onClick={() => {
                                startGoogleOauth();
                            }}
                        >
                            {stepName === 'completed'
                                ? 'Connected!'
                                : 'Connect Email'}
                        </Button>
                    </Box>
                </Grow>
            </Box>
        );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                bgcolor: theme.colors.offWhite,
                width: '100%',
            }}
        >
            <CssBaseline />
            <AppBar
                position="static"
                sx={{ bgcolor: theme.colors.primary, color: '#ffffff' }}
            >
                <Toolbar>
                    <Typography variant="h6" component="div">
                        SimplyWise
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container
                sx={{
                    flexGrow: 1,
                    minWidth: '100%',
                }}
                style={{
                    paddingLeft: 0,
                    paddingRight: 0,
                }}
            >
                <Paper
                    variant="outlined"
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        minHeight: height - 120,
                        margin: 0,
                        padding: 0,
                    }}
                >
                    {stepName === 'waiting_for_process' ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flex: 1,
                                flexDirection: 'column',
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{ mb: 3, fontWeight: '500', fontSize: 20 }}
                            >
                                {topMerchants[currentMerchant] ? (
                                    <span>
                                        Analyzing{' '}
                                        <strong>
                                            {topMerchants[currentMerchant]}{' '}
                                        </strong>
                                        transactions...
                                    </span>
                                ) : (
                                    'Analyzing transactions...'
                                )}
                            </Typography>
                            <CircularProgress
                                color={'secondary'}
                                size={40}
                                thickness={4}
                            />
                        </Box>
                    ) : (
                        <>
                            {LeftSide}
                            {RightSide}
                        </>
                    )}
                </Paper>
            </Container>
            <Box sx={{ bgcolor: '#f8f9fa', p: 2, textAlign: 'center' }}>
                <Link href="/support">Support</Link> |{' '}
                <Link href="/terms">Terms of Use</Link> |{' '}
                <Link href="/privacy">Privacy Policy</Link>
            </Box>
        </Box>
    );
};

export default ReconciliationFlow;
