import { useState } from 'react';
import CTAButton from '../Buttons/CTAButton';
import { onWebAppLandingTextingContinue } from '../../tracking/trackers';
import TextingModal from './TextingModal';

const TextingCollectionCTA = (props: {
    label: string;
    sublabel?: string;
    onCTAClick: () => void;
}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    return (
        <>
            <CTAButton
                text={props.label}
                sublabel={props.sublabel}
                style={{
                    marginTop: 40,
                    borderRadius: 30,
                }}
                onClick={() => {
                    props?.onCTAClick();
                    setIsModalOpen(true);
                    onWebAppLandingTextingContinue({
                        source: 'hero',
                    });
                }}
                cancelNavigation
            />
            <TextingModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
        </>
    );
};

export default TextingCollectionCTA;
