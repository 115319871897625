import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './providers/AuthProvider';

const ExpiredRedirection = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        // Currently only redirecting expired async subscriptions
        if (user?.subscription_data?.product?.async) {
            if (
                user?.subscription_data?.status !== 'active' ||
                !user?.subscription_data?.payment_method
            ) {
                if (user?.organization) {
                    // navigate('/teams/payment');
                } else {
                    // navigate('/settings/subscriptions');
                }
            }
        }
    }, [user]);

    return null;
};

export default ExpiredRedirection;
