import { Suspense, cloneElement, lazy, useEffect, useState } from 'react';
import Footer from '../../../screens/Landing/Footer';
import {
    CTAContainer,
    HeaderWrapper,
    LandingBody,
    LeftSection,
    PageContainer,
    SectionContentWrapper,
    SectionWrapper,
} from '../../../screens/Landing/styles';
// import { CheckApplePayAvailability } from '../../Experiments/CheckApplePayAvailability';
import LandingSection from '../../../screens/Landing/LandingSection';
import theme from '../../../theme';
import { heroGradient1 } from '../../../util/styleConstants';
import { PrimaryCTA } from '../../Onboarding/PrimaryCTA';
import { Body, BodyBold, SectionHeader, SectionSubtitle } from '../../Text';
import SectionStickyHeaderLogo from '../elements/SectionStickyHeaderLogo';
import { CTAAlignmentWrapper } from '../elements/styles';
import { VariationCallToAction } from '../elements/VariationCallToAction';
import { VariationCallToActionSmall } from '../elements/VariationCallToActionSmall';
import { VariationDescription } from '../elements/VariationDescription';
import { VariationHeader } from '../elements/VariationHeader';
import {
    MAIN_VISUAL_DIMENSIONS,
    VariationMainVisual,
} from '../elements/VariationMainVisual';
import { VariationSecondaryVisual } from '../elements/VariationSecondaryVisual';
import { getOS } from '../../../util/isIOS';
import {
    AnimatedSectionContainer,
    AnimatedSectionContainerInner,
} from './styles';
import { PageVariation, SubAppInfo } from './types';
import useMobileDetect from './useMobileDetect';
import useWindowSize from './useWindowSize';

const ReportsDemo = lazy(
    () => import('../../../assets/animations/svGator/ReportsDemo')
);
const FoldersDemo = lazy(
    () => import('../../../assets/animations/svGator/FoldersDemo')
);
const OcrDemo = lazy(
    () => import('../../../assets/animations/svGator/OcrDemo')
);
const EmailDemo = lazy(
    () => import('../../../assets/animations/svGator/EmailDemo')
);
const QuickbooksDemo = lazy(
    () => import('../../../assets/animations/svGator/QuickbooksSync')
);
const TextingDemo = lazy(
    () => import('../../../assets/animations/svGator/Texting')
);
const ConstructionLandingTrack = lazy(
    () => import('../../../assets/animations/svGator/ConstructionLandingTrack')
);
const ConstructionLandingWriteOffs = lazy(
    () =>
        import(
            '../../../assets/animations/svGator/ConstructionLandingWriteOffs'
        )
);
const ConstructionLandingEstimate = lazy(
    () =>
        import('../../../assets/animations/svGator/ConstructionLandingEstimate')
);

export enum SectionAnimation {
    REPORTS = 'reports',
    FOLDERS = 'folders',
    OCR = 'ocr',
    EMAIL = 'email',
    QUICKBOOKS = 'quickbooks',
    TEXTING = 'texting',
    CON_BIDS = 'con_bids',
    CON_TRACK = 'con_track',
    CON_WRITEOFFS = 'con_writeoffs',
}

const DEMO_ANIMATIONS: {
    [key in SectionAnimation]: JSX.Element;
} = {
    [SectionAnimation.REPORTS]: <ReportsDemo />,
    [SectionAnimation.FOLDERS]: <FoldersDemo />,
    [SectionAnimation.OCR]: <OcrDemo />,
    [SectionAnimation.EMAIL]: <EmailDemo />,
    [SectionAnimation.QUICKBOOKS]: <QuickbooksDemo />,
    [SectionAnimation.TEXTING]: <TextingDemo />,
    [SectionAnimation.CON_BIDS]: <ConstructionLandingEstimate />,
    [SectionAnimation.CON_TRACK]: <ConstructionLandingTrack />,
    [SectionAnimation.CON_WRITEOFFS]: <ConstructionLandingWriteOffs />,
};

export const Variation = (props: PageVariation) => {
    const currentDevice = useMobileDetect();

    const [subApps, setSubApps] = useState<SubAppInfo[]>(props.subApps || []);
    const [activeSubApp, setActiveSubApp] = useState<SubAppInfo | null>(
        props.subApps ? props.subApps.filter((app) => app.isActive)[0] : null
    );
    const [headerMarginTop, setHeaderMarginTop] = useState<string | number>(
        '0px'
    );
    const [isMobile, setIsMobile] = useState<boolean>(currentDevice.isMobile());
    const [scrollableHeader, setScrollableHeader] = useState<
        boolean | undefined
    >(props.scrollableHeader);
    const [hoverIndex, setHoverIndex] = useState<number | null>(null);

    const [, innerWidth] = useWindowSize();

    useEffect(() => {
        setIsMobile(currentDevice.isMobile());
        if (currentDevice.isMobile() && props.scrollableHeader) {
            setScrollableHeader(false);
        } else {
            setScrollableHeader(props.scrollableHeader);
        }
    }, [currentDevice, innerWidth]);

    const downloadApp = (subApp: SubAppInfo) => {
        // onWebAppCostEstimationClickDownload();
        const os = getOS();
        if (os === 'macos' || os === 'ios') {
            window.location.href = subApp.appStoreLink?.length
                ? subApp.appStoreLink
                : subApp.playStoreLink?.length
                ? subApp.playStoreLink
                : subApp.url ?? '/';
            return;
        }
        window.location.href = subApp.playStoreLink?.length
            ? subApp.playStoreLink
            : subApp.appStoreLink?.length
            ? subApp.appStoreLink
            : subApp.url ?? '/';
    };

    return (
        <>
            <SectionStickyHeaderLogo
                expName={props.variationName}
                pageId={props.baseName}
                headerMode={props.headerMode}
                style={props.headerStyle}
                headerLogo={props.headerLogo}
                scrollableHeader={scrollableHeader}
                setHeaderMarginTop={setHeaderMarginTop}
                headerWatermark={props.headerWatermark}
                headerLeafFull={props.headerLeafFull}
                headerLeafPath={props.headerLeafPath}
                slogan={props.slogan}
                isMobile={isMobile}
            >
                <>
                    {scrollableHeader &&
                    props.tabCallToAction &&
                    headerMarginTop === `${window.innerHeight * 0.4}px` ? (
                        <VariationCallToActionSmall
                            {...props.tabCallToAction}
                            baseName={props.baseName}
                            variationName={props.variationName}
                        />
                    ) : props.tabCallToAction && !scrollableHeader ? (
                        <VariationCallToActionSmall
                            {...props.tabCallToAction}
                            baseName={props.baseName}
                            variationName={props.variationName}
                        />
                    ) : null}
                </>
            </SectionStickyHeaderLogo>
            {/* <CheckApplePayAvailability landingPageId={'todo'} /> */}
            <PageContainer
                style={{
                    marginTop: headerMarginTop,
                    marginBottom: props.baseName.includes('Update') ? 20 : 0,
                    scrollBehavior: 'smooth',
                }}
            >
                <SectionWrapper style={{ backgroundImage: heroGradient1 }}>
                    <HeaderWrapper>
                        <LeftSection>
                            <SectionHeader>
                                <VariationHeader
                                    props={props.header}
                                    isMobile={isMobile}
                                />
                            </SectionHeader>

                            <SectionSubtitle
                                style={{
                                    marginBottom: 40,
                                }}
                            >
                                <VariationDescription
                                    props={props.description}
                                    isMobile={isMobile}
                                />
                            </SectionSubtitle>
                            <CTAContainer>
                                <VariationSecondaryVisual
                                    {...props.secondaryVisual}
                                />
                                <VariationCallToAction
                                    {...props.callToAction}
                                    baseName={props.baseName}
                                    variationName={props.variationName}
                                />
                            </CTAContainer>
                        </LeftSection>
                        {!props.baseName.includes('Update') && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingTop: 40,
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: theme.roundness,
                                        overflow: 'hidden',
                                        width:
                                            props.mainVisual.type ===
                                                'ImageElementComponent' &&
                                            props.mainVisual.width
                                                ? props.mainVisual.width
                                                : MAIN_VISUAL_DIMENSIONS.width,
                                        height:
                                            props.mainVisual.type ===
                                                'ImageElementComponent' &&
                                            props.mainVisual.height
                                                ? props.mainVisual.height
                                                : MAIN_VISUAL_DIMENSIONS.height,
                                    }}
                                >
                                    {!process.isServer && (
                                        <VariationMainVisual
                                            props={props.mainVisual}
                                            isMobile={isMobile}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {props.baseName.includes('Update') && (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    paddingTop: 40,
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: 16,
                                        backgroundColor: isMobile
                                            ? '#EEE7DD'
                                            : 'unset',
                                        width:
                                            props.mainVisual.type ===
                                                'ImageElementComponent' &&
                                            props.mainVisual.width
                                                ? props.mainVisual.width
                                                : MAIN_VISUAL_DIMENSIONS.width,
                                        height: isMobile
                                            ? 250
                                            : props.mainVisual.type ===
                                                  'ImageElementComponent' &&
                                              props.mainVisual.height
                                            ? props.mainVisual.height
                                            : MAIN_VISUAL_DIMENSIONS.height,
                                        position: 'relative',
                                        marginTop: isMobile ? 30 : 0,
                                    }}
                                >
                                    {!process.isServer && (
                                        <>
                                            {isMobile ? (
                                                <div
                                                    style={{
                                                        overflow: 'hidden',
                                                        height: 300,
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        right: 0,
                                                        left: 0,
                                                        alignSelf: 'center',
                                                    }}
                                                >
                                                    <VariationMainVisual
                                                        props={props.mainVisual}
                                                        isMobile={isMobile}
                                                    />
                                                </div>
                                            ) : (
                                                <VariationMainVisual
                                                    props={props.mainVisual}
                                                    isMobile={isMobile}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </HeaderWrapper>
                </SectionWrapper>
            </PageContainer>
            <LandingBody>
                {props.howItWorks.component}
                {props.trustedBy.component}
                {props.sectionsTitle?.component}
                {props.title && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: 50,
                        }}
                    >
                        <BodyBold
                            style={{
                                fontSize: isMobile ? 32 : 40,
                                alignSelf: 'center',
                                textAlign: 'center',
                            }}
                        >
                            {props.title}
                        </BodyBold>
                    </div>
                )}
                {subApps.length > 0 ? (
                    <AnimatedSectionContainer
                        style={{
                            paddingBottom: 40,
                        }}
                        marginTop={50}
                        marginBottom={0}
                    >
                        <AnimatedSectionContainerInner>
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: isMobile ? '100vw' : '60vw',
                                        position: 'relative',
                                        overflowX: isMobile
                                            ? 'scroll'
                                            : 'hidden',
                                        padding: isMobile ? '0 20px' : 0,
                                        zIndex: 1,
                                    }}
                                >
                                    {subApps.map((subApp, i) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    if (subApp.isActive) return;
                                                    setActiveSubApp(subApp);
                                                    setSubApps(
                                                        subApps.map((app) => ({
                                                            ...app,
                                                            isActive:
                                                                app.name ===
                                                                subApp.name,
                                                        }))
                                                    );
                                                }}
                                                onMouseOver={() => {
                                                    if (subApp.isActive) return;
                                                    setHoverIndex(i);
                                                }}
                                                onMouseLeave={() => {
                                                    setHoverIndex(null);
                                                }}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                    backgroundColor:
                                                        subApp.isActive
                                                            ? 'rgba(0, 177, 201, 0.1)'
                                                            : hoverIndex === i
                                                            ? 'rgba(0, 177, 201, 0.1)'
                                                            : theme.colors
                                                                  .white,
                                                    padding: '15px 28px',
                                                    borderRadius: 50,
                                                    border: `0.5px solid #00B1C9`,
                                                    width: 'auto',
                                                    margin: 10,
                                                    userSelect: 'none',
                                                }}
                                            >
                                                <img
                                                    alt={subApp.icon?.props.alt}
                                                    src={subApp.icon?.props.src}
                                                    style={{
                                                        width: 40,
                                                        height: 40,
                                                        marginRight: 10,
                                                    }}
                                                />
                                                <BodyBold
                                                    style={{
                                                        fontSize: isMobile
                                                            ? 12
                                                            : 15,
                                                        color: theme.colors
                                                            .primary,
                                                    }}
                                                >
                                                    {subApp.name}
                                                </BodyBold>
                                            </div>
                                        );
                                    })}
                                </div>
                                {activeSubApp ? (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: isMobile
                                                ? 'column'
                                                : 'row',
                                            marginTop: isMobile ? 40 : 20,
                                            borderRadius: 10,
                                            width: isMobile ? '85vw' : '100%',
                                            height: isMobile
                                                ? 'fit-content'
                                                : 470,
                                            backgroundColor:
                                                theme.colors.offWhite,
                                            padding: isMobile
                                                ? '0px 0px 20px 0px'
                                                : 0,
                                            overflow: isMobile
                                                ? 'unset'
                                                : 'hidden',
                                            position: 'relative',
                                            alignSelf: 'center',
                                        }}
                                    >
                                        {activeSubApp.heroImage && (
                                            <>
                                                {isMobile ? (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            position:
                                                                'absolute',
                                                            top: -100,
                                                            justifyContent:
                                                                'center',
                                                            overflow: 'hidden',
                                                            height: 340,
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: 400,
                                                                width: '100%',
                                                            }}
                                                        >
                                                            {/* {
                                                                activeSubApp.heroImage
                                                            } */}
                                                            {cloneElement(
                                                                activeSubApp.heroImage,
                                                                {
                                                                    width: '100%',
                                                                    height: 450,
                                                                }
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: isMobile
                                                                ? '100%'
                                                                : '46%',
                                                            marginBottom:
                                                                isMobile
                                                                    ? 20
                                                                    : 0,
                                                        }}
                                                    >
                                                        {activeSubApp.heroImage}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        <div
                                            style={{
                                                display: 'flex',
                                                padding: isMobile
                                                    ? '0px 30px'
                                                    : 40,
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                marginTop: isMobile ? 265 : 0,
                                                height: isMobile
                                                    ? '59%'
                                                    : '100%',
                                                width: isMobile
                                                    ? '100%'
                                                    : '50%',
                                                zIndex: 1,
                                            }}
                                        >
                                            <BodyBold
                                                style={{
                                                    color: theme.colors.primary,
                                                    fontSize: isMobile
                                                        ? 25
                                                        : 40,
                                                    marginBottom: 20,
                                                }}
                                            >
                                                {activeSubApp.title}
                                            </BodyBold>
                                            {activeSubApp.bulletPoints?.map(
                                                (bp) => (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'baseline',
                                                            marginTop: 10,
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <div>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    width: 10,
                                                                    height: 10,
                                                                    borderRadius:
                                                                        '50%',
                                                                    backgroundColor:
                                                                        theme
                                                                            .colors
                                                                            .secondary,
                                                                    marginRight: 10,
                                                                }}
                                                            />
                                                        </div>
                                                        <Body
                                                            style={{
                                                                color: 'rgba(24, 28, 63, 0.7)',
                                                            }}
                                                        >
                                                            {bp}
                                                        </Body>
                                                    </div>
                                                )
                                            )}
                                            <CTAAlignmentWrapper
                                                centerAlways={true}
                                            >
                                                <PrimaryCTA
                                                    onClick={() => {
                                                        if (
                                                            activeSubApp.redirectToStores
                                                        ) {
                                                            downloadApp(
                                                                activeSubApp
                                                            );
                                                            return;
                                                        }
                                                        window.location.href =
                                                            activeSubApp.url ??
                                                            '/';
                                                    }}
                                                    size={'normal'}
                                                    label={'Learn More'}
                                                    style={{
                                                        maxWidth: 400,
                                                        marginTop: 40,
                                                        marginLeft: 20,
                                                        marginRight: 20,
                                                        marginBottom: isMobile
                                                            ? 20
                                                            : 10,
                                                        backgroundColor:
                                                            theme.colors
                                                                .secondary,
                                                        borderRadius: 100,
                                                    }}
                                                />
                                            </CTAAlignmentWrapper>
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        </AnimatedSectionContainerInner>
                    </AnimatedSectionContainer>
                ) : null}
                {props.sections ? (
                    <AnimatedSectionContainer>
                        <AnimatedSectionContainerInner>
                            {props.sections.map((section, i) => {
                                const isRight = i % 2 === 0;
                                return (
                                    <LandingSection
                                        mode={isRight ? 'right' : 'left'}
                                        header={section.header}
                                        section={section.subheader}
                                        asset={
                                            <div
                                                style={{
                                                    width: 400,
                                                    height: 400,
                                                }}
                                            >
                                                {!process.isServer ? (
                                                    <Suspense>
                                                        {
                                                            DEMO_ANIMATIONS[
                                                                section
                                                                    .animation
                                                            ]
                                                        }
                                                    </Suspense>
                                                ) : null}
                                            </div>
                                        }
                                    />
                                );
                            })}
                        </AnimatedSectionContainerInner>
                    </AnimatedSectionContainer>
                ) : null}
                {props.pricing.component}
                {props.reviews.component}
                {props.reviewsVertical.component}
                {props.singlePricing.component}
                {props.finalCallToActionHeader || props.finalCallToAction ? (
                    <SectionWrapper
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            backgroundColor: '#F5F3E',
                            ...(props.finalCallToActionHeader
                                ? { padding: '200px 0' }
                                : {}),
                        }}
                    >
                        {props.finalCallToActionHeader ? (
                            <SectionHeader
                                style={{
                                    marginBottom: 80,
                                }}
                            >
                                Get Started
                            </SectionHeader>
                        ) : null}
                        {props.finalCallToAction ? (
                            <VariationCallToAction
                                {...props.finalCallToAction}
                                isFooter
                                baseName={props.baseName}
                                variationName={props.variationName}
                            />
                        ) : null}
                    </SectionWrapper>
                ) : null}
            </LandingBody>
            {props.ratingAndReviews && (
                <PageContainer
                    style={{
                        backgroundColor: theme.colors.offWhite,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '40px 10px',
                    }}
                >
                    <SectionContentWrapper
                        style={{
                            backgroundColor: theme.colors.offWhite,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {props.ratingAndReviews.ratingVisual && (
                            <img
                                alt={'rating visual'}
                                src={
                                    props.ratingAndReviews.ratingVisual.props
                                        .src
                                }
                                style={{
                                    width: isMobile ? ' 50%' : 200,
                                }}
                            />
                        )}
                        {props.ratingAndReviews?.title && (
                            <BodyBold
                                style={{
                                    fontSize: isMobile ? 30 : 40,
                                    textAlign: 'center',
                                    width: isMobile ? '90%' : 400,
                                    marginTop: 30,
                                    marginBottom: 20,
                                }}
                            >
                                {props.ratingAndReviews.title}
                            </BodyBold>
                        )}
                        {props.ratingAndReviews.reviews?.map((review) => (
                            <div
                                style={{
                                    width: '100%',
                                    padding: isMobile ? '30px' : '40px',
                                    backgroundColor: theme.colors.white,
                                    borderRadius: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Body
                                    style={{
                                        color: theme.colors.primary,
                                        fontStyle: 'italic',
                                        textAlign: 'center',
                                        // fontWeight: '600',
                                        fontSize: isMobile ? 16 : 25,
                                    }}
                                >
                                    "{review.quote}"
                                </Body>
                                <BodyBold
                                    style={{
                                        color: theme.colors.secondary,
                                        fontStyle: 'italic',
                                        textAlign: 'center',
                                        // fontWeight: '600',
                                        fontSize: isMobile ? 16 : 25,
                                        marginTop: 20,
                                    }}
                                >
                                    — {review.user}
                                </BodyBold>
                            </div>
                        ))}
                    </SectionContentWrapper>
                </PageContainer>
            )}
            <Footer
                mode={'normal'}
                hideFooterLinks={[]}
                footerData={props.footerData}
                isMobile={isMobile}
                baseName={props.baseName}
                variationName={props.variationName}
            />
        </>
    );
};
