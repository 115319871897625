import { Box, Modal } from '@mui/material';
import theme from '../../theme';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { CARD_DIMENSIONS } from '../FolderCard/styles';

type IModalLargeProps = {
    visible: boolean;
    children: React.ReactNode;
    onClose: () => void;
    style?: React.CSSProperties;
    maxWidth?: number;
};

const ModalLarge = ({
    children,
    visible,
    onClose,
    style,
    maxWidth: _maxWidth,
}: IModalLargeProps) => {
    const { width, height } = useWindowDimensions();

    const maxWidth = _maxWidth || CARD_DIMENSIONS.width * 6 + 100;
    return (
        <Modal open={visible} onClose={onClose}>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'absolute',
                    top: '10%',
                    left: Math.max((width - maxWidth) / 2, 0),
                    width: width < maxWidth ? width : maxWidth,
                    height: height * 0.8,
                    backgroundColor: theme.colors.white,
                    borderRadius: theme.roundness,
                    borderWidth: 0,
                    border: 'none',
                    overflow: 'scroll',
                    ...style,
                }}
            >
                {children}
            </Box>
        </Modal>
    );
};

export default ModalLarge;
