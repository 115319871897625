import { lazy, useMemo, useRef, useState } from 'react';
import { Button, Container } from '@mui/material';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import Resizer from 'react-image-file-resizer';
import TrustIcon from '../../assets/trust.svg';
import WindowsToCodeVideo from '../../assets/video/WindowsToCodeVideo.mp4';
import SectionStickyHeaderLogo from '../../components/LandingPageVariations/elements/SectionStickyHeaderLogo';
import theme from '../../theme';
import { onWebAppCodeCheckerClickDownload } from '../../tracking/trackers';
import { useQuery } from '../../util/hooks';
import './index.css';
// import PublicService from '../../services/public/service';
import { OPEN_AI_API_KEY } from '../../configuration';

const DownloadBadges = lazy(
    () => import('../../screens/Payment/DownloadBadges')
);

const CodeChecker = () => {
    const query = useQuery();

    // const [imageId, setImageId] = useState<string | undefined>(undefined);
    const [image, setImage] = useState<string | undefined>(undefined);
    const [base64Image, setBase64Image] = useState<any>(undefined);
    const [checkerStatus, setCheckerStatus] = useState<
        'none' | 'uploading' | 'uploaded' | 'analyzing' | 'done'
    >('none');
    const [progressWidth, setProgressWidth] = useState('0%');
    const [userState, setUserState] = useState<string | undefined>(undefined);
    const [htmlContent, setHtmlContent] = useState('');

    const imageInputRef = useRef<HTMLInputElement>(null);
    const imageOverlayRef = useRef<HTMLDivElement>(null);
    const scanLineRef = useRef<HTMLDivElement>(null);

    const downloadBadges = useMemo(() => {
        return !process.isServer ? (
            <DownloadBadges
                alternativeWebUrl="/"
                customData={{
                    source: 'code-checker',
                    initial_landing: query.get('initial-landing') || '',
                    experience: 'code-checker',
                }}
                branchAppStoreUrl="https://apps.apple.com/app/simplywise-receipt-scanner/id1538521095?ppid=e78e02eb-b58c-48b8-a142-c7b5f2e8e641"
                branchPlayStoreUrl="https://play.google.com/store/apps/details?id=com.simplywise.mobile&listing=code-checker"
                black
            />
        ) : null;
    }, []);

    const downloadApp = () => {
        onWebAppCodeCheckerClickDownload();
        setTimeout(() => {
            (
                document.getElementById('app-store-badge') ||
                document.getElementById('play-store-badge')
            )?.click();
        }, 1000);
    };

    const resizeFile = (file: File): Promise<Blob> => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1024,
                1024,
                'JPEG',
                85,
                0,
                //@ts-ignore
                (b: Blob) => {
                    resolve(b);
                },
                'blob'
            );
        });
    };

    const imageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _file = event?.target?.files?.[0];
        if (!_file) return;
        if (_file.type !== 'image/jpeg') return;
        setImage(URL.createObjectURL(_file));
        setCheckerStatus('uploading');
        simulateUploadProgress();
        resizeFile(_file)
            .then((resizedFile) => {
                const reader = new FileReader();
                reader.readAsDataURL(resizedFile);

                reader.onloadend = async () => {
                    setBase64Image(reader.result);
                    setCheckerStatus('uploaded');
                };

                // PublicService.uploadImage(resizedFile)
                //     .then((res) => {
                //         onWebAppCodeCheckerTakePicture({
                //             image_id: res.data.image_ids[0],
                //             exp_name: 'aug-25-remove-paywall',
                //         });
                //         setImageId(res.data.image_ids[0]);
                //         setImage(URL.createObjectURL(resizedFile));
                //         setCheckerStatus('done');
                //         // PublicService.getImageAnalyze(
                //         //     res.data.image_ids[0]
                //         // ).then((res) => {
                //         //     setImageAnalyze(res.data.cost_estimation);
                //         // });
                //     })
                //     .catch((err) => {});
            })
            .catch(() => {});
    };

    const simulateUploadProgress = () => {
        return new Promise<void>((resolve) => {
            let width = 0;
            const interval = setInterval(() => {
                if (width >= 100) {
                    clearInterval(interval);
                    resolve();
                } else {
                    width++;
                    setProgressWidth(width + '%');
                }
            }, 20);
        });
    };

    const startScanAnimation = () => {
        let scanPosition = 0;
        const animationDuration = 6000; // Changed from 3000 to 6000 (6 seconds)

        function animate(timestamp: number) {
            scanPosition =
                ((timestamp % animationDuration) / animationDuration) * 100;

            // Animate the scan line from top to bottom
            if (scanLineRef.current)
                scanLineRef.current.style.top = `${scanPosition}%`;

            // Animate the clip path to reveal color from top to bottom
            if (imageOverlayRef.current)
                imageOverlayRef.current.style.clipPath = `inset(0 0 ${
                    100 - scanPosition
                }% 0)`;

            if (scanLineRef.current && imageOverlayRef.current)
                requestAnimationFrame(animate);
        }

        requestAnimationFrame(animate);
    };

    const formatCodeCheckerResult = (data: any) => {
        if (data && Array.isArray(data.results)) {
            const resultsHTML = data.results
                .map(
                    (item: any) => `
                <div class="code-section">
                    <h3 class="code-header">
                        ${item.code}
                        <span class="importance-tag">[${item.importance}]</span>
                    </h3>
                    <ul class="checklist">
                        ${item.code_description
                            .map(
                                (desc: any) => `
                            <li>${desc}</li>
                        `
                            )
                            .join('')}
                    </ul>
                </div>
            `
                )
                .join('');

            return `
                <div class="code-checker-result">
                    ${resultsHTML}
                </div>
            `;
        } else {
            return '<p>No valid code checker results found.</p>';
        }
    };

    const analyzeImage = async () => {
        if (!base64Image) {
            alert('No image uploaded');
            return;
        }

        if (!userState) {
            alert('Please select your State');
            return;
        }

        setCheckerStatus('analyzing');
        startScanAnimation();

        const responseFormat = {
            name: 'CodeCheckerResponse',
            strict: true,
            schema: {
                type: 'object',
                required: ['results'],
                properties: {
                    results: {
                        type: 'array',
                        items: {
                            type: 'object',
                            required: [
                                'code',
                                'code_description',
                                'importance',
                            ],
                            properties: {
                                code: {
                                    type: 'string',
                                    description:
                                        'Detailed code section/subsection',
                                },
                                importance: {
                                    type: 'string',
                                    description:
                                        'How big a deal is compliance with this code? Is it a big deal, a small deal, or a medium deal?',
                                },
                                code_description: {
                                    type: 'array',
                                    description:
                                        'Detailed checklist items for compliance with the code. Each item is a string representing a checklist step. Include at least 3 items with specific information on what to check for',
                                    items: {
                                        type: 'string',
                                        description:
                                            'A specific compliance checklist item',
                                    },
                                },
                            },
                            additionalProperties: false,
                        },
                    },
                },
                additionalProperties: false,
            },
        };

        const promptContent = `You are an expert in government rules and codes. Analyze the image and provide information about relevant government rules and codes in ${userState} that a contractor should consider checking for. Present your response as a check list of specific things to check forwith information about Rule/Code, Checklist item, and Importance.`;

        const requestBody = {
            model: 'gpt-4o',
            messages: [
                {
                    role: 'system',
                    content: promptContent,
                },
                {
                    role: 'user',
                    content: [
                        {
                            type: 'text',
                            text: 'Analyze this image based on the given context.',
                        },
                        {
                            type: 'image_url',
                            image_url: {
                                url: `${base64Image}`,
                            },
                        },
                    ],
                },
            ],
            response_format: {
                type: 'json_schema',
                json_schema: responseFormat,
            },
            max_tokens: 5000,
        };

        await axios
            .post('https://api.openai.com/v1/chat/completions', requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${OPEN_AI_API_KEY}`,
                },
            })
            .then((res) => {
                const resultText = res.data.choices[0].message.content;
                const parsedDescription = JSON.parse(resultText);

                setHtmlContent(formatCodeCheckerResult(parsedDescription));
                setCheckerStatus('done');
            })
            .catch((err) => {
                console.error('Error received from OpenAI:', err);
            });
    };

    return (
        <Container
            maxWidth={false}
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: theme.colors.white,
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            {/* rendering it here to avoid lazy loading down the road */}
            <div style={{ display: 'none' }}>{downloadBadges}</div>
            {/* Header Section */}
            <SectionStickyHeaderLogo
                expName={'cost-estimator-page'}
                pageId={'cost-estimator-page'}
                headerMode={undefined}
                onClick={() => {
                    downloadApp();
                }}
            >
                <div></div>
            </SectionStickyHeaderLogo>
            {checkerStatus === 'uploading' ? (
                <div id="uploadingPage" className="page">
                    <div className="container">
                        <h2>Uploading Image</h2>
                        <div className="progress-bar">
                            <div
                                style={{
                                    width: progressWidth,
                                    height: '20px',
                                    backgroundColor: '#4CAF50',
                                    transition: 'width 0.5s',
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            ) : checkerStatus === 'uploaded' ? (
                <div id="stateSelectionPage" className="page">
                    <div className="container">
                        <h2>Select Your State</h2>
                        <select
                            title="states"
                            id="stateDropdown"
                            onChange={(
                                event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                                setUserState(event.target.value);
                            }}
                            required
                        >
                            <option value="" disabled selected>
                                Choose your state
                            </option>
                            <option value="Alabama">Alabama</option>
                            <option value="Alaska">Alaska</option>
                            <option value="Arizona">Arizona</option>
                            <option value="Arkansas">Arkansas</option>
                            <option value="California">California</option>
                            <option value="Colorado">Colorado</option>
                            <option value="Connecticut">Connecticut</option>
                            <option value="Delaware">Delaware</option>
                            <option value="Florida">Florida</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Idaho">Idaho</option>
                            <option value="Illinois">Illinois</option>
                            <option value="Indiana">Indiana</option>
                            <option value="Iowa">Iowa</option>
                            <option value="Kansas">Kansas</option>
                            <option value="Kentucky">Kentucky</option>
                            <option value="Louisiana">Louisiana</option>
                            <option value="Maine">Maine</option>
                            <option value="Maryland">Maryland</option>
                            <option value="Massachusetts">Massachusetts</option>
                            <option value="Michigan">Michigan</option>
                            <option value="Minnesota">Minnesota</option>
                            <option value="Mississippi">Mississippi</option>
                            <option value="Missouri">Missouri</option>
                            <option value="Montana">Montana</option>
                            <option value="Nebraska">Nebraska</option>
                            <option value="Nevada">Nevada</option>
                            <option value="New Hampshire">New Hampshire</option>
                            <option value="New Jersey">New Jersey</option>
                            <option value="New Mexico">New Mexico</option>
                            <option value="New York">New York</option>
                            <option value="North Carolina">
                                North Carolina
                            </option>
                            <option value="North Dakota">North Dakota</option>
                            <option value="Ohio">Ohio</option>
                            <option value="Oklahoma">Oklahoma</option>
                            <option value="Oregon">Oregon</option>
                            <option value="Pennsylvania">Pennsylvania</option>
                            <option value="Rhode Island">Rhode Island</option>
                            <option value="South Carolina">
                                South Carolina
                            </option>
                            <option value="South Dakota">South Dakota</option>
                            <option value="Tennessee">Tennessee</option>
                            <option value="Texas">Texas</option>
                            <option value="Utah">Utah</option>
                            <option value="Vermont">Vermont</option>
                            <option value="Virginia">Virginia</option>
                            <option value="Washington">Washington</option>
                            <option value="West Virginia">West Virginia</option>
                            <option value="Wisconsin">Wisconsin</option>
                            <option value="Wyoming">Wyoming</option>
                        </select>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                analyzeImage();
                            }}
                            id="submitStateButton"
                            className="cta-button"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            ) : checkerStatus === 'analyzing' ? (
                <div id="analyzingPage" className="page">
                    <div className="container">
                        <h2>Analyzing Image...</h2>
                        <div className="image-container">
                            <img
                                id="uploadedImagePreview"
                                src={image}
                                alt="Uploaded"
                            />
                            <div
                                ref={imageOverlayRef}
                                className="image-overlay"
                                style={{
                                    backgroundImage: `url(${image})`,
                                }}
                            ></div>
                            <div ref={scanLineRef} className="scan-line"></div>
                        </div>
                    </div>
                </div>
            ) : checkerStatus === 'done' ? (
                <div id="resultPage" className="page">
                    <div className="container">
                        <h2>Draft Checklist</h2>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: htmlContent,
                            }}
                        ></div>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href =
                                    '/welcome/cost-estimation?initial-landing=code-checker';
                            }}
                            className="cta-button"
                            style={{
                                maxWidth: '400px',
                                marginTop: 10,
                            }}
                        >
                            Try the Cost Estimator
                        </button>

                        {/* <button
                            onClick={(e) => {
                                e.preventDefault();
                                window.location.href =
                                    '/sales-tax-calculator?initial-landing=code-checker';
                            }}
                            className="cta-button variation"
                            style={{
                                maxWidth: '400px',
                                marginTop: 10,
                            }}
                        >
                            Try the Sales Tax Calculator
                        </button> */}

                        {/* <button
                            onClick={(e) => {
                                e.preventDefault();
                                setCheckerStatus('none');
                                setBase64Image(undefined);
                                setUserState(undefined);
                            }}
                            className="cta-button variation-3"
                            style={{
                                maxWidth: '400px',
                                marginTop: 10,
                            }}
                        >
                            Analyze Another Image
                        </button> */}

                        {/* <button
                            id="additionalFeaturesButton"
                            className="cta-button"
                            style={{ marginTop: '20px' }}
                        >
                            Continue
                        </button> */}

                        {/* <div id="feedbackSection" style={{ marginTop: '20px' }}>
                            <h3>How did we do?</h3>
                            <button id="thumbsUp" className="feedback-button">
                                👍
                            </button>
                            <button id="thumbsDown" className="feedback-button">
                                👎
                            </button>
                        </div> */}
                    </div>
                </div>
            ) : (
                <div className="page">
                    <div className="landing-container">
                        <div className="landing-content">
                            <h1 id="pageTitle">
                                Instant Building Code Checklists
                            </h1>
                            <p id="pageDescription">
                                Take a photo. Instantly get a checklist to be in
                                compliance with local building codes. 100% free.
                            </p>
                            <img
                                src={TrustIcon}
                                alt="Stars"
                                style={{
                                    height: 'auto',
                                    display: 'block',
                                    margin: isMobile ? '2rem 0' : '2rem auto',
                                }}
                            />
                            <input
                                placeholder="Upload Image"
                                type="file"
                                accept="image/*"
                                ref={imageInputRef}
                                onChange={imageUpload}
                                style={{ display: 'none' }}
                            />
                            <Button
                                variant="contained"
                                color="secondary"
                                sx={{
                                    'textTransform': 'none',
                                    'display': 'block',
                                    'width': '100%',
                                    'padding': '15px',
                                    'boxShadow': 'none !important',
                                    'backgroundColor': '#003e46',
                                    'color': 'white',
                                    'border': 'none',
                                    'borderRadius': '5px',
                                    'fontSize': '18px',
                                    'fontWeight': 'bold',
                                    'cursor': 'pointer',
                                    'transition': 'background-color 0.3s',
                                    'maxWidth': '400px',
                                    ':hover': {
                                        backgroundColor: '#002a30',
                                    },
                                }}
                                onClick={() => {
                                    imageInputRef.current?.click();
                                }}
                            >
                                Take Photo
                            </Button>
                        </div>
                        <video
                            autoPlay
                            muted
                            loop
                            playsInline
                            className="landing-video"
                        >
                            <source src={WindowsToCodeVideo} type="video/mp4" />
                        </video>
                    </div>
                </div>
            )}
        </Container>
    );
};

export default CodeChecker;
