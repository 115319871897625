import axios from 'axios';
import { API_BASE_URL } from '../configuration';

const instance = axios.create({
    baseURL: `${API_BASE_URL}/api/v2`,
});

// Auto-include the auth header if token exists
instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        const newHeaders = config.headers || {};
        newHeaders.Authorization = `Token ${token}`;
        config.headers = newHeaders;
    }
    return config;
});

export const axios_v1 = axios.create({
    baseURL: `${API_BASE_URL}/api/v1`,
});

export const axios_retry = axios.create({
    baseURL: `${API_BASE_URL}/api/v2`,
});

axios_retry.interceptors.response.use(undefined, (err) => {
    const { config } = err;
    if (!config || !config.retry) return Promise.reject(err);
    config.retry -= 1;
    const delayRetryRequest = new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve();
        }, config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => axios_retry(config));
});

export default instance;
