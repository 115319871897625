import { Button } from '@mui/material';
import theme from '../../../theme';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import CameraSVG from '../../../assets/icons/details/camera.svg';

const TakePhoto = ({ imageInputRef }: any) => {
    const { width } = useWindowDimensions();

    return (
        <>
            <div
                style={{
                    fontWeight: '700',
                    fontSize: 30,
                }}
            >
                Construction Project Cost Estimator
            </div>
            <div
                style={{
                    width: Math.min(width * 0.5, 250),
                    height: Math.min(width * 0.5, 250),
                    backgroundColor: theme.colors.offWhite,
                    borderRadius: 1000,
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    margin: 50,
                }}
            >
                <img src={CameraSVG} width={150} height={150} alt="camera" />
            </div>
            <div
                style={{
                    color: theme.colors.grayText,
                    width: Math.min(width * 0.8, 400),
                    fontSize: 16,
                    textAlign: 'center',
                    lineHeight: 1.5,
                }}
            >
                Take a photo of what you want to repair or modify (e.g. “paint
                this wall”)
            </div>
            <Button
                variant="contained"
                color="secondary"
                style={{
                    color: 'white',
                    boxShadow: 'none',
                    borderRadius: 25,
                    height: 50,
                    width: Math.min(width * 0.8, 400),
                    fontWeight: '700',
                    fontSize: 20,
                    marginTop: 30,
                }}
                onClick={() => {
                    imageInputRef.current?.click();
                }}
            >
                Take a Photo
            </Button>
        </>
    );
};

export default TakePhoto;
