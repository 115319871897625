import { IStandardResponse } from '../../types/API';

export type Tiers = 'base' | 'pro';

export enum SubscriptionPlatform {
    IOS = 'ios',
    ANDROID = 'android',
    STRIPE = 'stripe',
    PAYPAL = 'paypal',
}

export enum SubscriptionGroup {
    APP = 'app',
    QUICKBOOKS = 'quickbooks',
    ICONS = 'icons',
}

export enum AppSubscriptionTier {
    PREMIUM = 'premium',
    PRO = 'pro',
    BASE = 'base',
    FREE = 'free',
}

export type AppSubscriptionTierWithTeams = AppSubscriptionTier | 'teams';

export type SubscriptionTiers = AppSubscriptionTier; // | QuickbooksSubscriptionTier;

export enum SubscriptionCohort {
    APP_24_60 = 'simplywise_subscription_fixed',
    APP_30_90 = 'simplywise_subscription_30_and_90',
    APP_30_90_1_4 = 'simplywise_subscription_30_and_90_1_4',
    APP_30_90_1_5 = 'simplywise_subscription_30_and_90_1_5',
    APP_30_60 = 'simplywise_subscription_group_30_and_60',
    APP_24_90 = 'simplywise_subscription_group_24_and_90',

    QB_50 = 'plugin_quicbooks_group_50',
    QB_100 = 'plugin_quicbooks_group_100',
}

export enum SubscriptionInterval {
    MONTH = 'month',
    YEAR = 'year',
    WEEK = 'week',
}

export enum SubscriptionStatus {
    ACTIVE = 'active',
    CONSENT = 'consent',
    PAST_DUE = 'past_due',
    PAUSED = 'paused',
    CANCELLED = 'cancelled',
    PENDING = 'pending',

    EXPIRED = 'expired',
}

export type BillingEvent = {
    notification_type: string;
    notification_subtype?: string;
    product_id: string;
    platform: string;
    raw_json?: any;
    created_at?: string;
};

export interface IProduct {
    id: string;
    tier: SubscriptionTiers | string;
    name: string;
    description?: string;
    organization?: boolean;
    value?: number;
    currency?: string;
    interval?: SubscriptionInterval;
    async?: boolean;
    inheritence?: boolean;
    free_trial_period?: number;
    free_trial_period_unit?: string;
    platforms: SubscriptionPlatform[];
    cohorts: SubscriptionCohort[];
    groups: SubscriptionGroup[];
    quota?: {
        receipts?: {
            initial?: number;
            max?: undefined;
            method?: string;
        };
        seats?: {
            method: string;
            key?: string;
            value?: number;
        };
    };
    grandfathered?: boolean;
    unpurchasable?: boolean;
    minimum_quantity?: number;
}

export interface IStripeCreateSubscriptionResponse extends IStandardResponse {
    client_secret: string;
    subscription_id: string;
    subscription_status?: string;
    subscription_quantity?: number;
    product_id: string;
    trial_end?: string;
    user: {
        mobile: string;
    };
}

export interface ISetSubscriptionResponse {
    status: 'success' | 'error';
    error?: string;
    data?: number;
}

export interface ISubscriptionBillingEventsResponse {
    status: 'success' | 'error';
    error?: string;
    data?: { billing_events: BillingEvent[] };
}

export interface IStripeCancelSubscriptionResponse {
    status: 'success' | 'error';
    error?: string;
}

export interface ISubscriptionBillingEventsResponse {
    status: 'success' | 'error';
    error?: string;
    data?: { billing_events: BillingEvent[] };
}
