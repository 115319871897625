import { Button, Typography } from '@mui/material';
import styled from 'styled-components';
import theme from '../../theme';

export const SPACE_PADDING = 0;

export const CARD_DIMENSIONS = {
    width: 175, // bc gap
    height: 160,
    gap: 8,
};

export const CardContainer = styled(Button).attrs({
    variant: 'outlined',
})<{ isSelected?: boolean; noHover?: boolean }>`
    display: flex;
    flex-direction: column;
    box-shadow: ${theme.defaultShadow};
    border-width: 0px;
    margin: ${CARD_DIMENSIONS.gap}px;
    width: ${CARD_DIMENSIONS.width}px;
    height: ${CARD_DIMENSIONS.height}px;
    border-radius: ${theme.roundness}px;
    align-items: center;
    justify-content: space-around;
    text-transform: none;
    background-color: ${(props) =>
        props.isSelected ? theme.colors.primary50 : theme.colors.white};

    &:hover {
        background-color: ${(props) =>
            props.noHover
                ? props.isSelected
                    ? theme.colors.primary50
                    : theme.colors.white
                : theme.colors.folderHover};
        border-width: 0px;
        box-shadow: ${theme.defaultShadowDark};
    }
`;

export const CardIconContainer = styled.div`
    position: absolute;
    top: 20px;
    height: ${CARD_DIMENSIONS.height / 2}px;
    display: flex;
    align-items: center;
`;

export const CardBodyContainer = styled(Typography)`
    position: absolute;
    font-size: 16px;
    bottom: 20px;
    font-weight: 700;
    line-height: 1;
    color: ${theme.colors.blue100};
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
`;
