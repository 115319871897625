import { createContext, useContext, useEffect, useState } from 'react';
import {
    IReceipt,
    IUserCategoryGroup,
    IUserTag,
    ReceiptPlatform,
} from '../services/receipts/types';
import ReceiptService from '../services/receipts/service';
import { ALL_DOCUMENTS } from '../util/constants';
import CategoryGroupService from '../services/category_groups/service';
import { Space } from '../services/spaces/types';
import SpaceService from '../services/spaces/service';
import { StoreKey } from '../types/storeNames';
import { useAuth } from './AuthProvider';

const DB_NAME = 'simplywise-db';
const DB_VERSION = 2;
const CACHE_TTL = 1000 * 60 * 2;

interface IIndexedDbContextValue {
    addData: (storeName: StoreKey, data: any) => Promise<void>;
    getData: <T>(storeName: StoreKey) => Promise<T | null>;
    clearData: (storeName: StoreKey) => Promise<void>;
    getIsExpired: (storeName: StoreKey) => boolean;
    isDBInitiated: boolean;
    isDBNotSupported: boolean;
}

const IndexedDbContext = createContext<IIndexedDbContextValue>({
    addData: async () => {},
    getData: async () => Promise.resolve(null),
    clearData: async () => {},
    getIsExpired: () => true,
    isDBInitiated: false,
    isDBNotSupported: false,
});

/**
 * Purpose is to reduce initial page load time between refreshes by providing an pre-fetched, valid dataset.
 * Only requests/stores large datasets (all tags, all receipts, etc), and does not update individual records.
 * To access data that is aligned with the view, and changes with edits, refer to FolderProvider, SpaceProvider, and other local states.
 * Not all browsers support indexed db, so we cannot fully rely on it.
 * If indexed db is not supported, we load the data inside contexts as we normally would.
 * https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
const IndexedDBProvider = (props: any) => {
    const [isDBInitiated, setIsDBInitiated] = useState<boolean>(false);
    const [isDBNotSupported, setIsDBNotSupported] = useState<boolean>(false);
    const { token } = useAuth();

    const initDB = (): Promise<boolean> => {
        let request: IDBOpenDBRequest;
        let db: IDBDatabase;
        return new Promise((resolve) => {
            request = window.indexedDB.open(DB_NAME + token, DB_VERSION);

            request.onupgradeneeded = () => {
                db = request.result;

                if (!db.objectStoreNames.contains('receipts')) {
                    console.log('Creating receipts store');
                    db.createObjectStore('receipts', {
                        keyPath: 'receipt_id',
                    });
                }

                if (!db.objectStoreNames.contains('tags')) {
                    console.log('Creating tags store');
                    db.createObjectStore('tags', {
                        keyPath: 'tag_id',
                    });
                }

                if (!db.objectStoreNames.contains('category_groups')) {
                    console.log('Creating groups store');
                    db.createObjectStore('category_groups', {
                        keyPath: 'id',
                    });
                }
                if (!db.objectStoreNames.contains('spaces')) {
                    console.log('Creating spaces store');
                    db.createObjectStore('spaces', {
                        keyPath: 'id',
                    });
                }

                if (!db.objectStoreNames.contains('processing_receipts')) {
                    console.log('Creating processing_receipts store');
                    db.createObjectStore('processing_receipts', {
                        keyPath: 'receipt_id',
                    });
                }
            };

            request.onsuccess = () => {
                db = request.result;
                resolve(true);
            };

            request.onerror = () => {
                resolve(false);
            };
        });
    };

    const addData = (storeName: string, data: any): Promise<any> => {
        let db;
        let request: any;
        return new Promise((resolve) => {
            request = indexedDB.open(DB_NAME + token, DB_VERSION);

            request.onsuccess = () => {
                db = request.result;
                const tx = db.transaction(storeName, 'readwrite');
                const store = tx.objectStore(storeName);
                store.add(data);
                resolve(data);
            };

            request.onerror = () => {
                const error = request.error?.message;
                if (error) {
                    resolve(error);
                } else {
                    resolve('Unknown error');
                }
            };
        });
    };

    const getIsExpired = (storeName: StoreKey) => {
        const lastUpdate = parseInt(getLocalStorageItem(storeName) || '0');
        if (!lastUpdate || new Date().getTime() - lastUpdate > CACHE_TTL)
            return true;
        return false;
    };

    const getIsInvalid = (storeName: StoreKey) => {
        const item = getLocalStorageItem(storeName);
        if (!item) return true;
        return false;
    };

    // only returns data if not invalid
    const getData = async <T,>(storeName: StoreKey): Promise<T | null> => {
        let db;
        let request: any;
        const isInvalid = getIsInvalid(storeName);
        if (isInvalid) {
            return null;
        }
        return new Promise((resolve) => {
            request = indexedDB.open(DB_NAME + token, DB_VERSION);

            request.onsuccess = () => {
                console.log('request.onsuccess - getAllData');
                db = request.result;
                const tx = db.transaction(storeName, 'readonly');
                const store = tx.objectStore(storeName);
                const res = store.getAll();
                res.onsuccess = () => {
                    resolve(res.result);
                };
            };
        });
    };

    const clearData = (storeName: string): Promise<any> => {
        let db;
        let request: any;
        return new Promise<void>((resolve) => {
            request = indexedDB.open(DB_NAME + token, DB_VERSION);

            request.onsuccess = () => {
                console.log('request.onsuccess - clearData');
                db = request.result;
                const tx = db.transaction(storeName, 'readwrite');
                const store = tx.objectStore(storeName);
                const res = store.clear();
                res.onsuccess = () => {
                    resolve();
                };
            };
        });
    };

    useEffect(() => {
        if (!token) return;
        setIsDBInitiated(false);
        setIsDBNotSupported(false);
        if (window && window.indexedDB)
            initDB()
                .then(() => setIsDBInitiated(true))
                .catch(() => {
                    setIsDBInitiated(false);
                    setIsDBNotSupported(true);
                });
        else {
            setIsDBInitiated(false);
            setIsDBNotSupported(true);
        }
    }, [token]);

    return (
        <IndexedDbContext.Provider
            value={{
                addData,
                getData,
                clearData,
                getIsExpired,
                isDBInitiated,
                isDBNotSupported,
            }}
        >
            {props.children}
        </IndexedDbContext.Provider>
    );
};

export default IndexedDBProvider;

export const useIndexedDB = () => useContext(IndexedDbContext);

export const getStoreKey = (storeName: string) => {
    return `storeUpdatedAt[${storeName}]__${DB_VERSION}`;
};

const getLocalStorageItem = (storeName: StoreKey) =>
    localStorage.getItem(getStoreKey(storeName));

const setLocalStorageItem = (storeName: StoreKey) =>
    localStorage.setItem(
        getStoreKey(storeName),
        new Date().getTime().toString()
    );

const removeLocalStorageItem = (storeName: StoreKey) =>
    localStorage.removeItem(getStoreKey(storeName));

export const invalidateStore = (storeName: StoreKey) => {
    removeLocalStorageItem(storeName);
};

export const updateStore = (storeName: StoreKey) => {
    window.dispatchEvent(new Event(getStoreKey(storeName) + '_update_store'));
};

export const updateStoreAndState = (storeName: string) => {
    window.dispatchEvent(
        new Event(getStoreKey(storeName) + '_update_store_and_state')
    );
};

/**
 * Unlike other stores, this store does not listen to invalidation events
 * So we don't re-fetch all receipts while editing
 */

export const useReceiptsStore = () => {
    const { token } = useAuth();
    const {
        addData,
        getData,
        clearData,
        getIsExpired,
        isDBInitiated,
        isDBNotSupported,
    } = useIndexedDB();
    const [receipts, setReceipts] = useState<IReceipt[] | undefined>(undefined);

    const addReceipt = (receipt: IReceipt) => {
        return addData(StoreKey.RECEIPTS, receipt);
    };

    const loadReceipts = () => {
        return new Promise<void>((res) =>
            ReceiptService.loadReceipts(ALL_DOCUMENTS, []).then(
                ({ data: rs }) => {
                    setReceipts(rs);
                    clearData(StoreKey.RECEIPTS).then(() => {
                        rs.forEach((receipt) => {
                            addReceipt(receipt);
                        });
                        res();
                    });
                }
            )
        );
    };

    const getReceiptsFromStore = () => {
        return getData<IReceipt[]>(StoreKey.RECEIPTS);
    };

    const refetch = async () => {
        try {
            await loadReceipts();
            setLocalStorageItem(StoreKey.RECEIPTS);
        } catch (err) {
            console.error(err);
        }
    };

    const initData = async () => {
        try {
            const _rows = await getReceiptsFromStore();
            if (!_rows?.length) throw new Error('No data');
            setReceipts(_rows);
            if (getIsExpired(StoreKey.RECEIPTS)) {
                throw new Error('Expired data');
            }
        } catch (err) {
            refetch();
        }
    };

    useEffect(() => {
        if (isDBNotSupported) {
            setReceipts([]);
            return;
        }
        if (!isDBInitiated) return;
        initData();

        // check for realtime updates
        ReceiptService.loadDataUpdates(token!).then((res) => {
            const data = res.data as any;
            let receipts = false;
            let tags = false;
            for (let i = 0; i < data.length; i++) {
                if (data[i].update_type === 'receipt') receipts = true;
                else if (data[i].update_type === 'tags') tags = true;
            }
            if (receipts) {
                updateStore(StoreKey.RECEIPTS);
                refetch();
            }
            if (tags) {
                updateStoreAndState('tags');
                updateStoreAndState('spaces');
            }
        });
    }, [isDBInitiated, isDBNotSupported]);
    return { addReceipt, receipts, refetch };
};

export const useTagsStore = () => {
    const {
        addData,
        getData,
        clearData,
        getIsExpired,
        isDBInitiated,
        isDBNotSupported,
    } = useIndexedDB();
    const [tags, setTags] = useState<IUserTag[] | undefined>(undefined);
    const [categoryGroups, setCategoryGroups] = useState<
        IUserCategoryGroup[] | undefined
    >(undefined);

    const loadTags = (updateState: boolean = true) => {
        return new Promise<void>((res) =>
            ReceiptService.getReceiptTags().then(({ data }) => {
                if (updateState) setTags(data);
                clearData(StoreKey.TAGS).then(() => {
                    data.forEach((d) => {
                        addData(StoreKey.TAGS, d);
                    });
                    res();
                });
            })
        );
    };
    const addTag = (receipt: IUserTag) => {
        return addData(StoreKey.TAGS, receipt);
    };
    const getTagsFromStore = () => {
        return getData<IUserTag[]>(StoreKey.TAGS);
    };

    const loadCategoryGroups = (updateState: boolean = true) => {
        return new Promise<void>((res) =>
            CategoryGroupService.getCategoryGroups().then(({ data }) => {
                if (updateState) setCategoryGroups(data);
                clearData(StoreKey.CATEGORY_GROUPS).then(() => {
                    data.forEach((d) => {
                        addData(StoreKey.CATEGORY_GROUPS, d);
                    });
                    res();
                });
            })
        );
    };
    const addCategoryGroup = (receipt: IUserCategoryGroup) => {
        return addData(StoreKey.CATEGORY_GROUPS, receipt);
    };
    const getCategoryGroupsFromStore = () => {
        return getData<IUserCategoryGroup[]>(StoreKey.CATEGORY_GROUPS);
    };

    const refetchTags = (updateState: boolean = true) => {
        Promise.all([loadTags(updateState)]).then(() => {
            setLocalStorageItem(StoreKey.TAGS);
        });
    };
    const refetchCategoryGroups = (updateState: boolean = true) => {
        Promise.all([loadCategoryGroups(updateState)]).then(() => {
            setLocalStorageItem(StoreKey.CATEGORY_GROUPS);
        });
    };
    const refetchWithUpdatingState = () => {
        refetchTags(true);
        refetchCategoryGroups(true);
    };
    const refetchWithoutUpdatingState = () => {
        refetchTags(false);
        refetchCategoryGroups(false);
    };

    const initTags = async () => {
        try {
            const _rows = await getTagsFromStore();
            if (!_rows?.length) throw new Error('No data');
            setTags(_rows);
            if (getIsExpired(StoreKey.TAGS)) {
                throw new Error('Expired data');
            }
        } catch (err) {
            refetchTags(true);
        }
    };
    const initCategoryGroups = async () => {
        try {
            const _category_groups = await getCategoryGroupsFromStore();
            if (!_category_groups?.length) throw new Error('No data');
            setCategoryGroups(_category_groups);
            if (getIsExpired(StoreKey.CATEGORY_GROUPS)) {
                throw new Error('Expired data');
            }
        } catch (err) {
            refetchCategoryGroups(true);
        }
    };

    useEffect(() => {
        if (isDBNotSupported) {
            setTags([]);
            setCategoryGroups([]);
            return;
        }
        if (!isDBInitiated) return;
        initTags();
        initCategoryGroups();

        window.addEventListener(
            getStoreKey(StoreKey.TAGS) + '_update_store',
            refetchWithoutUpdatingState
        );
        window.addEventListener(
            getStoreKey(StoreKey.TAGS) + '_update_store_and_state',
            refetchWithUpdatingState
        );

        return () => {
            window.removeEventListener(
                getStoreKey(StoreKey.TAGS) + '_update_store',
                refetchWithoutUpdatingState
            );
            window.removeEventListener(
                getStoreKey(StoreKey.TAGS) + '_update_store_and_state',
                refetchWithUpdatingState
            );
        };
    }, [isDBInitiated, isDBNotSupported]);
    return {
        addTag,
        tags,
        addCategoryGroup,
        categoryGroups,
    };
};

export const useSpacesStore = () => {
    const {
        addData,
        getData,
        clearData,
        getIsExpired,
        isDBInitiated,
        isDBNotSupported,
    } = useIndexedDB();
    const [spaces, setSpaces] = useState<Space[] | undefined>(undefined);

    const loadSpaces = (updateState: boolean = true) => {
        return new Promise<void>((res) =>
            SpaceService.getSpaces().then(({ data }) => {
                if (updateState) setSpaces(data || []);
                clearData(StoreKey.SPACES).then(() => {
                    data?.forEach((d) => {
                        addData(StoreKey.SPACES, d);
                    });
                    res();
                });
            })
        );
    };
    const addSpace = (space: Space) => {
        return addData(StoreKey.SPACES, space);
    };
    const getSpacesFromStore = () => {
        return getData<Space[]>(StoreKey.SPACES);
    };

    const refetch = (updateState: boolean = true) => {
        loadSpaces(updateState).then(() => {
            setLocalStorageItem(StoreKey.SPACES);
        });
    };
    const refetchWithUpdatingState = () => {
        refetch(true);
    };
    const refetchWithoutUpdatingState = () => {
        refetch(false);
    };

    const initData = async () => {
        try {
            const _rows = await getSpacesFromStore();
            if (!_rows?.length) throw new Error('No data');
            setSpaces(_rows);
            if (getIsExpired(StoreKey.SPACES)) {
                throw new Error('Expired data');
            }
        } catch (err) {
            refetchWithUpdatingState();
        }
    };

    useEffect(() => {
        if (isDBNotSupported) {
            setSpaces([]);
            return;
        }
        if (!isDBInitiated) return;
        initData();

        window.addEventListener(
            getStoreKey(StoreKey.SPACES) + '_update_store',
            refetchWithoutUpdatingState
        );
        window.addEventListener(
            getStoreKey(StoreKey.SPACES) + '_update_store_and_state',
            refetchWithUpdatingState
        );

        return () => {
            window.removeEventListener(
                getStoreKey(StoreKey.SPACES) + '_update_store',
                refetchWithoutUpdatingState
            );
            window.removeEventListener(
                getStoreKey(StoreKey.SPACES) + '_update_store_and_state',
                refetchWithUpdatingState
            );
        };
    }, [isDBInitiated, isDBNotSupported]);
    return {
        addSpace,
        spaces,
    };
};

export const useProcessingReceiptsStore = () => {
    const {
        addData,
        getData,
        clearData,
        getIsExpired,
        isDBInitiated,
        isDBNotSupported,
    } = useIndexedDB();
    const { token } = useAuth();
    const [receipts, setReceipts] = useState<IReceipt[] | undefined>(undefined);

    const addReceipt = (receipt: IReceipt) => {
        return addData(StoreKey.PROCESSING_RECEIPTS, receipt);
    };

    const loadReceipts = (updateState: boolean = true) => {
        return new Promise<void>((res) =>
            ReceiptService.getProcessingReceipts([
                ReceiptPlatform.TEXTRACT_EMAIL,
                ReceiptPlatform.DESKTOP,
                ReceiptPlatform.GALLERY,
                ReceiptPlatform.VERYFI_EMAIL,
            ]).then(({ data: rs }) => {
                rs = rs.filter(
                    (r) =>
                        r.platform !== ReceiptPlatform.TEXTRACT_REALTIME &&
                        r.platform !== ReceiptPlatform.TEXTRACT
                );
                if (updateState) setReceipts(rs);
                clearData(StoreKey.PROCESSING_RECEIPTS).then(() => {
                    rs.forEach((receipt) => {
                        addReceipt(receipt);
                    });
                    res();
                });
            })
        );
    };

    const getReceiptsFromStore = () => {
        return getData<IReceipt[]>(StoreKey.PROCESSING_RECEIPTS);
    };

    const refetch = (updateState: boolean = true) => {
        loadReceipts(updateState).then(() => {
            setLocalStorageItem(StoreKey.PROCESSING_RECEIPTS);
        });
    };

    const refetchWithUpdatingState = () => {
        refetch(true);
    };
    const refetchWithoutUpdatingState = () => {
        refetch(false);
    };

    const initData = async () => {
        try {
            const _rows = await getReceiptsFromStore();
            if (!_rows?.length) throw new Error('No data');
            setReceipts(_rows);
            if (getIsExpired(StoreKey.PROCESSING_RECEIPTS)) {
                throw new Error('Expired data');
            }
        } catch (err) {
            refetch();
        }
    };

    useEffect(() => {
        if (isDBNotSupported) {
            setReceipts([]);
            return;
        }
        if (!isDBInitiated) return;
        initData();

        // check for realtime updates
        ReceiptService.loadInvalidationUpdates(token!).then((res) => {
            const load_many_invalidated = res.data.load_many_invalidated;
            if (load_many_invalidated) {
                updateStoreAndState(StoreKey.PROCESSING_RECEIPTS);
            }
        });

        window.addEventListener(
            getStoreKey(StoreKey.PROCESSING_RECEIPTS) + '_update_store',
            refetchWithoutUpdatingState
        );
        window.addEventListener(
            getStoreKey(StoreKey.PROCESSING_RECEIPTS) +
                '_update_store_and_state',
            refetchWithUpdatingState
        );

        return () => {
            window.removeEventListener(
                getStoreKey(StoreKey.PROCESSING_RECEIPTS) + '_update_store',
                refetchWithoutUpdatingState
            );
            window.removeEventListener(
                getStoreKey(StoreKey.PROCESSING_RECEIPTS) +
                    '_update_store_and_state',
                refetchWithUpdatingState
            );
        };
    }, [isDBInitiated, isDBNotSupported]);
    return { addReceipt, receipts };
};
