import { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

export const useHandlePrint = (onHandlePrintExtra?: () => void) => {
    const printableComponentRef = useRef<HTMLDivElement | null>(null);

    const [renderForPrint, setRenderForPrint] = useState<boolean>(false);

    const handlePrint = useReactToPrint({
        content: () => {
            onHandlePrintExtra && onHandlePrintExtra();
            return printableComponentRef.current;
        },
    });

    useEffect(() => {
        if (renderForPrint) {
            handlePrint();
            setRenderForPrint(false);
        }
        setRenderForPrint(false);
    }, [renderForPrint]);

    return {
        renderForPrint,
        setRenderForPrint,
        printableComponentRef,
    };
};
