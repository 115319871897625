import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import theme from '../../theme';

type IClickableTextProps = {
    text: string | React.ReactNode;
    onClick: () => void;
    disabled?: boolean;
    loading?: boolean;
    style?: React.CSSProperties;
};

const ClickableText = (props: IClickableTextProps) => {
    const { text, onClick, disabled, loading, style } = props;
    return (
        <Button
            style={{
                borderRadius: theme.roundness,
                textTransform: 'none',
                color: theme.colors.text,
                ...style,
            }}
            onClick={onClick}
            disabled={disabled || loading}
            variant={'text'}
        >
            {loading ? (
                <CircularProgress
                    color={'inherit'}
                    style={{ width: 24, height: 24 }}
                />
            ) : (
                text
            )}
        </Button>
    );
};

export default ClickableText;
