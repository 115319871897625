import React from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import { TouchableHighlight, Text } from '../../Touchables';
import theme from '../../../theme';
import { onWebAppClickDrawerItem } from '../../../tracking/trackers';
import { hexToRGB } from '../../../util/hexToRGB';

const DRAWER_ITEM_HEIGHT = 50;

const DrawerItem = ({
    text,
    MuiIcon,
    onClick,
    rightSection,
    style,
    textStyle,
    disabled,
    iconStyle,
}: {
    text: string;
    MuiIcon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
        muiName: string;
    };
    onClick?: () => void;
    rightSection?: React.ReactNode;
    style?: React.CSSProperties;
    textStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    disabled?: boolean;
}) => (
    <TouchableHighlight
        style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: 20,
            paddingRight: 20,
            height: DRAWER_ITEM_HEIGHT,
            ...style,
        }}
        onClick={() => {
            onWebAppClickDrawerItem({ item: text });
            if (disabled) return;
            onClick?.();
        }}
        underlayColor={hexToRGB(theme.colors.blue10, 0.5)}
    >
        <>
            <MuiIcon
                style={{
                    color: disabled ? '#aaa' : theme.colors.blue100,
                    marginRight: 10,
                    ...iconStyle,
                }}
            />
            <Text
                style={{
                    marginLeft: 12,
                    color: disabled ? '#aaa' : theme.colors.blue100,
                    ...textStyle,
                }}
            >
                {text}
            </Text>
            {rightSection}
        </>
    </TouchableHighlight>
);

export default DrawerItem;
