import * as yup from 'yup';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import * as Messages from './errorMessages';

export const US_PHONE = 'E.164';

/* 
    where all validators are stored
    try to make the validators reusable but it's ok if it's not
    yup is chainable so you can add more fields to other yup validators
*/
export const URL = yup.string().url();
export const PHONE_NUMBER = yup
    .string()
    .required(Messages.PHONE_REQUIRED)
    .test('test-phone-number-validation', Messages.INVALID_PHONE, (value) =>
        isPossiblePhoneNumber(value || '')
    );
export const PASSWORD = yup
    .string()
    .required(Messages.PASSWORD_REQUIRED)
    .min(6, Messages.PASSWORD_LENGTH);
const NAME = yup.string().required(Messages.NAME_REQUIRED);

export const EMAIL = yup
    .string()
    .email(Messages.INVALID_EMAIL)
    .required(Messages.EMAIL_REQUIRED);

export const SIGNUP = yup.object().shape({
    phoneNumber: PHONE_NUMBER,
    firstName: NAME,
});

export const LOGIN = yup.object().shape({
    phoneNumber: PHONE_NUMBER,
    password: yup.string().required(Messages.PASSWORD_REQUIRED),
});

export const TEXT = yup.string().max(100, Messages.FIELD_LENGTH).nullable();

export const MONEY = yup.number().typeError(Messages.MONEY_NUMBER);

export const FREE_TEXT = yup
    .string()
    .max(3500, Messages.NOTE_LENGTH)
    .nullable();

export const BEFORE_TODAY = yup
    .date()
    .max(new Date(new Date().setHours(23, 59, 59)), Messages.DATE_PAST);

export const AFTER_TODAY = yup
    .date()
    .min(new Date(new Date().setHours(0, 0, 0)), Messages.DATE_FUTTURE);

export const LINE_ITEM = yup.object().shape({
    name: TEXT,
    amount: MONEY,
});

export const RECEIPT = yup.object().shape({
    merchant: TEXT,
    amount: MONEY,
    date: yup.date(),
    notes: FREE_TEXT,
});

export const CATEGORY = TEXT.matches(/^[a-z0-9 ]+$/i, Messages.ALPHANUMERIC);
