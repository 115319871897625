import styled from 'styled-components';
import theme from '../../theme';

export const LoginContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.colors.background,
});

export const FormContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    marginTop: 50,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.colors.white,
    borderRadius: 20,
    padding: 20,
    width: 424,
    boxShadow: theme.spreadShadow,
});

export const LoginScreenContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    backgroundColor: theme.colors.white,
    padding: 40,
    borderRadius: 20,
});
