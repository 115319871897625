import { Typography } from '@mui/material';
import styled from 'styled-components';

export const Text = ({
    children,
    style,
}: {
    children: any;
    style: React.CSSProperties;
}) => (
    <Typography
        variant="body1"
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            fontWeight: '500',
            ...style,
        }}
    >
        {children}
    </Typography>
);

export const View = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const ScrollView = styled.div<{
    contentContainerStyle?: React.CSSProperties;
}>`
    display: flex;
    flex-direction: column;
    ${(props) => ({ ...props.contentContainerStyle })}
`;

export const TouchableOpacity = styled.div`
    display: flex;
    opacity: 1;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
`;

export const TouchableHighlight = styled.div<{ underlayColor?: string }>`
    display: flex;
    background-color: transparent;

    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.underlayColor};
    }
`;
