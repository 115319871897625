import React from 'react';
import UpgradeVisual from '../../assets/upgradeBadge.svg';
import { onWebAppClickUpgradeBadge } from '../../tracking/trackers';
import { useSubscriptionInfo } from '../../hooks/useSubscriptionInfo';
import { TouchableOpacity } from '../Touchables';
import { AppSubscriptionTierWithTeams } from '../../services/subscriptions/types';

const UpgradeBadge = ({
    source,
    upgradeTo,
    onPress,
}: {
    source: string;
    upgradeTo: AppSubscriptionTierWithTeams;
    onPress: () => void;
}) => {
    const { subscriptionData, isOrganizationProduct } = useSubscriptionInfo();
    return (
        <TouchableOpacity
            style={{
                marginTop: 5,
                marginBottom: 5,
                marginLeft: 20,
            }}
            onClick={() => {
                onWebAppClickUpgradeBadge({
                    upgradeTo,
                    upgradeFrom: isOrganizationProduct
                        ? 'teams'
                        : subscriptionData?.tier,
                    source,
                });
                onPress();
            }}
        >
            <img src={UpgradeVisual} alt="" />
        </TouchableOpacity>
    );
};

export default UpgradeBadge;
