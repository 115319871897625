import React, { useContext, useState } from 'react';

const ServerStateContext = React.createContext<any>({});

const ServerStateProvider = (props: {
    data?: any;
    children: React.ReactNode;
}) => {
    const [serverState] = useState<any | undefined>(
        props?.data || window?.data || undefined
    );

    return (
        <ServerStateContext.Provider value={serverState}>
            {props.children}
        </ServerStateContext.Provider>
    );
};

export default ServerStateProvider;

export const useServerState = () => useContext(ServerStateContext);
