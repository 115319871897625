import LogRocket from 'logrocket';
import ReactPixel from 'react-facebook-pixel';
import gtagEvents from './gtagEvents';
import outbrainPixelEvents from './outbrainPixelEvents';
import redditPixelEvents from './redditPixelEvents';
import ttqEvents from './ttqEvents';
import twitterPixelEvents from './twitterPixelEvents';
import uetqEvents from './uetqEvents';
import pintrestEvents from './pintrestEvents';

const multiChannelEvents = {
    beginCheckout: () => {
        // ReactPixel.track?.('InitiateCheckout');
        gtagEvents.beginCheckout();
        uetqEvents.beginCheckout();
    },
    signup: () => {
        ReactPixel.track?.('CompleteRegistration');
        gtagEvents.signup();
        uetqEvents.signup();
        redditPixelEvents.signup();
        twitterPixelEvents.signup();
        outbrainPixelEvents.signup();
        ttqEvents.completeRegistration();
        pintrestEvents.signup();
    },
    subscribe: () => {
        ReactPixel.track?.('StartTrial');
        gtagEvents.subscribe();
        uetqEvents.subscribe();
        ttqEvents.subscribe();
        redditPixelEvents.subscribe();
        twitterPixelEvents.subscribe();
        outbrainPixelEvents.subscribe();
        pintrestEvents.lead();
    },
    addToCart: () => {
        LogRocket.track?.('ClickedCTA');
        ReactPixel.track?.('AddToCart');
        gtagEvents.addToCart();
        uetqEvents.addToCart();
        redditPixelEvents.addToCart();
        outbrainPixelEvents.addToCart();
        twitterPixelEvents.addToCart();
    },
    // when the landing page loads
    pagevisit: () => {
        pintrestEvents.pagevisit();
    },
};

export default multiChannelEvents;
