export const size = {
    MOBILE_SM: '320px',
    MOBILE_MED: '375px',
    MOBILE_LG: '425px',
    TABLET_SM: '600px',
    TABLET: '768px',
    LAPTOP: '1024px',
    LAPTOP_LG: '1440px',
    DESKTOP: '2560px',
};

export const number = {
    MOBILE_SM: 320,
    MOBILE_MED: 375,
    MOBILE_LG: 425,
    TABLET_SM: 600,
    TABLET: 768,
    LAPTOP: 1024,
    LAPTOP_LG: 1440,
    DESKTOP: 2560,
};

const devices = {
    MOBILE_SM: `(min-width: ${size.MOBILE_SM})`,
    MOBILE_MED: `(min-width: ${size.MOBILE_MED})`,
    MOBILE_LG: `(min-width: ${size.MOBILE_LG})`,
    TABLET_SM: `(min-width: ${size.TABLET_SM})`,
    TABLET: `(min-width: ${size.TABLET})`,
    LAPTOP: `(min-width: ${size.LAPTOP})`,
    LAPTOP_LG: `(min-width: ${size.LAPTOP_LG})`,
    DESKTOP: `(min-width: ${size.DESKTOP})`,
};

export default devices;
