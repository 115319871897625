import React, {
    createContext,
    useContext,
    useState,
    Dispatch,
    SetStateAction,
    useEffect,
} from 'react';
import { VariationConfig } from '../../../components/LandingPageVariations/configuration/types';

export type iSignupSurveyAnswer = 'personal' | 'business' | 'both';

export enum PreSignupSurveyScreen {
    Goals = 'Goals',
    Usage = 'Usage',
    KindsOfWork = 'KindsOfWork',
    Business = 'Business',
    ScanReceipts = 'ScanReceipts',
    EmailReceipts = 'EmailReceipts',
    TaxPerson = 'TaxPerson',
    Quickbooks = 'Quickbooks',
    Reconcile = 'Reconcile',
    RecommendedPlan = 'RecommendedPlan',
}

type IPreSignupSurveyContext = {
    screen: PreSignupSurveyScreen;
    setScreen: (a: PreSignupSurveyScreen) => void;
    usageSurveryAnswer: string;
    setUsageSurveyAnswer: (answer: string) => void;
    preSignupSurveyAnswers: { [key in PreSignupSurveyScreen]?: string[] };
    setPreSignupSurveyAnswers: Dispatch<
        SetStateAction<{
            [key in PreSignupSurveyScreen]?: string[];
        }>
    >;
    goNext: (preSignupSurveyAnswers?: {
        [key in PreSignupSurveyScreen]?: string[];
    }) => void;
    variation?: VariationConfig;
};

const PreSignupSurveyContext = createContext<IPreSignupSurveyContext>({
    screen: PreSignupSurveyScreen.Goals,
    setScreen: () => {},
    usageSurveryAnswer: '',
    setUsageSurveyAnswer: () => {},
    preSignupSurveyAnswers: {
        Goals: [],
        Usage: [],
        KindsOfWork: [],
        Business: [],
        ScanReceipts: [],
        EmailReceipts: [],
        TaxPerson: [],
        Quickbooks: [],
        Reconcile: [],
        RecommendedPlan: [],
    },
    setPreSignupSurveyAnswers: () => {},
    goNext: () => {},
    variation: undefined,
});

export const PreSignupSurveyProvider = (props: {
    variation?: VariationConfig;
    goNext: (preSignupSurveyAnswers?: {
        [key in PreSignupSurveyScreen]?: string[];
    }) => void;
    usageSurveryAnswer: string;
    setUsageSurveyAnswer: (answer: string) => void;
    preSignupSurveyAnswers: { [key in PreSignupSurveyScreen]?: string[] };
    setPreSignupSurveyAnswers: Dispatch<
        SetStateAction<{
            [key in PreSignupSurveyScreen]?: string[];
        }>
    >;
    children: React.ReactNode;
}) => {
    const [screen, setScreen] = useState<PreSignupSurveyScreen>(
        props?.variation?.onboardingPresignupInitialScreen ||
            PreSignupSurveyScreen.Goals
    );

    useEffect(() => {
        setScreen(
            props?.variation?.onboardingPresignupInitialScreen ||
                PreSignupSurveyScreen.Goals
        );
    }, [props?.variation?.onboardingPresignupInitialScreen]);

    return (
        <PreSignupSurveyContext.Provider
            value={{
                screen,
                setScreen,
                usageSurveryAnswer: props?.usageSurveryAnswer,
                setUsageSurveyAnswer: props?.setUsageSurveyAnswer,
                preSignupSurveyAnswers: props?.preSignupSurveyAnswers,
                setPreSignupSurveyAnswers: props?.setPreSignupSurveyAnswers,
                goNext: props.goNext,
                variation: props.variation,
            }}
        >
            {props.children}
        </PreSignupSurveyContext.Provider>
    );
};

export const usePreSignupSurvey = () => useContext(PreSignupSurveyContext);
