import { Body } from '../../../components/Text';
import theme from '../../../theme';
import TinyCheck from '../../../assets/icons/tinyCheck.svg';

export const BulletPoint = ({ text }: { text: string }) => (
    <div style={{ display: 'flex', marginBottom: 10, flexDirection: 'row' }}>
        <img src={TinyCheck} alt="" width={20} height={20} />
        <Body style={{ color: theme.colors.blue60, marginLeft: 10 }}>
            {text}
        </Body>
    </div>
);
