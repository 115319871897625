import { Route } from 'react-router-dom';
import { LandingPageConfig } from '../configuration/types';
import { LandingPageVariation } from '../LandingPageVariation';

export const generateVariationsRoutes = (
    configs: LandingPageConfig[],
    prefix = 'lp-'
) => {
    return configs.map((landingPageConfig) => (
        <Route
            key={landingPageConfig.landingPageName}
            path={prefix + landingPageConfig.path}
            element={<LandingPageVariation {...landingPageConfig} />}
        />
    ));
};
