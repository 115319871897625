/**
 * formats number like: +11231231234
 * gets rid of any non-number and adds +1
 */
export default function unformatPhoneInput(phone: string | undefined) {
    let output;
    if (phone) {
        output = phone.replace(/[^0-9]/g, '');
        output[0] === '1' ? (output = '+' + output) : (output = '+1' + output);
    }
    return output || '';
}
