import { Box, Modal } from '@mui/material';
import theme from '../../theme';
import { number } from '../../util/devices';
import { hexToRGB } from '../../util/hexToRGB';

type IFullScreenModalProps = {
    visible: boolean;
    onClose: () => void;
    children: JSX.Element;
    maxWidth?: number;
    style?: React.CSSProperties;
    innerStyle?: React.CSSProperties;
};

const FullScreenModal = ({
    visible,
    onClose,
    children,
    maxWidth = number.TABLET,
    style,
    innerStyle,
}: IFullScreenModalProps) => {
    const modalWidth =
        window.innerWidth < maxWidth ? window.innerWidth * 0.9 : maxWidth;

    return (
        <Modal
            open={visible}
            onClose={onClose}
            style={{
                backgroundColor: hexToRGB(theme.colors.blue100, 0.75),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...style,
            }}
        >
            <Box
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    width: modalWidth,
                    backgroundColor: theme.colors.white,
                    borderRadius: theme.roundness,
                    borderWidth: 0,
                    border: 'none',
                    padding: 20,
                    ...innerStyle,
                }}
                p={6}
            >
                {children}
            </Box>
        </Modal>
    );
};

export default FullScreenModal;
