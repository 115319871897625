import { useEffect, useState } from 'react';
import { PasswordInput } from '../../../../components/Inputs';
import CircleWrapper from '../../../../assets/onboarding/circle-wrapper.svg';
import PhoneSecure from '../../../../assets/onboarding/phoneSecure.svg';
import { useSignup } from '../context';
import { GoogleContainerStyle } from '../styles';
import OnboardingCardTemplate from '../../../../components/OnboardingCardTemplate';
import LoadingLeaf from '../../../../components/LoadingLeaf';

const Password = () => {
    const { isLoading, setPassword: setPasswordInDb, variant } = useSignup();

    const [password, setPassword] = useState<string>('');

    useEffect(() => {
        if (variant === 'texting') {
            setPasswordInDb(undefined);
        }
    }, []);

    if (variant === 'texting') {
        return <OnboardingCardTemplate />;
    }

    return (
        <OnboardingCardTemplate
            title={'Create your password'}
            topComponent={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            minHeight: '250px',
                            minWidth: '250px',
                            background: `url(${CircleWrapper}) no-repeat center center / contain`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={PhoneSecure}
                            alt={'phone'}
                            style={{ width: 100 }}
                        />
                    </div>
                </div>
            }
            content={
                <GoogleContainerStyle>
                    <PasswordInput
                        value={password}
                        setValue={setPassword}
                        autoFocus={true}
                        style={{ width: '80%' }}
                    />
                </GoogleContainerStyle>
            }
            buttons={[
                {
                    text: 'Continue',
                    onClick: () => setPasswordInDb(password),
                    isLoading,
                    disabled: isLoading || password.length < 7,
                },
            ]}
        />
    );
};

export default Password;
