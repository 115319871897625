import styled from 'styled-components';
import { number } from '../../../util/devices';

export const AnimatedSectionContainer = styled.div<{
    marginTop?: number;
    marginBottom?: number;
}>`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: ${({ marginBottom }) => marginBottom ?? 100}px;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: ${({ marginTop }) => marginTop ?? 0}px;
`;

export const AnimatedSectionContainerInner = styled.div`
    display: flex;
    flex-direction: column;
    max-width: ${number.LAPTOP}px;
    justify-self: center;
`;
