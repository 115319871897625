import React, { useContext, useState } from 'react';
import { IReceipt } from '../services/receipts/types';

interface IUpdateReceiptProviderProps {
    children: React.ReactNode;
}

interface IUpdateReceiptContextValue {
    updatedReceipt: IReceipt | null;
    updateReceiptInList: (receipt: IReceipt) => void;
    deletedReceiptId: string | null;
    deleteReceiptInList: (receiptId: string | null) => void;
}

const UpdateReceiptContext = React.createContext<IUpdateReceiptContextValue>({
    updatedReceipt: null,
    updateReceiptInList: () => {},
    deletedReceiptId: null,
    deleteReceiptInList: () => {},
});

/**
 * When a receipt is updated in details view, it is set here
 * The list is "listening" when this changes, and will update accordingly
 */
const UpdateReceiptProvider = (props: IUpdateReceiptProviderProps) => {
    const [updatedReceipt, updateReceiptInList] = useState<IReceipt | null>(
        null
    );
    const [deletedReceiptId, deleteReceiptInList] = useState<string | null>(
        null
    );

    return (
        <UpdateReceiptContext.Provider
            value={{
                updatedReceipt,
                updateReceiptInList,
                deletedReceiptId,
                deleteReceiptInList,
            }}
        >
            {props.children}
        </UpdateReceiptContext.Provider>
    );
};

export default UpdateReceiptProvider;

export const useUpdateReceipt = () => useContext(UpdateReceiptContext);
