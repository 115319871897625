import React from 'react';
import { Body } from '../Text';
import { View } from '../Touchables';
import { useSubscriptionInfo } from '../../hooks/useSubscriptionInfo';
import { SubscriptionGroup } from '../../services/subscriptions/types';

export const SubscriptionBadgeComponent = ({
    color,
    text,
    style,
}: {
    color: string;
    text: string;
    style?: React.CSSProperties;
}) => {
    return (
        <View
            style={{
                backgroundColor: color,
                padding: 6,
                paddingTop: 4,
                paddingBottom: 4,
                borderRadius: 4,
                marginRight: 8,
                ...style,
            }}
        >
            <Body
                style={{
                    color: 'white',
                    fontWeight: '700',
                    textAlign: 'center',
                    fontSize: 14,
                }}
            >
                {text}
            </Body>
        </View>
    );
};

const SubscriptionStatusBadge = ({ group }: { group?: SubscriptionGroup }) => {
    const {
        subscriptionStatus,
        isInTrial,
        hasPaymentMethod,
        isStorePaymentMethod,
    } = useSubscriptionInfo(group);
    return (
        <View style={{ flexDirection: 'row' }}>
            <SubscriptionBadgeComponent
                color={subscriptionStatus.color}
                text={subscriptionStatus.text}
            />
            {isInTrial ? (
                <SubscriptionBadgeComponent color={'blue'} text={'Trial'} />
            ) : null}
            {!isStorePaymentMethod && !hasPaymentMethod ? (
                <SubscriptionBadgeComponent
                    color={'#fcba03'}
                    text={'No Payment Method'}
                />
            ) : null}
        </View>
    );
};

export default SubscriptionStatusBadge;
