import { Typography } from '@mui/material';
import React from 'react';
import theme from '../../theme';

type ITextProps = {
    children: React.ReactNode;
    style?: React.CSSProperties;
    className?: string;
};

export const Body = (props: ITextProps) => (
    <Typography
        variant="body1"
        style={{ ...props.style }}
        className={props.className}
    >
        {props.children}
    </Typography>
);

export const BodySmall = (props: ITextProps) => (
    <Typography variant="caption" style={{ lineHeight: 1.3, ...props.style }}>
        {props.children}
    </Typography>
);

export const BodyBold = (props: ITextProps) => (
    <Typography variant="body1" style={{ fontWeight: '700', ...props.style }}>
        {props.children}
    </Typography>
);

export const BodyMedium = (props: ITextProps) => (
    <Typography variant="body1" style={{ fontWeight: '500', ...props.style }}>
        {props.children}
    </Typography>
);

export const BodyClickable = (props: ITextProps & { onClick: () => void }) => (
    <Typography
        variant="body1"
        onClick={props.onClick}
        style={{
            color: theme.colors.primary400,
            textDecoration: 'underline',
            cursor: 'pointer',
            ...props.style,
        }}
        className={props.className}
    >
        {props.children}
    </Typography>
);

export const OnboardingHeader = (props: ITextProps) => (
    <Typography
        variant="h4"
        style={{
            fontWeight: '700',
            color: theme.colors.primary,
            textAlign: 'center',
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const OnboardingSubheader = (props: ITextProps) => (
    <Typography
        variant="h6"
        style={{
            color: theme.colors.primary,
            lineHeight: 1.5,
            textAlign: 'center',
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const OnboardingH5 = (props: ITextProps) => (
    <Typography
        variant="h5"
        style={{
            fontWeight: '700',
            color: theme.colors.primary,
            textAlign: 'center',
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const OnboardingBlueSubheader = (props: ITextProps) => (
    <OnboardingH5
        style={{
            textAlign: 'center',
            color: theme.colors.primary400,
            fontWeight: '600',
            ...props.style,
        }}
    >
        {props.children}
    </OnboardingH5>
);

export const OnboardingText = (props: ITextProps) => (
    <Typography
        variant="body1"
        style={{
            textAlign: 'center',
            fontWeight: '500',
            color: theme.colors.blue60,
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

// LANDING

export const SectionHeader = (props: ITextProps) => (
    <h1
        className="hero"
        style={{
            fontWeight: 'bold',
            color: theme.colors.primary,
            fontSize: '3.2rem',
            lineHeight: 1.1,
            margin: 0,
            ...props.style,
        }}
    >
        {props.children}
    </h1>
);

export const SectionSubheaderLarge = (props: ITextProps) => (
    <Typography
        variant="h3"
        style={{
            fontWeight: 'bolder',
            color: theme.colors.primary,
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const SectionSubheader = (props: ITextProps) => (
    <Typography
        variant="h4"
        style={{
            fontWeight: 'bolder',
            color: theme.colors.blue100,
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const SectionSubtitle = (props: ITextProps) => (
    <h3
        className="hero"
        style={{
            color: theme.colors.blue100,
            lineHeight: 1.5,
            fontWeight: '400',
            fontSize: '1.5rem',
            ...props.style,
        }}
    >
        {props.children}
    </h3>
);

export const Subtitle1 = (props: ITextProps) => (
    <Typography
        variant="subtitle1"
        style={{
            color: theme.colors.primary,
            lineHeight: 1.5,
            fontWeight: '400',
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const SectionDetails = (props: ITextProps) => (
    <Typography
        variant="body2"
        style={{
            color: theme.colors.primary,
            lineHeight: 1.5,
            fontWeight: '400',
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const BulletPoint = (props: ITextProps) => (
    <Typography
        variant="subtitle1"
        style={{
            color: theme.colors.primary,
            lineHeight: 1.5,
            fontWeight: '400',
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const CTAButtonText = (props: ITextProps) => (
    <Typography
        variant={'h4'}
        style={{
            lineHeight: 1.5,
            color: theme.colors.primary,
            fontWeight: '600',
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const FormTitle = (props: ITextProps) => (
    <Typography
        variant="h5"
        style={{
            lineHeight: 1.5,

            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

// RECEIPT DETAILS

export const FieldName = (props: ITextProps) => (
    <Typography
        variant="body1"
        style={{
            color: theme.colors.text,
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const FieldLabel = (props: ITextProps) => (
    <Typography
        variant="caption"
        style={{
            color: theme.colors.grayText,
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

// Shared Review / Report

export const SummaryHeader = (props: ITextProps) => (
    <Typography
        variant="h6"
        style={{
            color: theme.colors.blue100,
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const SummaryBody = (props: ITextProps) => (
    <Typography
        variant="body1"
        style={{
            fontWeight: '500',
            color: theme.colors.blue100,
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const SummaryBodyBold = (props: ITextProps) => (
    <Typography
        variant="body1"
        style={{
            fontWeight: '600',
            color: theme.colors.blue100,
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

// HOME SCREEN

export const SpaceHeader = (props: ITextProps) => (
    <Typography
        variant="h5"
        style={{
            fontWeight: '700',
            color: theme.colors.primary,
            fontSize: 32,
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

// CHARGE FINDER

export const ChargeFinderHeaderText = (props: ITextProps) => (
    <Typography
        variant="h5"
        style={{
            fontWeight: 'bold',
            color: theme.colors.primary,
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const ChargeFinderBody = (props: ITextProps) => (
    <Typography
        variant="body1"
        style={{
            fontWeight: 'bold',
            color: theme.colors.primary,
            textTransform: 'none',
            textAlign: 'left',
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const ChargeFinderBodySmall = (props: ITextProps) => (
    <Typography
        variant="body2"
        style={{
            color: theme.colors.primary,
            textTransform: 'none',
            textAlign: 'left',
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

// FAQ Page

export const FAQSectionTitle = (props: ITextProps) => (
    <Typography
        variant="h6"
        style={{
            color: theme.colors.primary,
            fontWeight: '700',
            textTransform: 'none',
            textAlign: 'left',
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

export const FAQQuestion = (props: ITextProps) => (
    <Typography
        variant="h6"
        style={{
            color: theme.colors.primary,
            fontWeight: '500',
            textTransform: 'none',
            textAlign: 'left',
            ...props.style,
        }}
    >
        {props.children}
    </Typography>
);

const formatBody = (str: string) => {
    return str.replace(/\n/g, '<br />');
};

export const FAQBody = (props: ITextProps) => (
    <Typography
        variant="body1"
        style={{
            color: theme.colors.primary,
            textTransform: 'none',
            textAlign: 'left',
            ...props.style,
        }}
        dangerouslySetInnerHTML={{
            __html: formatBody(props.children as string),
        }}
    >
        {props.children}
    </Typography>
);

// LOGIN

export const FormHeader = (props: ITextProps) => (
    <Typography
        variant="h5"
        style={{
            fontWeight: '700',
            fontSize: 28,
            padding: 20,
            paddingBottom: 5,
        }}
    >
        {props.children}
    </Typography>
);
