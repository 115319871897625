import styled from 'styled-components';
import Devices from '../../../util/devices';

export const PageContainerStyle = styled.div`
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    @media ${Devices.LAPTOP} {
        gap: 64px;
    }
`;

export const GoogleContainerStyle = styled.div`
    min-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
`;
