import DateParser from '../lib/date-parser';
import { useAuth } from '../providers/AuthProvider';
import { getLegacyProduct } from '../services/subscriptions/legacyProducts';
import {
    AppSubscriptionTier,
    AppSubscriptionTierWithTeams,
    SubscriptionGroup,
    SubscriptionInterval,
    SubscriptionPlatform,
} from '../services/subscriptions/types';

export const useSubscriptionInfo = (group?: SubscriptionGroup) => {
    const { user } = useAuth();

    const subscriptionData = group
        ? user?.subscription_data?.other?.[group]
        : user?.subscription_data;
    const maybeLegacyProduct = user?.subscription_tier
        ? getLegacyProduct(user?.subscription_tier)
        : undefined;
    const isGrandfathered =
        subscriptionData?.product?.grandfathered || user?.grandfathered;
    const isFreemium =
        subscriptionData?.product?.tier === AppSubscriptionTier.FREE ||
        subscriptionData?.tier === AppSubscriptionTier.FREE;
    const isUnpurchasedProduct = isGrandfathered || isFreemium;
    const canUpdatePlan = // Only allowing upgrades from the Manage subscriptions
        subscriptionData?.tier !== AppSubscriptionTier.PRO ||
        subscriptionData?.product?.interval !== SubscriptionInterval.YEAR;
    const isInTrial = subscriptionData?.is_in_trial;
    const isPro = subscriptionData?.product?.tier === AppSubscriptionTier.PRO;
    const isBase = subscriptionData?.product?.tier === AppSubscriptionTier.BASE;
    const isOrganizationProduct = !!subscriptionData?.product?.organization; // true for admin AND employees
    const seatsCount = subscriptionData?.quota?.seats?.current; // these are ONLY teams stripe subscriptions

    const isStorePaymentMethod =
        subscriptionData?.platform === SubscriptionPlatform.IOS ||
        subscriptionData?.platform === SubscriptionPlatform.ANDROID;

    const subscriptionStatus: { text: string; color: string } =
        subscriptionData?.status === 'active'
            ? { text: 'Active', color: 'green' }
            : subscriptionData?.status === 'cancelled'
            ? { text: 'Cancelled', color: 'orange' }
            : subscriptionData?.status === 'past_due'
            ? { text: 'Past Due', color: 'orange' }
            : subscriptionData?.status === 'pausd'
            ? { text: 'Paused', color: 'orange' }
            : subscriptionData?.status === 'consent'
            ? { text: 'Consent', color: 'orange' }
            : subscriptionData?.status === 'expired'
            ? { text: 'Expired', color: 'red' }
            : { text: 'Active', color: 'green' };

    const productName = subscriptionData?.product?.name;
    const productDescription = subscriptionData?.product?.description;
    const productValue = subscriptionData?.product ?? maybeLegacyProduct;
    const productPrice: string =
        subscriptionData?.product?.value === 0
            ? ''
            : typeof productValue?.value === 'number'
            ? `$${
                  productValue.value *
                  (isOrganizationProduct ? seatsCount ?? 1 : 1)
              }/${subscriptionData?.product?.interval}`
            : '';

    const subscriptionPaymentMethod =
        subscriptionData?.platform === SubscriptionPlatform.IOS
            ? 'Apple In-app purchases'
            : subscriptionData?.platform === SubscriptionPlatform.ANDROID
            ? 'Google Play Store'
            : subscriptionData?.platform === SubscriptionPlatform.PAYPAL
            ? 'PayPal'
            : subscriptionData?.platform === SubscriptionPlatform.STRIPE
            ? 'Credit Card'
            : null;

    const hasPaymentMethod = Boolean(user?.subscription_data?.payment_method);

    const periodStart: string | undefined = subscriptionData?.period_start
        ? new DateParser(subscriptionData?.period_start?.split(' ')[0])
              ?.toDateString?.()
              ?.split?.(' ')
              ?.slice?.(1)
              ?.join?.(' ')
        : undefined;
    const periodEnd = subscriptionData?.period_end
        ? new DateParser(subscriptionData?.period_end?.split(' ')[0])
              ?.toDateString?.()
              ?.split?.(' ')
              ?.slice?.(1)
              ?.join?.(' ')
        : undefined;

    const initialStartDate = subscriptionData?.stared_at
        ? subscriptionData?.stared_at?.split(' ')[0]
        : undefined;

    const isAnOrganziationAdmin = user?.organization?.user?.role === 'ADMIN';
    const isAnOrganziationEmployee =
        user?.organization?.user?.role === 'EMPLOYEE';

    const isNoPersonal = false;

    const availableUpgrades: AppSubscriptionTierWithTeams[] =
        subscriptionData?.product?.interval === SubscriptionInterval.YEAR
            ? subscriptionData?.tier === AppSubscriptionTier.PRO
                ? []
                : [AppSubscriptionTier.PRO]
            : isNoPersonal
            ? [AppSubscriptionTier.PRO]
            : [AppSubscriptionTier.BASE, AppSubscriptionTier.PRO];
    if (!isOrganizationProduct) {
        availableUpgrades.push('teams');
    }

    const subscriptionInterval =
        subscriptionData?.product?.interval ?? SubscriptionInterval.YEAR;

    return {
        subscriptionData,
        productName,
        productDescription,
        productPrice,
        subscriptionPaymentMethod,
        initialStartDate,
        periodStart,
        periodEnd,
        maybeLegacyProduct,
        isGrandfathered,
        isFreemium,
        isUnpurchasedProduct,
        canUpdatePlan,
        subscriptionStatus,
        isInTrial,
        isPro,
        isBase,
        isOrganizationProduct,
        isAnOrganziationAdmin,
        isAnOrganziationEmployee,
        availableUpgrades,
        subscriptionInterval,
        hasPaymentMethod,
        seatsCount,
        isStorePaymentMethod,
    };
};
