import { useRef } from 'react';
import { Button, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ChevronRight } from '@mui/icons-material';
import theme from '../../theme';
import { HEADER_HEIGHT } from '../../util/constants';
import { Body } from '../Text';
import { useHandleOutsideClick } from '../../hooks/useHandleOutsideClick';

const HeaderDropdown = ({
    items,
    visible,
    setVisible,
    insideClickRefs = [],
    style,
}: {
    items: ({
        name: string;
        onClick: () => void;
        Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
            muiName: string;
        };
    } | null)[];
    visible: boolean;
    setVisible: (visible: boolean) => void;
    insideClickRefs?: React.RefObject<HTMLDivElement>[];
    style?: React.CSSProperties;
}) => {
    const ref = useRef(null);

    useHandleOutsideClick([ref, ...insideClickRefs], () => {
        setVisible(false);
    });

    if (!visible) return <></>;

    return (
        <div
            style={{
                position: 'absolute',
                top: HEADER_HEIGHT - 8,
                right: '5%',
                backgroundColor: theme.colors.white,
                borderRadius: 10,
                width: 240,
                boxShadow: theme.lightShadow,
                zIndex: 100,
                filter: `drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2))`,
                ...style,
            }}
            ref={ref}
        >
            {items.map((item) => {
                if (!item) return null;
                const { Icon: _Icon, name, onClick } = item;
                const Icon = _Icon ?? ChevronRight;
                return (
                    <Button
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            height: 60,
                            justifyContent: 'flex-start',
                            width: '100%',
                            borderRadius: 0,
                        }}
                        onClick={() => {
                            setVisible(false);
                            onClick();
                        }}
                    >
                        <Icon
                            style={{
                                color: theme.colors.secondary,
                                marginRight: 10,
                                marginLeft: 10,
                            }}
                        />
                        <Body
                            style={{
                                textAlign: 'left',
                                color: theme.colors.blue100,
                                fontWeight: '600',
                                textTransform: 'none',
                            }}
                        >
                            {name}
                        </Body>
                    </Button>
                );
            })}
        </div>
    );
};

export default HeaderDropdown;
