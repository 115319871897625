import MobileDetect from 'mobile-detect';

const md = new MobileDetect(window.navigator.userAgent);

export const isIOS = md.os() === 'iOS';

export const isPhone = md.isPhoneSized();

export const getOS = () => {
    let userAgent = window.navigator.userAgent.toLowerCase(),
        macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
        windowsPlatforms = /(win32|win64|windows|wince)/i,
        iosPlatforms = /(iphone|ipad|ipod)/i,
        os = null;

    if (macosPlatforms.test(userAgent)) {
        os = 'macos';
    } else if (iosPlatforms.test(userAgent)) {
        os = 'ios';
    } else if (windowsPlatforms.test(userAgent)) {
        os = 'windows';
    } else if (/android/.test(userAgent)) {
        os = 'android';
    } else if (!os && /linux/.test(userAgent)) {
        os = 'linux';
    }

    return os;
};
