import React, { lazy, useEffect, useState } from 'react';
import {
    InputAdornment,
    IconButton,
    TextField,
    CircularProgress,
    TextFieldProps,
} from '@mui/material';
import { SearchRounded } from '@mui/icons-material';
import { Visibility, VisibilityOff, Close, Search } from '@mui/icons-material';
import { styled } from 'styled-components';
import theme from '../../theme';
import { BodyBold } from '../Text';
import { NumberInput, TextInput } from './styles';

const ReactPhoneInput = lazy(() => import('react-phone-input-material-ui'));

type IInputProps = {
    value: string;
    setValue: (
        v: string
    ) => void | React.Dispatch<React.SetStateAction<string>>;
    helperText?: string;
    placeholder?: string;
    autoFocus?: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    style?: React.CSSProperties;
    className?: string;
    inputStyle?: React.CSSProperties;
    isIcon?: boolean;
    muiProps?: Omit<TextFieldProps, 'variant'>;
};

export const PhoneNumberInput = (
    props: IInputProps & { label: string }
): JSX.Element => {
    const {
        value,
        label,
        helperText,
        placeholder,
        autoFocus,
        setValue,
        onBlur,
        style,
    } = props;
    return (
        <div style={{ width: '80%', marginBottom: 10, ...style }}>
            <ReactPhoneInput
                value={value}
                onChange={(v) => {
                    // Don't allow number to start with "1" because with US that means it's the area code, which we don't want
                    if (v.length > 0 && v[0] === '1') {
                        setValue(v.slice(1));
                    } else {
                        setValue(v);
                    }
                }}
                country={'us'}
                defaultMask={'... ... ....'}
                alwaysDefaultMask={true}
                disableCountryCode={true}
                onlyCountries={['us']}
                inputProps={{
                    label,
                    helperText,
                    autoFocus,
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                    variant: 'outlined',
                    color: 'secondary',
                    bordercolor: 'secondary',
                    style: { backgroundColor: 'transparent' },
                }}
                placeholder={placeholder}
                component={TextField}
                onBlur={onBlur}
            />
        </div>
    );
};

export const ConfirmationCodeInput = (props: IInputProps) => {
    const { value, setValue, helperText, placeholder, autoFocus } = props;
    return (
        <NumberInput
            label={' '}
            value={value}
            onChange={(event) => {
                if (event.target.value.length > 6) return;
                setValue(event.target.value);
            }}
            type={'number'}
            variant={'outlined'}
            helperText={helperText}
            placeholder={placeholder}
            autoFocus={autoFocus}
            style={{ width: '100%' }}
            InputLabelProps={{ shrink: false }}
        />
    );
};

export const BasicInput = (props: IInputProps & { label?: string }) => {
    const {
        value,
        setValue,
        helperText,
        label,
        placeholder,
        autoFocus,
        style,
        className,
        onBlur,
        onFocus,
        inputStyle,
        isIcon,
    } = props;
    return (
        <div style={{ ...style }}>
            <BodyBold>{label}</BodyBold>
            {isIcon ? (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <SearchRounded
                        style={{
                            width: 35,
                            height: 35,
                            color: 'gray',
                            position: 'absolute',
                            zIndex: 1,
                            top: 45,
                            left: 10,
                        }}
                    />
                    <TextInput
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                        type={'text'}
                        variant={'outlined'}
                        helperText={helperText}
                        placeholder={placeholder}
                        autoFocus={autoFocus}
                        className={className}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        style={{ width: '100%', ...inputStyle }}
                        InputProps={{
                            style: {
                                borderRadius: 8,
                                paddingLeft: 40,
                            },
                        }}
                    />
                </div>
            ) : (
                <TextInput
                    value={value}
                    onChange={(event) => setValue(event.target.value)}
                    type={'text'}
                    variant={'outlined'}
                    helperText={helperText}
                    placeholder={placeholder}
                    autoFocus={autoFocus}
                    className={className}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    style={{ width: '100%', ...inputStyle }}
                    InputProps={{
                        style: {
                            borderRadius: 8,
                            paddingLeft: 10,
                        },
                    }}
                />
            )}
        </div>
    );
};

export const InputNoHeader = (props: IInputProps & { label?: string }) => {
    const {
        value,
        setValue,
        helperText,
        label,
        placeholder,
        autoFocus,
        style,
        className,
        muiProps,
        onBlur,
        onFocus,
    } = props;
    return (
        <TextField
            value={value}
            onChange={(event) => setValue(event.target.value)}
            type={'text'}
            label={label}
            variant={'outlined'}
            helperText={helperText}
            placeholder={placeholder}
            autoFocus={autoFocus}
            className={className}
            style={{ width: '80%', ...style }}
            onBlur={() => {
                onBlur?.();
            }}
            onFocus={() => {
                onFocus?.();
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                    onBlur?.();
                }
            }}
            {...muiProps}
        />
    );
};

export const PasswordInput = (
    props: IInputProps & {
        toggleVisibility?: boolean;
    }
) => {
    const {
        value,
        setValue,
        helperText,
        placeholder,
        autoFocus,
        toggleVisibility,
        style,
    } = props;

    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <TextInput
            label={'Password'}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            type={showPassword ? 'text' : 'password'}
            variant={'outlined'}
            helperText={helperText}
            placeholder={placeholder}
            autoFocus={autoFocus}
            style={{ width: '80%', ...style }}
            InputProps={
                toggleVisibility
                    ? {
                          endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={() =>
                                          setShowPassword(!showPassword)
                                      }
                                  >
                                      {showPassword ? (
                                          <Visibility
                                              style={{
                                                  color: theme.colors.secondary,
                                              }}
                                          />
                                      ) : (
                                          <VisibilityOff
                                              style={{
                                                  color: theme.colors.secondary,
                                              }}
                                          />
                                      )}
                                  </IconButton>
                              </InputAdornment>
                          ),
                      }
                    : undefined
            }
        />
    );
};

export const PINInput = (
    props: IInputProps & {
        toggleVisibility?: boolean;
    }
) => {
    const {
        value,
        setValue,
        helperText,
        placeholder,
        autoFocus,
        toggleVisibility,
        style,
    } = props;

    const [showPasscode, setShowPasscode] = useState<boolean>(false);

    return (
        <TextInput
            label={'PIN'}
            value={value}
            onChange={(event) => setValue(event.target.value)}
            type={showPasscode ? 'text' : 'password'}
            variant={'outlined'}
            helperText={helperText}
            placeholder={placeholder}
            autoFocus={autoFocus}
            style={{ width: '80%', ...style }}
            InputProps={
                toggleVisibility
                    ? {
                          endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton
                                      aria-label="toggle PIN visibility"
                                      onClick={() =>
                                          setShowPasscode(!showPasscode)
                                      }
                                  >
                                      {showPasscode ? (
                                          <Visibility />
                                      ) : (
                                          <VisibilityOff />
                                      )}
                                  </IconButton>
                              </InputAdornment>
                          ),
                      }
                    : undefined
            }
        />
    );
};

export const SearchInput = ({
    search,
    setSearch,
    onSubmit,
    isSearching,
    style,
    searchBarStyle,
    placeholder,
    searchHeight: _searchHeight,
    disableEditing,
    disableX,
    searchIconStyle,
    hideIcon,
}: {
    search: string;
    setSearch: (s: string) => void;
    onSubmit?: () => Promise<void>;
    isSearching?: boolean;
    style?: React.CSSProperties;
    searchBarStyle?: React.CSSProperties;
    placeholder?: string;
    searchHeight?: number;
    disableEditing?: boolean;
    disableX?: boolean;
    searchIconStyle?: React.CSSProperties;
    hideIcon?: boolean;
}) => {
    const [borderIsFocused, setBorderIsFocused] = useState<boolean>(false);
    const [showX, setShowX] = useState<boolean>(false);
    const searchHeight = _searchHeight || 40;

    useEffect(() => {
        if (disableX) return;
        setShowX(search.length > 0);
    }, [search]);

    return (
        <div
            style={{
                position: 'relative',
                height: searchHeight,
                ...style,
            }}
        >
            {hideIcon ? null : (
                <Search
                    style={{
                        position: 'absolute',
                        color: theme.colors.secondary,
                        top: searchHeight / 6,
                        left: searchHeight / 2.5,
                        ...searchIconStyle,
                    }}
                />
            )}
            <input
                type="text"
                placeholder={placeholder || 'Search your receipts...'}
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (
                        onSubmit &&
                        (e.key === 'Enter' || e.key === 'NumpadEnter')
                    ) {
                        onSubmit();
                    }
                }}
                style={{
                    width: '100%',
                    borderRadius: 8,
                    borderImage: 'none',
                    border: 'none',
                    boxShadow: borderIsFocused
                        ? `inset 0 0 0 1.5px ${theme.colors.secondary}`
                        : theme.lightShadow,
                    outline: 'none',
                    height: searchHeight,
                    backgroundColor: disableEditing
                        ? theme.colors.white
                        : undefined,
                    fontSize: 16,
                    fontWeight: '500',
                    paddingLeft: hideIcon ? 20 : 50,
                    ...searchBarStyle,
                }}
                disabled={disableEditing}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onFocus={() => {
                    setBorderIsFocused(true);
                }}
                onBlur={() => {
                    setBorderIsFocused(false);
                }}
            />
            <div
                style={{
                    position: 'absolute',
                    right: 10,
                    top: searchHeight / 5,
                }}
                onClick={() => setSearch('')}
            >
                {isSearching ? (
                    <CircularProgress color={'secondary'} size={20} />
                ) : (
                    <Close
                        style={{
                            display: showX ? 'block' : 'none',
                            width: searchHeight * 0.7,
                            color: theme.colors.blue60,
                        }}
                    />
                )}
            </div>
        </div>
    );
};

// INPUTS FOR LOGIN SCREEN

const BaseInput = styled.input`
    height: 50px;
    border-radius: 8px;
    border: 1px solid ${theme.colors.blue20};
    padding-left: 20px;
    font-size: 15px;
    color: ${theme.colors.blue100};
    font-weight: 500;
    width: 100%;

    ::placeholder {
        color: ${theme.colors.blue60};
    }
`;

export const InputCustom = ({
    inputProps,
    LeftAccessory,
    RightAccessory,
    style,
}: {
    inputProps?: React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >;
    LeftAccessory?: React.ReactNode;
    RightAccessory?: React.ReactNode;
    style?: React.CSSProperties;
}) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
                width: '100%',
                flex: 1,
                ...style,
            }}
        >
            <div
                style={{
                    height: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'absolute',
                    left: 0,
                }}
            >
                {LeftAccessory}
            </div>
            <BaseInput {...inputProps} />
            <div
                style={{
                    height: '100%',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'absolute',
                    right: 0,
                }}
            >
                {RightAccessory}
            </div>
        </div>
    );
};

export const PhoneNumberInputCustom = ({
    value,
    setValue,
    style,
}: {
    value: string;
    setValue: (v: string) => void;
    style?: React.CSSProperties;
}) => {
    const handlePhoneNumberChange = (e: any) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        // Update the state with the formatted phone number
        setValue(formattedPhoneNumber);
    };

    const formatPhoneNumber = (phoneNumber: string) => {
        const numsOnly = phoneNumber.replace(/[^\d]/g, '');
        const formatted =
            numsOnly.slice(0, 3) +
            ' ' +
            numsOnly.slice(3, 6) +
            ' ' +
            numsOnly.slice(6, 10);
        return formatted.trim();
    };

    const leftAccessoryWidth = 40;

    return (
        <InputCustom
            inputProps={{
                style: {
                    paddingLeft: leftAccessoryWidth + 10,
                    width: '100%',
                    ...style,
                },
                value: value,
                onChange: handlePhoneNumberChange,
                maxLength: 12,
                placeholder: '000 000 0000',
                type: 'tel',
            }}
            LeftAccessory={
                <div
                    style={{
                        height: '60%',
                        width: leftAccessoryWidth,
                        fontSize: 15,
                        color: theme.colors.blue60,
                        fontWeight: 500,
                        borderRight: `1px solid ${theme.colors.blue20}`,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <div>+1</div>
                </div>
            }
        />
    );
};

export const PasswordInputCustom = ({
    value,
    setValue,
    style,
}: {
    value: string;
    setValue: (v: string) => void;
    style?: React.CSSProperties;
}) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const Icon = showPassword ? Visibility : VisibilityOff;

    return (
        <InputCustom
            inputProps={{
                style: {
                    paddingLeft: 20,
                    width: '100%',
                    ...style,
                },
                value: value,
                onChange: (e) => setValue(e.target.value),
                type: showPassword ? undefined : 'password',
                placeholder: 'Password',
            }}
            RightAccessory={
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: 30,
                        height: '100%',
                    }}
                >
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        <Icon
                            style={{
                                color: theme.colors.blue60,
                            }}
                        />
                    </IconButton>
                </div>
            }
        />
    );
};
