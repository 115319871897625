import { useEffect, useState } from 'react';
import useLocalStoragePrefs from '../../components/Spreadsheet/hooks/useLocalStoragePrefs';
import { COLUMNS, IColumn } from '../../components/Spreadsheet/constants';
import { IInboxReceipt } from './context';

/**
 * Stores props that we want access to from the spreadsheet header (which would normally be in the body)
 */
const useInboxColumns = ({
    spreadsheetKey,
    inboxReceipts,
}: {
    spreadsheetKey: string;
    inboxReceipts: IInboxReceipt[];
}) => {
    const { data: localStoragePrefs } = useLocalStoragePrefs(spreadsheetKey);

    let defaultColumns = localStoragePrefs?.columns
        ? localStoragePrefs.columns
        : COLUMNS.filter(({ isDefault }) => !!isDefault);
    const platformColumn = COLUMNS.find((c) => c.key === 'platform');
    // bc you can't save reconciled on processing receipts
    defaultColumns = defaultColumns.filter((c) => !c.disableInInbox);
    if (platformColumn) {
        if (!defaultColumns.find((c) => c.key === 'platform')) {
            defaultColumns = [
                ...defaultColumns.splice(0, 1),
                platformColumn,
                ...defaultColumns.splice(0),
            ];
        }
        defaultColumns = defaultColumns.filter((c) => c.key !== 'folder');
        defaultColumns = [
            ...defaultColumns.splice(0, 1),
            COLUMNS.find((c) => c.key === 'folder')!,
            ...defaultColumns.splice(0),
        ];
    } else {
        // filter out platform column
        defaultColumns = defaultColumns.filter((c) => c.key !== 'platform');
    }

    const [columns, setColumns] = useState<IColumn[]>(defaultColumns);

    const includeEmployeeColumn = () => {
        setColumns((prevColumns) => {
            const employeeColumn = COLUMNS.find((c) => c.key === 'employee');
            if (
                employeeColumn &&
                !prevColumns.find((c) => c.key === 'employee')
            ) {
                return [
                    ...prevColumns.splice(0, 1),
                    employeeColumn,
                    ...prevColumns.splice(0),
                ];
            }
            return prevColumns;
        });
    };

    useEffect(() => {
        const someDocumentHasEmployeeField = inboxReceipts.some((receipt) => {
            return receipt?.additional_fields?.external;
        });
        if (someDocumentHasEmployeeField) {
            includeEmployeeColumn();
        }
    }, [inboxReceipts.length]);

    return {
        columns,
        setColumns,
        includeEmployeeColumn,
    };
};

export default useInboxColumns;
