import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SignupScreen, useSignup } from '../context';
import { PhoneNumberInputCustom } from '../../../../components/Inputs';
import OnboardingCardTemplate from '../../../../components/OnboardingCardTemplate';
import CircleWrapper from '../../../../assets/onboarding/circle-wrapper.svg';
import Phone from '../../../../assets/onboarding/phone.svg';
import { useQuery } from '../../../../util/hooks';
import theme from '../../../../theme';
import * as Validators from '../../../../util/validators';
import ClickableText from '../../../../components/Buttons/ClickableText';
import unformatPhoneInput from '../../../../util/unformatMobile';
import { MIN_MOBILE_LENGTH } from '../../../Login';
import {
    onWebAppSignupSuggestedMobileDetected,
    onWebAppSignupSuggestedMobileFailed,
} from '../../../../tracking/trackers';
import AuthService from '../../../../services/auth/service';
import { ICheckAccountResponse } from '../../../../services/auth/types';

const PhoneNumber = () => {
    const {
        isLoading,
        setScreen,
        mobile,
        setMobile,
        sendCodeToMobile,
        shareToken,
    } = useSignup();
    const navigate = useNavigate();
    const location = useLocation();
    const query = useQuery();
    const variant = query.get('variant');
    const suggestedMobile = query.get('suggested_mobile');
    const isManualEntry = query.get('manualCodeEntry');

    useEffect(() => {
        if (suggestedMobile && suggestedMobile !== 'null') {
            const formattedSuggestedPhone = unformatPhoneInput(suggestedMobile);
            const cleanedSuggestedPhone = formattedSuggestedPhone.replace(
                '+1',
                ''
            );
            const seprateFormatted =
                cleanedSuggestedPhone.slice(0, 3) +
                ' ' +
                cleanedSuggestedPhone.slice(3, 6) +
                ' ' +
                cleanedSuggestedPhone.slice(6, 10);
            Validators.PHONE_NUMBER.validate(formattedSuggestedPhone)
                .then(async () => {
                    onWebAppSignupSuggestedMobileDetected({
                        mobile: cleanedSuggestedPhone,
                    });
                    setMobile(seprateFormatted);
                    let checkAccountResponse: ICheckAccountResponse | undefined;
                    try {
                        checkAccountResponse = await AuthService.checkAccount(
                            seprateFormatted
                        );
                    } catch (e) {
                        console.error('Error checking account', e);
                    }

                    if (checkAccountResponse?.additionalInfo === 'password') {
                        console.error('Account exists');
                        navigate(
                            `/login?${
                                shareToken ? `share_token=${shareToken}` : ''
                            }${
                                seprateFormatted
                                    ? `&mobile=${seprateFormatted}`
                                    : ''
                            }`,
                            {
                                state: {
                                    ...location.state,
                                },
                            }
                        );
                        return;
                    }

                    // sendCodeToMobile(seprateFormatted);
                    setScreen(SignupScreen.Name);
                })
                .catch(() => {
                    console.error('Invalid suggested mobile');
                    onWebAppSignupSuggestedMobileFailed({
                        mobile: cleanedSuggestedPhone,
                    });
                });
        }
    }, [suggestedMobile]);

    const title =
        variant === 'salestax'
            ? 'Get Free Sales Tax Report'
            : variant === 'texting'
            ? 'Enable 2-Factor Authentication'
            : variant === 'share'
            ? 'Accept Invite'
            : 'Create account';

    const subtitle =
        variant === 'salestax'
            ? 'SimplyWise connects to your gmail to create your free 2022 sales tax report!'
            : variant === 'texting'
            ? 'This will keep your account secure'
            : undefined;

    return (
        <OnboardingCardTemplate
            title={title}
            subtitle={subtitle}
            topComponent={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            minHeight: '250px',
                            minWidth: '250px',
                            background: `url(${CircleWrapper}) no-repeat center center / contain`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img src={Phone} alt={'phone'} style={{ width: 100 }} />
                    </div>
                </div>
            }
            content={
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ margin: 10 }} />
                    <PhoneNumberInputCustom
                        value={mobile}
                        setValue={(s) => setMobile(s)}
                    />
                    {variant === 'texting' ? null : (
                        <ClickableText
                            text={`Already have an account? Log in`}
                            onClick={() => {
                                navigate(
                                    `/login?${
                                        shareToken
                                            ? `share_token=${shareToken}`
                                            : ''
                                    }`,
                                    {
                                        state: {
                                            ...location.state,
                                        },
                                    }
                                );
                            }}
                            style={{
                                color: theme.colors.blue60,
                            }}
                        />
                    )}
                </div>
            }
            buttons={[
                {
                    text:
                        variant === 'share' || variant === 'texting'
                            ? 'Continue'
                            : 'Sign up',
                    onClick: async () => {
                        let checkAccountResponse:
                            | ICheckAccountResponse
                            | undefined;
                        try {
                            checkAccountResponse =
                                await AuthService.checkAccount(mobile);
                        } catch (e) {
                            console.error('Error checking account', e);
                        }

                        // TODO: Enable once the auth flow is the same as mobile, right now if people think they're signup flow we should continue since some users might
                        // not have set a password yet
                        // if (
                        //     checkAccountResponse?.additionalInfo === 'password'
                        // ) {
                        //     console.error('Account exists');
                        //     navigate(
                        //         `/login?${
                        //             shareToken
                        //                 ? `share_token=${shareToken}`
                        //                 : ''
                        //         }${mobile ? `&mobile=${mobile}` : ''}`,
                        //         {
                        //             state: {
                        //                 ...location.state,
                        //             },
                        //         }
                        //     );
                        //     return;
                        // }

                        if (isManualEntry) {
                            if (
                                variant === 'organization' ||
                                variant === 'texting'
                            ) {
                                sendCodeToMobile(mobile);
                            } else {
                                setScreen(SignupScreen.Name);
                            }
                        } else {
                            sendCodeToMobile(mobile);
                        }
                    },
                    isLoading,
                    disabled:
                        isLoading ||
                        !mobile ||
                        mobile.includes('_') ||
                        mobile.length < MIN_MOBILE_LENGTH,
                },
            ]}
        />
    );
};

export default PhoneNumber;
