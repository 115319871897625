import { emitter } from '@marvelapp/react-ab-test';

const experimentIndex = 0;
const ChargeFinderVariants = [
    {
        name: 'ChargeFinderCTA-Exp-June19',
        variants: {
            A: 'base',
        },
    },
];

emitter.defineVariants(
    ChargeFinderVariants[experimentIndex].name,
    Object.keys(ChargeFinderVariants[experimentIndex].variants)
);

export default ChargeFinderVariants[experimentIndex];
