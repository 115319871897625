import { Modal, Paper } from '@mui/material';
import BottomButton from '../Buttons/BottomButton';
import randId from '../../util/randId';
import TextingAnimation from '../../assets/animations/svGator/TextSimplyWise';

export const TEXTING_ONBOARDING_NUMBER = '+15175102015';

const TextingModal = ({
    isModalOpen,
    setIsModalOpen,
}: {
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
}) => {
    return (
        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <Paper
                style={{
                    position: 'absolute',
                    width: '90%',
                    top: '25%',
                    left: '25%',
                    padding: 20,

                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        fontWeight: 'bold',
                        fontSize: 20,
                    }}
                >
                    Send us a text to get started
                </div>
                <div style={{ height: 20 }} />
                <div
                    style={{
                        width: 250,
                    }}
                >
                    <TextingAnimation />
                </div>
                <div style={{ height: 20 }} />
                <BottomButton
                    text="Continue"
                    noshadow
                    onClick={() => {
                        let visitorSlug = localStorage.getItem('visitor_slug');
                        if (!visitorSlug) {
                            visitorSlug = randId();
                            localStorage.setItem('visitor_slug', visitorSlug);
                        }
                        window.location.href = `sms:${TEXTING_ONBOARDING_NUMBER}?body=I'd like to get started with SimplyWise #${visitorSlug}`;
                        setIsModalOpen(false);
                    }}
                />
            </Paper>
        </Modal>
    );
};

export default TextingModal;
