import React, { useEffect } from 'react';
import {
    Container,
    Typography,
    Button,
    Grid,
    Avatar,
    Card,
    CardContent,
    Box,
    useMediaQuery,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import LandingPhoneImage from '../../assets/reconciliation_flow/landing/phone.svg';
import AsSeen1 from '../../assets/reconciliation_flow/landing/as_seen_1.svg';
import AsSeen2 from '../../assets/reconciliation_flow/landing/as_seen_2.svg';
import AsSeen3 from '../../assets/reconciliation_flow/landing/as_seen_3.svg';
import AsSeen4 from '../../assets/reconciliation_flow/landing/as_seen_4.svg';
import AsSeen5 from '../../assets/reconciliation_flow/landing/as_seen_5.svg';
import AsSeen6 from '../../assets/reconciliation_flow/landing/as_seen_6.svg';
import UserPic from '../../assets/reconciliation_flow/landing/user_pic.svg';
import HowItWorks1 from '../../assets/reconciliation_flow/landing/how_it_works_1.svg';
import HowItWorks2 from '../../assets/reconciliation_flow/landing/how_it_works_2.svg';
import HowItWorks3 from '../../assets/reconciliation_flow/landing/how_it_works_3.svg';
import Image1 from '../../assets/reconciliation_flow/landing/image1.jpg';
import Image2 from '../../assets/reconciliation_flow/landing/image2.jpg';
import Rating from '../../assets/reconciliation_flow/landing/rating.svg';
import Rating2 from '../../assets/reconciliation_flow/landing/rating2.svg';
import LeafForHeader from '../../assets/leafForHeader.svg';
import LogoWhite from '../../assets/landing/logo_white.svg';
import theme from '../../theme';
import { number as deviceWidths } from '../../util/devices';
import navigationActions from '../../util/navigationActions';
import {
    onWebAppLandingPageCTAClick,
    onWebAppLoadLandingPage,
} from '../../tracking/trackers';
import multiChannelEvents from '../../tracking/multiChannelEvents';
import { useQuery } from '../../util/hooks';

const EXPERIMENT_NAME = 'wtc-web-onb-sep-4';

const ReconciliationLanding = ({
    title,
    subtitle,
}: {
    title?: string;
    subtitle?: string;
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = !useMediaQuery(`(min-width:${deviceWidths.TABLET}px)`);
    const query = useQuery();
    const wtcData = {
        origin_web_url: query.get('origin_web_url'),
        web_url: query.get('web_url'),
        source: query.get('source'),
    };

    const onClickCTA = (ctaPosition: string) => {
        multiChannelEvents.addToCart();
        onWebAppLandingPageCTAClick({
            pageId: location.pathname,
            expName: EXPERIMENT_NAME,
            ctaPosition,
            ...wtcData,
        });
        const { url, options } = navigationActions.wtcOnboardingSignup();
        navigate(url, options);
    };

    useEffect(() => {
        multiChannelEvents.pagevisit();
        onWebAppLoadLandingPage({
            pageId: location.pathname,
            expName: EXPERIMENT_NAME,
            ...wtcData,
        });
    }, []);

    return (
        <Container
            maxWidth={false}
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: theme.colors.white,
                overflowX: 'hidden',
            }}
        >
            {/* Header Section */}
            <header
                style={{
                    backgroundColor: theme.colors.primary,
                    padding: '20px 0',
                    textAlign: 'center',
                }}
            >
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    maxWidth={'lg'}
                    marginX={'auto'}
                    sx={{ px: { xs: 2, md: 4 } }}
                >
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src={LogoWhite}
                            alt="Logo"
                            style={{ width: 150, cursor: 'pointer' }}
                            onClick={() => {
                                navigate('/reconciliationLanding');
                            }}
                        />
                    </Grid>
                    {isMobile ? null : (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{
                                    color: 'white',
                                    boxShadow: 'none',
                                    borderRadius: 10,
                                    height: 50,
                                }}
                                onClick={() => onClickCTA('header')}
                            >
                                Get Started Free
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </header>

            {/* Main Section */}
            <Box sx={{ p: 2, pb: 4, backgroundColor: theme.colors.offWhite }}>
                <Grid
                    container
                    style={{
                        marginTop: isMobile ? 20 : 50,
                        paddingLeft: 0,
                        paddingRight: 0,
                    }}
                    maxWidth={'lg'}
                    marginX={'auto'}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: 500,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Box
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: isMobile
                                        ? 'center'
                                        : 'flex-start',
                                    marginBottom: isMobile ? 20 : 0,
                                }}
                            >
                                <img
                                    src={Rating}
                                    alt="Rating"
                                    style={{ width: 230 }}
                                />
                            </Box>
                            <Typography
                                variant="h3"
                                sx={{
                                    fontWeight: '700',
                                    textAlign: isMobile ? 'center' : 'left',
                                }}
                            >
                                {title ?? 'Never worry about an Amazon charge'}
                            </Typography>
                            <Typography
                                variant="body1"
                                style={{
                                    marginTop: 20,
                                    textAlign: isMobile ? 'center' : 'left',
                                }}
                            >
                                {subtitle ??
                                    'Find fraud in seconds when you auto-reconcile accounts with Amazon, email, & paper receipts.'}
                            </Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{
                                    marginTop: 30,
                                    color: 'white',
                                    boxShadow: 'none',
                                    borderRadius: 35,
                                    height: 60,
                                    width: 280,
                                    fontSize: 16,
                                    fontWeight: '700',
                                }}
                                onClick={() => onClickCTA('hero')}
                            >
                                Get Started for Free
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <img
                            alt=""
                            src={LandingPhoneImage}
                            style={{ maxWidth: '100%' }}
                        />
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: { xs: '90%', md: '80%' },
                        maxWidth: '800px',
                        marginX: 'auto',
                        flexWrap: 'wrap',
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            width: { xs: '100%', md: 'auto' },
                            textAlign: { xs: 'center', md: 'left' },
                            color: theme.colors.grayText,
                            fontWeight: '500',
                        }}
                    >
                        As Seen on
                    </Typography>
                    <img src={AsSeen1} alt="As Seen 1" style={{ width: 50 }} />
                    <img src={AsSeen2} alt="As Seen 2" style={{ width: 50 }} />
                    <img src={AsSeen3} alt="As Seen 3" style={{ width: 50 }} />
                    <img src={AsSeen4} alt="As Seen 4" style={{ width: 50 }} />
                    <img src={AsSeen5} alt="As Seen 5" style={{ width: 50 }} />
                    <img src={AsSeen6} alt="As Seen 6" style={{ width: 50 }} />
                </Box>
            </Box>

            {/* Customer Reviews Section */}
            <Box
                style={{
                    marginTop: 50,
                    textAlign: 'center',
                }}
                sx={{ px: 3 }}
                maxWidth={'lg'}
                marginX={'auto'}
            >
                <Typography
                    variant="h4"
                    style={{ textAlign: 'center', fontWeight: '700' }}
                >
                    700,000+ Customers Love Us
                </Typography>
                <Grid container spacing={4} style={{ marginTop: 20 }}>
                    <Grid item xs={12} md={6}>
                        <Card
                            sx={{
                                backgroundColor: theme.colors.offWhite,
                                boxShadow: 'none',
                                borderRadius: 4,
                                px: 3,
                                height: { xs: 230, md: 200 },
                            }}
                        >
                            <CardContent>
                                <Typography>
                                    Game-changer for my contracting business.
                                    Reconciling receipts used to take hours.
                                    Now, I just get alerted as my receipts
                                    automatically match with my bank.
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        mt: { xs: 3, md: 4 },
                                        alignItems: 'center',
                                    }}
                                >
                                    <Avatar
                                        src={UserPic}
                                        style={{
                                            marginTop: 10,
                                            marginRight: 10,
                                        }}
                                    />
                                    <Typography
                                        style={{
                                            marginTop: 10,
                                            fontWeight: '700',
                                        }}
                                    >
                                        Mike67
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card
                            sx={{
                                backgroundColor: theme.colors.offWhite,
                                boxShadow: 'none',
                                borderRadius: 4,
                                px: 3,
                                height: { xs: 230, md: 200 },
                            }}
                        >
                            <CardContent>
                                <Typography>
                                    Last year I wasted HOURS hunting through my
                                    email and my glove compartment for receipts
                                    to reconcile my accounts. Now this app does
                                    it for me in seconds.
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        mt: { xs: 3, md: 4 },
                                        alignItems: 'center',
                                    }}
                                >
                                    <Avatar
                                        src={UserPic}
                                        style={{
                                            marginTop: 10,
                                            marginRight: 10,
                                        }}
                                    />
                                    <Typography
                                        style={{
                                            marginTop: 10,
                                            fontWeight: '700',
                                        }}
                                    >
                                        paulsonhomeimprvmts
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            {/* How It Works Section */}
            <Box
                style={{ marginTop: 80, textAlign: 'center' }}
                maxWidth={'lg'}
                marginX={'auto'}
                sx={{
                    px: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h4" sx={{ fontWeight: '700' }}>
                    How It Works
                </Typography>
                <Grid container spacing={4} style={{ marginTop: 3 }}>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                            mt: { xs: 3, md: undefined },
                            px: { xs: undefined, md: 3 },
                        }}
                    >
                        <img
                            alt="Placeholder for connect accounts"
                            src={HowItWorks1}
                        />
                        <Typography
                            sx={{ fontWeight: '600', fontSize: 19, mt: 1 }}
                        >
                            Connect Accounts
                        </Typography>
                        <Typography
                            sx={{ color: theme.colors.grayText, mt: 1 }}
                        >
                            Link email, Amazon, Paypal and bank accounts
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                            mt: { xs: 3, md: undefined },
                            px: { xs: undefined, md: 3 },
                        }}
                    >
                        <img
                            alt="Placeholder for scan paper receipts"
                            src={HowItWorks2}
                        />
                        <Typography
                            sx={{ fontWeight: '600', fontSize: 19, mt: 1 }}
                        >
                            Scan Paper Receipts
                        </Typography>
                        <Typography
                            sx={{ color: theme.colors.grayText, mt: 1 }}
                        >
                            Get paper receipt? Use your phone to auto-scan them
                            in.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{
                            mt: { xs: 3, md: undefined },
                            px: { xs: undefined, md: 3 },
                        }}
                    >
                        <img
                            alt="Placeholder for automatic matching"
                            src={HowItWorks3}
                        />
                        <Typography
                            sx={{ fontWeight: '600', fontSize: 19, mt: 1 }}
                        >
                            Automatic Matching
                        </Typography>
                        <Typography
                            sx={{ color: theme.colors.grayText, mt: 1 }}
                        >
                            Email & scanned receipts auto-reconcile with your
                            transactions.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>

            {/* Automated Receipts Reconciliation Section */}
            <Typography
                variant="h4"
                sx={{ fontWeight: '700', mb: 4 }}
                style={{ marginTop: 100, textAlign: 'center' }}
            >
                Automated Receipts Reconciliation
            </Typography>
            <Grid
                container
                maxWidth={'lg'}
                marginX={'auto'}
                sx={{
                    px: 1,
                    alignItems: 'center',
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        p: 2,
                        justifyContent: 'center',
                        display: { xs: 'none', md: 'flex' },
                    }}
                >
                    <img alt="" src={Image1} style={{ width: 400 }} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        px: { xs: 2, md: 9 },
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="h4" sx={{ fontWeight: '700' }}>
                        Save Time
                    </Typography>
                    <Typography
                        variant="body1"
                        style={{ marginTop: 10, color: theme.colors.grayText }}
                    >
                        No more tedious manual spreadsheet entry. SimplyWise
                        does the heavy lifting for you.
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        p: 2,
                        justifyContent: 'center',
                        display: { xs: 'flex', md: 'none' },
                    }}
                >
                    <img alt="" src={Image1} style={{ width: 400 }} />
                </Grid>
            </Grid>

            <Grid
                container
                style={{ marginTop: 50 }}
                maxWidth={'lg'}
                marginX={'auto'}
                sx={{
                    px: 1,
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        px: { xs: 2, md: 9 },
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="h4" sx={{ fontWeight: '700' }}>
                        Get Peace of Mind
                    </Typography>
                    <Typography
                        variant="body1"
                        style={{ marginTop: 10, color: theme.colors.grayText }}
                    >
                        With a record for every charge, you’ll always know
                        exactly what each transaction was for – and when
                        something is fraud.
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ p: 2, display: 'flex', justifyContent: 'center' }}
                >
                    <img alt="" src={Image2} style={{ width: 400 }} />
                </Grid>
            </Grid>

            {/* Footer Section */}
            <Box
                style={{
                    marginTop: 50,
                    backgroundColor: '#001844',
                    padding: '50px 20px',
                    textAlign: 'center',
                    backgroundImage: `url(${LeafForHeader})`,
                    backgroundPositionX: '-200px',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Box
                    maxWidth={'lg'}
                    marginX={'auto'}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="h4"
                        style={{ color: 'white', fontWeight: '700' }}
                    >
                        Reconcile in seconds with AI
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={{
                            marginTop: 30,
                            color: 'white',
                            boxShadow: 'none',
                            borderRadius: 35,
                            height: 60,
                            width: 280,
                            fontSize: 16,
                            fontWeight: '700',
                        }}
                        sx={{ mb: 2 }}
                        onClick={() => onClickCTA('footer')}
                    >
                        Get Started for Free
                    </Button>
                    <img src={Rating2} alt="Rating" style={{ width: 230 }} />
                </Box>
            </Box>

            {/* Footer Links */}
            <div
                style={{
                    backgroundColor: '#001844',

                    padding: '20px 0',
                    textAlign: 'center',
                    color: 'white',
                }}
            >
                <Typography>SimplyWise</Typography>
                <Grid
                    container
                    justifyContent="center"
                    spacing={2}
                    style={{ marginTop: 10 }}
                >
                    <Grid item>
                        <Typography
                            component="a"
                            href="/about"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            About Us
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            component="a"
                            href="/privacy"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            Privacy Policy
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            component="a"
                            href="/terms"
                            style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                            Terms & Conditions
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
};

export default ReconciliationLanding;
