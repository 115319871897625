import { AppSubscriptionTier, IProduct, SubscriptionInterval } from './types';

export const DONATION_LEGACY_PRODUCTS: Record<string, Partial<IProduct>> = {
    simplywise_subscription_fixed_0: {
        name: 'SimplyWise Pro',
        tier: AppSubscriptionTier.PRO,
        value: 49.99,
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 3,
    },
    simplywise_subscription_fixed_2: {
        name: 'SimplyWise Pro',
        tier: AppSubscriptionTier.PRO,
        value: 19.9,
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 3,
    },
    simplywise_subscription_fixed_3: {
        name: 'SimplyWise Pro',
        tier: AppSubscriptionTier.PRO,
        value: 19.99,
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 0,
    },
    simplywise_subscription_fixed_4: {
        name: 'SimplyWise Pro',
        tier: AppSubscriptionTier.PRO,
        value: 24.99,
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 3,
    },
    simplywise_subscription_fixed_5: {
        name: 'SimplyWise Pro',
        tier: AppSubscriptionTier.PRO,
        value: 14.99,
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 3,
    },
    simplywise_subscription_fixed_6: {
        name: 'SimplyWise Pro',
        tier: AppSubscriptionTier.PRO,
        value: 3.99,
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 0,
    },
    simplywise_subscription_fixed_8: {
        name: 'SimplyWise Pro',
        tier: AppSubscriptionTier.PRO,
        value: 19.99,
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
    },
};

const unformatSubscription = (tier: string) => {
    const ANNUAL_IOS_PRICE_INDEX = [
        '$10.99',
        '$19.99',
        '$34.99',
        '$44.99',
        '$55.99',
        '$69.99',
        '$79.99',
        '$94.99',
        '$104.99',
        '$114.99',
        '$129.99',
        '$139.99',
        '$154.99',
        '$164.99',
        '$174.99',
        '$189.99',
        '$199.99',
        '$214.99',
        '$224.99',
        '$239.99',
    ];

    if (tier.substring(24, 30) === 'annual') {
        return parseInt(
            ANNUAL_IOS_PRICE_INDEX[parseInt(tier.split('_')[3], 10)].substring(
                1
            ),
            10
        );
    }
    return parseInt(tier.split('_')[3], 10) + 0.99;
};

export const getLegacyProduct = (productId: string) => {
    if (DONATION_LEGACY_PRODUCTS[productId]) {
        return DONATION_LEGACY_PRODUCTS[productId];
    } else if (
        productId?.includes('simplywise_subscription_donation') ||
        productId?.includes('simplywise_subscription_web')
    ) {
        return {
            name: 'SimplyWise Pro',
            tier: AppSubscriptionTier.PRO,
            value: unformatSubscription(productId).toFixed(2),
            interval: SubscriptionInterval.MONTH,
            free_trial_period: 0,
        };
    } else if (productId?.includes('simplywise_subscription_annual')) {
        return {
            name: 'SimplyWise Pro',
            tier: AppSubscriptionTier.PRO,
            value: unformatSubscription(productId!).toFixed(2),
            interval: SubscriptionInterval.YEAR,
            free_trial_period: 0,
        };
    } else if (productId?.includes('price_')) {
        return {
            name: 'SimplyWise Pro',
            tier: AppSubscriptionTier.PRO,
            value:
                parseInt(productId.match(/price_(\d+)/)?.[1] || '0', 10) || 0,
            interval: productId?.includes('weekly')
                ? SubscriptionInterval.WEEK
                : SubscriptionInterval.YEAR,
            free_trial_period: 0,
        };
    }
};
