import { useEffect, useState } from 'react';

export const useLocalStorageItem = (itemName: string) => {
    const [value, setValue] = useState<any | null>(
        localStorage.getItem(itemName)
    );

    useEffect(() => {
        window.addEventListener('storage', itemListener);
        setValue(localStorage.getItem(itemName));
    }, []);

    const itemListener = (event: StorageEvent) => {
        /**
         * NOTE (AR): Window event listener doesnt trigger in same tab when
         * localStorage.setItem is called. You will need to call window.dispatchEvent
         * to trigger a storage event manually.
         * We should probably switch to using redux-persist for an easier to use alternative.
         */
        if (event.key === itemName) {
            const newValue = event.newValue;
            if (newValue) {
                setValue(newValue);
            } else {
                setValue(null);
            }
        }
    };

    return value;
};
