import theme from '../../../theme';
import { number } from '../../../util/devices';
import { SectionHeader, SectionSubtitle } from '../../Text';

type IReview = {
    quote: string;
    user: string;
};

export type IReviewsVerticalConfig = {
    reviews: IReview[];
    title?: string;
};

export const SectionReviewsVertical = ({
    reviews,
    title,
}: {
    reviews: IReview[];
    title?: string;
}) => {
    return (
        <div
            style={{
                backgroundColor: '#F6F3EF',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            {title ? (
                <SectionHeader
                    style={{
                        textAlign: 'center',
                        padding: 40,
                        paddingTop: 60,
                        paddingBottom: 0,
                    }}
                >
                    {title}
                </SectionHeader>
            ) : null}
            <div style={{ marginTop: 60, width: 0 }} />
            {reviews.map(({ quote, user }) => (
                <div
                    style={{
                        display: 'flex',
                        alignSelf: 'center',
                        flexDirection: 'column',
                        maxWidth: 800,
                        backgroundColor: theme.colors.white,
                        padding: window.innerWidth > number.TABLET_SM ? 60 : 40,
                        boxShadow: theme.defaultShadow,
                        marginBottom: 20,
                    }}
                >
                    {quote.split('\n').map((q) => (
                        <SectionSubtitle style={{ textAlign: 'center' }}>
                            {q}
                        </SectionSubtitle>
                    ))}
                    <SectionSubtitle
                        style={{
                            textAlign: 'center',
                            fontWeight: '700',
                            color: theme.colors.blue60,
                        }}
                    >
                        {user}
                    </SectionSubtitle>
                </div>
            ))}
        </div>
    );
};
