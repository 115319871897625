import { useEffect, useRef } from 'react';

const HTML = `
        <link
            media="print"
            onload="this.onload=null;this.removeAttribute('media');"
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Nunito:display=swap"
        />
        <link
            media="print"
            onload="this.onload=null;this.removeAttribute('media');"
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons:display=swap"
        />
        <link
            media="print"
            onload="this.onload=null;this.removeAttribute('media');"
            rel="stylesheet"
            href="https://fonts.googleapis.com/css?family=Inter::wght@400;500;600;700&display=swap"
        />
        <!-- end Async fonts -->


        <!-- Leed Feeder -->
        <script> (function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js'); })(document,'script'); })('Xbp1oaEEnLbaEdVj'); </script>

        <!-- Clearbit -->
        <script src="https://tag.clearbitscripts.com/v1/pk_eaa768f315598511aa8d67da562d82de/tags.js" referrerpolicy="strict-origin-when-cross-origin"></script>
        
        <!-- Google Tag Manager (noscript) -->
        <noscript>
            <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-TWVQ64X"
                height="0"
                width="0"
                style="display: none; visibility: hidden"
            >
            </iframe>
        </noscript>
        <!-- End Google Tag Manager (noscript) -->
        <!-- Bing UET Tag -->
        <script async>
            (function (w, d, t, r, u) {
                var f, n, i;
                (w[u] = w[u] || []),
                    (f = function () {
                        var o = { ti: '355012360' };
                        (o.q = w[u]),
                            (w[u] = new UET(o)),
                            w[u].push('pageLoad');
                    }),
                    (n = d.createElement(t)),
                    (n.src = r),
                    (n.async = 1),
                    (n.onload = n.onreadystatechange =
                        function () {
                            var s = this.readyState;
                            (s && s !== 'loaded' && s !== 'complete') ||
                                (f(), (n.onload = n.onreadystatechange = null));
                        }),
                    (i = d.getElementsByTagName(t)[0]),
                    i.parentNode.insertBefore(n, i);
            })(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');
        </script>
        <!-- Google Tag Manager (for Artsai) -->
        <script async>
            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start': new Date().getTime(),
                    'event': 'gtm.js',
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-TWVQ64X');
        </script>
        <!-- Global site tag (gtag.js) - Google Ads: 778255320 -->
        <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=AW-778255320"
        ></script>
        <script async>
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'AW-778255320');
        </script>

        <!---Clearbit tracking -->
        <script async src="https://tag.clearbitscripts.com/v1/pk_8c5fa9e570f7b5a0ab3f56ca21c8298f/tags.js" referrerpolicy="strict-origin-when-cross-origin"></script>

        <!-- Event snippet for "Add to cart" conversion page -->
        <script async>
            function gtagAddToCart(url) {
                var callback = function () {
                    if (typeof url != 'undefined') {
                        window.location = url;
                    }
                };
                gtag('event', 'conversion', {
                    send_to: 'AW-778255320/6lPKCIv1yMkDENj3jPMC',
                    event_callback: callback,
                });
                return false;
            }
            // Event snippet for "Begin checkout" conversion page
            function gtagBeginCheckout(url) {
                var callback = function () {
                    if (typeof url != 'undefined') {
                        window.location = url;
                    }
                };
                gtag('event', 'conversion', {
                    send_to: 'AW-778255320/IfhXCI71yMkDENj3jPMC',
                    event_callback: callback,
                });
                return false;
            }
            // Event snippet for "Subscribe" conversion page
            function gtagSubscribe(url) {
                var callback = function () {
                    if (typeof url != 'undefined') {
                        window.location = url;
                    }
                };
                gtag('event', 'conversion', {
                    send_to: 'AW-778255320/WayBCJT1yMkDENj3jPMC',
                    event_callback: callback,
                });
                return false;
            }
            // Event snippet for "Sign-up" conversion page
            function gtagSignup(url) {
                var callback = function () {
                    if (typeof url != 'undefined') {
                        window.location = url;
                    }
                };
                gtag('event', 'conversion', {
                    send_to: 'AW-778255320/KujBCJH1yMkDENj3jPMC',
                    event_callback: callback,
                });
                return false;
            }
        </script>

        <!-- TikTok -->
        <script async>
            !(function (w, d, t) {
                w.TiktokAnalyticsObject = t;
                var ttq = (w[t] = w[t] || []);
                (ttq.methods = [
                    'page',
                    'track',
                    'identify',
                    'instances',
                    'debug',
                    'on',
                    'off',
                    'once',
                    'ready',
                    'alias',
                    'group',
                    'enableCookie',
                    'disableCookie',
                ]),
                    (ttq.setAndDefer = function (t, e) {
                        t[e] = function () {
                            t.push(
                                [e].concat(
                                    Array.prototype.slice.call(arguments, 0)
                                )
                            );
                        };
                    });
                for (var i = 0; i < ttq.methods.length; i++)
                    ttq.setAndDefer(ttq, ttq.methods[i]);
                (ttq.instance = function (t) {
                    for (
                        var e = ttq._i[t] || [], n = 0;
                        n < ttq.methods.length;
                        n++
                    )
                        ttq.setAndDefer(e, ttq.methods[n]);
                    return e;
                }),
                    (ttq.load = function (e, n) {
                        var i =
                            'https://analytics.tiktok.com/i18n/pixel/events.js';
                        (ttq._i = ttq._i || {}),
                            (ttq._i[e] = []),
                            (ttq._i[e]._u = i),
                            (ttq._t = ttq._t || {}),
                            (ttq._t[e] = +new Date()),
                            (ttq._o = ttq._o || {}),
                            (ttq._o[e] = n || {});
                        var o = document.createElement('script');
                        (o.type = 'text/javascript'),
                            (o.async = !0),
                            (o.src = i + '?sdkid=' + e + '&lib=' + t);
                        var a = document.getElementsByTagName('script')[0];
                        a.parentNode.insertBefore(o, a);
                    });
                ttq.load('CBRB8H3C77UFHQ3IUTC0');
                ttq.page();
            })(window, document, 'ttq');
        </script>

        <!-- Reddit Pixel -->
        <script async>
            !(function (w, d) {
                if (!w.rdt) {
                    var p = (w.rdt = function () {
                        p.sendEvent
                            ? p.sendEvent.apply(p, arguments)
                            : p.callQueue.push(arguments);
                    });
                    p.callQueue = [];
                    var t = d.createElement('script');
                    (t.src = 'https://www.redditstatic.com/ads/pixel.js'),
                        (t.async = !0);
                    var s = d.getElementsByTagName('script')[0];
                    s.parentNode.insertBefore(t, s);
                }
            })(window, document);
            rdt('init', 't2_vxu2bgbe', {
                optOut: false,
                useDecimalCurrencyValues: true,
            });
            rdt('track', 'PageVisit');
        </script>
        <!-- End Reddit Pixel -->

        <!-- Twitter conversion tracking base code -->
        <script async>
            !(function (e, t, n, s, u, a) {
                e.twq ||
                    ((s = e.twq =
                        function () {
                            s.exe
                                ? s.exe.apply(s, arguments)
                                : s.queue.push(arguments);
                        }),
                    (s.version = '1.1'),
                    (s.queue = []),
                    (u = t.createElement(n)),
                    (u.async = !0),
                    (u.src = 'https://static.ads-twitter.com/uwt.js'),
                    (a = t.getElementsByTagName(n)[0]),
                    a.parentNode.insertBefore(u, a));
            })(window, document, 'script');
            twq('config', 'odwtp');
        </script>
        <!-- End Twitter conversion tracking base code -->

        <!-- Outbrain Pixel -->
        <!-- <script async data-obct type="text/javascript">
            /** DO NOT MODIFY THIS CODE**/
            !(function (_window, _document) {
                var OB_ADV_ID = '002beda8e25df818cd779d01312a53d2e9';
                if (_window.obApi) {
                    var toArray = function (object) {
                        return Object.prototype.toString.call(object) ===
                            '[object Array]'
                            ? object
                            : [object];
                    };
                    _window.obApi.marketerId = toArray(
                        _window.obApi.marketerId
                    ).concat(toArray(OB_ADV_ID));
                    return;
                }
                var api = (_window.obApi = function () {
                    api.dispatch
                        ? api.dispatch.apply(api, arguments)
                        : api.queue.push(arguments);
                });
                api.version = '1.1';
                api.loaded = true;
                api.marketerId = OB_ADV_ID;
                api.queue = [];
                var tag = _document.createElement('script');
                tag.async = true;
                tag.src = 'https://amplify.outbrain.com/cp/obtp.js';
                tag.type = 'text/javascript';
                var script = _document.getElementsByTagName('script')[0];
                script.parentNode.insertBefore(tag, script);
            })(window, document);
            obApi('track', 'PAGE_VIEW');
        </script> -->
        <!-- End Outbrain Pixel -->

        <!-- Pinterest Tag -->
        <script async>
            !(function (e) {
                if (!window.pintrk) {
                    window.pintrk = function () {
                        window.pintrk.queue.push(
                            Array.prototype.slice.call(arguments)
                        );
                    };
                    var n = window.pintrk;
                    (n.queue = []), (n.version = '3.0');
                    var t = document.createElement('script');
                    (t.async = !0), (t.src = e);
                    var r = document.getElementsByTagName('script')[0];
                    r.parentNode.insertBefore(t, r);
                }
            })('https://s.pinimg.com/ct/core.js');
            pintrk('load', '2613926111879', { em: '<user_email_address>' });
            pintrk('page');
        </script>
        <noscript>
            <img
                height="1"
                width="1"
                style="display: none"
                alt=""
                src="https://ct.pinterest.com/v3/?event=init&tid=2613926111879&pd[em]=<hashed_email_address>&noscript=1"
            />
        </noscript>
        <!-- end Pinterest Tag -->
        
        <!-- Linkedin Tag -->
        <script type="text/javascript">
            _linkedin_partner_id = "3270130";
            window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
            window._linkedin_data_partner_ids.push(_linkedin_partner_id);
            </script><script type="text/javascript">
            (function(l) {
            if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
            window.lintrk.q=[]}
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);})(window.lintrk);
        </script>
        <noscript>
            <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=3270130&fmt=gif" />
        </noscript>
        <!-- end Linkedin Tag -->
`;

const ClientMountedHTML = () => {
    const isFirstRender = useRef(true);

    useEffect(() => {
        if (!isFirstRender.current) return;
        isFirstRender.current = false;

        const slotHtml = document.createRange().createContextualFragment(HTML);
        document.head.appendChild(slotHtml);
    }, []);

    return null;
};

export default ClientMountedHTML;
