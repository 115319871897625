// @ts-nocheck
import parseFormat from 'moment-parseformat';
import { parse } from 'date-fns';

export default class DateParser extends Date {
    constructor(...options) {
        let parsedDate;

        // Try to parse with a different date format only if it's not a valid date
        if (options?.[0]) {
            const initialDate = new Date(...options);
            if (isNaN(initialDate.getTime())) {
                try {
                    let format = parseFormat(...options)
                        .replaceAll('YYYY', 'yyyy')
                        .replaceAll('D', 'd');
                    parsedDate = parse(options[0], format, new Date());
                } catch (e) {
                    // Handle the error or use a default date
                    parsedDate = new Date(); // Default to current date
                }
            } else {
                parsedDate = initialDate;
            }
        } else {
            parsedDate = new Date(); // Default to current date if no options provided
        }

        // Call super with the parsed date or default to the current date
        super(parsedDate);

        // If parsedDate is still invalid, you could throw an error or handle it accordingly
        if (isNaN(this.getTime())) {
            throw new Error('Invalid date');
        }
    }
}
