import { useEffect } from 'react';

export const useHandleOutsideClick = (
    ref: any | any[],
    callback: () => void
) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        const handleClickOutside = (e: any) => {
            if (ref.length) {
                const noRefsContainIt = ref.every(
                    (r: any) => r.current && !r.current.contains(e.target)
                );
                if (noRefsContainIt) {
                    callback();
                }
            } else if (ref.current && !ref.current.contains(e.target)) {
                callback();
            }
        };
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};
