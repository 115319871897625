export const PASSWORD_LENGTH =
    'Your new password must be at least 6 characters';
export const INVALID_PHONE = 'Please enter a valid phone number';
export const PHONE_REQUIRED = 'A phone number is required';
export const PASSWORD_REQUIRED = 'A password is required';
export const NAME_REQUIRED = 'Please enter a name';
export const INVALID_EMAIL = 'Please enter a valid email';
export const EMAIL_REQUIRED = 'Please enter an email';
export const FIELD_LENGTH = 'This field must be less than 100 characters';
export const NOTE_LENGTH = 'Notes must be less than 3500 characters';
export const DATE_PAST = 'Please select a date in the past';
export const DATE_FUTTURE = 'Please select a date in the future';
export const MONEY_NUMBER = 'Amount must be a number';
export const ALPHANUMERIC = 'Name must be alphanumeric (A-z, 0-9)';
