import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LogoBlue from '../../../assets/landing/logo_blue.svg';
import LogoIcon from '../../../assets/landing/logo_icon.svg';
import { Body } from '../../../components/Text';
import theme from '../../../theme';
import { onWebAppLandingPageCTAClick } from '../../../tracking/trackers';
import { HEADER_HEIGHT } from '../../../util/constants';
import Devices, { number } from '../../../util/devices';
import { isIOS } from '../../../util/isIOS';
import { getURLWithoutParams } from '../../../util/url';
import './style.css';

interface SectionStickyHeaderLogoProps {
    pageId: string;
    expName?: string;
    headerMode?: 'login' | 'signup';
    ctaLabel?: string;
    labelStyle?: React.CSSProperties;
    children?: JSX.Element | JSX.Element[] | null;
    onClick?: () => void;
    style?: React.CSSProperties;
    headerLogo?: JSX.Element;
    scrollableHeader?: boolean;
    setHeaderMarginTop?: (height: string | number) => void;
    headerWatermark?: JSX.Element;
    headerLeafFull?: JSX.Element;
    headerLeafPath?: string;
    slogan?: string;
    isMobile?: boolean;
}

const interpolateValues = (
    x: number,
    x1: number,
    x2: number,
    y1: number,
    y2: number
) => Math.round((y1 + ((x - x1) / (x2 - x1)) * (y2 - y1)) * 100) / 100;

const SectionStickyHeaderLogo = (props: SectionStickyHeaderLogoProps) => {
    const navigate = useNavigate();

    const onLoginClick = props.onClick
        ? props.onClick
        : () => {
              if (props.headerMode === 'login') {
                  navigate('/login');
              } else {
                  onWebAppLandingPageCTAClick({
                      pageId: props.pageId,
                      expName: props.expName,
                      ctaPosition: 'navbar',
                      url: getURLWithoutParams(),
                      isIOS,
                  });

                  navigate('/welcome/sign-up');
              }
          };

    const headerRef = useRef<HTMLDivElement>(null);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);
    const [headerHeight, setHeaderHeight] = useState<string | number>(
        props.scrollableHeader ? '40vh' : props.style?.height ?? 80
    );

    const [scaleLogo, setScaleLogo] = useState(1);
    const [xLogo, setXLogo] = useState(0);
    const [scaleLeaf, setScaleLeaf] = useState(1);
    const [xLeaf, setXLeaf] = useState(0);
    const [yLeaf, setYLeaf] = useState(0);
    const [slogan, setSlogan] = useState('');

    const [isPathVisible, setIsPathVisible] = useState(true);

    const onScroll = _.throttle(() => {
        if (!headerRef.current) return;
        if (headerHeight === '40vh') setHeaderHeight(window.innerHeight * 0.4);

        const scrollTop = window.scrollY || document.documentElement.scrollTop;
        if (!isScrolling) setIsScrolling(true);

        const interpolatedHeightValue = interpolateValues(
            Math.min(scrollTop, window.innerHeight * 0.4),
            window.innerHeight * 0.4,
            0,
            Number(props.style?.height) ?? 80,
            window.innerHeight * 0.4
        );
        setHeaderHeight(interpolatedHeightValue);
        props.setHeaderMarginTop?.(
            Math.min(scrollTop, window.innerHeight * 0.4) + 'px'
        );

        setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    }, 100);

    useEffect(() => {
        if (!props.scrollableHeader) return;
        document.addEventListener('scroll', onScroll, true);
        return () => document.removeEventListener('scroll', onScroll, true);
    });

    useEffect(() => {
        if (!props.scrollableHeader) return;
        setTimeout(() => {
            setIsPathVisible(false);
            if (props.slogan) {
                setTimeout(() => {
                    animateText(props.slogan!);
                }, 1000);
            }
        }, 2500);
    }, []);

    useEffect(() => {
        if (!isScrolling) return;

        const interpolatedScaleLogoValue = interpolateValues(
            Number(headerHeight),
            window.innerHeight * 0.4,
            0,
            1,
            0.15
        );

        const interpolatedXLogoValue = interpolateValues(
            Number(headerHeight),
            window.innerHeight * 0.4,
            0,
            0,
            -250
        );

        const interpolatedScaleLeafValue = interpolateValues(
            Number(headerHeight),
            window.innerHeight * 0.4,
            0,
            1,
            1
        );

        const interpolatedXLeafValue = interpolateValues(
            Number(headerHeight),
            window.innerHeight * 0.4,
            0,
            0,
            240
        );

        const interpolatedYLeafValue = interpolateValues(
            Number(headerHeight),
            window.innerHeight * 0.4,
            0,
            0,
            -105
        );

        setScaleLogo(interpolatedScaleLogoValue);
        setXLogo(interpolatedXLogoValue);
        setScaleLeaf(interpolatedScaleLeafValue);
        setXLeaf(interpolatedXLeafValue);
        setYLeaf(interpolatedYLeafValue);
    }, [lastScrollTop]);

    const animateText = async (fullText: string) => {
        let text = '';
        for (let i = 0; i < fullText.length; i += 1) {
            text += fullText.substring(i, i + 1);
            setSlogan(text);
            await new Promise((resolve) => setTimeout(resolve, 50));
        }
    };

    return (
        <HeaderContainerStyle
            ref={headerRef}
            style={{
                boxShadow: theme.defaultShadow,
                ...props.style,
                height: props.scrollableHeader
                    ? headerHeight
                    : props.style?.height,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: number.LAPTOP,
                }}
            >
                <HeaderLogoAStyle
                    href={props.onClick ? '#' : '/'}
                    onClick={props.onClick}
                >
                    {props.scrollableHeader &&
                        props.headerWatermark &&
                        props.headerLeafPath && (
                            <AnimatePresence>
                                {isPathVisible ? (
                                    <motion.svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 396.28 638.45"
                                        width={200}
                                        style={{
                                            position: 'absolute',
                                            scale: scaleLeaf,
                                            x: xLeaf,
                                            y: yLeaf,
                                        }}
                                    >
                                        <motion.path
                                            d={props.headerLeafPath}
                                            fill="transparent"
                                            strokeWidth="3"
                                            stroke={theme.colors.secondary}
                                            strokeLinecap={'round'}
                                            initial={{ pathLength: 0 }}
                                            animate={{ pathLength: 1 }}
                                            transition={{
                                                duration: 1.5,
                                                ease: 'easeInOut',
                                                delay: 0.5,
                                            }}
                                        />
                                        <motion.path
                                            d={props.headerLeafPath}
                                            fill="transparent"
                                            strokeWidth="3"
                                            stroke={'#004a54'}
                                            strokeLinecap={'round'}
                                            initial={{ pathLength: 0 }}
                                            animate={{ pathLength: 1 }}
                                            transition={{
                                                duration: 2,
                                                ease: 'easeInOut',
                                                delay: 0.6,
                                            }}
                                        />
                                    </motion.svg>
                                ) : (
                                    <>
                                        {props.headerLeafFull && (
                                            <motion.img
                                                style={{
                                                    position: 'absolute',
                                                    scale: scaleLeaf,
                                                    x: xLeaf,
                                                    y: yLeaf,
                                                }}
                                                transition={{
                                                    ease: 'linear',
                                                }}
                                                alt="simplywise leaf"
                                                src={
                                                    props.headerLeafFull.props
                                                        .src
                                                }
                                                width={200}
                                            />
                                        )}
                                        {props.headerLogo && (
                                            <motion.img
                                                style={{
                                                    scale: scaleLogo,
                                                    x: xLogo,
                                                    zIndex: 100,
                                                }}
                                                transition={{
                                                    ease: 'linear',
                                                }}
                                                alt="simplywise"
                                                src={props.headerLogo.props.src}
                                                width={600}
                                            />
                                        )}
                                    </>
                                )}
                                {lastScrollTop === 0 && (
                                    <>
                                        <motion.div
                                            style={{
                                                position: 'absolute',
                                                x:
                                                    (90 /
                                                        (props.slogan?.length ??
                                                            1)) *
                                                    45,
                                                y: 80,
                                                color: theme.colors.white,
                                                fontSize:
                                                    (22 / slogan.length) * 45,
                                                fontWeight: 'normal',
                                            }}
                                            transition={{
                                                ease: 'linear',
                                            }}
                                        >
                                            {slogan}
                                        </motion.div>
                                        {/* <motion.div
                                            style={{
                                                position: 'absolute',
                                                scale: scaleLogo / 2,
                                                x: 0,
                                                y: 350,
                                            }}
                                            transition={{
                                                ease: 'linear',
                                            }}
                                        >
                                            <div className="scroll-down">
                                                <div className="mouse">
                                                    <div className="scroller"></div>
                                                </div>
                                                <div
                                                    style={{
                                                        fontSize: 20,
                                                        fontWeight: 'normal',
                                                        letterSpacing: 4,
                                                        marginLeft: 30,
                                                        color: theme.colors
                                                            .white,
                                                    }}
                                                >
                                                    Scroll down to continue
                                                </div>
                                            </div>
                                        </motion.div> */}
                                    </>
                                )}
                            </AnimatePresence>
                        )}
                    {props.headerLogo &&
                    props.scrollableHeader ? null : props.headerLogo ? (
                        props.headerLogo
                    ) : (
                        <>
                            <HeaderLogoStyle
                                src={LogoIcon}
                                alt="white leaf inside blue square logo"
                            />
                            <HeaderNameStyle
                                src={LogoBlue}
                                alt={'SimplyWise'}
                            />
                        </>
                    )}
                </HeaderLogoAStyle>
                {!props.scrollableHeader && props.headerWatermark && (
                    <img
                        src={props.headerWatermark.props.src}
                        alt="watermark"
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: props.isMobile ? 150 : 480,
                            height: 80,
                        }}
                    />
                )}
                {props.headerMode === 'login' ? (
                    <LoginButtonStyle onClick={onLoginClick}>
                        <BodyStyle>{'Log in'}</BodyStyle>
                    </LoginButtonStyle>
                ) : props.children ? (
                    props.children
                ) : (
                    <LoginButtonStyle onClick={onLoginClick}>
                        <BodyStyle>
                            {props.ctaLabel ?? 'Start for free'}
                        </BodyStyle>
                    </LoginButtonStyle>
                )}
            </div>
        </HeaderContainerStyle>
    );
};

export const HeaderContainerStyle = styled.div`
    display: flex;
    z-index: 100;
    background-color: ${theme.colors.white};
    min-height: ${HEADER_HEIGHT}px;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: sticky;
    width: 100%;
    left: 0;
    top: 0;
    padding: 10px 16px;
    justify-content: center;

    @media ${Devices.TABLET_SM} {
        padding: 10px 45px;
    }
`;

export const HeaderLogoStyle = styled.img`
    max-width: 32px;
    width: calc(100vw - 300px);
    display: none;
    @media ${Devices.MOBILE_SM} {
        display: block;
    }
`;

export const HeaderNameStyle = styled.img`
    max-width: 120px;
    max-height: 28px;
    margin-left: 8px;
`;

export const HeaderLogoAStyle = styled.a`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
`;

export const LoginButtonStyle = styled.button`
    display: flex;
    color: ${theme.colors.white};
    text-decoration: none;
    border-radius: ${5}px;
    background-color: ${theme.colors.primary};
    cursor: pointer;
    text-decoration: none;
    border: none;

    padding: 8px 16px;

    &:hover {
        text-decoration: none;
        color: ${theme.colors.white};
        background-color: ${theme.colors.secondary};
    }
`;

export const BodyStyle = styled(Body)`
    font-weight: bold !important;
`;

export default SectionStickyHeaderLogo;
