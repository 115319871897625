import { COLUMNS, IColumn } from '../constants';
import { ISortData } from './useSortData';

const PREFS_KEY = 'prefs';

type ILocalStoragePrefsData = {
    sortData: ISortData;
    columns: IColumn[];
};

const useLocalStoragePrefs = (spreadsheetKey?: string | undefined) => {
    const key = spreadsheetKey ? `${PREFS_KEY}-${spreadsheetKey}` : PREFS_KEY;

    const getData = () => {
        return JSON.parse(
            localStorage.getItem(key) ?? '{}'
        ) as ILocalStoragePrefsData | null;
    };
    let data = getData();

    const notStaleColumns = (data?.columns ?? []).map(
        (c) => COLUMNS.find((c2) => c2.key === c.key) ?? c
    );

    if (data?.columns) {
        data.columns = notStaleColumns;
    }

    const updateData = ({
        sortData,
        columns,
    }: {
        sortData?: ISortData;
        columns?: IColumn[];
    }) => {
        localStorage.setItem(
            key,
            JSON.stringify({
                sortData: sortData ?? data?.sortData,
                columns: columns ?? data?.columns,
            })
        );
    };

    return {
        data,
        updateData,
        getData,
    };
};

export default useLocalStoragePrefs;
