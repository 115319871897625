import { useState } from 'react';
import {
    BodyStyle,
    LoginButtonStyle,
} from '../LandingPageVariations/elements/SectionStickyHeaderLogo';
import { onWebAppLandingTextingContinue } from '../../tracking/trackers';
import TextingModal from './TextingModal';

const TextingCollectionSmallCTA = (props: {
    label: string;
    onCTAClick: () => void;
}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    return (
        <>
            <LoginButtonStyle
                onClick={() => {
                    props?.onCTAClick();
                    setIsModalOpen(true);
                    onWebAppLandingTextingContinue({
                        source: 'header',
                    });
                }}
            >
                <BodyStyle>{props.label}</BodyStyle>
            </LoginButtonStyle>
            <TextingModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
        </>
    );
};

export default TextingCollectionSmallCTA;
