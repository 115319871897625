import React, { lazy, useMemo, useRef, useState } from 'react';
import { CircularProgress, Container, Button } from '@mui/material';
import Resizer from 'react-image-file-resizer';
import EXIF from 'exifr';
import { jsonrepair } from 'jsonrepair';
import { isMobile } from 'react-device-detect';
import theme from '../../theme';
import SectionStickyHeaderLogo from '../../components/LandingPageVariations/elements/SectionStickyHeaderLogo';
import PublicService from '../../services/public/service';
import {
    onWebAppPhotoAIClickUploadAnotherPhoto,
    onWebAppPhotoAIGotViewpoint,
    onWebAppPhotoAITakePicture,
} from '../../tracking/trackers';
import getAddressFromCoordinates from '../../util/getAddressFromCoordinates';
import Google from '../../assets/icons/google.svg';
import './index.css';
import { ButtonContainer } from '../../components/Buttons/BottomButton';
import { BodyBold } from '../../components/Text';

const DownloadBadges = lazy(
    () => import('../../screens/Payment/DownloadBadges')
);

type ProcessingImage = {
    id: string;
    image: string;
    latLng?: { lat: Number; lng: Number };
    geoLocation?: string;
    image_id?: string;
    viewpoint?: string;
    tags?: string[];
    viewpoints?: Record<string, ProcessingImage[]>;
    progress?: string;
    title?: string;
};

const PhotoAIProgressReport = () => {
    const [images, setImages] = useState<ProcessingImage[]>([]);
    const imageInputRef = useRef<HTMLInputElement>(null);

    const resizeFile = (file: File): Promise<Blob> => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1024,
                1024,
                'JPEG',
                85,
                0,
                //@ts-ignore
                (b: Blob) => {
                    resolve(b);
                },
                'blob'
            );
        });
    };

    const updateImage = (
        image_id: string,
        updateObject: Partial<ProcessingImage>
    ) => {
        setImages((v) => {
            const imageIndex = v.findIndex((i) => i.id === image_id);
            if (imageIndex === -1) return v;
            const newImages = [...v];
            newImages[imageIndex] = {
                ...newImages[imageIndex],
                ...updateObject,
            };
            return newImages;
        });
    };

    const onUploadImage = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const _files = event?.target?.files || [];
        const filesLength = Array.from(_files)?.length;
        const _images: ProcessingImage[] = [];
        if (filesLength > 5) {
            alert('You can only upload 5 images at a time');
            return;
        }
        for (let _file of _files) {
            try {
                if (!_file) continue;
                if (_file.type !== 'image/jpeg' && _file.type !== 'image/webp')
                    continue;
                const id = Math.random().toString(36).substring(7);
                const image: ProcessingImage = {
                    id,
                    image: URL.createObjectURL(_file),
                };
                setImages((v) => [...v, image]);
                const gps = await EXIF.gps(_file);
                image.latLng = { lat: gps?.latitude, lng: gps?.longitude };
                updateImage(image?.id, {
                    latLng: {
                        lat: gps?.latitude,
                        lng: gps?.longitude,
                    },
                });
                try {
                    const address = await getAddressFromCoordinates({
                        latitude: gps?.latitude,
                        longitude: gps?.longitude,
                    });
                    image.geoLocation = address;
                    updateImage(image?.id, {
                        geoLocation: address,
                    });
                } catch (e) {
                    console.error('Error getting address', e);
                }
                const resizedFile = await resizeFile(_file);
                const res = await PublicService.uploadImage(resizedFile);
                onWebAppPhotoAITakePicture({
                    image_id: res.data.image_ids[0],
                    exp_name: 'oct-8-photo-ai-location',
                    number_of_images: filesLength,
                });
                const imageId = res.data.image_ids[0];
                image.image_id = imageId;
                updateImage(image?.id, {
                    image_id: imageId,
                });
                _images.push(image);
            } catch (e) {
                console.error('Error uploading image', e);
                continue;
            }
        }

        const imageIds = _images?.map((image) => image?.image_id || '');
        let date: string | undefined;
        try {
            const exif = await EXIF.parse(_files[0]);
            date = exif?.DateTimeOriginal as string;
        } catch (e) {}
        PublicService.getPhotoInformation(imageIds, date).then((res) => {
            let data = res.data?.location
                ?.replace(/```/g, '')
                ?.replace('json', '');
            data = jsonrepair(data);
            let json: any = {};
            try {
                json = JSON.parse(data);
            } catch (e) {
                console.error('error', e, data);
            }
            onWebAppPhotoAIGotViewpoint({
                image_ids: imageIds,
                progress: json?.Progress,
                title: json?.Title,
            });
            for (let image of _images) {
                updateImage(image?.id, {
                    progress: json?.Progress,
                    title: json?.Title,
                });
            }
        });
    };

    const downloadBadges = useMemo(() => {
        return !process.isServer ? (
            <DownloadBadges
                alternativeWebUrl="/"
                customData={{
                    source: 'photo-ai',
                    experience: 'photo-ai',
                }}
                branchAppStoreUrl="https://apps.apple.com/app/simplywise-receipt-scanner/id1538521095?ppid=e78e02eb-b58c-48b8-a142-c7b5f2e8e641"
                branchPlayStoreUrl="https://play.google.com/store/apps/details?id=com.simplywise.mobile&listing=estimator"
                black
            />
        ) : null;
    }, []);

    const downloadApp = () => {
        onWebAppPhotoAIClickUploadAnotherPhoto();
        setTimeout(() => {
            (
                document.getElementById('app-store-badge') ||
                document.getElementById('play-store-badge')
            )?.click();
        }, 1000);
    };

    const imagesArrayWithPlaceholders = [
        ...images,
        ...new Array(images?.length > 2 ? 0 : 2 - images?.length).fill({}),
    ];
    const imagesWithoutProgress = images?.filter((image) => !image?.progress);

    return (
        <Container
            maxWidth={false}
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: theme.colors.white,
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <div style={{ display: 'none' }}>{downloadBadges}</div>
            <SectionStickyHeaderLogo
                expName={'photo-ai-page'}
                pageId={'photo-ai-page'}
                headerMode={undefined}
                onClick={() => {}}
            >
                <div></div>
            </SectionStickyHeaderLogo>
            <input
                type="file"
                ref={imageInputRef}
                accept="image/*;capture=filesystem"
                multiple
                onChange={onUploadImage}
                style={{ display: 'none' }}
            />
            <div
                style={{
                    display: 'flex',
                    justifyContent: images?.length ? 'flex-start' : 'center',
                    alignItems: 'center',
                    flex: 1,
                    width: '100%',
                    flexDirection: 'column',
                    padding: '0 20px',
                }}
            >
                {!images?.length ? (
                    <ButtonContainer
                        style={{
                            maxWidth: 300,
                        }}
                        onClick={() => {
                            imageInputRef.current?.click();
                        }}
                    >
                        <BodyBold>Open Gallery</BodyBold>
                    </ButtonContainer>
                ) : null}
                {images?.length ? null : (
                    <div
                        style={{
                            fontWeight: '700',
                            marginTop: 10,
                            color: 'gray',
                            fontSize: 14,
                        }}
                    >
                        Upload Photos to Get Started
                    </div>
                )}

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        padding: '20px 0px',
                        maxWidth: isMobile ? '100%' : 800,
                    }}
                >
                    {(images?.length
                        ? imagesWithoutProgress
                        : imagesArrayWithPlaceholders
                    )?.map((image) => (
                        <div
                            key={image.id}
                            style={{
                                margin: 10,
                            }}
                        >
                            {image.image ? (
                                <div
                                    style={{
                                        position: 'relative',
                                    }}
                                >
                                    <img
                                        src={image.image}
                                        style={{
                                            width: 150,
                                            height: 'auto',
                                            borderRadius: 10,
                                        }}
                                    />
                                    {!image?.progress ? (
                                        <CircularProgress
                                            size={25}
                                            color="secondary"
                                            thickness={10}
                                            style={{
                                                position: 'absolute',
                                                top: -15,
                                                right: -15,
                                            }}
                                        />
                                    ) : null}
                                </div>
                            ) : (
                                <div
                                    style={{
                                        width: 150,
                                        height: 150,
                                        backgroundColor: 'rgba(0,0,0,0.1)',
                                        borderRadius: 10,
                                    }}
                                />
                            )}
                        </div>
                    ))}
                </div>

                {images?.[0]?.progress ? (
                    <div
                        style={{
                            maxWidth: 800,
                        }}
                    >
                        <div
                            style={{
                                fontWeight: '700',
                                fontSize: 24,
                                lineHeight: 1.5,
                                color: 'black',
                                marginBottom: 18,
                                marginTop: -25,
                                textAlign: 'center',
                            }}
                        >
                            Draft Progress Report
                        </div>
                        <div
                            style={{
                                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
                                borderRadius: 5,
                                padding: 20,
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <ButtonContainer
                                    style={{
                                        width: '100%',
                                        padding: '10px 20px',
                                    }}
                                    onClick={() => {
                                        onWebAppPhotoAIClickUploadAnotherPhoto();
                                        const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&su=${images?.[0]?.title}&body=${images?.[0]?.progress}`;
                                        window.open(gmailLink);
                                    }}
                                >
                                    <div
                                        style={{
                                            fontWeight: '700',
                                            fontSize: 14,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: 10,
                                            }}
                                        />
                                        Edit Draft
                                    </div>
                                </ButtonContainer>
                            </div>

                            <div
                                style={{
                                    height: 20,
                                }}
                            />

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingBottom: 10,
                                    borderBottom: '1px solid rgba(0,0,0,0.1)',
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: 12,
                                        fontWeight: '700',
                                        lineHeight: 2,
                                        color: 'gray',
                                    }}
                                >
                                    Subject
                                </div>
                                <div
                                    style={{
                                        width: 10,
                                    }}
                                />
                                <div
                                    style={{
                                        fontWeight: '700',
                                        fontSize: 14,
                                        lineHeight: 1.5,
                                    }}
                                >
                                    {images?.[0]?.title}
                                </div>
                            </div>
                            <div
                                style={{
                                    height: 10,
                                }}
                            />
                            <div
                                style={{
                                    fontSize: 14,
                                    lineHeight: 1.4,
                                    margin: 10,
                                }}
                            >
                                {images?.[0]?.progress
                                    ?.split?.('\n')
                                    ?.map?.((line) => {
                                        return (
                                            <div
                                                style={{
                                                    ...(line?.startsWith('-')
                                                        ? {
                                                              marginLeft: 20,
                                                              fontWeight: 600,
                                                              lineHeight: 1.5,
                                                          }
                                                        : {}),
                                                }}
                                            >
                                                {line}
                                            </div>
                                        );
                                    })}
                            </div>

                            {images?.[0]?.progress ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                    }}
                                >
                                    {images?.map((image) => (
                                        <img
                                            src={image.image}
                                            style={{
                                                width: 100,
                                                height: 'auto',
                                                margin: 5,
                                                borderRadius: 10,
                                            }}
                                        />
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    </div>
                ) : null}
            </div>
        </Container>
    );
};

export default PhotoAIProgressReport;
