import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Container } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import Resizer from 'react-image-file-resizer';
import EXIF from 'exifr';
import theme from '../../theme';
import SectionStickyHeaderLogo from '../../components/LandingPageVariations/elements/SectionStickyHeaderLogo';
import PublicService from '../../services/public/service';
import {
    onWebAppPhotoAIClickUploadAnotherPhoto,
    onWebAppPhotoAIGotGeolocation,
    onWebAppPhotoAIGotViewpoint,
    onWebAppPhotoAITakePicture,
} from '../../tracking/trackers';
import getAddressFromCoordinates from '../../util/getAddressFromCoordinates';
import './index.css';

const PhotoAI = () => {
    const imageInputRef = useRef<HTMLInputElement>(null);
    const [step, setStep] = useState<number>(0);
    const [imageId, setImageId] = useState<string | undefined>(undefined);
    const [image, setImage] = useState<string | undefined>(undefined);
    const [latLong, setLatLong] = useState<
        { lat: Number; lng: Number } | undefined
    >(undefined);
    const [geolocation, setGeolocation] = useState<string | undefined>(
        undefined
    );
    const [viewPoint, setViewPoint] = useState<string | undefined>(undefined);

    const resizeFile = (file: File): Promise<Blob> => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1024,
                1024,
                'JPEG',
                85,
                0,
                //@ts-ignore
                (b: Blob) => {
                    resolve(b);
                },
                'blob'
            );
        });
    };

    const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _file = event?.target?.files?.[0];
        if (!_file) return;
        if (_file.type !== 'image/jpeg' && _file.type !== 'image/webp') return;
        // @ts-ignore
        EXIF.gps(_file).then((gps) => {
            setLatLong({ lat: gps?.latitude, lng: gps?.longitude });
            if (!gps) {
                navigator?.geolocation?.getCurrentPosition((position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    setLatLong({ lat: latitude, lng: longitude });
                });
            }
        });
        resizeFile(_file)
            .then((resizedFile) => {
                PublicService.uploadImage(resizedFile)
                    .then((res) => {
                        let isCamera = false;
                        EXIF.parse(_file)
                            .then((data) => {
                                if (!data?.ExifVersion) {
                                    isCamera = true;
                                }
                            })
                            .finally(() => {
                                onWebAppPhotoAITakePicture({
                                    image_id: res.data.image_ids[0],
                                    exp_name: 'sep-24-photo-ai-location',
                                    is_camera: isCamera,
                                });
                            });

                        const imageId = res.data.image_ids[0];
                        setImageId(imageId);
                        PublicService.getPhotoInformation(imageId).then(
                            (res) => {
                                const data = res.data?.location
                                    ?.replace(/```/g, '')
                                    ?.replace('json', '');
                                const json = JSON.parse(data);
                                setViewPoint(json?.Location);
                                onWebAppPhotoAIGotViewpoint({
                                    image_id: imageId,
                                    viewpoint: json?.Location,
                                });
                            }
                        );
                        setImage(URL.createObjectURL(resizedFile));
                    })
                    .catch((err) => {});
            })
            .catch(() => {});
        setStep(1);
    };

    useEffect(() => {
        if (latLong) {
            getAddressFromCoordinates({
                latitude: latLong.lat,
                longitude: latLong.lng,
            })
                .then((res) => {
                    setGeolocation(res);
                    onWebAppPhotoAIGotGeolocation({
                        geolocation: res,
                    });
                })
                .catch((e) => {
                    console.error('error', e);
                });
        }
    }, [latLong]);

    return (
        <Container
            maxWidth={false}
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: theme.colors.white,
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <SectionStickyHeaderLogo
                expName={'photo-ai-page'}
                pageId={'photo-ai-page'}
                headerMode={undefined}
                onClick={() => {}}
            >
                <div></div>
            </SectionStickyHeaderLogo>
            <input
                type="file"
                accept="image/*;capture=camera"
                ref={imageInputRef}
                onChange={onUploadImage}
                style={{ display: 'none' }}
            />

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    width: '100%',
                    flexDirection: 'column',
                    padding: '0 20px',
                }}
            >
                {step === 0 ? (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flex: 1,
                                width: '100%',
                                flexDirection: 'column',
                                padding: '0 20px',
                            }}
                        >
                            <div style={{ fontWeight: '700' }}>
                                Take a photo of your project
                            </div>
                            <div
                                style={{
                                    color: theme.colors.grayText,
                                    fontSize: 16,
                                    textAlign: 'center',
                                }}
                            >
                                Upload the first photo of your project to get
                                started
                            </div>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{
                                    color: 'white',
                                    boxShadow: 'none',
                                    borderRadius: 10,
                                    height: 50,
                                    width: 200,
                                    fontWeight: '700',
                                    marginTop: 20,
                                }}
                                onClick={() => {
                                    imageInputRef.current?.click();
                                }}
                            >
                                Take a Photo
                            </Button>
                        </div>
                    </>
                ) : (
                    <div
                        style={{
                            display: 'flex',
                            // justifyContent: 'center',
                            // alignItems: 'center',
                            flex: 1,
                            width: '100%',
                            flexDirection: 'column',
                            padding: '0 20px',
                        }}
                    >
                        {viewPoint ? (
                            <>
                                <div style={{ height: 50 }}></div>
                                <div
                                    style={{
                                        fontSize: 14,
                                        fontWeight: '700',
                                        color: 'gray',
                                    }}
                                >
                                    Project
                                </div>
                                <div
                                    style={{
                                        fontSize: 26,
                                        fontWeight: '700',
                                        color: 'black',
                                    }}
                                >
                                    {geolocation || 'Project A'}
                                </div>
                                <div style={{ height: 50 }}></div>
                                <div
                                    style={{
                                        fontSize: 18,
                                        fontWeight: '400',
                                        color: 'white',
                                        backgroundColor: '#1c1c1c',
                                        width: 'fit-content',
                                        padding: '5px 20px',
                                        borderRadius: 5,
                                    }}
                                >
                                    {viewPoint}
                                </div>
                                <div style={{ height: 20 }}></div>
                                <img
                                    src={image}
                                    style={{
                                        width: 150,
                                        borderRadius: 10,
                                    }}
                                />
                                <div style={{ height: 50 }}></div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                        onWebAppPhotoAIClickUploadAnotherPhoto();
                                        imageInputRef.current?.click();
                                    }}
                                    style={{
                                        color: 'white',
                                        boxShadow: 'none',
                                        borderRadius: 10,
                                        height: 50,
                                        width: '80%',
                                        maxWidth: 400,
                                        fontWeight: '600',
                                        fontSize: 16,
                                        marginTop: 15,
                                        marginBottom: 120,
                                        alignSelf: 'center',
                                    }}
                                    startIcon={<AddCircle />}
                                >
                                    Upload
                                </Button>
                                <div style={{ height: 50 }}></div>
                            </>
                        ) : (
                            <div
                                style={{
                                    gap: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flex: 1,
                                    width: '100%',
                                    flexDirection: 'column',
                                    padding: '0 20px',
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    style={{ color: theme.colors.secondary }}
                                />
                                <div style={{ fontSize: 15 }}>
                                    Performing magic...
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </Container>
    );
};

export default PhotoAI;
