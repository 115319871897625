// @ts-ignore
import { useExperiment } from '@marvelapp/react-ab-test';
import { SignupScreen, useSignup } from '../context';
import { BasicInput } from '../../../../components/Inputs';
import OnboardingCardTemplate from '../../../../components/OnboardingCardTemplate';
import CircleWrapper from '../../../../assets/onboarding/circle-wrapper.svg';
import AppHands from '../../../../assets/icons/app_hands.svg';
import { onWebAppSetEmailSuccess } from '../../../../tracking/trackers';
import { useLocalStorageItem } from '../../../../hooks/useLocalStorageItem';

const Name = () => {
    const { isLoading, email, setEmail, setScreen } = useSignup();

    const landingPageName = useLocalStorageItem('landingPageName');
    const { activeVariant } = useExperiment(
        // As useExperiment crashes if no experiment is found, we default to Google Ads Welcome
        // but it has no effect in the default case since landingPageId is not found
        landingPageName || 'Google Ads Welcome'
    );

    return (
        <OnboardingCardTemplate
            title={
                activeVariant === 'VariantEnterEmail'
                    ? 'Enter your email'
                    : 'Enter your work email'
            }
            topComponent={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            minHeight: '250px',
                            minWidth: '250px',
                            background: `url(${CircleWrapper}) no-repeat center center / contain`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src={AppHands}
                            alt={'email'}
                            style={{ width: 100 }}
                        />
                    </div>
                </div>
            }
            content={
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <div style={{ margin: 10 }} />
                    <BasicInput
                        style={{ width: '100%', marginBottom: 20 }}
                        value={email}
                        setValue={setEmail}
                        label={'Email'}
                        placeholder={'john@company.com'}
                        autoFocus
                    />
                </div>
            }
            buttons={[
                {
                    text: 'Continue',
                    onClick: () => {
                        setScreen(SignupScreen.PhoneNumber);
                        onWebAppSetEmailSuccess({
                            email,
                        });
                    },
                    isLoading,
                    disabled: isLoading || !email || !email.includes('@'),
                },
            ]}
        />
    );
};

export default Name;
