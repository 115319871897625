import { createContext, useContext, useEffect, useState } from 'react';

export interface BrowserDetails {
    ipAddress: string | null;
    userAgent: string | null;
}

const BrowserDetailsContext = createContext<BrowserDetails>({
    ipAddress: null,
    userAgent: null,
});

export const BrowserDetailsProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [ipAddress, setIpAddress] = useState<string | null>(null);
    const [userAgent, setUserAgent] = useState<string | null>(null);

    const getIpAddress = async () => {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIpAddress(data.ip);
    };

    const getUserAgent = () => {
        setUserAgent(navigator.userAgent);
    };

    useEffect(() => {
        getIpAddress();
        getUserAgent();
    }, []);

    return (
        <BrowserDetailsContext.Provider
            value={{
                ipAddress,
                userAgent,
            }}
        >
            {children}
        </BrowserDetailsContext.Provider>
    );
};

export const useBrowserDetails = () => {
    const context = useContext(BrowserDetailsContext);
    return context;
};
