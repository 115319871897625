import {
    SectionSubheader,
    SectionSubtitle,
    SectionDetails,
} from '../../components/Text';
import {
    MediaContainerDesktop,
    MediaContainerMobile,
    LandingSectionContainer,
    LandingSectionColumn,
} from './styles';

interface ILandingSectionProps {
    /* The side to show the asset; if mode === 'right',
     * the asset displays on the right-hand side of the page
     */
    mode: 'right' | 'left';
    header: string;
    section: string;
    detailSection?: string;
    asset: JSX.Element;
    style?: React.CSSProperties;
}

const LandingSection = (props: ILandingSectionProps) => {
    const { mode, header, section, detailSection, asset, style } = props;
    const assetSection = <LandingSectionColumn>{asset}</LandingSectionColumn>;
    const textSection = (
        <LandingSectionColumn
            style={{ flex: 1, paddingLeft: 20, paddingRight: 20 }}
        >
            <SectionSubheader style={{}}>{header}</SectionSubheader>
            <SectionSubtitle style={{ marginTop: 20 }}>
                {section}
            </SectionSubtitle>
            <SectionDetails
                style={{
                    marginTop: 20,
                    marginBottom: 10,
                }}
            >
                {detailSection || ''}
            </SectionDetails>
        </LandingSectionColumn>
    );
    const rightSection = mode === 'right' ? assetSection : textSection;
    const leftSection = mode === 'right' ? textSection : assetSection;
    return (
        <LandingSectionContainer style={style}>
            <MediaContainerDesktop>
                {leftSection}
                {rightSection}
            </MediaContainerDesktop>
            <MediaContainerMobile>
                {textSection}
                {assetSection}
            </MediaContainerMobile>
        </LandingSectionContainer>
    );
};

export default LandingSection;
