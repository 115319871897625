import React, { lazy, useEffect, useMemo, useRef, useState } from 'react';
import { Button, CircularProgress, Container, Modal } from '@mui/material';
import Resizer from 'react-image-file-resizer';
import { ThumbDown, ThumbUp } from '@mui/icons-material';
import { motion, useAnimate } from 'framer-motion';
import theme from '../../theme';
import SectionStickyHeaderLogo from '../../components/LandingPageVariations/elements/SectionStickyHeaderLogo';
import PublicService from '../../services/public/service';
import {
    onWebAppCostEstimationClickDownload,
    onWebAppCostEstimationClickPayment,
    onWebAppCostEstimationClickShare,
    onWebAppCostEstimationEnterPrompt,
    onWebAppCostEstimationFeedback,
    onWebAppCostEstimationLoadVariant,
    onWebAppCostEstimationTakePicture,
    onWebAppCostEstimationUserType,
    onWebAppCostEstimationViewPaywall,
} from '../../tracking/trackers';
import Survey from '../../components/Survey';
import { TouchableOpacity } from '../../components/Touchables';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import './index.css';
import { useQuery } from '../../util/hooks';
import PaymentTiers from '../Onboarding/PaymentTiers';

const DownloadBadges = lazy(
    () => import('../../screens/Payment/DownloadBadges')
);

const CostEstimation = () => {
    const { width } = useWindowDimensions();
    const query = useQuery();
    const imageInputRef = useRef<HTMLInputElement>(null);
    const [imageId, setImageId] = useState<string | undefined>(undefined);
    const [image, setImage] = useState<string | undefined>(undefined);
    const [step, setStep] = useState<number>(0);
    const [prompt, setPrompt] = useState<string>('');
    const [imageAnalyze, setImageAnalyze] = useState<string | undefined>(
        undefined
    );
    const [costEstimation, setCostEstimation] = useState<string | undefined>(
        undefined
    );
    const [userType, setUserType] = useState<string | undefined>(undefined);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
    const [showPaywallModal, setShowPaywallModal] = useState<boolean>(false);
    const [scope, animate] = useAnimate();
    const tableRef = useRef<HTMLDivElement>(null);

    const costEstimationLines = costEstimation
        ?.replaceAll('Total,,,,', 'Total,,,')
        .replaceAll('total,,,,', 'total,,,')
        ?.split('\n');
    const tableLineStart = costEstimationLines?.findIndex((l) =>
        l.toLowerCase().replaceAll(' ', '').includes('item,quantity')
    );
    let tableLineEnd = -1;
    if (tableLineStart && costEstimationLines)
        for (let i = tableLineStart + 1; i < costEstimationLines?.length; i++) {
            if (costEstimationLines[i].split(',').length > 3) tableLineEnd = i;
            else {
                break;
            }
        }

    const downloadText = useMemo(() => {
        if (!userType) return ['', ''];
        if (
            userType === 'landlord' ||
            userType === 'homeowner' ||
            userType === 'other'
        ) {
            const rand = Math.floor(Math.random() * 2);
            if (rand === 0)
                return [
                    'Stay on budget with AI',
                    'Track every project expense with the #1 construction receipt tracker',
                ];
            return [
                'Keep your project budget on track',
                'Track every project expense with the #1 construction receipt tracker',
            ];
        }
        const rand = Math.floor(Math.random() * 4);
        if (rand === 0)
            return [
                'Make more money on each job',
                'Track expenses by job with the #1 construction receipt tracker',
            ];

        if (rand === 1)
            return [
                'Turn estimates into earnings',
                'Track expenses by job with the #1 construction receipt tracker',
            ];
        if (rand === 2)
            return [
                'Turn estimates into profits',
                'Track expenses by job with the #1 construction receipt tracker',
            ];
        return [
            'Use AI to automate more admin work',
            'Track every project expense with the #1 construction receipt tracker',
        ];
    }, [userType]);

    useEffect(() => {
        if (!downloadText[0].length) return;
        onWebAppCostEstimationLoadVariant({
            variant: downloadText[0],
        });
    }, [downloadText]);

    const resizeFile = (file: File): Promise<Blob> => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1024,
                1024,
                'JPEG',
                85,
                0,
                //@ts-ignore
                (b: Blob) => {
                    resolve(b);
                },
                'blob'
            );
        });
    };

    const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const _file = event?.target?.files?.[0];
        if (!_file) return;
        if (_file.type !== 'image/jpeg') return;
        resizeFile(_file)
            .then((resizedFile) => {
                PublicService.uploadImage(resizedFile)
                    .then((res) => {
                        onWebAppCostEstimationTakePicture({
                            image_id: res.data.image_ids[0],
                            exp_name: 'aug-25-remove-paywall',
                        });
                        setImageId(res.data.image_ids[0]);
                        setImage(URL.createObjectURL(resizedFile));
                        // PublicService.getImageAnalyze(
                        //     res.data.image_ids[0]
                        // ).then((res) => {
                        //     setImageAnalyze(res.data.cost_estimation);
                        // });
                    })
                    .catch((err) => {});
            })
            .catch(() => {});
        setStep(1);
    };

    const animateText = async (fullText: string) => {
        let text = '';
        for (let i = 0; i < fullText.length; i += 40) {
            text += fullText.substring(i, i + 40);
            setCostEstimation(text);
            await new Promise((resolve) => setTimeout(resolve, 100));
        }
        setIsCompleted(true);
    };

    const downloadApp = () => {
        onWebAppCostEstimationClickDownload();
        setTimeout(() => {
            (
                document.getElementById('app-store-badge') ||
                document.getElementById('play-store-badge')
            )?.click();
        }, 1000);
    };

    const downloadBadges = useMemo(() => {
        return !process.isServer ? (
            <DownloadBadges
                alternativeWebUrl="/"
                customData={{
                    source: 'cost-estimation',
                    initial_landing: query.get('initial-landing') || '',
                    experience: 'cost-estimation',
                }}
                branchAppStoreUrl="https://apps.apple.com/app/simplywise-receipt-scanner/id1538521095?ppid=e78e02eb-b58c-48b8-a142-c7b5f2e8e641"
                branchPlayStoreUrl="https://play.google.com/store/apps/details?id=com.simplywise.mobile&listing=estimator"
                black
            />
        ) : null;
    }, []);

    const userTypeList = useMemo(
        () =>
            [
                {
                    text: 'Contractor',
                    value: 'contractor',
                },
                {
                    text: 'Landlord',
                    value: 'landlord',
                },
                {
                    text: 'Homeowner',
                    value: 'homeowner',
                },
            ]
                .map((value) => ({
                    value,
                    sort: Math.random(),
                }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value),
        []
    );

    const ctaType = useMemo(() => {
        return 'appstore';
    }, []);

    useEffect(() => {
        if (step === 2 && imageId && prompt) {
            PublicService.getCostEstimation(imageId, prompt).then((res) => {
                const fullText = res.data.cost_estimation;
                animateText(fullText);
            });
        }
    }, [step, imageId, prompt]);

    useEffect(() => {
        if (isCompleted && userType) {
            // setTimeout(() => setShowFeedbackModal(true), 12000);
        }
    }, [isCompleted, userType]);

    useEffect(() => {
        if (showPaywallModal) {
            onWebAppCostEstimationViewPaywall();
        }
    }, [showPaywallModal]);

    const animateScanLight = async (height: number) => {
        for (let i = 0; i < 600; i++) {
            await animate(
                scope.current,
                {
                    top: height - 40,
                },
                {
                    duration: 0.9,
                    type: 'spring',
                }
            );
            await animate(
                scope.current,
                {
                    top: 0,
                },
                {
                    duration: 0.9,
                    type: 'spring',
                }
            );
        }
    };

    const animateTable = async () => {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        tableRef.current?.scroll({
            left: tableRef.current.scrollWidth,
            behavior: 'smooth',
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        tableRef.current?.scroll({
            left: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        if (tableRef.current) {
            animateTable();
        }
    }, [tableRef.current]);

    return (
        <Container
            maxWidth={false}
            style={{
                paddingLeft: 0,
                paddingRight: 0,
                backgroundColor: theme.colors.white,
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            {/* rendering it here to avoid lazy loading down the road */}
            <div style={{ display: 'none' }}>{downloadBadges}</div>
            {/* Header Section */}
            <SectionStickyHeaderLogo
                expName={'cost-estimator-page'}
                pageId={'cost-estimator-page'}
                headerMode={undefined}
                onClick={() => {
                    downloadApp();
                }}
            >
                <div></div>
            </SectionStickyHeaderLogo>
            <input
                type="file"
                accept="image/*;capture=camera"
                capture="environment"
                ref={imageInputRef}
                onChange={onUploadImage}
                style={{ display: 'none' }}
            />

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flex: 1,
                    width: '100%',
                    flexDirection: 'column',
                    padding: '0 20px',
                }}
            >
                {step === 0 ? (
                    <>
                        <div style={{ fontWeight: '700' }}>
                            What do you want to repair/install?
                        </div>
                        <div
                            style={{
                                color: theme.colors.grayText,
                                fontSize: 16,
                            }}
                        >
                            Include the object/space/room in the photo
                        </div>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{
                                color: 'white',
                                boxShadow: 'none',
                                borderRadius: 10,
                                height: 50,
                                width: 200,
                                fontWeight: '700',
                                marginTop: 20,
                            }}
                            onClick={() => {
                                imageInputRef.current?.click();
                            }}
                        >
                            Take a Photo
                        </Button>
                    </>
                ) : step === 1 ? (
                    <>
                        <div style={{ fontWeight: '700', marginTop: 20 }}>
                            Describe your project
                        </div>
                        <textarea
                            placeholder="Include the type of work and any dimensions if you have them"
                            rows={4}
                            style={{
                                width: '100%',
                                padding: 10,
                                borderRadius: 10,
                                borderColor: '#ccc',
                                borderWidth: 1,
                                fontSize: 16,
                                marginTop: 10,
                                maxWidth: 650,
                            }}
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{
                                color: 'white',
                                boxShadow: 'none',
                                borderRadius: 10,
                                height: 50,
                                width: 200,
                                fontWeight: '700',
                                marginTop: 20,
                            }}
                            onClick={() => {
                                setStep(2);
                                onWebAppCostEstimationEnterPrompt({
                                    prompt,
                                });
                            }}
                            disabled={!prompt}
                        >
                            Get My Estimate
                        </Button>
                    </>
                ) : (
                    <>
                        {userType && costEstimationLines ? (
                            <>
                                <div
                                    style={{
                                        fontSize: 15,
                                        fontWeight: '500',
                                        padding: '50px 20px',
                                        color: theme.colors.grayText,
                                        maxWidth: Math.min(width * 0.98, 650),
                                    }}
                                >
                                    {tableLineStart &&
                                        tableLineEnd &&
                                        tableLineStart !== -1 &&
                                        tableLineEnd !== -1 && (
                                            <>
                                                <div
                                                    style={{
                                                        overflowX: 'scroll',
                                                        margin: '10px auto',
                                                    }}
                                                    ref={tableRef}
                                                >
                                                    <table
                                                        style={{
                                                            width: 600,
                                                        }}
                                                    >
                                                        <thead>
                                                            <tr>
                                                                {costEstimationLines[
                                                                    tableLineStart
                                                                ]
                                                                    .split(',')
                                                                    .map(
                                                                        (
                                                                            header
                                                                        ) => (
                                                                            <th
                                                                                key={
                                                                                    header
                                                                                }
                                                                                style={{
                                                                                    paddingLeft: 5,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    header
                                                                                }
                                                                            </th>
                                                                        )
                                                                    )}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {costEstimationLines
                                                                .slice(
                                                                    tableLineStart +
                                                                        1,
                                                                    tableLineEnd +
                                                                        1
                                                                )
                                                                .map(
                                                                    (
                                                                        line,
                                                                        index
                                                                    ) => (
                                                                        <tr
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            {line
                                                                                .split(
                                                                                    ','
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        cell,
                                                                                        cellIndex
                                                                                    ) => (
                                                                                        <td
                                                                                            key={
                                                                                                cellIndex
                                                                                            }
                                                                                            style={{
                                                                                                paddingLeft: 5,
                                                                                            }}
                                                                                        >
                                                                                            {cell.length >
                                                                                            25
                                                                                                ? cell.slice(
                                                                                                      0,
                                                                                                      25
                                                                                                  ) +
                                                                                                  '...'
                                                                                                : cell}
                                                                                        </td>
                                                                                    )
                                                                                )}
                                                                        </tr>
                                                                    )
                                                                )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent:
                                                            'center',
                                                        marginBottom: 20,
                                                    }}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => {
                                                            downloadApp();
                                                            // PublicService.getXlsxExport(
                                                            //     costEstimationLines
                                                            //         .slice(
                                                            //             tableLineStart,
                                                            //             tableLineEnd +
                                                            //                 1
                                                            //         )
                                                            //         .filter(
                                                            //             (
                                                            //                 line
                                                            //             ) =>
                                                            //                 !line
                                                            //                     .toLowerCase()
                                                            //                     .startsWith(
                                                            //                         'total,'
                                                            //                     )
                                                            //         )
                                                            //         .map(
                                                            //             (
                                                            //                 line
                                                            //             ) =>
                                                            //                 line.replaceAll(
                                                            //                     '$',
                                                            //                     ''
                                                            //                 )
                                                            //         )
                                                            //         .join('\n')
                                                            // ).then((result) => {
                                                            //     window.location.href =
                                                            //         result.data.url;
                                                            // });
                                                        }}
                                                        style={{
                                                            color: 'white',
                                                            fontWeight: '700',
                                                        }}
                                                    >
                                                        Download & Edit as XLSX
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                </div>
                                {/* <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{
                                        color: 'white',
                                        boxShadow: 'none',
                                        borderRadius: 10,
                                        height: 50,
                                        width: 200,
                                        fontWeight: '700',
                                        marginTop: 5,
                                    }}
                                    onClick={() => {
                                        onWebAppCostEstimationClickShare();
                                        const body = `Check out this estimate I got from the SimplyWise app!\r\n\r\n${costEstimation}`;
                                        window.location.href = `sms:?body=${encodeURIComponent(
                                            body
                                        )}`;
                                    }}
                                >
                                    Share Estimate
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    style={{
                                        color: theme.colors.secondary,
                                        boxShadow: 'none',
                                        borderRadius: 10,
                                        height: 50,
                                        width: 200,
                                        fontWeight: '700',
                                        marginTop: 10,
                                    }}
                                    onClick={() => {
                                        window.location.reload();
                                    }}
                                >
                                    Try Again
                                </Button> */}
                                <div style={{ height: 50 }}></div>
                                <div
                                    style={{
                                        fontSize: 26,
                                        fontWeight: '700',
                                        color: 'black',
                                    }}
                                >
                                    {downloadText[0]}
                                </div>
                                <div
                                    style={{
                                        fontSize: 18,
                                        fontWeight: '400',
                                        color: '#555',
                                    }}
                                >
                                    {downloadText[1]}
                                </div>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={
                                        ctaType === 'appstore'
                                            ? downloadApp
                                            : () => setShowPaywallModal(true)
                                    }
                                    style={{
                                        color: 'white',
                                        boxShadow: 'none',
                                        borderRadius: 10,
                                        height: 50,
                                        width: '80%',
                                        maxWidth: 400,
                                        fontWeight: '600',
                                        fontSize: 20,
                                        marginTop: 15,
                                        marginBottom: 120,
                                    }}
                                >
                                    Continue
                                </Button>
                                <Modal
                                    open={showFeedbackModal}
                                    onClose={() => setShowFeedbackModal(false)}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            backgroundColor: theme.colors.white,
                                            borderRadius: 10,
                                            width: 300,
                                            padding: 20,
                                            fontWeight: '600',
                                        }}
                                    >
                                        Was the estimate helpful?
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                gap: 60,
                                                marginTop: 20,
                                                marginBottom: 10,
                                            }}
                                        >
                                            <TouchableOpacity
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: 50,
                                                    height: 50,
                                                }}
                                                onClick={() => {
                                                    onWebAppCostEstimationFeedback(
                                                        {
                                                            feedback:
                                                                'thumbs-down',
                                                        }
                                                    );
                                                    setShowFeedbackModal(false);
                                                }}
                                            >
                                                <ThumbDown
                                                    style={{
                                                        color: theme.colors
                                                            .secondary,
                                                        width: 35,
                                                        height: 35,
                                                    }}
                                                />
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: 50,
                                                    height: 50,
                                                }}
                                                onClick={() => {
                                                    onWebAppCostEstimationFeedback(
                                                        {
                                                            feedback:
                                                                'thumbs-up',
                                                        }
                                                    );
                                                    setShowFeedbackModal(false);
                                                }}
                                            >
                                                <ThumbUp
                                                    style={{
                                                        color: theme.colors
                                                            .secondary,
                                                        width: 35,
                                                        height: 35,
                                                    }}
                                                />
                                            </TouchableOpacity>
                                        </div>
                                    </div>
                                </Modal>
                                <Modal
                                    open={showPaywallModal}
                                    onClose={() => setShowPaywallModal(false)}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            backgroundColor: theme.colors.white,
                                            width: width * 0.9,
                                            borderRadius: 10,
                                            maxWidth: 500,
                                            padding: 20,
                                        }}
                                    >
                                        <PaymentTiers
                                            goToPayment={() => {
                                                onWebAppCostEstimationClickPayment();
                                                downloadApp();
                                            }}
                                            onPressFreemiumX={() => {
                                                setShowPaywallModal(false);
                                            }}
                                            paywallVariant="cost-estimation"
                                        />
                                    </div>
                                </Modal>
                                <div style={{ height: 50 }}></div>
                            </>
                        ) : (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 10,
                                    justifyContent: 'center',
                                }}
                            >
                                <CircularProgress
                                    size={40}
                                    style={{ color: theme.colors.secondary }}
                                />
                                <div style={{ fontSize: 15 }}>
                                    Performing estimating magic...
                                </div>
                                {userType ? null : (
                                    <>
                                        <div
                                            style={{
                                                fontSize: 22,
                                                marginTop: 30,
                                                fontWeight: '700',
                                                width: 300,
                                            }}
                                        >
                                            What best describes you?
                                        </div>
                                        <Survey
                                            choices={[
                                                ...userTypeList,
                                                {
                                                    text: 'Other',
                                                    value: 'other',
                                                },
                                            ]}
                                            style={{ width: 300 }}
                                            onSelect={(choice) => {
                                                setUserType(choice);
                                                onWebAppCostEstimationUserType({
                                                    userType: choice,
                                                });
                                            }}
                                        />
                                        <div
                                            style={{
                                                fontSize: 15,
                                                color: '#888',
                                            }}
                                        >
                                            We'll use this to personalize your
                                            estimate
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </>
                )}
            </div>
        </Container>
    );
};

export default CostEstimation;
