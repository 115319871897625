import { AxiosResponse } from 'axios';
import axios from '../../util/axios';
import { IChargeFinderMerchantResponse, IChargeFinderResponse } from './types';

export default class PlaidService {
    public static findChargeContent(
        merchant?: string,
        code?: string,
        merchant_slug?: string,
        code_slug?: string,
        page?: number,
        limit?: number,
        slugs_only?: boolean
    ): Promise<IChargeFinderResponse | undefined> {
        return axios
            .post('/receipts/plaid/chargefinder/codes/list', {
                merchant,
                code,
                merchant_slug,
                code_slug,
                page,
                limit,
                slugs_only,
            })
            .then((value: AxiosResponse<IChargeFinderResponse>) => value.data)
            .catch((e) => {
                console.log('Error fetching codes', e);
                throw e;
            });
    }

    public static getMerchants(
        merchant?: string,
        slug?: string
    ): Promise<IChargeFinderMerchantResponse | undefined> {
        return axios
            .post('/receipts/plaid/chargefinder/merchants/list', {
                merchant,
                slug,
            })
            .then(
                (value: AxiosResponse<IChargeFinderMerchantResponse>) =>
                    value.data
            )
            .catch((_) => {
                console.log('error fetching merchants');
                return undefined;
            });
    }
}
