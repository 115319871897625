import { useLocation } from 'react-router-dom';
import { VariationButtonElement } from '../configuration/types';
import { APP_STORE_LINK } from '../../../util/constants';
import CTAButton from '../../Buttons/CTAButton';
import { DownloadLinkWrapper } from '../../../screens/Landing/styles';
import { onWebAppLandingPageCTAClick } from '../../../tracking/trackers';
import { isIOS } from '../../../util/isIOS';
import { PrimaryCTA } from '../../Onboarding/PrimaryCTA';
import multiChannelEvents from '../../../tracking/multiChannelEvents';
import TextingCollectionCTA from '../../Onboarding/TextingCollectionCTA';
import { CTAAlignmentWrapper } from './styles';
import { useCTAAction } from './useCTAAction';

export const VariationCallToAction = (
    props: VariationButtonElement & {
        baseName: string;
        variationName: string;
        isFooter?: boolean;
        buttonWidth?: number | string;
    }
) => {
    const location = useLocation() as any;
    const ctaAction = useCTAAction(props.action);

    const onCTAClick = () => {
        multiChannelEvents.addToCart();
        onWebAppLandingPageCTAClick({
            pageId: props.baseName,
            expName: props.variationName,
            ctaPosition: props.assignedPosition,
            isIOS,
            url: location.pathname,
        });
        ctaAction();
    };

    if (props.action === 'StartForFreeForSalesTaxOnboarding') {
        return (
            <CTAAlignmentWrapper centerAlways={props.isFooter}>
                <PrimaryCTA
                    onClick={() => onCTAClick()}
                    size={'normal'}
                    label={props.label}
                    sublabel={props.sublabel}
                    style={{ maxWidth: 400, marginTop: 40 }}
                />
            </CTAAlignmentWrapper>
        );
    }

    if (props.action === 'TeamsCollectionSignup') {
        return (
            <>
                <TextingCollectionCTA
                    label={props.label}
                    sublabel={props.sublabel}
                    onCTAClick={onCTAClick}
                />
            </>
        );
    }

    if (props.action === 'DownloadApp') {
        return (
            <DownloadLinkWrapper
                href={APP_STORE_LINK}
                target={'_blank'}
                style={{ textDecoration: 'none', width: 300 }}
            >
                <CTAButton
                    text={props.label}
                    style={{ marginTop: 40 }}
                    onClick={() => onCTAClick()}
                    cancelNavigation
                />
            </DownloadLinkWrapper>
        );
    }

    return (
        <CTAAlignmentWrapper centerAlways={props.isFooter}>
            <PrimaryCTA
                onClick={() => onCTAClick()}
                size={'normal'}
                label={props.label}
                labelStyle={props.labelStyle}
                sublabel={props.sublabel}
                sublabelStyle={props.sublabelStyle}
                style={{
                    maxWidth: 400,
                    marginTop: 40,
                    marginLeft: 20,
                    marginRight: 20,
                    ...(props.buttonWidth && { width: props.buttonWidth }),
                    ...(props.style || {}),
                }}
            />
        </CTAAlignmentWrapper>
    );
};
