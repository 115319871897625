import filterDuplicates from '../../../util/filterDuplicates';
import { IUserCategoryGroup, IUserTag } from '../../receipts/types';
import {
    CompoundCategoryAndCategoryGroupId,
    DEFAULT_SPACE_ID,
    Space,
} from '../types';

// Default space is only created if it has folders unassigned to any space -- refer to orderSpaces
const DEFAULT_SPACE = {
    id: DEFAULT_SPACE_ID,
    name: 'Home',
    custom: true,
};

/**
 * Designates folders with no space into a "Home" space
 * adds categoryIds to spaces so that they can be displayed in the UI
 * and orders the spaces
 */
export const getSpacesForDisplay = (
    spacesDirectlyFromDb: Space[],
    categoryGroups: IUserCategoryGroup[],
    folders: IUserTag[]
) => {
    const newSpaces: Space[] = [
        ...spacesDirectlyFromDb
            .filter((a) => a.id)
            .map((s) => ({ ...s, categoryIds: [] })),
    ];

    const _addFoldersToSpace = (
        _spaceId: string | number | undefined,
        _ids: CompoundCategoryAndCategoryGroupId[]
    ) => {
        const spaceId = _spaceId ?? DEFAULT_SPACE.id;
        const spaceIndex = newSpaces.findIndex(
            (userSpace) => userSpace.id === spaceId
        );

        // If the space is not found, add it to the default space
        if (spaceIndex === -1) {
            const hasHomeSpace = newSpaces.some(
                (s) => s.id === DEFAULT_SPACE.id
            );
            if (!hasHomeSpace) {
                newSpaces.unshift({
                    id: DEFAULT_SPACE.id,
                    name: DEFAULT_SPACE.name,
                    categoryIds: _ids,
                    order: -1,
                });
            }
        } else {
            newSpaces[spaceIndex] = {
                ...newSpaces[spaceIndex],
                categoryIds: filterDuplicates([
                    ...(newSpaces[spaceIndex].categoryIds || []),
                    ..._ids,
                ]),
            };
        }
    };

    // From the user category groups, add itself and the categories whithin that the user has access to
    categoryGroups?.forEach?.((_ctag) => {
        const categoryGroupInnerFolderIds = folders
            ?.filter((tag) => tag.category_group_ids?.includes(_ctag.id))
            .map((t) => t.tag_id);

        _addFoldersToSpace(_ctag.space_id, [
            ...categoryGroupInnerFolderIds,
            _ctag.id,
        ]);
    });

    // Add all the categories that the user has access to
    folders?.forEach?.((_tag) => {
        // Safe-guard excluding the categories in category groups that are already assigned to a space including the default space
        // Note: the same safe-guard can be applied to category groups renderings to prevent folders being shows if shared and are in multiple categories
        const isCategoryAlreadyAssignedToASpace = newSpaces.some((userSpace) =>
            userSpace.categoryIds?.includes(_tag.tag_id)
        );
        if (isCategoryAlreadyAssignedToASpace) return;

        _addFoldersToSpace(_tag.space_id, [_tag.tag_id]);
    });

    // Delete the default space if user has removed all folders in a session
    const defaultSpaceIndex = newSpaces.findIndex(
        (userSpace) => userSpace.id === DEFAULT_SPACE.id
    );
    if (
        defaultSpaceIndex !== -1 &&
        !newSpaces[defaultSpaceIndex].categoryIds?.length
    ) {
        newSpaces.splice(defaultSpaceIndex, 1);
    }

    const sortedSpaces = newSpaces.sort((a: Space, b: Space) => {
        const orderA = a.order && typeof a.order === 'number' ? a.order : 0;
        const orderB = b.order && typeof b.order === 'number' ? b.order : 0;
        return orderA - orderB;
    });

    return sortedSpaces;
};
