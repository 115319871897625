import { Button, Modal } from '@mui/material';
import { ThumbDown, ThumbUp } from '@mui/icons-material';
import { useState } from 'react';
import { TouchableOpacity } from '../../../components/Touchables';
import theme from '../../../theme';
import {
    onWebAppCostEstimationFeedback,
    onWebAppCostEstimationClickPayment,
    onWebAppCostEstimationAnswerFurtherQuestion,
} from '../../../tracking/trackers';
import PaymentTiers from '../../Onboarding/PaymentTiers';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import CostEstimatorWand from '../../../assets/animations/svGator/CostEstimatorWand';
import MeasuringTape from '../../../assets/animations/svGator/MeasuringTape';
import Survey from '../../../components/Survey';
import { Body, BodyBold } from '../../../components/Text';
import { formatAmount } from '../../../util/formatAmount';
import Ruler from '../../../assets/icons/ruler.svg';

const FinalStep = ({
    costEstimationLines,
    tableLineStart,
    tableLineEnd,
    downloadText,
    downloadApp,
    ctaType,
    setShowPaywallModal,
    showPaywallModal,
    setShowFeedbackModal,
    showFeedbackModal,
    questionLoading,
    questionsToAnswer,
    initialQuestionCount,
    setQuestionLoading,
    setNewInstruction,
    refineEstimate,
    imageId,
    setQuestionsToAnswer,
    prompt,
    costEstimationLineItems,
    isLineItemMeasurable,
    estimateTotal,
    removeLineItem,
    updateLineItem,
    setIsAddMarkup,
}: {
    costEstimationLines: string[];
    tableLineStart: number | undefined;
    tableLineEnd: number;
    downloadText: string[];
    downloadApp: () => void;
    ctaType: string;
    setShowPaywallModal: (show: boolean) => void;
    showPaywallModal: boolean;
    setShowFeedbackModal: (show: boolean) => void;
    showFeedbackModal: boolean;
    questionLoading: boolean;
    questionsToAnswer: { question: string; choices: string[] }[];
    initialQuestionCount: number;
    setQuestionLoading: (loading: boolean) => void;
    setNewInstruction: React.Dispatch<React.SetStateAction<string>>;
    refineEstimate: (instruction: string, isRefine: boolean) => void;
    imageId: string | undefined;
    setQuestionsToAnswer: (
        questions: { question: string; choices: string[] }[]
    ) => void;
    prompt: string;
    costEstimationLineItems: string[][];
    isLineItemMeasurable: (index: number) => boolean;
    estimateTotal: string;
    removeLineItem: (index: number) => void;
    updateLineItem: (
        index: number | undefined,
        field: number,
        value: string
    ) => void;
    setIsAddMarkup: (isAddMarkup: boolean) => void;
}) => {
    const { width } = useWindowDimensions();

    const [editingLineItemIndex, setEditingLineItemIndex] = useState<
        number | undefined
    >(undefined);
    const [editingValues, setEditingValues] = useState<string[]>([]);

    const onLineItemClick = (index: number) => {
        const editingLineItems = JSON.parse(
            JSON.stringify(costEstimationLineItems)
        );

        setEditingValues(editingLineItems[index]);
        setEditingLineItemIndex(index);
    };

    if (!costEstimationLines) {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 10,
                    justifyContent: 'center',
                    width: Math.min(width * 0.8, 400),
                }}
            >
                <div style={{ fontSize: 25 }}>Drafting Estimate...</div>
                <CostEstimatorWand />
            </div>
        );
    }

    if (questionsToAnswer.length) {
        return (
            <div style={{ flex: 1 }}>
                <div
                    style={{
                        width: Math.min(width * 0.8, 600),
                        marginBottom: 20,
                    }}
                >
                    <div
                        style={{
                            height: 10,
                            backgroundColor: '#E0E0E0',
                            borderRadius: 5,
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: `${
                                    100 -
                                    (questionsToAnswer.length /
                                        initialQuestionCount) *
                                        100
                                }%`,
                                backgroundColor: theme.colors.primary400,
                                borderRadius: 5,
                            }}
                        />
                    </div>
                </div>
                {questionLoading ? (
                    <div style={{ flex: 1 }}>
                        <div
                            style={{
                                alignItems: 'center',
                                marginTop: 20,
                                justifyContent: 'center',
                                flex: 1,
                            }}
                        >
                            <div
                                style={{
                                    alignItems: 'center',
                                    margin: 20,
                                }}
                            >
                                <div style={{ fontSize: 25 }}>
                                    Refining Estimate...
                                </div>
                            </div>
                            <div
                                style={{
                                    width: Math.min(width * 0.8, 400),
                                    alignSelf: 'center',
                                    margin: 'auto',
                                }}
                            >
                                <MeasuringTape />
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div
                            style={{
                                fontSize: 25,
                                width: Math.min(width * 0.8, 600),
                            }}
                        >
                            {questionsToAnswer[0].question}
                        </div>
                        <div
                            style={{
                                width: Math.min(width * 0.8, 600),
                                marginTop: 20,
                                alignSelf: 'center',
                                flex: 1,
                                paddingBottom: 50,
                            }}
                        >
                            <Survey
                                choices={questionsToAnswer[0].choices.map(
                                    (c) => ({
                                        text: c,
                                        value: c,
                                    })
                                )}
                                textStyle={{
                                    textAlign: 'left',
                                }}
                                onSelect={(c) => {
                                    setNewInstruction((prev: string) => {
                                        const question =
                                            questionsToAnswer[0].question;
                                        if (!prev?.length) {
                                            prev = 'ANSWERS:\n';
                                        }
                                        const n = prev + `- ${question} ${c}\n`;
                                        if (questionsToAnswer.length === 1) {
                                            refineEstimate(n, false);
                                        }
                                        return n;
                                    });
                                    setQuestionLoading(true);
                                    onWebAppCostEstimationAnswerFurtherQuestion(
                                        {
                                            image_id: imageId || '',
                                            prompt,
                                            questions_left:
                                                questionsToAnswer.length - 1,
                                        }
                                    );
                                    setQuestionsToAnswer(
                                        questionsToAnswer.slice(1)
                                    );
                                    setTimeout(() => {
                                        setQuestionLoading(false);
                                    }, (Math.random() * 1 + 0.4) * 1000);
                                }}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }

    return (
        <>
            <div
                style={{
                    fontSize: 15,
                    fontWeight: '500',
                    padding: '50px 20px',
                    color: theme.colors.grayText,
                    maxWidth: Math.min(width * 0.98, 750),
                }}
            >
                <BodyBold
                    style={{
                        fontSize: 23,
                        textAlign: 'center',
                    }}
                >
                    Total: {formatAmount(parseFloat(estimateTotal || '0'))}
                </BodyBold>
                <div style={{ marginTop: 10 }} />
                {tableLineStart &&
                tableLineEnd &&
                tableLineStart !== -1 &&
                tableLineEnd !== -1 ? (
                    <>
                        {costEstimationLineItems.map((l, i) => {
                            if (i === editingLineItemIndex) {
                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            borderRadius: 5,
                                            border: `1px solid rgb(216, 216, 216)`,
                                            justifyContent: 'center',
                                            padding: 15,
                                            margin: 10,
                                        }}
                                    >
                                        <BodyBold
                                            style={{
                                                marginBottom: 10,
                                            }}
                                        >
                                            Item
                                        </BodyBold>
                                        <input
                                            placeholder="Item"
                                            type="text"
                                            value={l[0]}
                                            disabled={l[0] === 'Markup'}
                                            onChange={(e) => {
                                                const _editingValues =
                                                    editingValues;
                                                _editingValues[0] =
                                                    e.target.value;
                                                setEditingValues(
                                                    _editingValues
                                                );
                                            }}
                                            style={{
                                                padding: 10,
                                                marginBottom: 10,
                                            }}
                                        />
                                        {l[0] === 'Markup' ? null : (
                                            <>
                                                <BodyBold
                                                    style={{
                                                        marginBottom: 10,
                                                    }}
                                                >
                                                    Quantity
                                                </BodyBold>
                                                <input
                                                    placeholder="Quantity"
                                                    type="text"
                                                    value={l[1]}
                                                    onChange={(e) => {
                                                        const _editingValues =
                                                            editingValues;
                                                        _editingValues[1] =
                                                            e.target.value;
                                                        setEditingValues(
                                                            _editingValues
                                                        );
                                                    }}
                                                    style={{
                                                        padding: 10,
                                                        marginBottom: 10,
                                                    }}
                                                />
                                            </>
                                        )}
                                        <BodyBold
                                            style={{
                                                marginBottom: 10,
                                            }}
                                        >
                                            {l[0] === 'Markup'
                                                ? 'Percentage'
                                                : 'Price'}
                                        </BodyBold>
                                        <input
                                            placeholder="Price"
                                            type="text"
                                            value={l[2]}
                                            onChange={(e) => {
                                                const _editingValues =
                                                    editingValues;
                                                _editingValues[2] =
                                                    e.target.value;
                                                setEditingValues(
                                                    _editingValues
                                                );
                                            }}
                                            style={{
                                                padding: 10,
                                                marginBottom: 10,
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: 10,
                                                marginTop: 10,
                                            }}
                                        >
                                            <TouchableOpacity
                                                onClick={() => {
                                                    for (
                                                        let j = 0;
                                                        j <
                                                        editingValues.length;
                                                        j++
                                                    ) {
                                                        updateLineItem(
                                                            i,
                                                            j,
                                                            editingValues[j]
                                                        );

                                                        if (
                                                            j ===
                                                            editingValues.length -
                                                                1
                                                        ) {
                                                            if (
                                                                isLineItemMeasurable(
                                                                    i
                                                                )
                                                            ) {
                                                                updateLineItem(
                                                                    i,
                                                                    4,
                                                                    'ft'
                                                                );
                                                            }
                                                            setEditingLineItemIndex(
                                                                undefined
                                                            );
                                                            setEditingValues(
                                                                []
                                                            );
                                                        }
                                                    }
                                                }}
                                                style={{
                                                    display: 'flex',
                                                    width: 100,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor:
                                                        theme.colors.secondary,
                                                    color: theme.colors.white,
                                                    borderRadius: 5,
                                                    padding: 15,
                                                }}
                                            >
                                                <BodyBold>SAVE</BodyBold>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onClick={() => {
                                                    if (l[0] === 'Markup') {
                                                        setIsAddMarkup(false);
                                                    }
                                                    removeLineItem(i);
                                                    setEditingValues([]);
                                                    setEditingLineItemIndex(
                                                        undefined
                                                    );
                                                }}
                                                style={{
                                                    display: 'flex',
                                                    width: 100,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor:
                                                        theme.colors.error,
                                                    color: theme.colors.white,
                                                    borderRadius: 5,
                                                    padding: 15,
                                                }}
                                            >
                                                <BodyBold>DELETE</BodyBold>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onClick={() => {
                                                    setEditingValues([]);
                                                    setEditingLineItemIndex(
                                                        undefined
                                                    );
                                                }}
                                                style={{
                                                    display: 'flex',
                                                    width: 100,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    backgroundColor:
                                                        theme.colors.grayText,
                                                    color: theme.colors.white,
                                                    borderRadius: 5,
                                                    padding: 15,
                                                }}
                                            >
                                                <BodyBold>CANCEL</BodyBold>
                                            </TouchableOpacity>
                                        </div>
                                    </div>
                                );
                            }
                            return (
                                <TouchableOpacity
                                    onClick={() => {
                                        onLineItemClick(i);
                                    }}
                                    style={{
                                        flexDirection: 'row',
                                        borderRadius: 5,
                                        border: `1px solid rgb(216, 216, 216)`,
                                        alignItems: 'center',
                                        padding: 15,
                                        margin: 10,
                                    }}
                                >
                                    <div
                                        style={{
                                            flexDirection: 'column',
                                            flex: 1,
                                        }}
                                    >
                                        <BodyBold
                                            style={{
                                                marginRight: 20,
                                            }}
                                        >
                                            {l[0]}{' '}
                                            {l[0] === 'Markup'
                                                ? `(${l[2]}%)`
                                                : null}
                                        </BodyBold>
                                        {l[0] === 'Markup' ? null : (
                                            <Body
                                                style={{
                                                    color: theme.colors
                                                        .grayText,
                                                    fontWeight: '600',
                                                    marginTop: 5,
                                                    fontSize: 14,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                {isLineItemMeasurable(i) ? (
                                                    <>
                                                        <img
                                                            alt="ruler"
                                                            src={Ruler}
                                                            width={18}
                                                            height={15}
                                                            style={{
                                                                marginRight: 10,
                                                                marginTop: 3,
                                                            }}
                                                        />
                                                    </>
                                                ) : null}
                                                {isLineItemMeasurable(i)
                                                    ? l[1] + ' sq. ft '
                                                    : l[1] + ' '}
                                                <div
                                                    style={{
                                                        color: '#aaa',
                                                        paddingTop: -15,
                                                        margin: '0px 5px',
                                                    }}
                                                >
                                                    x
                                                </div>
                                                ${l[2]}
                                            </Body>
                                        )}
                                    </div>
                                    <BodyBold
                                        style={{
                                            marginLeft: 10,
                                        }}
                                    >
                                        {formatAmount(parseFloat(l[3] || '0'))}
                                    </BodyBold>
                                </TouchableOpacity>
                            );
                        })}
                    </>
                ) : null}
            </div>
            {/* <Button
                variant="contained"
                color="secondary"
                style={{
                    color: 'white',
                    boxShadow: 'none',
                    borderRadius: 10,
                    height: 50,
                    width: 200,
                    fontWeight: '700',
                    marginTop: 5,
                }}
                onClick={() => {
                    onWebAppCostEstimationClickShare();
                    const body = `Check out this estimate I got from the SimplyWise app!\r\n\r\n${costEstimation}`;
                    window.location.href = `sms:?body=${encodeURIComponent(
                        body
                    )}`;
                }}
            >
                Share Estimate
            </Button>
            <Button
                variant="outlined"
                color="secondary"
                style={{
                    color: theme.colors.secondary,
                    boxShadow: 'none',
                    borderRadius: 10,
                    height: 50,
                    width: 200,
                    fontWeight: '700',
                    marginTop: 10,
                }}
                onClick={() => {
                    window.location.reload();
                }}
            >
                Try Again
            </Button> */}
            <div style={{ height: 50 }}></div>
            <div
                style={{
                    fontSize: 26,
                    fontWeight: '700',
                    color: 'black',
                    textAlign: 'center',
                    width: Math.min(width * 0.8, 500),
                    marginBottom: 10,
                }}
            >
                {downloadText[0]}
            </div>
            <div
                style={{
                    fontSize: 18,
                    fontWeight: '400',
                    color: '#555',
                    textAlign: 'center',
                    width: Math.min(width * 0.8, 400),
                }}
            >
                {downloadText[1]}
            </div>
            <Button
                variant="contained"
                color="secondary"
                onClick={
                    ctaType === 'appstore'
                        ? downloadApp
                        : () => setShowPaywallModal(true)
                }
                style={{
                    color: 'white',
                    boxShadow: 'none',
                    borderRadius: 10,
                    height: 50,
                    width: '80%',
                    maxWidth: 400,
                    fontWeight: '600',
                    fontSize: 20,
                    marginTop: 15,
                    marginBottom: 120,
                }}
            >
                Continue
            </Button>
            <Modal
                open={showFeedbackModal}
                onClose={() => setShowFeedbackModal(false)}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: theme.colors.white,
                        borderRadius: 10,
                        width: 300,
                        padding: 20,
                        fontWeight: '600',
                    }}
                >
                    Was the estimate helpful?
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 60,
                            marginTop: 20,
                            marginBottom: 10,
                        }}
                    >
                        <TouchableOpacity
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 50,
                                height: 50,
                            }}
                            onClick={() => {
                                onWebAppCostEstimationFeedback({
                                    feedback: 'thumbs-down',
                                });
                                setShowFeedbackModal(false);
                            }}
                        >
                            <ThumbDown
                                style={{
                                    color: theme.colors.secondary,
                                    width: 35,
                                    height: 35,
                                }}
                            />
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 50,
                                height: 50,
                            }}
                            onClick={() => {
                                onWebAppCostEstimationFeedback({
                                    feedback: 'thumbs-up',
                                });
                                setShowFeedbackModal(false);
                            }}
                        >
                            <ThumbUp
                                style={{
                                    color: theme.colors.secondary,
                                    width: 35,
                                    height: 35,
                                }}
                            />
                        </TouchableOpacity>
                    </div>
                </div>
            </Modal>
            <Modal
                open={showPaywallModal}
                onClose={() => setShowPaywallModal(false)}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: theme.colors.white,
                        width: width * 0.9,
                        borderRadius: 10,
                        maxWidth: 500,
                        padding: 20,
                    }}
                >
                    <PaymentTiers
                        goToPayment={() => {
                            onWebAppCostEstimationClickPayment();
                            downloadApp();
                        }}
                        onPressFreemiumX={() => {
                            setShowPaywallModal(false);
                        }}
                        paywallVariant="cost-estimation"
                    />
                </div>
            </Modal>
            <div style={{ height: 50 }}></div>
        </>
    );
};

export default FinalStep;
