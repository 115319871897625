// @ts-ignore
import { useExperiment } from '@marvelapp/react-ab-test';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAmplitude } from '../../providers/AmplitudeProvider';
import {
    flushExternal,
    onWebAppLoadLandingPage,
} from '../../tracking/trackers';
import { isIOS } from '../../util/isIOS';
import useOriginWebUrl from '../../screens/Onboarding/useOriginWebUrl';
import multiChannelEvents from '../../tracking/multiChannelEvents';
import { Variation } from './configuration/Variation';
import { useConfigToPageVariation } from './configuration/useConfigToPageVariation';
import { LandingPageConfig } from './configuration/types';

export const LandingPageVariation = (props: LandingPageConfig) => {
    const location = useLocation() as any;
    useOriginWebUrl();

    const { isInitialized } = useAmplitude();
    const [searchParams] = useSearchParams();

    const { selectVariant, activeVariant } = useExperiment(
        props.landingPageName
    );
    // Purpose of setting this in localStorage is so we can call useExperiment hook and get activeCohort from anywhere

    useEffect(() => {
        localStorage.setItem('landingPageName', props.landingPageName);
        const { branchAppStoreUrl, branchPlayStoreUrl } = props;
        if (branchAppStoreUrl) {
            localStorage.setItem('branchAppStoreUrl', branchAppStoreUrl);
        }
        if (branchPlayStoreUrl) {
            localStorage.setItem('branchPlayStoreUrl', branchPlayStoreUrl);
        }
    }, []);

    const [eventsHandled, setEventsHandled] = useState(false);

    const baseVariation = (
        <Variation
            {...useConfigToPageVariation(
                props.baseVariation,
                props.landingPageName,
                'baseVariation'
            )}
        />
    );

    const variations =
        Object.keys(props.ABVariations ?? {}).length === 0
            ? { baseVariation }
            : calcABVariations(props);

    const variant =
        selectVariant(variations) ??
        variations?.[Object.keys(variations || {})[0]] ??
        baseVariation;

    useEffect(() => {
        const handleEvents = async () => {
            if (isInitialized) {
                multiChannelEvents.pagevisit();

                let visitorId = localStorage.getItem('visitor_id');
                if (!visitorId) {
                    visitorId = uuidv4();
                    localStorage.setItem('visitor_id', visitorId);
                }

                const referrerMobile = searchParams.get('ref');
                const referralCohort = searchParams.get('ref_cohort');

                if (referrerMobile) {
                    localStorage.setItem('referrer_mobile', referrerMobile);
                }
                if (referralCohort) {
                    localStorage.setItem('referral_cohort', referralCohort);
                }
                onWebAppLoadLandingPage({
                    pageId: props.landingPageName,
                    expName: props.expName,
                    cohortName: activeVariant,
                    url: location.pathname,
                    isIOS,
                    visitor_id: visitorId,
                    referral_cohort: referralCohort,
                    referrer_mobile: referrerMobile,
                });
                await flushExternal();

                setEventsHandled(true);
            }
        };

        handleEvents();
    }, [isInitialized]);

    if (variant?.props?.redirectBranch) {
        if (eventsHandled) return <Navigate to={'/redirect-download'} />;
        return null;
    }

    if (variant?.props?.redirectUrl) {
        if (eventsHandled)
            return (
                <Navigate
                    to={`/redirect-url?url=${variant?.props?.redirectUrl}`}
                />
            );
        return null;
    }

    return variant;
};

export const calcABVariations = (
    landingPageVariation: LandingPageConfig
): Record<string, JSX.Element> | null => {
    const keys = Object.keys(landingPageVariation.ABVariations ?? {});

    if (keys.length === 0) {
        return null;
    }

    return keys.reduce((ac, abVariation) => {
        const abConfig = {
            ...landingPageVariation.baseVariation,
            ...(landingPageVariation.ABVariations?.[abVariation] ?? {}),
        };

        const variationInfo = useConfigToPageVariation(
            abConfig,
            landingPageVariation.landingPageName,
            abVariation
        );

        const variationComp = <Variation {...variationInfo} />;

        return { ...ac, [abVariation]: variationComp };
    }, {} as Record<string, JSX.Element>);
};
