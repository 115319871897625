import { ButtonBase } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import theme from '../../theme';
import { BUTTON_HEIGHT } from '../../util/constants';
import { CTAButtonText, SectionSubtitle } from '../Text';
import { useCTAAction } from '../LandingPageVariations/elements/useCTAAction';
import { CallToActions } from '../LandingPageVariations/configuration/types';

type ICTAButtonProps = {
    text: string;
    sublabel?: string;
    onClick?: () => void;
    disabled?: boolean;
    mode?: 'footer' | 'normal';
    style?: React.CSSProperties;
    cancelNavigation?: boolean;
    altNavigation?: string;
    action?: CallToActions;
};

const CTAButton = (props: ICTAButtonProps) => {
    const {
        text,
        sublabel,
        onClick,
        disabled,
        mode,
        style,
        cancelNavigation,
        altNavigation,
        action,
    } = props;

    const navigate = useNavigate();
    const ctaAction = useCTAAction(action);

    const footerStyles =
        mode === 'footer'
            ? {
                  maxWidth: 300,
                  minWidth: 100,
                  backgroundColor: theme.colors.white,
                  height: BUTTON_HEIGHT * 1.2,
              }
            : {};

    return (
        <ButtonBase
            style={{
                width: isMobile ? '100%' : '70%',
                borderRadius: 10,
                padding: 25,
                color: theme.colors.white,
                backgroundColor: disabled
                    ? theme.colors.disabled
                    : theme.colors.primary400,
                fontWeight: '600',
                height: BUTTON_HEIGHT + 10,
                ...footerStyles,
                ...style,
            }}
            disabled={disabled}
            onClick={() => {
                onClick && onClick();
                if (!cancelNavigation) {
                    if (altNavigation) {
                        navigate(altNavigation);
                    } else {
                        ctaAction();
                    }
                }
            }}
        >
            {mode === 'footer' ? (
                <CTAButtonText>Try Now</CTAButtonText>
            ) : (
                <SectionSubtitle
                    style={{
                        color: theme.colors.white,
                        fontWeight: '600',
                        lineHeight: 1,
                    }}
                >
                    {text}
                    <span
                        style={{
                            fontWeight: 'lighter',
                        }}
                    >
                        {sublabel ? ` - ${sublabel}` : ''}
                    </span>
                </SectionSubtitle>
            )}
        </ButtonBase>
    );
};

export default CTAButton;
