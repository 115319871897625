export const getAmountInUSD = (
    amount: number,
    exchangeRate?: number | undefined
) => {
    return amount / (exchangeRate || 1);
};

/**
 * gets total taking into account split categories and exchange rate
 */
export const getReceiptTotal = (
    receiptTotal: number | undefined,
    folderTotals: { [category_id: string]: number },
    folderId?: string,
    folderIds?: string[] | undefined,
    exchangeRate?: number | undefined
) => {
    let result: number | undefined = 0;
    if (folderIds) {
        // there's an edge case where the totals values are off by like a cent each
        // what we can do is say, if every value from totals is encompassed in folderIds, just return the total amount
        const eachFolderTotalIsInFolderIds = Object.keys(folderTotals).every(
            (id) => folderIds.includes(id)
        );
        if (eachFolderTotalIsInFolderIds) {
            result = receiptTotal;
        } else {
            const totals: number[] = folderIds.map(
                (id) => folderTotals[id] || 0
            );
            result = totals.reduce((a, b) => a + b, 0);
        }
    } else if (folderId) {
        const totals = folderTotals[folderId];
        if (totals === undefined) {
            result = receiptTotal;
        } else {
            result = folderTotals[folderId];
        }
    } else {
        result = receiptTotal;
    }
    return getAmountInUSD(result ?? 0, exchangeRate);
};

/**
 * gets tax taking into account split categories and exchange rate
 */
export const getCategoryTax = (
    receiptTotal: number | undefined,
    receiptTax: number | undefined,
    folderTotals: { [category_id: string]: number },
    folderId?: string,
    folderIds?: string[] | undefined,
    exchangeRate?: number | undefined
) => {
    const taxRate = receiptTax && receiptTotal ? receiptTax / receiptTotal : 0;
    const splitTotal = getReceiptTotal(
        receiptTotal,
        folderTotals,
        folderId,
        folderIds
    );
    const result = taxRate * splitTotal;
    return getAmountInUSD(result, exchangeRate);
};

/**
 * used for getting (of X total)
 */
export const getReceiptTotalWithoutSplit = (
    receiptTotal: number | undefined,
    exchangeRate?: number | undefined
) => {
    const total = receiptTotal || 0;
    return getAmountInUSD(total, exchangeRate);
};
