import { useNavigate } from 'react-router-dom';
import { useSubscriptionInfo } from '../../../hooks/useSubscriptionInfo';
import { useAuth } from '../../../providers/AuthProvider';
import theme from '../../../theme';
import { truncateText } from '../../../util/truncateText';
import UserAvatar from '../../Avatar';
import { SubscriptionBadgeComponent } from '../../SubscriptionStatusBadge';
import { Subtitle1 } from '../../Text';
import { View } from '../../Touchables';
import UpgradeBadge from '../../UpgradeBadge';
import { AppSubscriptionTier } from '../../../services/subscriptions/types';

const DrawerHeader = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { isPro, isBase, isInTrial, isFreemium, isOrganizationProduct } =
        useSubscriptionInfo();

    return (
        <>
            <View
                style={{
                    paddingTop: 15,
                    paddingBottom: 5,
                    paddingLeft: 20,
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                <UserAvatar size={40} />
                <View
                    style={{
                        paddingLeft: 15,
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Subtitle1
                        style={{
                            fontWeight: '700',
                            color: theme.colors.blue100,
                        }}
                    >
                        {truncateText(user?.first_name ?? '', 12)}
                    </Subtitle1>
                    <View
                        style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        <SubscriptionBadgeComponent
                            color={theme.colors.blue60}
                            text={
                                isInTrial
                                    ? 'Trial'
                                    : isFreemium
                                    ? 'Free'
                                    : isBase
                                    ? 'Base'
                                    : isOrganizationProduct
                                    ? 'Teams'
                                    : 'Pro'
                            }
                            style={{ marginRight: 10 }}
                        />
                    </View>
                    {/* <Subtitle
                                style={{
                                    fontSize: 14,
                                    color: theme.colors.blue100,
                                }}
                            >
                                {user?.mobile}
                            </Subtitle> */}
                </View>
            </View>
            {isOrganizationProduct ? null : (
                <UpgradeBadge
                    source={'drawer'}
                    upgradeTo={isPro ? 'teams' : AppSubscriptionTier.PRO}
                    onPress={() => {
                        navigate('/settings/subscriptions');
                    }}
                />
            )}
        </>
    );
};

export default DrawerHeader;
