import { CircularProgress } from '@mui/material';
import { Body, BodySmall } from '../Text';
import theme from '../../theme';

const FixedLoadingIndicator = () => {
    return (
        <div
            style={{
                position: 'fixed',
                padding: 20,
                bottom: 20,
                right: 20,
                backgroundColor: theme.colors.white,
                borderRadius: 10,
                boxShadow: theme.lightShadow,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                zIndex: 100000,
            }}
        >
            <CircularProgress color={'secondary'} size={30} />
            <div style={{ alignItems: 'flex-start', marginLeft: 20 }}>
                <Body style={{ textAlign: 'left' }}>Downloading...</Body>
                <BodySmall style={{ textAlign: 'left' }}>
                    This may take a minute
                </BodySmall>
            </div>
        </div>
    );
};

export default FixedLoadingIndicator;
