import { useEffect, useState } from 'react';
import {
    IProcessingReceipt,
    IReceipt,
    ReceiptPlatform,
} from '../../services/receipts/types';
import ReceiptService from '../../services/receipts/service';
import { useAsyncEffect } from '../../util/hooks';
import { useAuth } from '../../providers/AuthProvider';
import {
    useProcessingReceiptsStore,
    useReceiptsStore,
} from '../../providers/IndexedDBProvider';
import { IInboxReceipt } from './context';

/**
 * This is different than mobile inbox
 * It's going to ingest only uncategorized receipts
 */
export const useUncategorizedInboxReceipts = () => {
    const [receipts, setReceipts] = useState<IReceipt[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { token } = useAuth();
    const { receipts: receiptsInStore, refetch } = useReceiptsStore();

    useAsyncEffect(async () => {
        if (token) {
            setIsLoading(true);
            if (!receiptsInStore) return;
            if (receiptsInStore.length > 0) {
                setReceipts(
                    receiptsInStore.filter(
                        (r) =>
                            r.categories.length === 0 &&
                            (r.platform === ReceiptPlatform.DESKTOP ||
                                r.platform === ReceiptPlatform.TEXTRACT_EMAIL)
                    )
                );
                setIsLoading(false);
                return;
            }
            const res = await ReceiptService.loadUncategorizedReceipts();
            const allUncategorizedReceipts = res.data;
            const uncategorizedDesktopAndEReceipts =
                allUncategorizedReceipts.filter((r) => {
                    return (
                        r.platform === ReceiptPlatform.DESKTOP ||
                        r.platform === ReceiptPlatform.TEXTRACT_EMAIL
                    );
                });
            setReceipts(uncategorizedDesktopAndEReceipts);
            setIsLoading(false);
        }
    }, [token, receiptsInStore]);

    const removeFromUncategorizedList = (receiptId: string) => {
        setReceipts(receipts.filter((r) => r.receipt_id !== receiptId));
    };

    return {
        receipts,
        count: receipts.length,
        removeFromUncategorizedList,
        isLoading,
        refetch,
    };
};

export const useProcessingInboxReceipts = () => {
    const [receipts, setReceipts] = useState<IProcessingReceipt[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { receipts: processingReceiptsInStore } =
        useProcessingReceiptsStore();

    useEffect(() => {
        if (!processingReceiptsInStore) return;
        if (processingReceiptsInStore.length > 0) {
            setReceipts(processingReceiptsInStore);
            return;
        }
        refreshProcessingReceipts();
    }, [processingReceiptsInStore]);

    const refreshProcessingReceipts = async () => {
        setIsLoading(true);
        const { data: allProcessingReceipts, status } =
            await ReceiptService.getProcessingReceipts([
                ReceiptPlatform.VERYFI_EMAIL,
                ReceiptPlatform.TEXTRACT_EMAIL,
                ReceiptPlatform.DESKTOP,
                ReceiptPlatform.GALLERY,
            ]);
        setIsLoading(false);
        if (status === 'error') {
            return;
        }

        const inboxProcessingReceipts =
            (allProcessingReceipts?.filter(
                (r) =>
                    r.platform === ReceiptPlatform.VERYFI_EMAIL ||
                    r.platform === ReceiptPlatform.TEXTRACT_EMAIL ||
                    r.platform === ReceiptPlatform.GALLERY ||
                    r.platform === ReceiptPlatform.DESKTOP
            ) as IInboxReceipt[]) || [];

        setReceipts(inboxProcessingReceipts);
    };

    return { receipts, refreshProcessingReceipts, isLoading };
};
