import { lazy } from 'react';
import theme from '../../../../theme';
import logoWhite from '../../../../assets/logoWhite.svg';
import { BodyContainer } from '../styles';
import { IChargeFinderCode } from '../../../../services/plaid/types';
import './index.css';

const DownloadBadges = lazy(() => import('../../../Payment/DownloadBadges'));

const BlogPostFooter = ({
    code,
    commonCodes,
}: {
    code?: IChargeFinderCode;
    commonCodes?: IChargeFinderCode[];
}) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flexWrap: 'wrap',
                width: '100%',
                background: theme.colors.primary,
                padding: '50px 0px',
            }}
        >
            <BodyContainer>
                <img alt="" src={logoWhite} />
                <div
                    // className="section"
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        paddingTop: 20,
                    }}
                >
                    <div>
                        <div
                            style={{
                                color: 'white',
                                fontWeight: 'bold',
                                marginTop: 20,
                                marginBottom: 20,
                            }}
                        >
                            Popular codes
                        </div>
                        <div
                            style={{
                                color: 'white',
                            }}
                        >
                            {commonCodes?.map((c) => {
                                return (
                                    <div key={c.slug}>
                                        <a
                                            href={`/whats-this-charge/${code?.merchant?.slug}/${c.slug}`}
                                            style={{
                                                marginBottom: 10,
                                                textDecoration: 'none',
                                                color: 'gray',
                                                fontSize: 16,
                                            }}
                                        >
                                            {c.code}
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div style={{ width: 150, height: 50 }} />
                    <div>
                        <div
                            style={{
                                color: 'white',
                                fontWeight: 'bold',
                                marginTop: 20,
                            }}
                        >
                            Download the App
                        </div>
                        <div style={{ marginLeft: 50 }}>
                            {!process.isServer ? (
                                <DownloadBadges
                                    alternativeWebUrl="/link/chargefinder"
                                    customData={{
                                        source: 'code',
                                    }}
                                    black
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </BodyContainer>
        </div>
    );
};

export default BlogPostFooter;
