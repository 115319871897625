import AmericaDottedMap from '../../../assets/landing/america_dotted_map.svg';
import {
    ResponsiveImage,
    SectionWrapper,
} from '../../../screens/Landing/styles';

export const SectionTrustedBy = () => {
    return (
        <SectionWrapper style={{ marginTop: 60 }}>
            <ResponsiveImage small src={AmericaDottedMap} alt="" />
        </SectionWrapper>
    );
};
