import { Check, CheckCircleRounded, CheckRounded } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import { SectionWrapper } from '../../../screens/Landing/styles';
import theme from '../../../theme';
import { SectionHeader } from '../../Text';

const SinglePricingPlan = ({
    price,
    bulletPoints,
}: {
    price?: string;
    bulletPoints?: string[];
}) => {
    return (
        <SectionWrapper
            style={{
                backgroundColor: theme.colors.white,
                marginTop: 70,
            }}
        >
            <SectionHeader>Pricing</SectionHeader>
            <div style={{ height: 40 }} />
            <div
                style={{
                    width: isMobile ? '100%' : '85%',
                    padding: '25px 50px',
                    borderRadius: 10,
                    boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.04)',
                }}
            >
                <SectionHeader
                    style={{
                        textAlign: 'center',
                        marginBottom: 50,
                        fontSize: 28,
                    }}
                >
                    {price}
                </SectionHeader>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        marginBottom: 20,
                    }}
                >
                    {bulletPoints?.map((point, i) => (
                        <div
                            key={i}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                            }}
                        >
                            <CheckCircleRounded
                                color="secondary"
                                style={{
                                    marginTop: 10,
                                }}
                            />
                            <div style={{ width: 20 }} />
                            <div
                                style={{
                                    fontSize: 16,
                                }}
                            >
                                {point}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </SectionWrapper>
    );
};

export default SinglePricingPlan;
