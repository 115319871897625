import React, { createContext, useContext, useState } from 'react';
import { CircularProgress } from '@mui/material';
import theme from '../theme';
import FullScreenModal from '../components/FullScreenModal';
import { Body, BodyBold } from '../components/Text';
import { getTextWidth } from '../util/getTextWidth';
import BottomButton from '../components/Buttons/BottomButton';

type IConfirmationProps = {
    message: string;
    onConfirm: () => void;
    description?: string;
    dangerous?: boolean;
};

type IAlertProps = {
    message: string;
    description?: string;
};

const ModalText = ({ text }: { text: string }) => {
    return (
        <>
            {text.split('\n').map((t) => (
                <BodyBold
                    style={{
                        fontSize: 20,
                        color: theme.colors.blue100,
                        textAlign: 'center',
                    }}
                >
                    {t}
                </BodyBold>
            ))}
        </>
    );
};

const ModalDescription = ({ description }: { description: string }) => (
    <Body
        style={{
            color: theme.colors.blue100,
            marginTop: 10,
            fontWeight: '500',
            textAlign: 'center',
        }}
    >
        {description}
    </Body>
);

interface IFullScreenModalProps {
    setFullScreenModal: (c: JSX.Element) => void;
    setFullScreenLoader: ({ message }: { message: string }) => void;
    setFullScreenConfirmation: (confirmation: IConfirmationProps) => void;
    closeFullScreenModal: () => void;
    setFullScreenAlert: (alert: IAlertProps) => void;
}

const FullScreenModalContext = createContext<IFullScreenModalProps>({
    setFullScreenModal: () => {},
    setFullScreenLoader: () => {},
    setFullScreenConfirmation: () => {},
    closeFullScreenModal: () => {},
    setFullScreenAlert: () => {},
});

const FullScreenModalProvider = (props: { children: React.ReactNode }) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [content, setContent] = useState<JSX.Element | null>(null);
    const [loaderMessage, setLoaderMessage] = useState<string | null>(null);
    const [confirmation, setConfirmation] = useState<IConfirmationProps | null>(
        null
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [alert, setAlert] = useState<IAlertProps | null>(null);

    const textToMeasure =
        loaderMessage ??
        confirmation?.description ??
        confirmation?.message ??
        alert?.description ??
        alert?.message ??
        '';

    const renderMe = (
        <FullScreenModal
            visible={visible}
            onClose={() => setVisible(false)}
            maxWidth={
                textToMeasure
                    ? Math.min(600, getTextWidth(textToMeasure, 24)) + 40
                    : undefined
            }
        >
            {confirmation ? (
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <ModalText text={confirmation.message} />
                    {confirmation.description ? (
                        <ModalDescription
                            description={confirmation.description}
                        />
                    ) : null}
                    <div style={{ marginTop: 20 }} />
                    <BottomButton
                        text={'Yes'}
                        onClick={async () => {
                            setIsLoading(true);
                            await confirmation.onConfirm();
                            setIsLoading(false);
                            closeFullScreenModal();
                        }}
                        isLoading={isLoading}
                        style={{
                            width: 120,
                            alignSelf: 'center',
                        }}
                    />
                    <BottomButton
                        text={'No'}
                        mode="text"
                        onClick={() => {
                            closeFullScreenModal();
                        }}
                        style={{
                            width: 120,
                            alignSelf: 'center',
                        }}
                        textStyle={{ color: theme.colors.blue100 }}
                    />
                </div>
            ) : loaderMessage ? (
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <ModalText text={loaderMessage} />
                    <div style={{ marginTop: 20 }} />
                    <CircularProgress
                        style={{
                            marginRight: 5,
                            color: theme.colors.secondary,
                        }}
                        size={40}
                    />
                </div>
            ) : alert ? (
                <>
                    <ModalText text={alert.message} />
                    {alert.description ? (
                        <ModalDescription description={alert.description} />
                    ) : null}
                    <div style={{ margin: 15 }} />
                    <BottomButton
                        text={'Got it'}
                        mode="text"
                        onClick={async () => {
                            closeFullScreenModal();
                        }}
                        isLoading={isLoading}
                        disabled={isLoading}
                        style={{
                            width: 120,
                            alignSelf: 'center',
                        }}
                        textStyle={{ color: theme.colors.blue100 }}
                    />
                </>
            ) : (
                content ?? <></>
            )}
        </FullScreenModal>
    );

    const setFullScreenModal = (c: JSX.Element) => {
        setVisible(true);
        setContent(c);
    };

    const setFullScreenLoader = ({ message }: { message: string }) => {
        setVisible(true);
        setLoaderMessage(message);
    };

    const setFullScreenConfirmation = (confirmation: IConfirmationProps) => {
        setVisible(true);
        setConfirmation(confirmation);
    };

    const setFullScreenAlert = (a: IAlertProps) => {
        setVisible(true);
        setAlert(a);
    };

    const closeFullScreenModal = () => {
        setVisible(false);
        setContent(null);
        setLoaderMessage(null);
        setConfirmation(null);
        setAlert(null);
    };

    return (
        <FullScreenModalContext.Provider
            value={{
                setFullScreenAlert,
                setFullScreenModal,
                setFullScreenLoader,
                setFullScreenConfirmation,
                closeFullScreenModal,
            }}
        >
            {props.children}
            {renderMe}
        </FullScreenModalContext.Provider>
    );
};

export default FullScreenModalProvider;

export const useFullScreenModal = () => useContext(FullScreenModalContext);
