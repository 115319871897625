import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import theme from '../../theme';

export const TextInput = styled(TextField).attrs(() => ({
    variant: 'outlined',
    color: 'secondary',
    bordercolor: theme.colors.blue100,
}))``;

export const NumberInput = styled(TextField).attrs(() => ({
    inputMode: 'numeric',
    pattern: '[0-9]*',
    variant: 'outlined',
    color: 'secondary',
    bordercolor: theme.colors.blue100,
}))``;
