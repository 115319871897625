import { Typography } from '@mui/material';
import styled from 'styled-components';
import theme from '../../theme';
import { HEADER_HEIGHT } from '../../util/constants';
import Devices, { number } from '../../util/devices';

export const FeatureCalloutColumns = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${Devices.TABLET} {
        width: 45%;
    }
`;

export const FeatureCalloutContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media ${Devices.TABLET} {
        flex-direction: row;
    }
`;

export const ScanAndManageIcon = styled.div`
    display: none;
    justify-content: center;
    align-items: center;

    @media ${Devices.TABLET} {
        display: flex;
    }
`;

export const SectionContentWrapper = styled.div<{
    backgroundColor?: string;
    width?: string;
    maxWidth?: number;
}>`
    width: 90%;
    max-width: 900px;
    background-color: ${(props) => props.backgroundColor || theme.colors.white};

    @media ${Devices.TABLET} {
        width: ${(props) => props.width || '90%'};
        max-width: ${(props) => props.maxWidth || '900'}px;
    }
`;

export const SectionWrapper = styled.div<{ backgroundColor?: string }>`
    background-color: ${(props) => props.backgroundColor || theme.colors.white};
    padding: 0px;
    padding-top: 0px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const CTAContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media ${Devices.TABLET} {
        align-items: flex-start;
        justify-content: flex-start;
    }
`;

export const PageContainer = styled.div.attrs(({ style }) => ({
    style,
}))`
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    z-index: 50;
`;

export const EnterNumberContainer = styled.div`
    display: flex;
    margin: 15px -15px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media ${Devices.TABLET} {
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
    }
`;

export const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 2;
    width: 100%;
    padding-right: 0px;
    padding-top: 40px;

    @media ${Devices.TABLET} {
        min-width: 400px;
        width: 40vw;
        justify-content: center;
        align-items: flex-start;
        padding-right: 20px;
    }
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 1000px;
    @media ${Devices.TABLET} {
        flex-direction: row;
    }
`;

export const LandingBody = styled.div`
    z-index: 50;
    background-color: ${theme.colors.white};
    padding: 0px 15px;
`;

export const HeaderRightSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: none;

    @media ${Devices.TABLET} {
        display: flex;
    }
`;

export const HeaderContainer = styled.div`
    z-index: 100;
    background-color: ${theme.colors.offWhiteSolid};
    min-height: ${HEADER_HEIGHT}px;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: sticky;
    width: 100%;
    left: 0;
    top: 0;
    padding: 10px 45px;
    justify-content: center;
    @media ${Devices.TABLET_SM} {
        justify-content: space-between;
    }
`;

export const FooterContainer = styled.div.attrs(({ style }) => ({
    style,
}))`
    background-color: ${theme.colors.primary};
    min-height: 32vh;
    display: flex;
    flex: 1;
    justify-content: space-around;
    padding: 1vh 0;
    align-items: center;
    flex-direction: column;
`;

export const FooterLinksContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    max-width: 450px;
    align-items: center;
    color: white;
    margin-top: 13px;
    margin-bottom: 60px;
    text-align: center;
`;

export const FooterLinksColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 30px;
    margin-bottom: 40px;
    text-align: center;
    width: 100%;
    max-width: 600px;

    @media ${Devices.MOBILE_LG} {
        flex-direction: row;
    }
`;

export const FooterLink = styled.a`
    height: 30px;
    color: ${theme.colors.white};
`;

export const FooterBadgeContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media ${Devices.MOBILE_LG} {
        flex-direction: row;
    }
`;

export const FooterDownloadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;
`;

export const MediaContainerDesktop = styled.div`
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media ${Devices.TABLET} {
        display: flex;
    }
`;

export const MediaContainerMobile = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;

    @media ${Devices.TABLET} {
        display: none;
    }
`;

export const LandingSectionColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    @media ${Devices.TABLET} {
        padding: 0 2vw;
        align-items: flex-start;
    }
`;

export const LandingSectionContainer = styled.div`
    display: flex;
    width: 100%;
    flex: 1;
    align-items: center;
    justify-content: center;

    min-height: 60vh;
    flex-direction: column;
    margin: 5vh 0;

    @media ${Devices.TABLET} {
        min-height: 30vh;
        flex-direction: row;
    }
`;

export const PricingTiersContainer = styled.div`
    display: flex;
    width: 80%;
    flex-direction: column;
    max-width: 700px;

    @media ${Devices.TABLET_SM} {
        flex-direction: row;
        width: 95%;
    }
`;

export const PricingTierContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    background-color: ${theme.colors.white};
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
    padding-top: 20px;
    width: 100%;

    @media ${Devices.TABLET_SM} {
        width: 50%;
        margin-left: 20px;
        margin-right: 20px;
    }
`;

export const ResponsiveImage = styled.img<{ small?: boolean }>`
    width: ${number.MOBILE_SM}px;
    @media ${number.MOBILE_SM} {
        width: ${number.MOBILE_SM}px;
    }
    @media ${Devices.MOBILE_MED} {
        width: ${number.MOBILE_MED}px;
    }
    @media ${Devices.MOBILE_LG} {
        width: ${number.MOBILE_LG}px;
    }
    @media ${Devices.TABLET_SM} {
        width: ${number.TABLET_SM}px;
    }
    @media ${Devices.TABLET} {
        width: ${(props) => (props.small ? number.TABLET_SM : number.TABLET)}px;
    }
    @media ${Devices.LAPTOP} {
        width: ${(props) => (props.small ? number.TABLET_SM : number.LAPTOP)}px;
    }
`;

export const ResponsiveTextContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: center;
    max-width: 600px;
    justify-content: center;
    align-items: center;
    height: 30%;

    @media ${Devices.MOBILE_LG} {
        height: 40%;
    }
    @media ${Devices.TABLET} {
        height: 60%;
    }
    @media ${Devices.LAPTOP} {
        height: 80%;
    }
`;

export const ResponsiveText = styled.span`
    font-weight: 600;
    font-size: 30px;
    color: ${theme.colors.primary};
    @media ${Devices.MOBILE_LG} {
        font-size: 40px;
    }
    @media ${Devices.TABLET} {
        font-size: 50px;
    }
    @media ${Devices.LAPTOP} {
        font-size: 60px;
    }
`;

export const HeroTitle = styled(Typography)`
    text-align: center;
    @media ${Devices.TABLET} {
        text-align: center;
    }
`;

export const TrustStarsResponsive = styled.img`
    align-self: center;

    @media ${Devices.TABLET} {
        align-self: flex-start;
    }
`;

export const LoginButtonContainer = styled.a`
    color: ${theme.colors.white};
    text-decoration: none;
    border-radius: ${theme.roundness}px;
    background-color: ${theme.colors.primary400};
    position: absolute;
    right: 5%;
    display: none;
    cursor: pointer;
    text-decoration: none;

    @media ${Devices.TABLET_SM} {
        display: inline;
    }
`;

export const DownloadLinkWrapper = styled.a`
    align-self: center;

    @media ${Devices.TABLET} {
        align-self: flex-start;
    }
`;

export const RightSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`;
