import { AxiosResponse } from 'axios';
import axios from '../../util/axios';
import { IApiResponse } from '../upload/types';
import { ISpaceItem } from '../receipts/types';
import { dispatchAndUpdateStore } from '../../util/dispatchAndUpdateStore';
import { StoreKey } from '../../types/storeNames';
import {
    IFolderIconSuggestionResponse,
    IReceiptTagResponse,
    IReceiptTagsResponse,
} from './types';

const ORGANIZATION_STORES = [
    StoreKey.CATEGORY_GROUPS,
    StoreKey.TAGS,
    StoreKey.SPACES,
];
export default class FolderService {
    public static deleteFolder(
        folderId: string
    ): Promise<IReceiptTagsResponse> {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/tags/delete', { tag_id: folderId })
                .then((value: AxiosResponse<IReceiptTagsResponse>) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }

    public static createFolder(
        name: string,
        iconId?: string | null,
        space_id?: number | null
    ): Promise<IReceiptTagResponse> {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/tags/create', {
                    name,
                    icon_id: iconId,
                    space_id,
                })
                .then((value: AxiosResponse<IReceiptTagResponse>) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }

    public static editFolder(
        folderId: string,
        name?: string,
        iconId?: string | null
    ): Promise<IReceiptTagsResponse> {
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/tags/edit', {
                    tag_id: folderId,
                    new_name: name,
                    new_icon: iconId,
                })
                .then((value: AxiosResponse<IReceiptTagsResponse>) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }

    public static getFolderIconSuggestion(
        folderName: string
    ): Promise<IFolderIconSuggestionResponse> {
        return axios
            .post(`/receipts/get-category-icon-suggestion`, {
                folder_name: folderName,
            })
            .then((value: AxiosResponse<IFolderIconSuggestionResponse>) => {
                return value.data;
            });
    }

    public static reorderFolders(order: ISpaceItem[]): Promise<IApiResponse> {
        // This is strange but necessary: endpoint expects tag_id for groups to be the group id
        const foldersWhereGroupsAlsoHaveTagIds = order.map((folder) => {
            if (folder.type === 'group') {
                return {
                    ...folder,
                    tag_id: folder.id,
                };
            }
            return folder;
        });
        return dispatchAndUpdateStore(
            axios
                .post('/receipts/tags/reorder', {
                    order: foldersWhereGroupsAlsoHaveTagIds,
                })
                .then((value: AxiosResponse<IApiResponse>) => {
                    return value.data;
                }),
            ORGANIZATION_STORES
        );
    }
}
