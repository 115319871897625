import {
    invalidateStore,
    updateStore,
    updateStoreAndState,
} from '../providers/IndexedDBProvider';
import { StoreKey } from '../types/storeNames';

/**
 * Thunk that invalidates the stores you pass in, then
 * refreshes the cache when the request is done (does not update state by default)
 */
export const dispatchAndUpdateStore = async <T>(
    request: Promise<T>,
    stores: StoreKey[],
    options?: { shouldUpdateState?: false }
) => {
    // first, invalidate the cache
    stores.forEach((store) => invalidateStore(store));
    return request.finally(() => {
        if (options?.shouldUpdateState) {
            // refreshes cache AND updates store (affects the View)
            stores.forEach((store) => updateStoreAndState(store));
        } else {
            // refresh the cache in background (won't change the View)
            stores.forEach((store) => updateStore(store));
        }
    });
};
