import { useFolders } from '../screens/ReceiptsHome/folderContext';
import {
    ICategoryGroupWithItems,
    ISpaceItem,
    ISpaceWithItems,
    IUserTag,
} from '../services/receipts/types';
import { useSpaces } from '../services/spaces/context';
import { DEFAULT_SPACE_ID, Space } from '../services/spaces/types';

/**
 * Returns spaces with "items" property that contains folders and groups
 * groups also have "items" property that contains folders
 */
export const useSpacesWithItems = (props?: {
    folders?: IUserTag[];
    spaces?: Space[];
}): ISpaceWithItems[] => {
    const { folders: fs, categoryGroups } = useFolders();
    const { spaces: sps } = useSpaces();
    const spaces = props?.spaces ?? sps;
    const folders = props?.folders ?? fs;

    const foldersToBeGrouped: IUserTag[] = [];
    const foldersWithoutGroups: IUserTag[] = [];
    folders.forEach((folder) => {
        const categoryGroupIds = folder.category_group_ids ?? [];
        // important because with sharing folders, many people can assign a folder to a group
        const userOwnsCategoryGroupItIsAssignedTo = categoryGroupIds.some(
            (id) => categoryGroups.find((cg) => cg.id === id)
        );
        if (userOwnsCategoryGroupItIsAssignedTo) {
            foldersToBeGrouped.push(folder);
        } else {
            foldersWithoutGroups.push(folder);
        }
    });
    const categoryGroupsWithNestedFolders = foldersToBeGrouped.reduce(
        (acc: Map<number, ICategoryGroupWithItems>, curr) => {
            const categoryGroupId = curr.category_group_ids?.[0];
            if (categoryGroupId) {
                const categoryGroup = categoryGroups.find(
                    (cg) => cg.id === categoryGroupId
                );
                if (categoryGroup) {
                    const existingGroup = acc.get(categoryGroupId);
                    if (existingGroup) {
                        existingGroup.items.push(curr);
                    } else {
                        acc.set(categoryGroupId, {
                            ...categoryGroup,
                            items: [curr],
                            type: 'group',
                        });
                    }
                }
            }
            return acc;
        },
        new Map()
    );
    const groups = Array.from(categoryGroupsWithNestedFolders.values());
    const mergedFoldersAndGroups: ISpaceItem[] = [
        ...foldersWithoutGroups.map((f) => ({
            ...f,
            icon: f.icon_id,
            type: 'tag' as 'tag',
        })),
        ...groups.map((g) => ({ ...g, type: 'group' as 'group' })),
    ];
    // sort with priority for "order" property; undefined order is sorted by name
    const nonOrderedFoldersSortedByName = mergedFoldersAndGroups
        .filter(({ order }) => typeof order !== 'number')
        .sort((a, b) => a.name.localeCompare(b.name));
    const orderedFoldersSorted = mergedFoldersAndGroups
        .filter(({ order }) => typeof order === 'number')
        .sort(({ order: a }, { order: b }) => (a ?? 0) - (b ?? 0));
    const sorted = orderedFoldersSorted.concat(nonOrderedFoldersSortedByName);

    const groupedBySpace = sorted.reduce(
        (acc: Map<number | string, ISpaceWithItems>, curr: ISpaceItem) => {
            const spaceThatItemBelongsTo = spaces.find((s) => {
                switch (curr.type) {
                    case 'tag':
                        return s.categoryIds?.includes(curr.tag_id);
                    case 'group':
                        return s.categoryIds?.includes(curr.id);
                    default:
                        return false;
                }
            });
            const spaceId = spaceThatItemBelongsTo?.id ?? DEFAULT_SPACE_ID; // lets you create the "Home" space
            const existingSpace = acc.get(spaceId);
            if (existingSpace) {
                existingSpace.items.push(curr);
            } else {
                const space = spaces.find((s) => s.id === spaceId);
                if (space) {
                    acc.set(spaceId, {
                        ...space,
                        items: [curr],
                    });
                }
            }
            return acc;
        },
        new Map()
    );
    const spacesAsArray = Array.from(groupedBySpace.values()).sort((a, b) => {
        return (a.order ?? 0) - (b.order ?? 0);
    });
    return spacesAsArray;
};
