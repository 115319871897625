import { cloneElement } from 'react';
import { VariationImageElement } from '../configuration/types';

export const MAIN_VISUAL_DIMENSIONS = {
    width: 330,
    height: 500,
};

export const VariationMainVisual = ({
    props,
    isMobile,
}: {
    props: VariationImageElement;
    isMobile?: boolean;
}) => {
    if (props.type === 'ImageElementUrl') {
        return <img src={props.url} alt={props.alt} />;
    }

    if (props.type === 'ImageElementComponent') {
        return cloneElement(props.component, {
            width: !isMobile
                ? props.width ?? MAIN_VISUAL_DIMENSIONS.width
                : '100%',
            height: !isMobile
                ? props.height ?? MAIN_VISUAL_DIMENSIONS.height
                : 450,
        });
    }

    return null;
};
