import { GOOGLE_MAP_API_KEY } from '../configuration';

function getAddressFromCoordinates({
    latitude,
    longitude,
}: {
    latitude: Number;
    longitude: Number;
}) {
    return new Promise<string>((resolve, reject) => {
        fetch(
            'https://maps.googleapis.com/maps/api/geocode/json?address=' +
                latitude +
                ',' +
                longitude +
                '&key=' +
                GOOGLE_MAP_API_KEY
        )
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 'OK') {
                    resolve(responseJson?.results?.[0]?.formatted_address);
                } else {
                    reject('not found');
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export default getAddressFromCoordinates;
