import { useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import ReactPixel from 'react-facebook-pixel';
import LeafForHeader from '../../../assets/leafForHeader.svg';
import Logo from '../../../assets/logoWhite.svg';
import { onWebAppViewOrganizationTypeformSuccess } from '../../../tracking/trackers';
import Checkmark from '../../../assets/successCheck.svg';
import { useAmplitude } from '../../../providers/AmplitudeProvider';

const TypeformSuccess = () => {
    const { isInitialized } = useAmplitude();
    const isMobile = useMediaQuery('(max-width: 900px)');

    useEffect(() => {
        if (isInitialized) {
            ReactPixel.track?.('CompleteRegistration');
            onWebAppViewOrganizationTypeformSuccess();
        }
    }, [isInitialized]);

    return (
        <div
            style={{
                width: '100%',
            }}
        >
            <img
                src={LeafForHeader}
                alt=""
                width={500}
                style={{
                    position: 'absolute',
                    right: 0,
                }}
            />
            <div style={{ margin: 'auto' }}>
                <div
                    style={{
                        width: '100%',
                        height: '100vh',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: 'center',
                        backgroundColor: '#181C3F',
                        padding: '5%',
                    }}
                >
                    <div
                        style={{
                            width: isMobile ? '100%' : '50%',
                            flex: 1,
                        }}
                    >
                        <img src={Logo} alt="logo" width={200} />
                        <div style={{ marginTop: 25 }} />
                        <div
                            style={{
                                fontWeight: 'bold',
                                fontSize: '3.5rem',
                                color: 'white',
                            }}
                        >
                            Demo Scheduled!
                        </div>
                        <div
                            style={{
                                fontWeight: 'bold',
                                fontSize: '2rem',
                                color: 'white',
                                marginTop: 30,
                            }}
                        >
                            We look forward to meeting you soon.
                        </div>
                    </div>
                    <img src={Checkmark} alt="checkmark" width={200} />
                </div>
            </div>
        </div>
    );
};

export default TypeformSuccess;
