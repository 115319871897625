import { ChatBubbleOutline } from '@mui/icons-material';
import moment from 'moment';
import { useState } from 'react';
import { ButtonContainer } from '../../../../components/Buttons/BottomButton';
import { TextInput } from '../../../../components/Inputs/styles';
import { IChargeFinderCode } from '../../../../services/plaid/types';
import { onWebAppBlogPostComment } from '../../../../tracking/trackers';
import { BodyContainer } from '../styles';

const BlogPostComments = ({ code }: { code?: IChargeFinderCode }) => {
    const [commentDialog, setCommentDialog] = useState(false);

    const [comment, setComment] = useState('');

    const onSendComment = () => {
        setCommentDialog(false);
        setComment('');
        onWebAppBlogPostComment({
            code: code?.code,
            name: '',
            comment,
        });
        alert("Your comment has been sent! we'll review it shortly");
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flexWrap: 'wrap',
                width: '100%',
                background: '#d6d6d6',
                padding: '50px 0px',
            }}
        >
            <BodyContainer>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <ChatBubbleOutline color="secondary" />
                    <div style={{ width: 10 }} />
                    <h5>SimplyWise Community Comments</h5>
                </div>
                <div style={{ height: 5 }} />
                {!code?.comments?.length ? (
                    <>
                        <div
                            style={{
                                fontSize: '0.8em',
                                textAlign: 'center',
                                margin: 10,
                            }}
                        >
                            <b>Be the first to comment!</b>
                        </div>
                    </>
                ) : null}
                {code?.comments?.map((comment, index) => (
                    <div
                        key={index.toString()}
                        style={{
                            borderTop: '2px solid #E5E5E5',
                            margin: 5,
                            width: '100%',
                            padding: 20,
                            fontSize: 16,
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            <b>{comment.author}</b>
                            <div style={{ flex: 1 }} />
                            <div
                                style={{
                                    fontSize: 12,
                                }}
                            >
                                {moment(comment.updated_at).fromNow()}
                            </div>
                        </div>
                        <div>{comment.text}</div>
                    </div>
                ))}
                <div style={{ height: 20 }} />
                <ButtonContainer
                    onClick={() => {
                        setCommentDialog(!commentDialog);
                    }}
                    style={{
                        width: 200,
                        fontWeight: 'bold',
                    }}
                >
                    Write Comment
                </ButtonContainer>
                {commentDialog ? (
                    <>
                        <div style={{ height: 20 }} />
                        <TextInput
                            placeholder="Write your comment here..."
                            value={comment}
                            onChange={(e) => {
                                const value = e?.target?.value;
                                setComment(value);
                            }}
                            multiline
                            fullWidth
                        />
                        <div style={{ height: 10 }} />
                        <ButtonContainer
                            onClick={onSendComment}
                            style={{
                                width: 150,
                                fontWeight: 'bold',
                                fontSize: '0.8em',
                                alignSelf: 'flex-end',
                            }}
                        >
                            Send
                        </ButtonContainer>
                    </>
                ) : null}
            </BodyContainer>
            <div style={{ height: 20 }} />
        </div>
    );
};

export default BlogPostComments;
