import { Modal, Box } from '@mui/material';
import { number } from '../../util/devices';

type IDesktopModalProps = {
    children: JSX.Element;
    visible: boolean;
    setVisible: (visible: boolean) => void;
    onCloseExtra?: () => void;
    style?: React.CSSProperties;
};

const DesktopModal = ({
    children,
    visible,
    setVisible,
    onCloseExtra,
    style,
}: IDesktopModalProps) => {
    return (
        <Modal
            open={visible}
            onClose={() => {
                setVisible(false);
                onCloseExtra && onCloseExtra();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                style={{
                    position: 'absolute',
                    top: '10%',
                    left: '50%',
                    transform: 'translate(-50%, 0%)',
                    maxWidth: number.DESKTOP,
                    minWidth: number.MOBILE_SM,
                    backgroundColor: 'white',
                    borderRadius: 20,
                    padding: 40,
                    height: '80%',
                    overflowY: 'scroll',
                    ...style,
                }}
            >
                {children}
            </Box>
        </Modal>
    );
};

export default DesktopModal;
