import { Typography } from '@mui/material';
import theme from '../../theme';
import './style.css';
import icon from '../../assets/icon.png';
import { IDeductionReceipt } from '../../services/ereceipts/types';

const headerStyles = {
    color: theme.colors.blue100,
    fontWeight: 700,
    fontSize: 20,
};

const bodyStyles = {
    color: theme.colors.blue80,
    fontWeight: 500,
    fontSize: 16,
    marginTop: 5,
};

const LOGO_SIZE = 80;

const ReceiptRow = ({ receipt }: { receipt: IDeductionReceipt }) => (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: 30,
            borderRadius: 8,
            borderColor: theme.colors.blue10,
            borderWidth: 1,
            borderStyle: 'solid',
            alignItems: 'flex-start',
            justifyContent: 'center',
            padding: 10,
        }}
    >
        <div
            style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginLeft: 10,
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    width: LOGO_SIZE,
                    height: LOGO_SIZE,
                    marginRight: 20,
                    overflow: 'hidden',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    borderRadius: 8,
                }}
            >
                <img
                    src={receipt.logo || icon}
                    alt=""
                    style={{
                        maxWidth: LOGO_SIZE,
                        maxHeight: LOGO_SIZE,
                        opacity: receipt.logo ? 1 : 0.3,
                    }}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography style={headerStyles}>{receipt.merchant}</Typography>
                <Typography
                    style={{
                        ...headerStyles,
                        color: theme.colors.secondary,
                    }}
                >
                    {receipt.deductible_amount > 0
                        ? `$${receipt.deductible_amount.toFixed(2)}`
                        : `$${receipt.amount.toFixed(2)}`}
                </Typography>
            </div>
        </div>
        <div style={{ margin: 10 }}>
            {receipt.deductible_amount > 0 ? (
                <div>
                    <Typography style={headerStyles}>{`Category:`}</Typography>
                    <Typography style={bodyStyles}>
                        {` ${receipt.deductible_category}`}
                    </Typography>
                </div>
            ) : null}
            <div style={{ marginTop: 10 }}>
                <Typography style={headerStyles}>{`Explanation:`}</Typography>
                <Typography style={bodyStyles}>
                    {`${receipt.deductible_explanation}`}
                </Typography>
            </div>
        </div>
    </div>
);

export default ReceiptRow;
