import {
    SubscriptionInterval,
    SubscriptionPlatform,
    SubscriptionCohort,
    SubscriptionGroup,
    IProduct,
    SubscriptionTiers,
} from './types';

export const STRIPE_PRICE_ID_BASE = 'price_1LBJ2VJPcTvRC1gjXW6k6vnd';
export const STRIPE_PRICE_ID_PRO = 'price_1LBJ4dJPcTvRC1gj659g2cyO';
export const STRIPE_PRICE_ORGANIZATION_SEAT_70_YEARLY =
    'subscription_organization_seat_70_yearly_platform_stripe';
export const STRIPE_PRICE_INHERITENCE_SEAT_70_YEARLY =
    'subscription_inheritence_seat_70_yearly_platform_stripe';
export const STRIPE_PRICE_INHERITENCE_SEAT_90_YEARLY =
    'subscription_inheritence_seat_90_yearly_platform_stripe';
export const PAYPAL_PRICE_ID_BASE = 'P-0J080700BK064932UMLUW5SY';
export const PAYPAL_PRICE_ID_PRO = 'P-1X147514HV076345AMLUXATY';

// Note: Only one product per (platform, cohort, interval, tier) combination must be available, otherwise the other product should belong to another cohort (We can add automated tests for this)

const APP_24_60_PRODUCTS = {
    simplywise_subscription_fixed_9: {
        id: 'simplywise_subscription_fixed_9',
        tier: 'base',
        name: 'SimplyWise Personal',
        value: 23.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: SubscriptionInterval.YEAR,
        platforms: [SubscriptionPlatform.IOS, SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_24_60],
        groups: [SubscriptionGroup.APP],
    },
    simplywise_subscription_fixed_10: {
        id: 'simplywise_subscription_fixed_10',
        tier: 'pro',
        name: 'SimplyWise Business',
        value: 59.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.IOS, SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_24_60],
        groups: [SubscriptionGroup.APP],
    },

    [STRIPE_PRICE_ID_BASE]: {
        id: STRIPE_PRICE_ID_BASE,
        tier: 'pro',
        name: 'SimplyWise Personal',
        value: 23.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.STRIPE],
        cohorts: [SubscriptionCohort.APP_24_60],
        groups: [SubscriptionGroup.APP],
    },
    [STRIPE_PRICE_ID_PRO]: {
        id: STRIPE_PRICE_ID_PRO,
        tier: 'pro',
        name: 'SimplyWise Personal',
        value: 59.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.STRIPE],
        cohorts: [SubscriptionCohort.APP_24_60],
        groups: [SubscriptionGroup.APP],
    },

    [PAYPAL_PRICE_ID_BASE]: {
        id: PAYPAL_PRICE_ID_BASE,
        tier: 'pro',
        name: 'SimplyWise Personal',
        value: 23.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.PAYPAL],
        cohorts: [SubscriptionCohort.APP_24_60],
        groups: [SubscriptionGroup.APP],
    },
    [PAYPAL_PRICE_ID_PRO]: {
        id: PAYPAL_PRICE_ID_PRO,
        tier: 'pro',
        name: 'SimplyWise Personal',
        value: 23.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.PAYPAL],
        cohorts: [SubscriptionCohort.APP_24_60],
        groups: [SubscriptionGroup.APP],
    },
};

const APP_30_90_PRODUCTS = {
    'subscription_base_price_30_group_30and90': {
        id: 'subscription_base_price_30_group_30and90',
        tier: 'base',
        name: 'SimplyWise Personal',
        value: 29.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.IOS, SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP],
    },
    'subscription_pro_price_90_group_30and90': {
        id: 'subscription_pro_price_90_group_30and90',
        tier: 'pro',
        name: 'SimplyWise Business',
        value: 89.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.IOS, SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP],
    },
    'subscription_premium_price_120_group_30and90': {
        id: 'subscription_premium_price_120_group_30and90',
        tier: 'premium',
        name: 'SimplyWise Business Premium',
        value: 119.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.IOS, SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP, SubscriptionGroup.QUICKBOOKS],
    },

    'subscription_base_price_1_group_30and90_term_weekly': {
        id: 'subscription_base_price_1_group_30and90_term_weekly',
        tier: 'base',
        name: 'SimplyWise Personal',
        value: 0.99,
        currency: 'USD',
        interval: SubscriptionInterval.WEEK,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP],
    },
    'subscription_base_1_30and90_weekly': {
        id: 'subscription_base_1_30and90_weekly',
        tier: 'base',
        name: 'SimplyWise Personal',
        value: 0.99,
        currency: 'USD',
        interval: SubscriptionInterval.WEEK,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.ANDROID],
        cohorts: [
            SubscriptionCohort.APP_30_90,
            SubscriptionCohort.APP_30_90_1_4,
            SubscriptionCohort.APP_30_90_1_5,
        ],
        groups: [SubscriptionGroup.APP],
    },
    'subscription_pro_price_3_group_30and90_term_weekly': {
        id: 'subscription_pro_price_3_group_30and90_term_weekly',
        tier: 'pro',
        name: 'SimplyWise Business',
        value: 2.99,
        currency: 'USD',
        interval: SubscriptionInterval.WEEK,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP],
    },
    'subscription_pro_3_30and90_weekly': {
        id: 'subscription_pro_3_30and90_weekly',
        tier: 'pro',
        name: 'SimplyWise Business',
        value: 2.99,
        currency: 'USD',
        interval: SubscriptionInterval.WEEK,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP],
    },
    'subscription_pro_price_4_group_30and90_term_weekly': {
        id: 'subscription_pro_price_4_group_30and90_term_weekly',
        tier: 'pro',
        name: 'SimplyWise Business',
        value: 3.99,
        currency: 'USD',
        interval: SubscriptionInterval.WEEK,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP],
    },
    'subscription_pro_4_30and90_weekly': {
        id: 'subscription_pro_4_30and90_weekly',
        tier: 'pro',
        name: 'SimplyWise Business',
        value: 3.99,
        currency: 'USD',
        interval: SubscriptionInterval.WEEK,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_30_90_1_4],
        groups: [SubscriptionGroup.APP],
    },
    'subscription_pro_5_30and90_weekly': {
        id: 'subscription_pro_5_30and90_weekly',
        tier: 'pro',
        name: 'SimplyWise Business',
        value: 4.99,
        currency: 'USD',
        interval: SubscriptionInterval.WEEK,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_30_90_1_5],
        groups: [SubscriptionGroup.APP],
    },

    'freemium_group_30and90': {
        id: 'freemium_group_30and90',
        tier: 'free',
        name: 'SimplyWise Free',
        value: 0,
        currency: undefined,
        interval: undefined,
        free_trial_period: undefined,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS, SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
    },
    'freemium_group_30and90_cohort_limited-scans': {
        id: 'freemium_group_30and90_cohort_limited-scans',
        tier: 'free',
        name: 'SimplyWise Free',
        value: 0,
        currency: undefined,
        interval: undefined,
        free_trial_period: undefined,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS, SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
    },
    'freemium_group_30and90_cohort_limited-ocr': {
        id: 'freemium_group_30and90_cohort_limited-ocr',
        tier: 'free',
        name: 'SimplyWise Free',
        value: 0,
        currency: undefined,
        interval: undefined,
        free_trial_period: undefined,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS, SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
    },
};

const APP_30_60_PRODUCTS = {
    'subscription_base_price_30_group_30and60': {
        id: 'subscription_base_price_30_group_30and60',
        tier: 'base',
        name: 'SimplyWise Personal',
        value: 29.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_30_60],
        groups: [SubscriptionGroup.APP],
    },
    'subscription_pro_price_60_group_30and60': {
        id: 'subscription_pro_price_60_group_30and60',
        tier: 'pro',
        name: 'SimplyWise Business',
        value: 59.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_30_60],
        groups: [SubscriptionGroup.APP],
    },

    'freemium_group_30and60': {
        id: 'freemium_group_30and60',
        tier: 'free',
        name: 'SimplyWise Free',
        value: 0,
        currency: undefined,
        interval: undefined,
        free_trial_period: undefined,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_30_60],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
    },
    'freemium_group_30and60_cohort_limited-scans': {
        id: 'freemium_group_30and60_cohort_limited-scans',
        tier: 'free',
        name: 'SimplyWise Free',
        value: 0,
        currency: undefined,
        interval: undefined,
        free_trial_period: undefined,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_30_60],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
    },
    'freemium_group_30and60_cohort_limited-storage': {
        id: 'freemium_group_30and60_cohort_limited-storage',
        tier: 'free',
        name: 'SimplyWise Free',
        value: 0,
        currency: undefined,
        interval: undefined,
        free_trial_period: undefined,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_30_60],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
        quota: {
            receipts: {
                initial: 250,
                max: undefined,
                method: 'events-metadata-calculated',
            },
        },
    },
    'freemium_group_30and90_cohort_limited-storage-organization': {
        id: 'freemium_group_30and90_cohort_limited-storage-organization',
        tier: 'pro',
        name: 'SimplyWise Free',
        value: 0,
        currency: undefined,
        interval: undefined,
        free_trial_period: undefined,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_30_60],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
        quota: {
            receipts: {
                initial: 100,
                max: undefined,
                method: 'events-metadata-calculated',
            },
        },
    },
    'freemium_group_30and60_cohort_limited-ocr': {
        id: 'freemium_group_30and60_cohort_limited-ocr',
        tier: 'free',
        name: 'SimplyWise Free',
        value: 0,
        currency: undefined,
        interval: undefined,
        free_trial_period: undefined,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_30_60],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
    },
};

const APP_24_90_PRODUCTS = {
    'subscription_base_price_24_group_24and90': {
        id: 'subscription_base_price_24_group_24and90',
        tier: 'base',
        name: 'SimplyWise Personal',
        value: 23.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_24_90],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
    },
    'subscription_pro_price_90_group_24and90': {
        id: 'subscription_pro_price_90_group_24and90',
        tier: 'pro',
        name: 'SimplyWise Business',
        value: 89.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: 'Days',
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_24_90],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
    },

    'freemium_group_24and90': {
        id: 'freemium_group_24and90',
        tier: 'free',
        name: 'SimplyWise Free',
        value: 0,
        currency: undefined,
        interval: undefined,
        free_trial_period: undefined,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_24_90],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
    },
    'freemium_group_24and9_cohort_limited-scans': {
        id: 'freemium_group_24and90_cohort_limited-scans',
        tier: 'free',
        name: 'SimplyWise Free',
        value: 0,
        currency: undefined,
        interval: undefined,
        free_trial_period: undefined,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_24_90],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
    },
    'freemium_group_24and90_cohort_limited-ocr': {
        id: 'freemium_group_24and90_cohort_limited-ocr',
        tier: 'free',
        name: 'SimplyWise Free',
        value: 0,
        currency: undefined,
        interval: undefined,
        free_trial_period: undefined,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_24_90],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
    },
};

const QUICKBOOKS_PRODUCTS = {
    plugin_quickbooks_grandfathered: {
        id: 'plugin_quickbooks_grandfathered',
        tier: 'base',
        name: 'QuickBooks Sync',
        value: 0,
        currency: undefined,
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 0,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS, SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.QB_50],
        groups: [SubscriptionGroup.QUICKBOOKS],
        grandfathered: true,
        unpurchasable: true,
    },

    plugin_quickbooks_price_50_group_50_term_annually: {
        id: 'plugin_quickbooks_price_50_group_50_term_annually',
        tier: 'base',
        name: 'QuickBooks Sync',
        value: 49.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 0,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.QB_50],
        groups: [SubscriptionGroup.QUICKBOOKS],
    },
    plugin_quickbooks_50_50_annually: {
        id: 'plugin_quickbooks_50_50_annually',
        tier: 'base',
        name: 'QuickBooks Sync',
        value: 49.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 0,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.QB_50],
        groups: [SubscriptionGroup.QUICKBOOKS],
    },
    plugin_quickbooks_price_100_group_100_term_annually: {
        id: 'plugin_quickbooks_price_100_group_100_term_annually',
        tier: 'base',
        name: 'QuickBooks Sync',
        value: 99.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 0,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.QB_100],
        groups: [SubscriptionGroup.QUICKBOOKS],
    },
    plugin_quickbooks_100_100_annually: {
        id: 'plugin_quickbooks_100_100_annually',
        tier: 'base',
        name: 'QuickBooks Sync',
        value: 99.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 0,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.QB_100],
        groups: [SubscriptionGroup.QUICKBOOKS],
    },
};

const ORGANIZATION_PRODUCTS: Record<string, IProduct> = {
    [STRIPE_PRICE_ORGANIZATION_SEAT_70_YEARLY]: {
        id: STRIPE_PRICE_ORGANIZATION_SEAT_70_YEARLY,
        organization: true,
        tier: 'pro',
        name: 'SimplyWise Organization',
        value: 69.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: undefined,
        async: true,
        platforms: [SubscriptionPlatform.STRIPE],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP, SubscriptionGroup.QUICKBOOKS],
        quota: {
            seats: {
                method: 'events-metadata-calculated',
                key: 'quantity',
            },
        },
        minimum_quantity: 5,
    },
    [STRIPE_PRICE_INHERITENCE_SEAT_70_YEARLY]: {
        id: STRIPE_PRICE_INHERITENCE_SEAT_70_YEARLY,
        organization: true,
        tier: 'pro',
        name: 'SimplyWise Organization',
        value: 69.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: undefined,
        async: true,
        inheritence: true,
        platforms: [SubscriptionPlatform.STRIPE],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP, SubscriptionGroup.QUICKBOOKS],
        quota: {
            seats: {
                method: 'events-metadata-calculated',
                key: 'quantity',
            },
        },
        minimum_quantity: 3,
    },
    [STRIPE_PRICE_INHERITENCE_SEAT_90_YEARLY]: {
        id: STRIPE_PRICE_INHERITENCE_SEAT_90_YEARLY,
        organization: true,
        tier: 'pro',
        name: 'SimplyWise Organization',
        value: 89.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: undefined,
        async: true,
        inheritence: true,
        platforms: [SubscriptionPlatform.STRIPE],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP, SubscriptionGroup.QUICKBOOKS],
        quota: {
            seats: {
                method: 'events-metadata-calculated',
                key: 'quantity',
            },
        },
        minimum_quantity: 1,
    },
    subscription_pro_price_90_team_2_group_30and90: {
        id: 'subscription_pro_price_90_team_2_group_30and90',
        organization: true,
        tier: 'pro',
        name: 'SimplyWise Teams',
        value: 179.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: undefined,
        async: true,
        inheritence: true,
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP, SubscriptionGroup.QUICKBOOKS],
        quota: {
            seats: {
                method: 'static',
                value: 2,
            },
        },
    },
    subscription_pro_price_90_team_3_group_30and90: {
        id: 'subscription_pro_price_90_team_3_group_30and90',
        organization: true,
        tier: 'pro',
        name: 'SimplyWise Teams',
        value: 269.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: undefined,
        async: true,
        inheritence: true,
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP, SubscriptionGroup.QUICKBOOKS],
        quota: {
            seats: {
                method: 'static',
                value: 3,
            },
        },
    },
    subscription_pro_price_90_team_4_group_30and90: {
        id: 'subscription_pro_price_90_team_4_group_30and90',
        organization: true,
        tier: 'pro',
        name: 'SimplyWise Teams',
        value: 359.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: undefined,
        async: true,
        inheritence: true,
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP, SubscriptionGroup.QUICKBOOKS],
        quota: {
            seats: {
                method: 'static',
                value: 4,
            },
        },
    },
    subscription_pro_price_90_team_5_group_30and90: {
        id: 'subscription_pro_price_90_team_5_group_30and90',
        organization: true,
        tier: 'pro',
        name: 'SimplyWise Teams',
        value: 449.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: undefined,
        async: true,
        inheritence: true,
        platforms: [SubscriptionPlatform.IOS],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP, SubscriptionGroup.QUICKBOOKS],
        quota: {
            seats: {
                method: 'static',
                value: 5,
            },
        },
    },
    subscription_pro_90_team_2_30and90: {
        id: 'subscription_pro_90_team_2_30and90',
        organization: true,
        tier: 'pro',
        name: 'SimplyWise Teams',
        value: 179.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: undefined,
        async: true,
        inheritence: true,
        platforms: [SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP, SubscriptionGroup.QUICKBOOKS],
        quota: {
            seats: {
                method: 'static',
                value: 2,
            },
        },
    },
    subscription_pro_90_team_3_30and90: {
        id: 'subscription_pro_90_team_3_30and90',
        organization: true,
        tier: 'pro',
        name: 'SimplyWise Teams',
        value: 269.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: undefined,
        async: true,
        inheritence: true,
        platforms: [SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP, SubscriptionGroup.QUICKBOOKS],
        quota: {
            seats: {
                method: 'static',
                value: 3,
            },
        },
    },
    subscription_pro_90_team_4_30and90: {
        id: 'subscription_pro_90_team_4_30and90',
        organization: true,
        tier: 'pro',
        name: 'SimplyWise Teams',
        value: 359.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: undefined,
        async: true,
        inheritence: true,
        platforms: [SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP, SubscriptionGroup.QUICKBOOKS],
        quota: {
            seats: {
                method: 'static',
                value: 4,
            },
        },
    },
    subscription_pro_90_team_5_30and90: {
        id: 'subscription_pro_90_team_5_30and90',
        organization: true,
        tier: 'pro',
        name: 'SimplyWise Teams',
        value: 449.99,
        currency: 'USD',
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 7,
        free_trial_period_unit: undefined,
        async: true,
        inheritence: true,
        platforms: [SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_30_90],
        groups: [SubscriptionGroup.APP, SubscriptionGroup.QUICKBOOKS],
        quota: {
            seats: {
                method: 'static',
                value: 5,
            },
        },
    },
};

const SHARED_PRODUCTS = {
    'grandfathered': {
        id: 'grandfathered',
        tier: 'pro',
        name: 'SimplyWise Pro',
        value: 0,
        currency: undefined,
        interval: SubscriptionInterval.YEAR,
        free_trial_period: 0,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS, SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_24_60],
        groups: [SubscriptionGroup.APP],
        grandfathered: true,
        unpurchasable: true,
    },

    'freemium': {
        id: 'freemium',
        tier: 'free',
        name: 'SimplyWise Free',
        value: 0,
        currency: undefined,
        interval: undefined,
        free_trial_period: undefined,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS, SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_24_60],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
    },
    'freemium_cohort_limited-scans': {
        id: 'freemium_cohort_limited-scans',
        tier: 'free',
        name: 'SimplyWise Free',
        value: 0,
        currency: undefined,
        interval: undefined,
        free_trial_period: undefined,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS, SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_24_60],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
    },
    'freemium_cohort_limited-ocr': {
        id: 'freemium_cohort_limited-ocr',
        tier: 'free',
        name: 'SimplyWise Free',
        value: 0,
        currency: undefined,
        interval: undefined,
        free_trial_period: undefined,
        free_trial_period_unit: undefined,
        platforms: [SubscriptionPlatform.IOS, SubscriptionPlatform.ANDROID],
        cohorts: [SubscriptionCohort.APP_24_60],
        groups: [SubscriptionGroup.APP],
        unpurchasable: true,
    },
};

export const PRODUCTS: Record<string, IProduct> = {
    ...APP_24_60_PRODUCTS,
    ...APP_30_90_PRODUCTS,
    ...APP_30_60_PRODUCTS,
    ...APP_24_90_PRODUCTS,
    ...QUICKBOOKS_PRODUCTS,
    ...ORGANIZATION_PRODUCTS,
    ...SHARED_PRODUCTS,
};

const DEFUALT_COHORTS: Record<string, SubscriptionCohort> = {
    [SubscriptionGroup.APP]: SubscriptionCohort.APP_30_90,
    [SubscriptionGroup.QUICKBOOKS]: SubscriptionCohort.QB_100,
};

// Returns grouped products by tier by filtering group, cohort and interval
export const getProducts = <TierType extends SubscriptionTiers>(
    group: SubscriptionGroup, // Note: back-end is set-up for array of groups, in case of bundles of modules, we can query for products in an array
    cohort: SubscriptionCohort,
    interval: SubscriptionInterval | undefined,
    seats?: number
): Record<TierType, IProduct[]> => {
    let products: IProduct[] = [];
    products = Object.values(PRODUCTS).filter((product) => {
        return (
            product.cohorts.includes(cohort) &&
            product.groups.includes(group) &&
            product.interval === interval &&
            !product.grandfathered
        );
    });

    if (seats && seats > 1) {
        const seatProducts = products.filter((product) => {
            return product?.quota?.seats?.value === seats;
        });
        if (seatProducts?.length) products = seatProducts;
    }

    // Fallback for once we add a new cohort not supported on a platform
    // Note: with getting the products from the server this will not be required
    const defaultGroupCohort = DEFUALT_COHORTS?.[group];
    if (products.length === 0 && defaultGroupCohort) {
        products = Object.values(PRODUCTS).filter((product) => {
            return (
                product.cohorts.includes(defaultGroupCohort) &&
                product.groups.includes(group) &&
                product.interval === interval &&
                !product.grandfathered
            );
        });
    }

    const groupedProducts = products.reduce((acc: any, product) => {
        if (!acc[product?.tier]) {
            acc[product?.tier] = [];
        }

        acc[product?.tier].push(product);

        return acc;
    }, {});

    return groupedProducts;
};
