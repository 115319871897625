import { ArrowBackIos } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const BackButton = ({
    onClick,
    style,
}: {
    onClick: () => void;
    style?: any;
}) => (
    <IconButton
        style={{
            display: 'flex',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
            width: 30,
            height: 30,
            ...style,
        }}
        onClick={onClick}
    >
        <ArrowBackIos style={{ marginLeft: 10, width: 20, height: 20 }} />
    </IconButton>
);

export default BackButton;
