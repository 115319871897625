import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import './index.css';
// @ts-ignore
import { useExperiment } from '@marvelapp/react-ab-test';
import { CircularProgress } from '@mui/material';
import { useServerState } from '../../../providers/ServerStateProvider';
import PlaidService from '../../../services/plaid';
import {
    IChargeFinderCode,
    IChargeFinderMerchant,
} from '../../../services/plaid/types';
import { OnWebAppChargeFinderLoad } from '../../../tracking/trackers';
import ChargeFinderHeader from './ChargeFinderHeader';
import ChargeFinderVariants from './Components/ChargeFinderVariants';
import CodeScript from './Components/CodeScript';
import { PageContainer } from './styles';

export type IChargeFinderCodeData = {
    merchant: IChargeFinderMerchant | undefined;
    codes: IChargeFinderCode | undefined;
    merchantCommonCodes: IChargeFinderCode[] | undefined;
    variant?: string | undefined;
};

const ChargeFinderMerchantCode = () => {
    // only dev needs this
    const { merchant: urlMerchant, code: urlCode } = useParams<{
        merchant: string;
        code: string;
    }>();
    const merchant = urlMerchant;
    const code = urlCode;

    const serverState = useServerState() as IChargeFinderCodeData;

    const [data, setData] = useState<IChargeFinderCodeData | undefined>(
        serverState
    );

    const expName = ChargeFinderVariants.name;
    const { selectVariant } = useExperiment(expName);
    const variant = selectVariant(ChargeFinderVariants.variants);

    useEffect(() => {
        if (!data?.merchant || !data?.codes) {
            PlaidService.findChargeContent(undefined, undefined, merchant, code)
                .then((data) => {
                    const codes = data?.data ?? [];
                    const code = codes[0];
                    PlaidService.getMerchants(undefined, merchant).then(
                        (res2) => {
                            setData({
                                merchant: res2?.data?.[0],
                                codes: code,
                                merchantCommonCodes: codes.slice(0, 5),
                            });
                        }
                    );
                })
                .catch((err) => {
                    console.log('Error finding charge content', err);
                });
        }
        OnWebAppChargeFinderLoad({
            experimentName: expName,
            variantName: variant,
        });
    }, []);

    if (!data)
        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        padding: '80px',
                    }}
                >
                    <CircularProgress />
                </div>
            </>
        );

    return (
        <PageContainer>
            <Helmet>
                <title>{`Is ${data?.codes?.code} on my credit card statement fraud? | SimplyWise`}</title>
                <meta
                    name="description"
                    content={`Worried about ${data?.codes?.code} on your credit card? SimplyWise tells how to tell if this is fraud | SimplyWise`}
                />
                <link
                    rel="canonical"
                    href={`https://www.simplywise.com/whats-this-charge/${merchant}/${code}`}
                />
            </Helmet>
            <ChargeFinderHeader code={data?.codes?.code} />
            <CodeScript data={data} variant={variant} />
        </PageContainer>
    );
};

export default ChargeFinderMerchantCode;
