import './index.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { CircularProgress } from '@mui/material';
import PlaidService from '../../../services/plaid';
import {
    IChargeFinderCode,
    IChargeFinderMerchant,
} from '../../../services/plaid/types';
import { useServerState } from '../../../providers/ServerStateProvider';
import { PageContainer } from './styles';
import MerchantScript from './Components/MerchantScript';
import ChargeFinderHeader from './ChargeFinderHeader';

export type IChargeFinderMerchantData = {
    merchant: IChargeFinderMerchant | undefined;
    codes: IChargeFinderCode[] | undefined;
};

const ChargeFinderMerchant = () => {
    // only dev needs this
    const { merchant: urlMerchant } = useParams<{ merchant: string }>();
    const merchant = urlMerchant;

    const serverState = useServerState() as IChargeFinderMerchantData;

    const [data, setData] = useState<IChargeFinderMerchantData | undefined>(
        serverState
    );

    // for dev only (won't fire in prod)
    useEffect(() => {
        if (!data?.merchant) {
            PlaidService.findChargeContent(
                undefined,
                undefined,
                merchant,
                undefined
            )
                .then((res) => {
                    const codes = res?.data ?? [];
                    PlaidService.getMerchants(undefined, merchant).then(
                        (res2) => {
                            setData({ merchant: res2?.data?.[0], codes });
                        }
                    );
                })
                .catch((err) => {
                    console.log('Error finding charge content', err);
                });
        }
    }, []);

    if (!data?.merchant)
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </div>
        );

    return (
        <PageContainer>
            <Helmet>
                <title>{`What is this charge on my credit card? ${data?.merchant?.merchant} on credit card statements | SimplyWise`}</title>
                <meta
                    name="description"
                    content={`SimplyWise tells how to tell if charges from ${data?.merchant?.merchant} on your credit card statement are legitimate or fraud | SimplyWise`}
                />
                <link
                    rel="canonical"
                    href={`https://www.simplywise.com/whats-this-charge/${merchant}`}
                />
            </Helmet>
            <ChargeFinderHeader />
            <MerchantScript data={data} />
        </PageContainer>
    );
};

export default ChargeFinderMerchant;
