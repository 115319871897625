import React, {
    PropsWithChildren,
    createContext,
    useContext,
    useMemo,
    useState,
} from 'react';
import { Close } from '@mui/icons-material';
import _ from 'lodash';
import { useFolders } from '../../screens/ReceiptsHome/folderContext';
import ShareService from '../share/service';
import { IUserTag } from '../receipts/types';
import { useSpaces } from '../spaces/context';
import { ILink } from '../link/types';
import {
    onWebAppOrganizationSharedFolder,
    onWebAppSendSharedFolderInvite,
    onWebAppSendSharedFolderSMS,
} from '../../tracking/trackers';
import FoldersDemo from '../../assets/animations/svGator/FoldersDemo';
import { IStandardResponse } from '../../types/API';
import { useDeepCompareEffect } from '../../util/hooks';
import ReceiptService from '../receipts/service';
import { useAuth } from '../../providers/AuthProvider';
import ModalLarge from '../../components/ModalLarge';
import BottomButton from '../../components/Buttons/BottomButton';
import { useSnackbar } from '../../providers/SnackbarProvider';
import { IAccountMember, IMemberAccessResponse } from './types';

export interface ISharingListState {
    members: IAccountMember[];
    incomingMembers: IAccountMember[];
    outgoingMembers: IAccountMember[];
    getMembers: () => void;
    setMemberAccess: (
        member: IAccountMember,
        shareType?: 'email' | 'sms' | 'native' | 'none',
        source?: string
    ) => Promise<
        | {
              didShare: boolean;
              shareToken?: ILink;
          }
        | undefined
    >;
    resendInvite: (member: IAccountMember) => Promise<IMemberAccessResponse>;
    acceptInvite: (member: IAccountMember) => Promise<IStandardResponse>;
    getFolderShareInfo: (category: IUserTag) => IAccountMember[];
}

const SharingListContext = createContext<ISharingListState>({
    members: [],
    incomingMembers: [],
    outgoingMembers: [],
    getMembers: () => {},
    setMemberAccess: () =>
        new Promise<{
            didShare: boolean;
            shareToken?: ILink;
        }>(() => {}),
    resendInvite: () => new Promise<IMemberAccessResponse>(() => {}),
    acceptInvite: () => new Promise<IStandardResponse>(() => {}),
    getFolderShareInfo: () => [],
});

export const SharingListProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const { token, user } = useAuth();
    const { folders, refresh } = useFolders();
    const { refreshSpaces } = useSpaces();
    const { setSnackbar } = useSnackbar();

    const [members, setMembers] = useState<IAccountMember[]>([]);

    const [inviteModal, setInviteModal] = useState<{
        open: boolean;
        token: string;
        name: string;
    }>({
        open: false,
        token: '',
        name: '',
    });

    const getMembers = async () => {
        if (!token) return;

        // NOTE: We can add a loading state for sharing list to not get it on each app open
        // we should take care of the loading state in all the screens so user can't take action without it
        // if (!isInitialLoad.current) setIsMemberLoading(true);
        ShareService.getMembers(token).then((membersResponse) => {
            const responseMembers = (membersResponse?.data?.members || [])?.map(
                (m) => ({
                    ...m,
                    user: {
                        ...m.user,
                        first_name: m?.user?.first_name || 'No name',
                    },
                })
            );
            const sortedMembers = _.sortBy(responseMembers, [
                (m) => m?.user?.first_name?.toLowerCase(),
            ]);
            setMembers(sortedMembers);
        });
    };

    useDeepCompareEffect(() => {
        getMembers();
    }, [folders, user]);

    const setMemberAccess = async (
        member: IAccountMember,
        shareType?: 'email' | 'sms' | 'native' | 'none',
        source?: string
    ): Promise<
        | {
              didShare: boolean;
              shareToken?: ILink;
          }
        | undefined
    > => {
        try {
            const shareResponse = await ShareService.setMemberAccess(
                token!,
                member
            );
            refresh();
            refreshSpaces();

            const shareToken = shareResponse?.data?.token;
            onWebAppSendSharedFolderInvite({
                type: 'link',
                source: source || 'unknown',
                share_token: shareToken?.id,
                categoryIds: member?.tags?.map((t) => t?.tag?.tag_id) || [],
                update: Boolean(shareToken?.id),
            });

            if (shareToken) {
                if (user?.subscription_data?.product?.organization) {
                    onWebAppOrganizationSharedFolder({
                        type: 'native',
                        source: source || 'unknown',
                        share_token: shareToken?.id,
                        num_of_categories: member?.tags?.length,
                        update: Boolean(shareToken?.id),
                    });
                }

                if (shareType === 'none') {
                } else {
                    try {
                        onWebAppSendSharedFolderSMS({
                            type: 'native',
                            source: source || 'unknown',
                            share_token: shareToken?.id,
                            num_of_categories: member?.tags?.length,
                            update: Boolean(shareToken?.id),
                        });
                        setSnackbar({
                            message: 'Invited successfully',
                            status: 'success',
                        });
                        setInviteModal({
                            open: true,
                            token: shareToken?.id || '',
                            name: member?.user?.first_name || '',
                        });
                        return {
                            didShare: true,
                            shareToken,
                        };
                    } catch (error) {
                        console.error('Failed to share', error);
                        setSnackbar({
                            message: 'Failed to share',
                            status: 'error',
                        });
                        return {
                            didShare: false,
                            shareToken,
                        };
                    }
                }

                if (shareResponse?.data?.email) {
                    setSnackbar({
                        message: 'Email sent successfully',
                        status: 'success',
                    });
                    onWebAppSendSharedFolderSMS({
                        type: 'native',
                        source: source || 'unknown',
                        share_token: shareToken?.id,
                        num_of_categories: member?.tags?.length,
                        update: Boolean(shareToken?.id),
                    });
                    return {
                        didShare: true,
                        shareToken,
                    };
                }
            }

            return {
                didShare: false,
                shareToken,
            };
        } catch (error) {
            console.error('Failed to share', error);
            setSnackbar({
                message: 'Failed to share',
                status: 'error',
            });
            return {
                didShare: false,
            };
        }
    };

    const resendInvite = async (member: IAccountMember) => {
        onWebAppSendSharedFolderSMS({
            type: 'native',
            num_of_categories: member?.tags?.length,
            resend: true,
        });

        const resendResponse = await ShareService.resendMemberTokenEmail(
            token!,
            member?.user?.id!
        );

        setInviteModal({
            open: true,
            token: resendResponse?.data?.token?.id || '',
            name: member?.user?.first_name || '',
        });

        return resendResponse;
    };

    const acceptInvite = async (member: IAccountMember) => {
        const acceptResponse = await ReceiptService.acceptShareToken(
            member?.user?.id as unknown as string
        );
        refresh();
        refreshSpaces();
        return acceptResponse;
    };

    const getFolderShareInfo = (category: IUserTag) => {
        let _members: IAccountMember[] = [];
        // Incoming shared user of the folder from back-end
        _members = _members?.concat(
            category?.shared_users?.map((u) => ({
                user: u,
                tags: [{ type: 'incoming', tag: category }],
                status: 'active',
            })) || []
        );
        // Outgoing shared user of the folder from the members
        // This list for incoming will be incomplete and we should also reverse the names
        for (const member of members) {
            if (
                member?.tags
                    ?.filter((t) => t?.type === 'outgoing')
                    ?.map((t) => t?.tag?.tag_id)
                    ?.includes(category?.tag_id)
            ) {
                _members.push({
                    ...member,
                    tags: member?.tags?.filter(
                        (t) => t?.tag?.tag_id === category?.tag_id
                    ),
                });
            }
        }
        _members = _.uniqBy(_members, 'user.id');
        return _members;
    };

    const outgoingMembers = useMemo(() => {
        return members
            ?.map((m) => {
                if (m?.tags?.some((t) => t?.type === 'outgoing')) {
                    return {
                        ...m,
                        partial: 'outgoing',
                        tags: m?.tags?.filter((t) => t?.type === 'outgoing'),
                    };
                }
            })
            .filter((m) => m) as IAccountMember[];
    }, [members]);

    const incomingMembers = useMemo(() => {
        return members
            ?.map((m) => {
                if (m?.tags?.some((t) => t?.type === 'incoming')) {
                    return {
                        ...m,
                        partial: 'incoming',
                        tags: m?.tags?.filter((t) => t?.type === 'incoming'),
                    };
                }
            })
            .filter((m) => m) as IAccountMember[];
    }, [members]);

    return (
        <>
            <SharingListContext.Provider
                value={{
                    members,
                    getMembers,
                    setMemberAccess,
                    resendInvite,
                    acceptInvite,
                    getFolderShareInfo,
                    incomingMembers,
                    outgoingMembers,
                }}
            >
                {children}
            </SharingListContext.Provider>
            <ModalLarge
                visible={inviteModal.open}
                onClose={() =>
                    setInviteModal({ open: false, token: '', name: '' })
                }
                style={{
                    maxHeight: 700,
                }}
            >
                <div>
                    <Close
                        onClick={() =>
                            setInviteModal({ open: false, token: '', name: '' })
                        }
                        style={{
                            position: 'absolute',
                            top: 20,
                            right: 20,
                            cursor: 'pointer',
                        }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: 300,
                            height: 300,
                        }}
                    >
                        <FoldersDemo />
                    </div>
                    <div style={{ margin: 40 }} />
                    <div
                        style={{
                            fontSize: 18,
                            fontWeight: 'bold',
                        }}
                    >
                        Copy & Share Link to Invite {inviteModal?.name || ''}
                    </div>
                    <div style={{ margin: 5 }} />
                    <div
                        style={{
                            fontSize: 14,
                            textAlign: 'center',
                        }}
                    >
                        {inviteModal?.name || ''} must use this link to accept
                        these shared folders.
                    </div>
                    <div style={{ margin: 10 }} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: '#f5f5f5',
                                fontSize: 12,
                                padding: '10px 20px',
                                borderRadius: 10,
                                margin: 10,
                            }}
                        >
                            https://simplywise.com/link/
                            {inviteModal?.token}
                        </div>
                        <BottomButton
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    `https://simplywise.com/link/${inviteModal?.token}`
                                );
                                setSnackbar({
                                    message: 'Link copied, share it now!',
                                    status: 'success',
                                });
                            }}
                            text="Copy Link"
                        />
                    </div>
                </div>
            </ModalLarge>
        </>
    );
};

export const useSharing = () => useContext(SharingListContext);

export default SharingListContext;
