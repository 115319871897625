import { useState } from 'react';
import parsePhoneNumberFromString from 'libphonenumber-js';
import AuthService from '../../../services/auth/service';
import {
    onWebAppSubmitPasswordFailure,
    onWebAppSubmitPasswordSuccess,
} from '../../../tracking/trackers';
import * as Validators from '../../../util/validators';
import { useAuth } from '../../../providers/AuthProvider';

const useSetPassword = (
    mobile: string | undefined,
    code: string | undefined
) => {
    const { setToken } = useAuth();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [passwordIsSet, setPasswordIsSet] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    // sets the token in local storage to make requests as an authenticated user
    const setPassword = (password: string | undefined) => {
        if (!mobile || !code) {
            console.error('mobile or code not set');
            return;
        }
        setIsLoading(true);
        setErrorMessage('');
        const formattedMobile =
            parsePhoneNumberFromString(mobile, 'US')?.format(
                Validators.US_PHONE
            ) || '';
        AuthService.setPassword(formattedMobile, code, password)
            .then((res) => {
                if (res.token) {
                    setToken(res.token);
                    setPasswordIsSet(true);
                    setIsLoading(false);
                    onWebAppSubmitPasswordSuccess({ mobile: formattedMobile });
                    return;
                }
                AuthService.getToken(formattedMobile, password!)
                    .then(({ token }) => {
                        setToken(token);
                        // previously, we didn't await getting the token,
                        // but when users are doing the /payment route with sign up, setPasswordIsSet(true) triggers the payment page, and the payment page requires a token
                        if (res.status === 'success') {
                            onWebAppSubmitPasswordSuccess({
                                mobile: formattedMobile,
                            });
                            setPasswordIsSet(true);
                        }
                    })
                    .finally(() => setIsLoading(false));
            })
            .catch((err) => {
                onWebAppSubmitPasswordFailure({ mobile: formattedMobile });
                console.error('error in sendSignupLink:', err);
                setErrorMessage('Failed to set password');
                setIsLoading(false);
            });
    };

    return { isLoading, passwordIsSet, errorMessage, setPassword };
};

export default useSetPassword;
