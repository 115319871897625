import EmailCheck from '../../../../assets/onboarding/emailCheck.svg';
import OnboardingCardTemplate from '../../../../components/OnboardingCardTemplate';

// provides instructions after confirmation code has been sent to you
const ConfirmationCode = () => {
    return (
        <OnboardingCardTemplate
            title={'We texted you a link'}
            subtitle={`Open to confirm your phone number`}
            content={
                <img
                    src={EmailCheck}
                    alt="Email Check"
                    style={{ marginLeft: 20 }}
                />
            }
            buttons={[
                {
                    text: 'Open Messages',
                    onClick: () => {
                        window.location.href = 'messages:';
                    },
                },
            ]}
        />
    );
};

export default ConfirmationCode;
