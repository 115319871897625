import { Avatar } from '@mui/material';
import { useAuth } from '../../providers/AuthProvider';
import theme from '../../theme';

export interface IUserAvatarProps {
    size?: number;
    color?: string;
    style?: React.CSSProperties;
}

export default function UserAvatar(props: IUserAvatarProps) {
    const { user } = useAuth();

    const userPrefix = user ? user.first_name.charAt(0) : 'A';
    const _color = props.color ? props.color : theme.colors.secondary;
    const style = props.style ? props.style : {};

    return <Avatar sx={{ bgcolor: _color, ...style }}>{userPrefix}</Avatar>;
}
