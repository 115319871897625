import React from 'react';
import styled from 'styled-components';
import featuredMetrics3 from '../../assets/onboarding/featuredMetrics3_updated.svg';
import featuredMetrics2 from '../../assets/onboarding/featuredMetrics2_updated.svg';
import Devices from '../../util/devices';

export const MetricsShowcase = ({
    style,
    mode = 'full',
    variant = 'two_metrics',
}: {
    mode?: 'full' | 'reduced';
    variant?: 'two_metrics' | 'three_metrics';
    style?: React.CSSProperties;
}) => {
    const source =
        variant === 'two_metrics' ? featuredMetrics2 : featuredMetrics3;

    return (
        <MetricContainerStyle style={style}>
            {mode === 'full' ? (
                <MetricImageFullStyle src={source} alt="Metrics Showcase" />
            ) : (
                <MetricImageReducedStyle src={source} alt="Metrics Showcase" />
            )}
        </MetricContainerStyle>
    );
};

const MetricContainerStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${Devices.TABLET_SM} {
        justify-content: flex-start;
    }
`;

const MetricImageFullStyle = styled.img`
    width: 300px;

    @media ${Devices.MOBILE_LG} {
        width: 400px;
    }

    @media ${Devices.TABLET_SM} {
        width: 580px;
    }

    @media ${Devices.LAPTOP} {
        width: 620px;
    }
`;

const MetricImageReducedStyle = styled.img`
    width: 280px;

    @media ${Devices.MOBILE_LG} {
        width: 320px;
    }

    @media ${Devices.TABLET_SM} {
        width: 380px;
    }

    @media ${Devices.LAPTOP} {
        width: 620px;
    }
`;
