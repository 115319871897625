export const getTextWidth = (text: string, fontSize: number): number => {
    const el = document.createElement('span');
    document.body.appendChild(el);

    // el.style.font = 'times new roman';
    el.style.fontSize = fontSize + 'px';
    el.style.height = 'auto';
    el.style.width = 'auto';
    el.style.position = 'absolute';
    el.style.whiteSpace = 'no-wrap';
    el.innerHTML = text;

    const width = Math.ceil(el.clientWidth);

    document.body.removeChild(el);

    return width;
};
