import { CircularProgress } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { SearchInput } from '../../../components/Inputs';
import { Body, ChargeFinderHeaderText } from '../../../components/Text';
import { useServerState } from '../../../providers/ServerStateProvider';
import PlaidService from '../../../services/plaid';
import { IChargeFinderMerchant } from '../../../services/plaid/types';
import theme from '../../../theme';
import ChargeFinderHeader from './ChargeFinderHeader';
import { BORDER_RADIUS } from './constants';
import './index.css';
import { ChargeFinderBodyContainer } from './styles';

const ChargeFinder = () => {
    const serverState = useServerState();

    const [search, setSearch] = useState<string>('');
    const [merchants, setMerchants] = useState<
        IChargeFinderMerchant[] | undefined
    >(serverState?.merchants);

    useEffect(() => {
        if (!merchants) {
            PlaidService.getMerchants()
                .then((data) => {
                    setMerchants(data?.data);
                })
                .catch((err) => {
                    console.log('Error finding merchants', err);
                });
        }
    }, [merchants]);

    const filteredMerchants = useMemo(() => {
        if (!search.trim()) return merchants;
        return merchants?.filter(
            (merchant) =>
                merchant.merchant
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                merchant.slug.toLowerCase().includes(search.toLowerCase())
        );
    }, [merchants, search]);

    const groupMerchantsAlphabetically = (
        merchants: IChargeFinderMerchant[] | undefined
    ) => {
        const groupedMerchants: { [key: string]: IChargeFinderMerchant[] } = {};
        merchants?.sort((a, b) => a.merchant.localeCompare(b.merchant));
        merchants?.forEach((merchant) => {
            const firstLetter = merchant.merchant[0].toUpperCase();
            if (!firstLetter.match(/[A-Z]/)) {
                groupedMerchants['#'] = groupedMerchants['#'] || [];
                groupedMerchants['#'].push(merchant);
            } else {
                groupedMerchants[firstLetter] =
                    groupedMerchants[firstLetter] || [];
                groupedMerchants[firstLetter].push(merchant);
            }
        });
        return groupedMerchants;
    };

    const groupedMerchants = useMemo(
        () => groupMerchantsAlphabetically(filteredMerchants),
        [filteredMerchants]
    );

    if (!merchants)
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </div>
        );

    return (
        <>
            <Helmet>
                <title>What's this charge? | SimplyWise</title>
                <meta
                    name="description"
                    content="Find any charge on your statement | SimplyWise"
                />
                <link
                    rel="canonical"
                    href="https://www.simplywise.com/whats-this-charge"
                />
            </Helmet>
            <ChargeFinderHeader />
            <ChargeFinderBodyContainer>
                <div
                    style={{
                        backgroundColor: theme.colors.primary,
                        width: '100%',
                        padding: '10% 5%',
                    }}
                >
                    <ChargeFinderHeaderText
                        style={{
                            textAlign: 'center',
                            fontSize: '3rem',
                            color: 'white',
                        }}
                    >
                        What's this charge?
                    </ChargeFinderHeaderText>
                    <div style={{ height: 25 }} />
                    <div style={{ maxWidth: 900, margin: 'auto' }}>
                        <SearchInput
                            search={search}
                            setSearch={setSearch}
                            placeholder={'Search for a merchant'}
                            searchBarStyle={{
                                borderRadius: BORDER_RADIUS,
                                border: '1px solid #E0E0E0',
                                paddingLeft: 60,
                            }}
                            searchHeight={60}
                            searchIconStyle={{
                                top: 16,
                                width: 28,
                                height: 28,
                                left: 22,
                                color: 'black',
                            }}
                            disableX
                            style={{ borderRadius: 25 }}
                        />
                    </div>
                </div>
                <div style={{ height: 25 }} />
                <div style={{ maxWidth: 900, margin: 'auto' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        {Object.keys(groupedMerchants)
                            .filter(
                                (letter) => groupedMerchants[letter].length > 0
                            )
                            .map((letter) => (
                                <div
                                    key={letter}
                                    style={{
                                        display: 'flex',
                                        padding: 20,
                                        flexDirection: 'column',
                                        width: 180,
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            padding: '20px 0',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Body
                                            style={{
                                                textAlign: 'left',
                                                fontWeight: 'bold',
                                                fontSize: '2.5rem',
                                            }}
                                        >
                                            {letter}
                                        </Body>
                                        <div
                                            style={{
                                                borderBottom:
                                                    '1px solid #E0E0E0',
                                            }}
                                        />
                                    </div>
                                    {groupedMerchants[letter].map(
                                        (merchant) => (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    padding: 10,
                                                    flexDirection: 'column',
                                                    justifyContent:
                                                        'space-between',
                                                    width: '100%',
                                                }}
                                                key={merchant.slug}
                                            >
                                                <Body
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    <a
                                                        style={{
                                                            textDecoration:
                                                                'none',
                                                            color: 'gray',
                                                        }}
                                                        href={`/whats-this-charge/${merchant.slug}`}
                                                    >
                                                        {merchant.merchant}
                                                    </a>
                                                </Body>
                                            </div>
                                        )
                                    )}
                                </div>
                            ))}
                    </div>
                </div>
            </ChargeFinderBodyContainer>
        </>
    );
};

export default ChargeFinder;
