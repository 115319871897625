import { useMediaQuery } from '@mui/material';
import { lazy, Suspense } from 'react';
import { IChargeFinderCodeData } from '../Code';
import {
    parseCodeDescription,
    parseStoryDescription,
    scamStorySecondSection,
} from '../helper';
// import FraudLevelNormal from '../../../../assets/chargeFinder/mediumFraud.svg';
// import VerifiableReceipt from '../../../../assets/chargeFinder/verifiableWithReceipt.svg';
import StoryImage from '../../../../assets/png/wtc/story_image_00.png';
import { BodyContainer } from '../styles';
import BlogPostComments from './BlogPostComments';
import BlogPostFooter from './BlogPostFooter';
import BlogPostHeader from './BlogPostHeader';
import CTASection from './CTASection';

const AnimatedGauge = lazy(() => import('./AnimatedGauge'));
const ScamStory = lazy(() => import('./ScamStory'));

const CodeScript = ({
    data,
    variant,
}: {
    data: IChargeFinderCodeData;
    variant?: string;
}) => {
    const code = data.codes?.code ?? '';
    const codeGptDescription = parseCodeDescription(
        data.codes?.metadata.description ?? ''
    );
    const merchantGptDescription = data.merchant?.metadata.description ?? '';
    const story = data.merchant?.metadata.story ?? '';
    const lastSection = data.merchant?.metadata.conclusion ?? '';
    const merchant = data.merchant?.merchant ?? '';
    const commonCodes = data?.merchantCommonCodes;

    const isMobile = useMediaQuery('(max-width: 900px)');

    const isScamStory = variant?.includes('scam') || variant?.includes('mix');
    const isMix = variant?.includes('mix');

    const scamStory = parseStoryDescription(story, isMobile, code, StoryImage);

    const secondSection = parseStoryDescription(
        scamStorySecondSection,
        isMobile,
        code
    );

    const conclusion = parseStoryDescription(lastSection, isMobile, code);

    return (
        <div>
            <BodyContainer>
                <div style={{ height: 10 }} />
                <BlogPostHeader
                    title={`Is "${code}" fraud?`}
                    isLoading={!code}
                    authorObject={data?.codes?.author_object}
                    date={data?.codes?.updated_at}
                />
                <div
                    style={{
                        height: 2,
                        width: '100%',
                        backgroundColor: '#E5E5E5',
                        borderRadius: 100,
                    }}
                />
                <div style={{ height: 20 }} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                    }}
                >
                    {process.isServer ? null : (
                        <Suspense>
                            <AnimatedGauge
                                code={data?.codes}
                                variant={variant}
                            />
                        </Suspense>
                    )}
                </div>
                {isScamStory ? (
                    process.isServer ? null : (
                        <Suspense>
                            {isMix && (
                                <div style={{ fontSize: '0.7em' }}>
                                    <h2>
                                        About "<b>{code}</b>"
                                    </h2>
                                    {merchantGptDescription}
                                    <div style={{ height: 20, width: 20 }} />
                                    {codeGptDescription}
                                </div>
                            )}
                            <ScamStory
                                merchant={merchant}
                                scamStory={scamStory}
                                secondSection={secondSection}
                                conclusion={conclusion}
                                variant={variant}
                                code={data?.codes}
                            />
                        </Suspense>
                    )
                ) : (
                    <div style={{ fontSize: '0.7em' }}>
                        <h2>
                            About "<b>{code}</b>"
                        </h2>
                        {merchantGptDescription}
                        <div style={{ height: 20, width: 20 }} />
                        {codeGptDescription}
                    </div>
                )}
                {!isScamStory && !isMix && (
                    <CTASection code={data?.codes} variant={variant} />
                )}
            </BodyContainer>
            <div style={{ height: 40 }} />
            <BlogPostComments code={data?.codes} />
            <BlogPostFooter code={data?.codes} commonCodes={commonCodes} />
        </div>
    );
};

export default CodeScript;
