import { getCurrencyByCode } from './currencies';

export const convertToNativeCurrency = (
    n: number | null | undefined,
    exchangeRate: number
) => {
    let num = n || 0;
    if (exchangeRate) {
        num *= exchangeRate;
    }
    return num.toFixed(2);
};

/**
 * Formats amount to 2 decimal points, dollar sign, and commas.
 */
export const formatAmount = (
    n: number | null | undefined,
    toFixed?: number, // pass to override decimal places
    currency?: string, // pass to display the currency symbol
    exchangeRate?: number // pass to convert to USD
) => {
    let num = n || 0;
    const negative = num < 0;
    if (negative) num *= -1;
    if (exchangeRate) {
        num /= exchangeRate;
    }
    const fixed = toFixed || 2;
    const currencyData = getCurrencyByCode(currency || 'USD');
    return `${negative ? '-' : ''}${currencyData.symbol}${num
        .toFixed(fixed)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export const formatCurrency = (n: number) => {
    const num = n || 0;
    return formatter.format(num);
};
