import { createContext, useContext } from 'react';
import { IProcessingReceipt, IReceipt } from '../../services/receipts/types';
import { SharedSpreadsheetDataProps } from '../../components/Spreadsheet/context';
import { IColumn } from '../../components/Spreadsheet/constants';
import useInboxColumns from './useInboxColumns';
import useInboxReceipts from './useInboxReceipts';

type InboxReceiptUnion =
    | (IReceipt & { type: 'stored' })
    | (IProcessingReceipt & { type: 'processing' });

export type IInboxReceipt = InboxReceiptUnion & SharedSpreadsheetDataProps;

type IInboxSpreadsheetProps = { isTextingFlow?: boolean };

type IInboxContext = {
    inboxReceipts: IInboxReceipt[];
    columns: IColumn[];
    isLoadingInboxReceipts: boolean;
    refetch: () => Promise<void>;
    spreadsheetKey: string;
} & IInboxSpreadsheetProps;

const spreadsheetKey = 'inbox';

const InboxContext = createContext<IInboxContext>({
    inboxReceipts: [],
    columns: [],
    isLoadingInboxReceipts: false,
    refetch: () => Promise.resolve(),
    spreadsheetKey,
});

export const InboxProvider = (props: { children: React.ReactNode }) => {
    const { inboxReceipts, isLoadingInboxReceipts, refetch } =
        useInboxReceipts();
    const { columns } = useInboxColumns({
        spreadsheetKey,
        inboxReceipts,
    });

    return (
        <InboxContext.Provider
            value={{
                inboxReceipts,
                columns,
                isLoadingInboxReceipts,
                refetch,
                spreadsheetKey,
                ...props,
            }}
        >
            {props.children}
        </InboxContext.Provider>
    );
};

export const useInbox = () => useContext(InboxContext);
