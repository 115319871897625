import React, { Suspense, lazy } from 'react';

import LeafSketchLoopBlue from '../../assets/animations/loading/LeafLoadingSketch-Blue.json';
import LeafSketchLoopWhite from '../../assets/animations/loading/LeafLoadingSketch-White.json';
import LeafSketchLoopGray from '../../assets/animations/loading/LeafLoadingSketch-Gray.json';

const Lottie = lazy(() => import('lottie-react'));

type iLoadingLeafProps = {
    size?: LoadingLeafSize;
    color?: 'white' | 'blue' | 'disabled';
    style?: React.CSSProperties;
};

export enum LoadingLeafSize {
    SMALL = 35,
    MEDIUM = 55,
    LARGE = 100,
    XLARGE = 180,
}

const LoadingLeaf = (props: iLoadingLeafProps) => {
    const { size, color, style } = props;

    const source =
        color === 'white'
            ? LeafSketchLoopWhite
            : color === 'disabled'
            ? LeafSketchLoopGray
            : LeafSketchLoopBlue;

    let width = size || LoadingLeafSize.LARGE;

    return (
        <div style={{ width }}>
            <Suspense>
                <Lottie
                    animationData={source}
                    autoPlay={true}
                    loop={true}
                    style={style}
                />
            </Suspense>
        </div>
    );
};

export default LoadingLeaf;
