//@ts-nocheck - window.uetq events are not typed

const sendUetqEvent = (event: string) => {
    window.uetq = window.uetq || [];
    window.uetq.push?.('event', event, {});
};

const uetqEvents = {
    beginCheckout: () => sendUetqEvent('begin_checkout'),
    signup: () => sendUetqEvent('signup'),
    subscribe: () => sendUetqEvent('subscribe'),
    addToCart: () => sendUetqEvent('add_to_cart'),
};

export default uetqEvents;
