import { BodyBold } from '../../Text';

export const SectionsTitle = ({ title }: { title: string }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
            }}
        >
            <BodyBold
                style={{
                    marginTop: '5%',
                    fontWeight: 'bold',
                    fontSize: '2.5rem',
                }}
            >
                {title}
            </BodyBold>
        </div>
    );
};
