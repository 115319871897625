import { useState } from 'react';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { useLocation } from 'react-router-dom';
import AuthService from '../../../services/auth/service';
import {
    onWebAppSignupUrlRequested,
    onWebAppSignupUrlSent,
    onWebAppSendCodeFailure,
    onWebAppSendCodeSuccess,
} from '../../../tracking/trackers';
import identify from '../../../tracking/identify';
import multiChannelEvents from '../../../tracking/multiChannelEvents';
import * as Validators from '../../../util/validators';
import { Experience } from '../../../services/receipts/types';

const useSendCode = (
    setMobileInContext: (m: string) => void,
    isManualCodeEntry?: boolean,
    name?: string,
    shareToken?: string,
    organizationToken?: string,
    experience?: Experience,
    email?: string,
    isOauthFlow?: boolean
) => {
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [codeIsSent, setCodeIsSent] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const originWebUrl = localStorage.getItem('originWebUrl');

    const onSuccess = (method: 'link' | 'manual', mobile: string) => {
        onWebAppSendCodeSuccess({
            method,
            mobile,
            originWebUrl: originWebUrl || 'none',
        }); // TODO: this is not submitting a code, it's sending a code
        multiChannelEvents.signup();
        setCodeIsSent(true);
    };

    const onFailure = (
        method: 'link' | 'manual',
        mobile: string,
        message?: string
    ) => {
        onWebAppSendCodeFailure({
            method,
            mobile,
            originWebUrl: originWebUrl || 'none',
        }); // TODO: this is not submitting a code, it's sending a code
        setErrorMessage(message || '');
    };

    const sendSignupLink = (mobile: string) => {
        onWebAppSignupUrlRequested({ mobile });
        AuthService.requestSignupUrl(
            mobile,
            originWebUrl || '', // we could send error but would rather it proceed without tracking than not proceed at all
            shareToken,
            organizationToken,
            pathname
        )
            .then((res) => {
                if (res.status === 'success') {
                    onSuccess('link', mobile);
                    onWebAppSignupUrlSent({
                        mobile,
                        origin_web_url: originWebUrl,
                    });
                } else {
                    onFailure('link', mobile);
                    setErrorMessage(res.error || 'An error has occured');
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const sendCodeForManualEntry = (mobile: string) => {
        Validators.PHONE_NUMBER.validate(mobile)
            .then(() => {
                if (isOauthFlow) {
                    AuthService.requestMobileChange(mobile)
                        .then((data) => {
                            onSuccess('manual', mobile);
                        })
                        .catch(async (err) => {
                            try {
                                // This will send them a new code in case they try reregistering with the same number they already used.
                                const requestCodeRes =
                                    await AuthService.requestPasswordReset(
                                        mobile
                                    );
                                if (requestCodeRes.error) {
                                    setErrorMessage(err.error);
                                    return;
                                }
                                onSuccess('manual', mobile);
                            } catch {
                                setErrorMessage(
                                    'That number is already in use.'
                                );
                            }
                        })
                        .finally(() => setIsLoading(false));
                }
                // Check that account exists. If failure, register new user
                else
                    AuthService.register(
                        mobile,
                        name,
                        shareToken,
                        organizationToken,
                        experience,
                        email
                    )
                        .then((data) => {
                            if (data.error) {
                                // Don't think this would ever trigger since axios throws error when not 20X
                                throw Error();
                            }
                            if (data.data) {
                                onSuccess('manual', mobile);
                            }
                        })
                        .catch(async (err) => {
                            try {
                                // This will send them a new code in case they try reregistering with the same number they already used.
                                const requestCodeRes =
                                    await AuthService.requestPasswordReset(
                                        mobile
                                    );
                                if (requestCodeRes.error) {
                                    setErrorMessage(err.error);
                                    return;
                                }
                                onSuccess('manual', mobile);
                            } catch {
                                setErrorMessage(
                                    'That number is already in use.'
                                );
                            }
                        })
                        .finally(() => setIsLoading(false));
            })
            .catch((err) => {
                onFailure('manual', mobile);
                console.error('sendCodeForManualEntry error:', err);
                setErrorMessage('Unable to validate phone number.');
            });
    };

    const sendCodeToMobile = (mobile: string) => {
        setIsLoading(true);
        setErrorMessage('');
        const formattedMobile =
            parsePhoneNumberFromString(mobile, 'US')?.format(
                Validators.US_PHONE
            ) || '';
        setMobileInContext(formattedMobile);
        identify(formattedMobile);
        if (isManualCodeEntry) {
            sendCodeForManualEntry(formattedMobile);
        } else {
            sendSignupLink(formattedMobile);
        }
    };

    return {
        isLoading,
        codeIsSent,
        errorMessage,
        sendCodeToMobile,
    };
};

export default useSendCode;
