import { ReactElement } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import theme from '../../theme';
import BackgroundImage from '../../assets/onboardingBackground.png';
import LogoWhite from '../../assets/landing/logo_white.svg';

export const ONBOARDING_HEADER_HEIGHT = 80;

// When using this, should render the child component in the "OnboardingCardTemplate" template
const OnboardingBackground = ({
    children,
}: {
    children: ReactElement | ReactElement[];
}) => {
    const { width } = useWindowDimensions();
    const isDesktop = width > 768;
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundImage: `url(${BackgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                width,
                height: '100vh',
            }}
        >
            <div
                style={{
                    minHeight: ONBOARDING_HEADER_HEIGHT,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <img
                    style={{
                        display: 'flex',
                        alignSelf: 'center',
                        width: 120,
                    }}
                    src={LogoWhite}
                    alt={''}
                />
            </div>
            {isDesktop ? (
                <div
                    style={{
                        height: '100%',
                        backgroundColor: theme.colors.screen,
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            marginTop: 20,
                            backgroundColor: theme.colors.white,
                            width: 600,
                            borderRadius: 40,
                            paddingTop: 40,
                            paddingBottom: 40,
                            paddingRight: 80,
                            paddingLeft: 80,
                            height: '80vh',
                        }}
                    >
                        {children}
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        height: '100%',
                        borderTopLeftRadius: 40,
                        borderTopRightRadius: 40,
                        backgroundColor: theme.colors.white,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingTop: 40,
                        paddingBottom: 40,
                    }}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

export default OnboardingBackground;
