import { useEffect, useState } from 'react';
import EReceiptsService from '../../../services/ereceipts/service';
import { onWebAppOnboardingEmailsFound } from '../../../tracking/trackers';
import { useQuery } from '../../../util/hooks';

const MAX_ATTEMPTS = 60;

export const useCreateEmailReceipts = (userDidConnectEmail: boolean) => {
    const query = useQuery();
    const emailsCount = query.get('emails_count'); // if defined, means it's the redirect link from google oauth
    const email = query.get('email'); // if defined, means it's the redirect link from google oauth

    const token = localStorage.getItem('token');

    const [importState, setImportState] = useState<string>('NOT_STARTED');

    // When token is found, start polling for processing receipts
    useEffect(() => {
        let intervalId: ReturnType<typeof setInterval> | undefined;
        let attempts = 0;
        const wipeInterval = () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
        if (token && userDidConnectEmail) {
            intervalId = setInterval(() => {
                if (attempts >= MAX_ATTEMPTS) {
                    setImportState('NO_RECEIPTS');
                    wipeInterval();
                    return;
                }
                EReceiptsService.gmailImportResult(email || '')
                    .then((response) => {
                        if (response.status === 'success') {
                            if (response.data.state !== importState)
                                setImportState(response.data.state);
                            if (
                                response.data.state === 'IMPORTED' ||
                                response.data.state === 'NO_RECEIPTS'
                            )
                                wipeInterval();
                        }
                    })
                    .finally(() => (attempts += 1));
            }, 1000);
        } else {
            wipeInterval();
        }
        return wipeInterval;
    }, [token, userDidConnectEmail]);

    useEffect(() => {
        if (importState === 'IMPORTED' || importState === 'NO_RECEIPTS') {
            onWebAppOnboardingEmailsFound({
                email: email || '',
                emailCount: parseInt(emailsCount || '', 10) || 0,
                import_state: importState,
            });
        }
    }, [importState]);

    return { importState };
};
