import styled from 'styled-components';
import Devices from '../../../util/devices';

export const CTAAlignmentWrapper = styled.div<{ centerAlways?: boolean }>`
    padding-in-line: 16px;
    width: 100%;
    display: flex;
    justify-content: center;

    @media ${Devices.TABLET} {
        justify-content: ${(props) =>
            props.centerAlways ? 'center' : 'flex-start'};
    }
`;
