import { Body } from '../Text';
import { HeaderButtonContainer } from './styles';

export type IHeaderButton = {
    text: string;
    icon: React.ReactNode;
    onClick: () => void;
    style?: React.CSSProperties;
};

const HeaderButton = ({ text, icon, onClick, style }: IHeaderButton) => {
    return (
        <HeaderButtonContainer onClick={onClick}>
            <div
                style={{
                    display: 'flex',
                    marginRight: 10,
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    ...style,
                }}
            >
                {icon}
            </div>
            <Body style={{ fontWeight: '600', lineHeight: 1 }}>{text}</Body>
        </HeaderButtonContainer>
    );
};

export default HeaderButton;
