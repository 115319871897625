import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header';
import navigationActions from '../../../util/navigationActions';

const ChargeFinderHeader = ({ code }: { code?: string | undefined }) => {
    const navigate = useNavigate();
    return (
        <Header
            navigatePath={'/'}
            style={{ overflow: 'hidden', boxShadow: 'none' }}
            showDownload
            downloadLabel={'Download App'}
            downloadWebUrl="/link/chargefinder"
            onClickDownload={() => {
                const { url, options } = navigationActions.chargeFinderCTA(
                    'header',
                    code
                );
                navigate(url, options);
            }}
        />
    );
};

export default ChargeFinderHeader;
