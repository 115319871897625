import React, { createContext, useContext, useState } from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import theme from '../theme';
import { Body } from '../components/Text';

const defaultSnackbarState: ISnackbarState = {
    message: '',
    duration: 4000,
    status: 'success',
};

export type ISnackbarState = {
    message: string;
    duration?: number;
    status?: AlertColor;
    delay?: number;
    link?: {
        text: string;
        onClick: () => void;
    };
};

interface ISnackbarProps {
    setSnackbar: (state: ISnackbarState) => void;
}

const SnackbarContext = createContext<ISnackbarProps>({
    setSnackbar: () => {},
});

const SnackbarProvider = (props: { children: React.ReactNode }) => {
    const [open, setOpen] = useState(false);
    const [snackbar, _setSnackbar] =
        useState<ISnackbarState>(defaultSnackbarState);
    const [nodeTimeout, setNodeTimeout] = useState<NodeJS.Timeout | null>(null);

    const handleClose = (e?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const status = snackbar.status ?? 'success';

    const renderMe = (
        <Snackbar
            open={open}
            onClose={handleClose}
            autoHideDuration={snackbar.duration}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <Alert
                severity={snackbar.status ?? 'success'}
                variant="filled"
                sx={{
                    width: '100%',
                    backgroundColor:
                        status === 'success' ? theme.colors.blue100 : undefined,
                    borderRadius: 3,
                    fontWeight: '700',
                    fontSize: 16,
                    paddingRight: 3,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                icon={<></>}
            >
                {snackbar.message.split('\n').map((m, index) => (
                    <div key={index.toString()}>{m}</div>
                ))}
                {snackbar.link ? (
                    <div
                        onClick={() => {
                            setOpen(false);
                            snackbar.link?.onClick?.();
                        }}
                    >
                        <Body
                            style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                        >
                            {snackbar.link.text}
                        </Body>
                    </div>
                ) : null}
            </Alert>
        </Snackbar>
    );

    const setSnackbar = (state: ISnackbarState) => {
        setTimeout(() => {
            setOpen(true);
            _setSnackbar(state);
            nodeTimeout && clearTimeout(nodeTimeout);
            setNodeTimeout(
                setTimeout(() => {
                    handleClose();
                    setNodeTimeout(null);
                }, state.duration ?? 4000)
            );
        }, state.delay ?? 0);
    };

    return (
        <SnackbarContext.Provider value={{ setSnackbar }}>
            {props.children}
            {renderMe}
        </SnackbarContext.Provider>
    );
};

export default SnackbarProvider;

export const useSnackbar = () => useContext(SnackbarContext);
