import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IReceipt } from '../../services/receipts/types';
import ReportService from '../../services/sharedreviews/service';
import { Space } from '../../services/spaces/types';
import filterDuplicates from '../../util/filterDuplicates';

const PAGE_SIZE = 50;

export const useReportReceipts = () => {
    const { id } = useParams();

    const [hasNextPage, setHasNextPage] = useState<boolean>(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [receipts, setReceipts] = useState<IReceipt[]>([]);
    const [spaces, setSpaces] = useState<Space[]>([]);
    const [isLoadingFirstReceipt, setIsLoadingFirstReceipt] =
        useState<boolean>(true);
    const [isLoadingReceipts, setIsLoadingReceipts] = useState<boolean>(true);

    const loadPaginated = () => {
        if (hasNextPage) {
            ReportService.loadReceiptsPaginated(
                id,
                PAGE_SIZE,
                currentPage
            ).then((res) => {
                const rs = res.data.results;
                if (!res.data.has_next_page) {
                    setHasNextPage(false);
                }
                setReceipts(rs);

                const newSpaces = Object.values(res.data.spaces ?? {});
                const noDuplicateSpaces = filterDuplicates<Space>([
                    ...spaces,
                    ...newSpaces,
                ]);
                const sortedSpaces = noDuplicateSpaces.sort(
                    (a, b) => (a?.order || 0) - (b.order || 0)
                );
                setSpaces(sortedSpaces);
                setCurrentPage(currentPage + 1);
                setIsLoadingFirstReceipt(false);
            });
        }
    };

    // Load ALL receipts, but paginatedly so don't have to wait to see something on screen
    // If we do lazy load only (on scroll to bottom), it makes the category totals potentially wrong since it fetches only part of a month
    useEffect(() => {
        if (hasNextPage) {
            loadPaginated();
        } else {
            setIsLoadingFirstReceipt(false);
            setIsLoadingReceipts(false);
        }
    }, [currentPage]);

    return {
        receipts,
        setReceipts,
        spaces,
        isLoadingReceipts,
        isLoadingFirstReceipt,
    };
};
