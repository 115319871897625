import styled from 'styled-components';
import { FieldName } from '../../components/Text';
import theme from '../../theme';

export const ReceiptListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    position: sticky;
    z-index: 2;
`;

export const StyledOverline = styled.p`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 10.7561px;
    line-height: 0px;
    letter-spacing: 1.61342px;
    text-transform: uppercase;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
`;

export const StyledReceiptText = styled.p`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 17.2098px;
    line-height: 1;
    letter-spacing: 0.161342px;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
`;

export const StyledReceiptAmount = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16.1342px;
    line-height: 26px;
    letter-spacing: 0.161342px;
    align-self: center;
`;

export const StyledStickyHeaderText = styled.span`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 17.2098px;
    line-height: 26px;
    letter-spacing: 0.107561px;
    color: #000000;
`;

export const ReceiptListItemName = ({
    children,
}: {
    children: React.ReactNode;
}) => (
    <FieldName
        style={{
            textOverflow: 'ellipsis',
            textAlign: 'left',
            fontWeight: 600,
        }}
    >
        {children}
    </FieldName>
);

export const ReceiptListItemContainer = styled.div<{
    selected?: boolean;
}>`
    display: flex;
    align-items: center;
    border-width: 0px;
    outline-color: ${theme.colors.secondary};
    outline-width: ${(props) => (props.selected ? 2 : 0)};
    outline-style: solid;
    outline-offset: -4px;
    background-color: ${theme.colors.white};
    border-radius: 10px;

    &:hover {
        cursor: pointer;
        background-color: ${theme.colors.background};
        z-index: 100;
        border-radius: ${(props) => (props.selected ? '10px' : '0px')};
    }
`;

export const SpreadsheetScreenContainer = styled.div`
    background-color: ${theme.colors.background};
    display: flex;
    flex-direction: column;
`;

export const SpreadsheetContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-left: 0px;
    flex-direction: column;
`;
