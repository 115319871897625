import { AxiosResponse } from 'axios';
import axios, { axios_retry } from '../../util/axios';
import { IImagetoPDFResponse, ITaxRateResponse } from './types';

export default class MiscService {
    public static getTaxRate(zipcode: string): Promise<ITaxRateResponse> {
        return axios
            .post('/misc/get-tax-rate', { zipcode })
            .then((value: AxiosResponse<ITaxRateResponse>) => value.data);
    }

    public static uploadImageToPDF(
        formData: FormData
    ): Promise<IImagetoPDFResponse> {
        return axios
            .post('/misc/pdf-from-image', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((value: AxiosResponse<IImagetoPDFResponse>) => value.data);
    }

    public static submitTrackingEvent(
        event: string,
        eventParams: any
    ): Promise<any> {
        const token = localStorage.getItem('token');
        return axios
            .post(
                '/misc/submit-tracking-event',
                {
                    event: event,
                    properties: eventParams,
                },
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            )
            .then((value: AxiosResponse<any>) => value.data);
    }

    public static getFileURL(id: string): Promise<string> {
        return axios_retry
            .post(
                `misc/fileurl/get`,
                {
                    file_id: id,
                },
                // @ts-ignore
                { retry: 28, retryDelay: 5000 }
            )
            .then(async (value) => {
                const url = value.data.data.url;
                if (
                    // @ts-ignore
                    window.ReactNativeWebView &&
                    // @ts-ignore
                    !window.disableInternalDownload
                ) {
                    try {
                        const base64 = await fetch(url, {
                            mode: 'cors',
                        })
                            .then((response) => response.blob())
                            .then((blob) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(blob);
                                return new Promise((res) => {
                                    reader.onloadend = () => {
                                        res(reader.result);
                                    };
                                });
                            });
                        //@ts-ignore
                        window.ReactNativeWebView.postMessage(
                            JSON.stringify({
                                type: 'download',
                                url: base64,
                                filename: url.split('/').pop().split('?')[0],
                            })
                        );
                    } catch {}
                } else {
                    let a = document.createElement('a');
                    a.href = url;
                    document.body.appendChild(a);
                    a.click();
                }
                return url;
            });
    }
}
