import { setUserId } from '@amplitude/analytics-browser';
import LogRocket from 'logrocket';
import unformatPhoneInput from '../util/unformatMobile';

const identify = (mobile: string) => {
    const formattedMobile = unformatPhoneInput(mobile);

    try {
        setUserId(formattedMobile);
    } catch (e) {
        console.error('Error identifying with Amplitude', e);
    }

    try {
        LogRocket.identify(formattedMobile);
    } catch (e) {
        console.error('Error identifying with LogRocket', e);
    }

    return formattedMobile;
};

export default identify;
