import axios from 'axios';
import { EXPORT_SERVICE_BASE_URL } from '../../configuration';
import ReportService from '../sharedreviews/service';

export default class ExportService {
    public static async downloadZip(
        identifier: string | undefined,
        imageIds: string[],
        filesName: string[] = []
    ): Promise<Blob> {
        identifier = identifier || localStorage.getItem('token') || undefined;
        const signature = await ReportService.exportSignature(
            identifier,
            JSON.stringify(imageIds)
        );

        return axios({
            url: `${EXPORT_SERVICE_BASE_URL}/zip`,
            method: 'POST',
            data: {
                image_ids: imageIds,
                file_names: filesName,
                signature,
            },
            responseType: 'blob',
        }).then((r) => {
            return r.data;
        });
    }
}
